import React from 'react';
import Icon from '@ant-design/icons';


const VideoCircleSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <g>
            <path d="M24.4,7.4C15,7.4,7.4,15.1,7.4,24.5c0,9.4,7.7,17.1,17.1,17.1c9.4,0,17.1-7.7,17.1-17.1C41.5,15.1,33.9,7.4,24.4,7.4z
			 M24.4,39.6c-8.3,0-15.1-6.8-15.1-15.1S16.1,9.4,24.4,9.4c8.3,0,15.1,6.8,15.1,15.1S32.7,39.6,24.4,39.6z"/>
        </g>
        <path d="M20,24.8v-5.3c0-0.9,0.9-1.4,1.7-1l4.6,2.6l4.6,2.6c0.8,0.4,0.8,1.5,0,2l-4.6,2.6L21.7,31C21,31.4,20,30.9,20,30V24.8z"/>
    </svg>
);


const VideoIcon = props => {


    return <Icon component={ VideoCircleSvg } className={ props.className }/>;

};


export default VideoIcon;