import React from "react";
import { NavLink } from "react-router-dom";

import { useVarParam } from "components/use-hooks";
import Icons from "components/icons";

import "./favorite-menu-bar.scss";


const FavoriteMenuBar = () => {

    return(
        <nav className="favorite-menu-bar menu-bar">
            <ul>
                { useVarParam().get("productsOn") &&
                    <li>
                        <NavLink to={`/favorite/products`}>
                            <Icons.Product />
                            <span>Products</span>
                        </NavLink>
                    </li>
                }
                <li>
                    <NavLink to={`/favorite/companies`}>
                        <Icons.Company />
                        <span>Companies</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`/favorite/users`}>
                        <Icons.Team />
                        <span>Contacts</span>
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
};

export default FavoriteMenuBar;