//const  idRegExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#&?]*).*/;
const  idRegExp = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/;


const parseVideoId = (url) => {
    const  match = url.match(idRegExp);

    return (match && match[5].length >= 10 && match[5].length <= 12) ? match[5] : false;
};

const embedLink = (url) => {
    return `https://www.youtube.com/embed/${ parseVideoId(url) }`;
};

const youtubeUrl = {
    parse : parseVideoId,
    embed : embedLink,
    idRegExp,
};

export default youtubeUrl;