import React from "react";
import { Select } from "antd";

import { authParamsVar } from "graphql/cache";

import "./language-selector.scss";


const { Option } = Select;

export const changeLanguage = ({value, store, onChange = () => {} }) => {

    if(Array.isArray(store)){

        for( let i = 0; i <= store.length; i++ ){
            changeLanguage({
                value,
                store : store[i]
            });
        }
    }

    if(store === "localstorage"){
        localStorage.setItem("interface-language", value);
    }

    if(store === "authParams"){
        authParamsVar({...authParamsVar(), interfaceLanguage: value});
    }

    onChange(value);
};

const LanguageSelector = ({
            store = ["localstorage", "authParams"],
            value = authParamsVar().interfaceLanguage,
            onChange
      }) => {

    return(
        <Select
            className="language-selector"
            labelInValue
            defaultValue={{ value }}
            onChange={ ( { value } ) => changeLanguage({value, store, onChange}) }
        >
            <Option value="en">English</Option>
            <Option value="de">Deutsch</Option>
        </Select>
    );
};

export default LanguageSelector;