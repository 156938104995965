import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Select } from "antd";

import Icons from "components/icons";
import { useVarParam } from "components/use-hooks";

import "./search-form.scss";


const { Option } = Select;

const SearchForm = ({ searchType="companies", setSearchType }) => {

    const [ searchTypeOpen, setSearchTypeOpen ] = useState(false);
    const history = useHistory();

    useEffect( () => {

        const clickEvent = (e) => {
            if(!e.target.closest(".search-type-wrap")){
                setSearchTypeOpen(false);
            }
        }

        window.addEventListener("click", clickEvent)

        return () => {
            window.removeEventListener('click', clickEvent);
        };
    })

    const productsOn = useVarParam().get("productsOn");


    return(
        <Form className="search-form">
            <div className="search-type-wrap">
                <div className="search-icon-wrap">
                    <Icons.Search />
                </div>
                <Select
                    className={ `search-form-type ${ !productsOn ? "one-item" : ""}` }
                    open={ searchTypeOpen }
                    suffixIcon={
                        <>
                        { productsOn &&
                            <button
                                type="button"
                                onClick={
                                    () => setSearchTypeOpen(!searchTypeOpen)
                                }
                            >
                                <Icons.Arrow type="filled"/>
                            </button>
                        }
                        </>
                    }
                    dropdownClassName={"search-form-type-dropdown"}
                    defaultValue={ searchType }
                    onSelect={
                        (value) => {
                            setSearchType(value)
                            setSearchTypeOpen(!searchTypeOpen);
                        }
                    }
                >
                    <Option value="companies">Companies</Option>
                    { productsOn && <Option value="products">Products</Option> }
                </Select>
            </div>
            <Input
                name="search"
                placeholder={ `Search for ${ searchType }… ` }
                onPressEnter={ (e) => {

                    if( e.target.value.length > 1 ) {
                        history.push(`/search/${searchType}/${e.target.value}`);
                    }
                    //e.target.value = "";
                }}
            />
        </Form>
    );
}

export default SearchForm;