import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Button, Modal } from "antd";

import { STAND_META_SET } from "graphql/mutation/stand-gql";

import Icons from "components/icons";
import { errorNotification, successNotification } from "components/request-result";
import { ColorPicker } from "components/service";


const ProductTopChoiceColor = ({ defaultColor, standId }) => {

    const [showModal, setShowModal] = useState(false);
    const [ currentColor, setCurrentColor ] = useState(defaultColor);

    const [ setTopBgColor, { loading }] = useMutation( STAND_META_SET,
        {
            update(cache, { data }) {

                const { label, message } = data.standMetaSet;

                successNotification({
                    title: label,
                    description: message
                });

                setShowModal(false);

            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    return(
        <div className="product-top-choice-color">
            <Button
                type="default"
                onClick={ () => setShowModal(true) }
            >
                Choose background color
            </Button>
            <Modal
                className={ "product-top-choice-color-modal" }
                visible={ showModal }
                centered
                width={ "auto" }
                closeIcon={ <Icons.Cross /> }
                footer={ null }
                onCancel={() => {
                    setShowModal(false);
                    setCurrentColor(defaultColor);
                }}
            >
                <h4>Choose color</h4>
                <ColorPicker.ColorGrid
                    currentColor={ currentColor }
                    setColor={ setCurrentColor }
                />
                <div className="modal-actions">
                    <Button
                        type="primary"
                        disabled={ loading }
                        loading={ loading }
                        onClick={ () => {

                            if(!currentColor){
                                errorNotification({
                                    label: "Set top products color failed",
                                    reason: "Please choose color"
                                });

                                return false;
                            }

                            setTopBgColor({
                                variables: {
                                    standId : standId,
                                    metaInput: [{
                                        meta_key: "top_bg_color",
                                        meta_group: "products",
                                        meta_value: currentColor
                                    }]
                                }
                            });
                        }}
                    >
                        Save
                    </Button>
                    <Button
                        type="default"
                        onClick={ () => {
                            setShowModal(false);
                            setCurrentColor(defaultColor);
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            </Modal>
        </div>
    );
};

export default ProductTopChoiceColor;