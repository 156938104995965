import React from "react";
import { Switch, Route, Redirect } from 'react-router-dom';

import Pages from "../pages";
import { useVarParam } from "components/use-hooks";


const ShowroomRoutes = ({ parent = "showroom", stand }) => {

    const { Showroom : { SubPage } } = Pages;

    const params = {
        stand,
        spanList: {
            xs: 24, md: 24, lg: 24, xl: 21, xxl: 16, span: 24
        },
        control: false
    };

    const productsOn = useVarParam().get("productsOn");

    return(
        <Switch>
            { productsOn &&
                <Route path={`/${ parent }-${ stand.id }/product`} exact>
                    <SubPage.Product { ...params } />
                </Route>
            }
            <Route path={`/${ parent }-${ stand.id }/company`} exact>
                <SubPage.Company { ...params } />
            </Route>
            { useVarParam().get("communicationOn") &&
                <Route path={`/${parent}-${stand.id}/communication`} exact>
                    <SubPage.Communication {...params} />
                </Route>
            }
            { useVarParam().get("downloadOn") &&
                <Route path={`/${parent}-${stand.id}/downloads`} exact>
                    <SubPage.Downloads {...params} />
                </Route>
            }
            <Route>
                <Redirect to={`/${ parent }-${ stand.id }/${ productsOn ? 'product' : 'company' }`} />
            </Route>
        </Switch>
    );
};

export default ShowroomRoutes;