import React from 'react';
import Icon from '@ant-design/icons';


const PackageSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M37.7,13.1h-8.4c0-2.8-2.4-5.1-5.2-5.1c-2.8,0-5.2,2.3-5.2,5.1h-8.4c-1.7,0-3.1,1.4-3.1,3.1v8.3v11.8
		c0,1.7,1.4,3.1,3.1,3.1h27.2c1.7,0,3.1-1.4,3.1-3.1V24.6v-8.3C40.8,14.5,39.4,13.1,37.7,13.1z M24.1,10c1.7,0,3.2,1.4,3.2,3.1h-6.4
		C20.9,11.4,22.3,10,24.1,10z M9.4,16.2c0-0.6,0.5-1.1,1.1-1.1h27.2c0.6,0,1.1,0.5,1.1,1.1v8.3c0,0.6-0.5,1.1-1.1,1.1H10.5
		c-0.6,0-1.1-0.5-1.1-1.1V16.2z M37.7,37.5H10.5c-0.6,0-1.1-0.5-1.1-1.1v-8.9c0.3,0.1,0.7,0.2,1.1,0.2h27.2c0.4,0,0.8-0.1,1.1-0.2
		v8.9C38.8,37,38.3,37.5,37.7,37.5z"/>
        <path d="M21.6,24.3h4.9c0.6,0,1-0.4,1-1s-0.4-1-1-1h-4.9c-0.6,0-1,0.4-1,1S21.1,24.3,21.6,24.3z"/>
    </svg>
);

const PackageIcon = props => {
    return <Icon component={ PackageSvg } />;

};


export default PackageIcon;