import React from "react";
import { Redirect, useRouteMatch } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { GET_ME } from "graphql/query/user-gql";

import { useVarParam } from "components/use-hooks";
import { metaFields } from "utils";


const RedirectorProfileRule = () => {

    const { data } = useQuery( GET_ME, { fetchPolicy: "cache-only" } ),
          match = useRouteMatch("/:page"),
          varParam = useVarParam();

    if( data !== undefined) {

        const meta = metaFields.normalize(data.me.meta_fields);

        let page = match === null ? "/" : match.params.page,
            flag = parseInt(metaFields.getValue(meta, "profile_filed_flag", 0));

        varParam.set({
            profileComplete: !!flag,
            pageLabelText: !flag ? "Fill your profile to start exploring" : ""
        });

        if (flag !== 1 && page !== "profile") {
            varParam.set({
                profileComplete: !!flag,
            });

            return <Redirect to="/profile/"/>
        }
    }

    return null;

};


const RouteRedirector = ({ children, rule }) => {

    if(rule === "userProfile"){
        return (
            <>
                <RedirectorProfileRule />
                { children }
            </>
        );
    }


    return children;
};

export default RouteRedirector;