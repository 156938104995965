import React from "react";
import {useParams, useRouteMatch} from "react-router-dom";

import { AnalyticsBlocks } from "components/analytics";


const AnalyticsProductSubPage = ({ standId }) => {

    const pathBase = "/showroom-configurator/analytics/";

    let { productId } = useParams();

    let match = useRouteMatch(`${ pathBase }product/${ productId }/:pageNum`);

    return(
        <div className="analytics-visitor">
            <AnalyticsBlocks.ProductSingleHeader
                standId={ standId }
                productId={ productId }
            />
            <AnalyticsBlocks.ProductSingle
                standId={ standId }
                productId={ parseInt(productId) }
                currentPage={ match ? parseInt(match.params.pageNum) : 1  }
                pathBase={ pathBase }
            />
        </div>
    );
}


export default AnalyticsProductSubPage;