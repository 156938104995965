import React from "react";
import Icons from "../../../icons";

const ProductFileWrap = ({ filepath }) => {

    const filename = filepath.replace(/^.*[\\/]/, '');

    return(
        <a
            href={filepath}
            target={"_blank"}
            rel={"noreferrer"}
            className="product-link-file-wrap"
        >
            <Icons.File />{ filename }
        </a>
    );
}

export default ProductFileWrap;