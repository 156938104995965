import { gql } from '@apollo/client';


export const FAVORITE_PRODUCTS = gql`
    query FavoriteProducts(
        $text : String
        $page : Int
    ){
        favoriteProducts(
            text : $text
            page : $page
            first: 500
        ){
            paginatorInfo{
                total
                currentPage
                hasMorePages
                perPage
                count
            }
            data{
                id
                cover
                title
                description
                type
                is_searchable
                is_top
                status
                my_favorite
                stand{
                    id
                    logo
                    title
                }
                updated_at
                created_at
            }
        }
    }
`;

export const FAVORITE_STANDS = gql`
    query FavoriteStands(
        $text : String
        $page : Int
    ){
        favoriteStands(
            text : $text
            page : $page
            first: 500
        ){
            paginatorInfo{
                total
                currentPage
                hasMorePages
                perPage
                count
            }
            data{
                id
                logo
                title
                description
                my_favorite
            }
        }
    }
`;

export const FAVORITE_USERS = gql`
    query FavoriteUsers(
        $text : String
        $page : Int
    ){
        favoriteUsers(
            text : $text
            page : $page
            first: 500
        ){
            paginatorInfo{
                total
                currentPage
                hasMorePages
                perPage
                count
            }
            data{
                id
                name
                surname
                email
                email_original
                locale
                status
                role
                avatar
                my_favorite
                meta_fields{
                    id
                    user_id
                    meta_key
                    meta_type
                    meta_value
                }
                stand{
                    id
                    ee_id
                    title
                    description
                    logo
                    my_favorite
                    meta_fields{
                        id
                        stand_id
                        meta_key
                        meta_type
                        meta_value
                        meta_group
                    }
                    status
                    is_moderated
                    created_at
                    updated_at
                }
                updated_at
                created_at
            }
        }
    }
`;
