import {
    platformParamsVar,
    platformParamsInitial,
    authParamsInitial,
    authParamsVar
} from "graphql/cache";

import { paramVar } from "utils";


const useVarParam = (type) => {

    if(type === "auth"){
        return paramVar(authParamsVar, authParamsInitial);
    }

    return paramVar(platformParamsVar, platformParamsInitial);

};

export default useVarParam;