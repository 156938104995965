import AnalyticsProductSubPage from "./analytics-product-sub-page";
import AnalyticsVisitorsSubPage from "./analytics-visitor-sub-page";


const AnalyticsSubPage = {
    Product : AnalyticsProductSubPage,
    Visitor : AnalyticsVisitorsSubPage
};

export default AnalyticsSubPage;
