import { useMutation } from "@apollo/client";

import { STAND_UPDATE_ATTACHMENT } from "graphql/mutation/stand-gql";

import { successNotification } from "components/request-result";


const useShowroomDownloadUpdate = ({ callBack = () => {} }) => {

    const [ updateMutation, { loading } ] = useMutation( STAND_UPDATE_ATTACHMENT,
        {
            update(cache, { data }) {

                const { label, message } = data.attachedUpdate;

                successNotification({
                    title: label,
                    description: message
                });

                callBack();
            }
        });

    return{
        updateMutation,
        loading
    }
}

export default useShowroomDownloadUpdate;