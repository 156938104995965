import React from 'react';
import Icon from '@ant-design/icons';


const SearchSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M38,37.7l-8.4-8.6C29.6,29,29.5,29,29.5,29c4.1-4.5,4.1-11.7-0.2-16.2c-2.1-2.2-5-3.4-8-3.4c0,0,0,0,0,0c-3,0-5.9,1.2-8,3.4
	        c-4.4,4.5-4.4,11.9,0,16.4c2.1,2.2,5,3.4,8,3.4c2.5,0,4.9-0.8,6.8-2.4c0,0.1,0.1,0.2,0.1,0.2l8.4,8.6c0.2,0.2,0.5,0.3,0.7,0.3
	        c0.3,0,0.5-0.1,0.7-0.3C38.4,38.7,38.4,38.1,38,37.7z M21.3,30.6c-2.5,0-4.8-1-6.6-2.8c-3.6-3.8-3.6-9.9,0-13.6
	        c1.8-1.8,4.1-2.8,6.6-2.8c0,0,0,0,0,0c2.5,0,4.8,1,6.6,2.8c3.6,3.8,3.6,9.9,0,13.6C26.1,29.6,23.7,30.6,21.3,30.6z"
        />
    </svg>
);

const SearchIcon = props => {
    return <Icon component={ SearchSvg } />;

};


export default SearchIcon;