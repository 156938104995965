import React from "react";
import { Form, Radio } from "antd";


const ProductMediaTypeForm = ({ form, mediaType, setMediaType }) => {

    return (
        <Form
            form={ form }
            name={ "product" }
            className={ "product-media-type-form" }
        >
            <Form.Item
                name="product_media_type-string"
                initialValue={ mediaType }
                rules={ [
                    { required: true, message: "Choose option" }
                ]}
            >
                <Radio.Group
                    onChange={ ({ target }) => setMediaType(target.value) }
                >
                    <Radio value="image">Image slider</Radio>
                    <Radio value="video">Youtube video</Radio>
                </Radio.Group>
            </Form.Item>
        </Form>
    );
};

export default ProductMediaTypeForm;