import React from "react";
import { NavLink } from "react-router-dom";

import Icons from "components/icons";


const ShowroomControlManageMenu = () => {
    return(
        <ul className="menu">
            <li>
                <NavLink to="/showroom-configurator/profile">
                    <Icons.Edit />
                    <span>Edit company profile</span>
                </NavLink>
            </li>
            <li>
                <NavLink to="/showroom-configurator/team">
                    <Icons.Team />
                    <span>Company Team</span>
                </NavLink>
            </li>
            <li>
                <NavLink to="/showroom-configurator/packages">
                    <Icons.Package />
                    <span>Packages</span>
                </NavLink>
            </li>
            <li>
                <NavLink to="/showroom-configurator/analytics/">
                    <Icons.Analytics />
                    <span>Showroom analytics</span>
                </NavLink>
            </li>
        </ul>
    )
};

export default ShowroomControlManageMenu;