import React, { useState }  from "react";
import { useMutation } from "@apollo/client";
import { Button, Modal } from "antd";

import { PACKAGE_REQUEST_CREATE } from "graphql/mutation/package-gql";

import {errorNotification, successNotification} from "components/request-result";
import Icons from "components/icons";


const PackageRequest = ({ packageId, standId }) => {

    const [ showModal, setShowModal ] = useState( false );

    const [ packageUpdateRequest, { loading } ] = useMutation(PACKAGE_REQUEST_CREATE,
        {
            update( cache, { data } ) {

                const {
                    packageRequestCreate : {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message,
                });

                setShowModal(false);
            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    return(
        <>
            <Button type="primary" onClick={ () => setShowModal(true) }>
                Request upgrade
            </Button>
            <Modal
                className="standard-request-modal"
                visible={ showModal }
                centered
                maskClosable={ false }
                closeIcon={ <Icons.Cross /> }
                onOk={ () => {
                    packageUpdateRequest({
                        variables: {
                            standId,
                            packageId
                        }
                    })
                }}
                onCancel={ () => setShowModal(false)}
                okButtonProps={{
                    loading
                }}
                cancelButtonProps={{
                    type: "default"
                }}
                okText="Confirm"
                cancelText="Cancel"
            >
                <p>Are you sure you want to request a different package for your showroom?</p>
                <p>The request does not change your package immediately, the changes will take effect only after confirmation by the moderator.</p>
            </Modal>
        </>
    );
}

export default PackageRequest;