import React from "react";
import { useQuery } from "@apollo/client";

import { GET_STAND_ATTACHMENTS } from "graphql/query/stand-gql";

import ShowroomDownloadsRow from "./showroom-downloads-row";
import { Loader } from "components/request-result";

import "./showroom-downloads.scss";


const ShowroomDownloads = ({ control, standId }) => {

    const { loading, data: { attachmentsStand } = {} } = useQuery( GET_STAND_ATTACHMENTS, {
        variables: {
            standId,
            status : control ? [ "active", "draft" ] : [ "active" ],
            orderBy : [{
                column: "created_at",
                order: "DESC"
            }]
        },
    });

    const {
        paginatorInfo = {},
        data : attachments = [],
    } = attachmentsStand ?? {};

    return (
        <div className="showroom-downloads">
            { attachments &&
                attachments.map( ( attachment ) => {
                    return(
                        <ShowroomDownloadsRow
                            key={ attachment.id }
                            control={ control }
                            attachment={ attachment }
                        />
                    )
                })
            }
            { (loading || !paginatorInfo.total) &&
                <div className="empty">
                    { loading && <Loader /> }
                    { !paginatorInfo.total &&
                        <>
                            { control ?
                                "Your showroom has no documents to download yet":
                                "Showroom has no documents to view"
                            }
                        </>
                    }
                </div>
            }
        </div>
    )
};

export default ShowroomDownloads;