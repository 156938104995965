import { InMemoryCache, makeVar } from "@apollo/client";


const cache = new InMemoryCache({
    typePolicies: {
        Product: {
            fields: {
                meta_fields: {
                    merge(existing, incoming) {
                        return incoming;
                    },
                },
                terms: {
                    merge(existing, incoming) {
                        return incoming;
                    },
                },
            },
        },
        Stand: {
            fields: {
                terms: {
                    merge(existing, incoming) {
                        return incoming;
                    },
                },
                exhibitors: {
                    merge(existing, incoming) {
                        return incoming;
                    },
                }
            },
        },
        Query: {
            fields: {
                expoHall: {
                    merge(existing, incoming) {
                        return incoming;
                    },
                },
                communications: {
                    merge(existing, incoming) {
                        return incoming;
                    },
                },
                authParams: {
                    read () {
                        return authParamsVar();
                    }
                },
                platformParams: {
                    read () {
                        return platformParamsVar();
                    }
                }
            },
        },
    },
});

export const authParamsInitial = {
    legacyBlock : false,
    languageSelector: false,
    sendConfirmationEmail: false,
    sendForgotPasswordEmail: false,
    interfaceLanguage: "",
    eeId: false
};

export const authParamsVar = makeVar(authParamsInitial);

export const platformParamsInitial = {
    //possible values: "", "bg-none", "bg-base", "bg-primary", "bg-primary-bg-color" "bg-primary-gradient"
    headerClass : ["bg-primary"],
    headerOn : true,
    headerContentOn : true,
    mainBlockClass: [],
    appBlockClass: [],
    searchOn: true,
    mapOpen: false,
    mapOn: false,
    productsOn: true,
    downloadOn: true,
    communicationOn: true,
    showroomChecklistDrawerOn: false,
    profileComplete: false,
    sidebarOpen: true,
    pageLabelText: ""
};

export const platformParamsVar = makeVar(platformParamsInitial);

export default cache;