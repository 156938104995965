import { gql } from '@apollo/client';

export const SUMMARY_STATISTICS = gql`
    query SummaryStatistics(
        $standId : ID!
    ){
        logSummaryStatistics(
            stand_id : $standId
        ){
            showroom_views
            showroom_favorites
            products_views
            products_favorites
        }
    }
`;

export const SUMMARY_STATISTICS_RANGE = gql`
    query SummaryStatisticsRange(
        $standId: ID!
        $model: SelectModelLogView!
        $date: DateRange
    ){
        logSummaryStatisticsRange(
            stand_id: $standId
            model: $model
            date: $date
        ){
            views
            favorites
            date
        }
    }
`;

export const VIEWS_LOG = gql`
    query LogViews(
        $standId : ID!
        $input : ModelLogView
        $sortByOrder : SortLogView
        $page : Int
    ){
        logViews(
            stand_id : $standId
            input : $input
            sortByOrder : $sortByOrder
            first : 15
            page : $page
        ){
            paginatorInfo{
                perPage
                currentPage
                total
            }
            data{
                id
                ee_id
                user_id
                stand_id
                product_id
                views
                favorite
                favorite_product
                pdf_views
                video_views
                attachment_views
                user{
                    id
                    name
                    surname
                    email_original
                    avatar
                    meta_fields{
                        id
                        user_id
                        meta_key
                        meta_type
                        meta_value
                        meta_group
                    }
                    stand{
                        id
                        ee_id
                        title
                        keywords
                        logo
                        meta_fields{
                            id
                            stand_id
                            meta_key
                            meta_type
                            meta_value
                            meta_group
                        }
                        status
                        is_moderated
                        created_at
                        updated_at
                    }
                }
                product{
                    id
                    cover
                    title
                    description
                    type
                    is_searchable
                    is_top
                    status
                    stand_id
                    my_favorite
                    meta_fields{
                        id
                        product_id
                        meta_key
                        meta_type
                        meta_value
                        meta_group
                    }
                    terms{
                        id
                        taxonomy_id
                        title
                        sort_number
                    }
                    stand{
                        id
                        logo
                        title
                    }
                    updated_at
                    created_at
                }
                created_at
                updated_at
            }
            
        }
    }
`;

export const LOG_VISITORS = gql`
    query LogVisitors(
        $standId : ID!
        $userId : ID
        $orderBy : [QueryLogVisitorsOrderByOrderByClause!]
        $page : Int
    ){
        logVisitors(
            stand_id : $standId
            user_id : $userId
            orderBy : $orderBy
            first : 15
            page : $page
        ){
            paginatorInfo{
                perPage
                currentPage
                total
            }
            data{
                id
                stand_id
                user_id
                ee_id
                view_stands
                view_products
                favorite_stands
                favorite_products
                user{
                    id
                    name
                    surname
                    email_original
                    avatar
                    meta_fields{
                        id
                        user_id
                        meta_key
                        meta_type
                        meta_value
                        meta_group
                    }
                    stand{
                        id
                        ee_id
                        title
                        keywords
                        logo
                        meta_fields{
                            id
                            stand_id
                            meta_key
                            meta_type
                            meta_value
                            meta_group
                        }
                        status
                        is_moderated
                        created_at
                        updated_at
                    }
                }
            }

        }
    }
`;

export const LOG_STAND_PRODUCT = gql`
    query LogStandProduct(
        $standId : ID!
        $productId: ID!
    ){
        logStandProduct(
            stand_id : $standId
            product_id : $productId

        ){
            product_views
            product_favorites
            video_views
            attachment_views
            product{
                id
                cover
                title
                description
                type
                is_searchable
                is_top
                status
                stand_id
                my_favorite
                meta_fields{
                    id
                    product_id
                    meta_key
                    meta_type
                    meta_value
                    meta_group
                }
                terms{
                    id
                    taxonomy_id
                    title
                    sort_number
                }
                stand{
                    id
                    logo
                    title
                }
                updated_at
                created_at
            }
        }
    }
`;