import React from "react";

import ShowroomCompanyAddress from "./showroom-company-address";
import ShowroomCompanyContacts from './showroom-company-contacts';
import ShowroomCompanyContent from "./showroom-company-content";
import { ShowroomCompanyWrap, WrapColumn } from "components/wraps";
import { forms } from "components/showroom";
import { metaFields } from "utils";

import "./showroom-about-company.scss";


const ShowroomAboutCompany = ({ stand, control }) => {

    const { AboutCompanyForm } = forms,
          { normalize } = metaFields,
          meta = normalize(stand.meta_fields);

    return(
        <ShowroomCompanyWrap className="showroom-about-company">
            <WrapColumn position="left">
                <ShowroomCompanyAddress meta={ meta } />
                <ShowroomCompanyContacts meta={ meta } />
            </WrapColumn>
            <WrapColumn position="right">
                { control ?
                    <AboutCompanyForm
                        meta={ meta }
                        standId={ stand.id }
                    /> :
                    <ShowroomCompanyContent
                        meta={ meta }
                    />
                }
            </WrapColumn>
        </ShowroomCompanyWrap>
    );

};

export default ShowroomAboutCompany;