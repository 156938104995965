const rulesToObj = (rulesFields = {}) => {

    let rules = {};

    if(rulesFields) {
        for (let [, field] of Object.entries(rulesFields)) {
            rules[field.rule_key] = field;
        }
    }

    return rules;
};


const getField = ( rulesFields, fieldName, valueName = "active", defaultVal = "" ) => {
    return rulesFields[fieldName] !== undefined ? rulesFields[fieldName][valueName] : defaultVal;
};


const packageRules = {
    normalize : rulesToObj,
    getField
};

export default packageRules;