import React from "react";
import { Link } from "react-router-dom";
import { Collapse } from 'antd';

import Icons from "components/icons";
import {useCheckSet} from "components/use-hooks";


const { Panel } = Collapse;


const DownloadPageStep = ({ closeDrawer, showroom, ...props}) => {

    const checkSet = useCheckSet(showroom);
    const checkResult = downloadStepCheck({ ...checkSet });

    if(!checkSet.pages.downloads){
        return null;
    }

    return(
        <Panel
            {...props}
            header={
                <>
                    <p className={`sub-text${ checkResult.blockFinish ? "-success" : "" }`}>
                        { checkResult.blockFinish ? "Done" : `${ checkResult.stepsLeft } steps left`}
                    </p>
                    <h5>Download page</h5>
                </>
            }
            key="download-page"
        >
            <div className={ `step ${ checkResult.pageBanner ? "done" : "" }` }>
                <div className="success-badge">
                    <Icons.Check />
                </div>
                <div className="description">
                    <h6>Add page banner</h6>
                    <p className="sub-text">
                        This is the first image people see on the Page.
                    </p>
                    <Link
                        onClick={ closeDrawer }
                        className="link-button second"
                        to="/showroom-configurator/downloads"
                    >
                        Add photo
                    </Link>
                </div>
            </div>
            {checkResult.documents !== "no package" &&
                <div className={`step ${checkResult.documents ? "done" : ""}`}>
                    <div className="success-badge">
                        <Icons.Check/>
                    </div>
                    <div className="description">
                        <h6>Add files ({checkResult.documentsLeft})</h6>
                        <p className="sub-text">
                            Upload promo materials, price lists and more.
                        </p>
                        <Link
                            onClick={closeDrawer}
                            className="link-button second"
                            to="/showroom-configurator/downloads"
                        >
                            Add files
                        </Link>
                    </div>
                </div>
            }
        </Panel>
    );
};

export const downloadStepCheck = ({ meta, rules = false, showroomCounts, pages } = {}) => {

    let checkResult = {
        stepsLeft: 0,
        stepAmount: 2,
        stepFinish: 0,
        blockFinish: false,
        pageBanner : false,
        documents : false,
        documentsLeft: 0
    };

    if(!pages.downloads){
        return false;
    }

    if(rules.fields && showroomCounts){
        const { fields } = rules;

        if(showroomCounts.stand_document_amount === fields?.stand_document_amount?.max_value){
            checkResult.stepFinish++;
            checkResult.documents = true;
        }

        checkResult.documentsLeft = fields?.stand_document_amount?.max_value - showroomCounts.stand_document_amount;

    } else{
        checkResult.stepAmount--;
        checkResult.documents = "no package";
    }

    if(meta && meta.getValue( meta.fields, "downloads_image_1" )){
        checkResult.stepFinish++;
        checkResult.pageBanner = true;
    }

    checkResult.blockFinish = checkResult.stepAmount === checkResult.stepFinish;
    checkResult.stepsLeft = checkResult.stepAmount - checkResult.stepFinish;

    return checkResult;

}

export default DownloadPageStep;