import {useEffect, useState} from "react";

const useColumnsChildren = (items) => {

    const [ columns, setColumns ] = useState(null);

    useEffect( () => {

        if(items !== undefined) {
            items.forEach(({props}) => {
                setColumns((prevState) => {
                    return {
                        ...prevState,
                        [`${props.position}Column`]: props.children
                    }
                });
            });
        }

    }, [ items ]);

    return {...columns};
};

export default useColumnsChildren;