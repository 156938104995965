import React from "react";
import { useMutation } from "@apollo/client";

import { STAND_UPLOAD_ATTACHMENT } from "graphql/mutation/stand-gql";
import { GET_STAND_ATTACHMENTS } from "graphql/query/stand-gql";

import Icons from "components/icons";
import { UploadFile } from "components/service";
import { Loader, successNotification } from "components/request-result";
import { useVarParam } from "components/use-hooks";

import "./showroom-downloads-attach.scss";


const ShowroomDownloadsAttach = ({ standId }) => {

    const varParam = useVarParam();

    const [ uploadMutation, { loading, client } ] = useMutation( STAND_UPLOAD_ATTACHMENT,
        {
            update(cache, { data }) {

                const { label, message, attachment } = data.attachedUpload;

                const variables = {
                    standId,
                    status : [ "active", "draft" ],
                    orderBy : [{
                        column: "created_at",
                        order: "DESC"
                    }]
                }

                const { attachmentsStand } = client.readQuery({
                    query: GET_STAND_ATTACHMENTS,
                    variables
                });

                client.writeQuery({
                    query: GET_STAND_ATTACHMENTS,
                    variables,
                    data: {
                        attachmentsStand: {
                            ...attachmentsStand,
                            data : [
                                {
                                    ...attachment,
                                    status: "draft"
                                },
                                ...attachmentsStand.data,
                            ],
                        }
                    },
                });

                varParam.set({ downloadModalId: attachment.id });

                successNotification({
                    title: label,
                    description: message
                });
            },
            refetchQueries: ["StandAmounts"]
        });

    return (
        <UploadFile
            variables={{ standId }}
            uploadMutation={ uploadMutation }
            loading={ loading }
            accept={ "*/*" }
            extraClass="showroom-attachment-upload-wrap"
        >
            <button
                type="button"
                className="showroom-attachment-upload"
            >
                { loading ?
                    <Loader /> :
                    <Icons.Download />
                } Add file
            </button>
        </UploadFile>
    )
}


export default ShowroomDownloadsAttach;