import React, { useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { ACTIVATE_ACCOUNT } from "graphql/mutation/user-gql";
import { authParamsVar } from "graphql/cache";

import { useExhibitionEvent } from "components/use-hooks";
import { Loader } from "components/request-result";

import "./activation-account-form.scss";


const ActivationAccountForm = () => {

    const match =  useRouteMatch("/confirm-account/:token"),
          [ requestMessage, setRequestMessage ] = useState({});

    const ee = useExhibitionEvent();

    authParamsVar({...authParamsVar(), legacyBlock: true});

    const [activationRequest, { loading, error, called }] = useMutation( ACTIVATE_ACCOUNT, {
            update(cache, { data : { userActivate } }) {
                setRequestMessage({
                    status: "success",
                    label: userActivate.label,
                    message: userActivate.message
                });
            }
        }
    );

    if(!called && match){

        activationRequest({
            variables : {
                token: match.params.token
            }
        }).catch((error) => {
            setRequestMessage({
                status: "error",
                label: error.graphQLErrors[0].message,
                message: error.graphQLErrors[0].extensions.reason
            });
        });
    }

    if(requestMessage.status === undefined){
        setRequestMessage({
            status: "error",
            label: "Activation token error",
            message: "Token not provided, please open or copy all link from activation E-Mail"
        });
    }

    return(
        <>
            <div className={`form-holder activation-account ${loading ? "loading" : ""}` }>
            { loading ?
                    <>
                        <Loader blockClass={"color-primary"} />
                        <p >Please wait, we are activate your account</p>
                    </>
                :
                <>
                    { !error && match &&
                    <>
                        <h1>Your account activated</h1>
                        <p>Please log in to enter { ee.title }</p>
                        <p>
                            <Link to="/" className="link-button">
                                <span>LOG IN</span>
                            </Link>
                        </p>
                    </>
                    }
                    { (error || !match) &&
                    <>
                        <h1>{ requestMessage.label }</h1>
                        <p>{ requestMessage.message }</p>
                        <p>
                            <Link to="/" className="route-link">To the sign in page</Link>
                        </p>
                    </>
                    }
                </>
            }
            </div>
        </>
    );
};


export default ActivationAccountForm;