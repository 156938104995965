import React from "react";
import { Link } from "react-router-dom";
import { Collapse } from 'antd';

import Icons from "components/icons";
import { useCheckSet } from "components/use-hooks";

const { Panel } = Collapse;


const PackagePageStep = ({ closeDrawer, showroom, ...props}) => {

    const checkSet = useCheckSet(showroom);
    const checkResult = packageStepCheck({ ...checkSet });

    return(
        <Panel
            { ...props }
            header={
                <>
                    <p className={`sub-text${ checkResult.blockFinish ? "-success" : "" }`}>
                        { checkResult.blockFinish ? "Done" : `${ checkResult.stepsLeft } steps left`}
                    </p>
                    <h5>Package</h5>
                </>
            }
            key="package-page"
        >
            <div className={ `step ${ checkResult.package ? "done" : "" }` }>
                <div className="success-badge">
                    <Icons.Check />
                </div>
                <div className="description">
                    <h6>Select the package</h6>
                    <p className="sub-text">
                        Choose the package that suits your needs. At any time you can order another one with a wide range of options.
                    </p>
                    <Link
                        onClick={ closeDrawer }
                        className="link-button second"
                        to="/showroom-configurator/packages"
                    >
                        Select package
                    </Link>
                </div>
            </div>
        </Panel>
    );
};

export const packageStepCheck = ({ rules } = {}) => {

    let checkResult = {
        stepAmount: 1,
        stepFinish: 0,
        stepsLeft: 0,
        blockFinish: false,
        package : false,
    };

    if(rules && rules.fields){
        checkResult.stepFinish++;
        checkResult.package = true;
    }

    checkResult.blockFinish = checkResult.stepAmount === checkResult.stepFinish;
    checkResult.stepsLeft = checkResult.stepAmount - checkResult.stepFinish;

    return checkResult;
}

export default PackagePageStep;