import React from 'react';
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Form, Input, Button } from 'antd';

import { USER_LOGIN } from "graphql/mutation/user-gql";
import { IS_LOGGED_IN } from "graphql/query/local-store-gql";
import { authParamsVar } from "graphql/cache";

import { errorNotification } from 'components/request-result';
import LanguageSelector from "components/languages";
import { useExhibitionEvent } from "components/use-hooks";

import "./sign-in-form.scss";


const formSubmit = ({ values, loginRequest }) => {

    loginRequest({
        variables : {
            data : {
                username: values.username,
                password: values.password,
                ee_id: authParamsVar().eeId
            }
        },
    });

};

const NormalSignInForm = (props) => {

    const [ form ] = Form.useForm(),
        { loginLoading, ee } = props;

    authParamsVar({
        ...authParamsVar(),
        legacyBlock: true,
        sendConfirmationEmail: false,
        sendForgotPasswordEmail: false
    });


    const formItemLayout ={
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };

    return (
        <div className="form-holder">
            <h1>
                Sign in to <br />
                { ee.title }
            </h1>
            <Form
                className="sign-in-form"
                form={ form }
                onFinish ={(values) => {
                    formSubmit({ values, ...props });
                }}
            >
                <Form.Item
                    label={ <span>E-Mail</span>}
                    {...formItemLayout}
                    name="username"
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail',
                        },
                        {
                            required: true,
                            message: 'Please input your Email'
                        }
                    ]}
                >
                    <Input name="login-email" />
                </Form.Item>
                <Form.Item
                    label={ <span>Password</span>}
                    {...formItemLayout}
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: 'Please input your Password!'
                        }
                    ]}
                >
                    <Input.Password
                        type="password"
                        name="password"
                    />
                </Form.Item>
                <Form.Item>
                    <div className="form-actions">
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={ loginLoading }
                        >
                            SIGN IN
                        </Button>
                        <Link to="/forgot-password/" className="login-form-link">Forgot password?</Link>
                    </div>
                </Form.Item>
                <div className="language-wrap">
                    <LanguageSelector />
                </div>
            </Form>

            <div className="sign-link-wrap">
                <span>Haven’t registered yet?</span>
                <Link to="/registration/">Go to registration</Link>
            </div>
        </div>
    );
};

const SignInForm = () => {

    const ee = useExhibitionEvent();

    const [loginRequest, {loading}] = useMutation(
        USER_LOGIN,
        {
            update(cache, { data : { userLogin } }) {

                const { access_token } = userLogin;

                localStorage.setItem("authToken", access_token);
                cache.writeQuery({
                    query: IS_LOGGED_IN,
                    data: {
                        isLoggedIn: access_token,
                    },
                });
            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    return(
        <NormalSignInForm
            loginRequest={loginRequest}
            loginLoading={ loading }
            ee={ ee }
        />
    );
};


export default SignInForm;