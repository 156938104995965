import React from 'react';
import {Col, Row} from "antd";

import './container-wraps.scss';


const ContainerWrap = ({
        children,
        className = null,
        spanList={
            xs: 24, md: 22, lg: 20, xl: 17, xxl: 16, span: 24
        }
}) => {

    return (
        <Row className={className} type="flex" justify="center" gutter={0}>
            <Col { ...spanList }>
                {children}
            </Col>
        </Row>
    );
};

export default ContainerWrap;