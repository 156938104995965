import * as THREE from 'three';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { CSS3DRenderer, CSS3DObject } from 'three/examples/jsm/renderers/CSS3DRenderer.js';

/*
const renderTypeSphere = (obj, { fullBlockWidth, objCount, index }) => {

    let phi = Math.acos(- 1 + (2 * index) / objCount),
        theta = Math.sqrt(objCount * Math.PI) * phi,
        radius = Math.sqrt(objCount) * fullBlockWidth / Math.PI;

    const vector = new THREE.Vector3()

    obj.position.setFromSphericalCoords(radius, phi, theta);

    //vector.copy(object.position).multiplyScalar(2);

    obj.lookAt(vector);

    return obj;

}

const prepareObjects = (objects, settings = {}) => {

    const targets = [],
          { type, objCount } = settings;

    for (let i = 0, l = objCount; i < l; i++){

        let object = new THREE.Object3D();

        switch(type){
            case "sphere":
                object = renderTypeSphere(object, { index : i, ...settings });
            break;

            case "table":

            break;

            case "coliseum":
            default:

        }

        targets.push(object);

    }

    return targets;

}
*/

function expoBuilder({ rowAmount }) {

    let camera, scene, renderer, controls;

    const objects = [],
          targets = { table: [], theater: [], coliseum: [], sphere: [] },
          sceneHolder = document.querySelector('.exhibition-stands');

    init();
    animate();

    function init() {

        const blockHeight = 180,
            blockWidth = 200,
            blockOffset = 20,
            fullBlockWidth = blockWidth + blockOffset,
            fullBlockHeight = blockHeight + blockOffset,
            standsWrap = sceneHolder.querySelector(".stand-3d-wrap");

        camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 200, 10000);

        scene = new THREE.Scene();

        // table
       // const tableCol = 24;

        const elements = document.querySelectorAll('.ee-stand');

        for (let i = 0/*, y = 0*/; i < elements.length; i++)
        {
            let element = elements[ i ];

            const objectCSS = new CSS3DObject(element);
            objectCSS.position.x = Math.random() * 4000 - 2000;
            objectCSS.position.y = Math.random() * 4000 - 2000;
            objectCSS.position.z = Math.random() * 4000 - 2000;
            scene.add(objectCSS);

            objects.push(objectCSS);
            /*
            const object = new THREE.Object3D();

            object.position.x = ((i % tableCol) * fullBlockWidth) - (tableCol * fullBlockWidth / 2) + fullBlockWidth / 2;
            object.position.y = (y - ((elements.length / tableCol) / 2)) * fullBlockHeight + fullBlockHeight / 2;

            if(i % tableCol + 1 === tableCol){
                y++;
            }

            targets.table.push(object);
            */
        }

        // sphere

        const vector = new THREE.Vector3();
        /*
        for (let i = 0, l = objects.length; i < l; i++)
        {

            let phi = Math.acos(- 1 + (2 * i) / l),
                theta = Math.sqrt(l * Math.PI) * phi,
                radius = Math.sqrt(objects.length) * fullBlockWidth / Math.PI;

            const object = new THREE.Object3D();

            object.position.setFromSphericalCoords(radius, phi, theta);

            //vector.copy(object.position).multiplyScalar(2);

            object.lookAt(vector);

            targets.sphere.push(object);

        }

        // helix
        /*

          for (let i = 0, l = objects.length; i < l; i++) {

            const theta = i * 0.6 + Math.PI;
            const y = -(i * 20) + 450;

            const object = new THREE.Object3D();

            object.position.setFromCylindricalCoords(650, theta, y);

            vector.x = object.position.x * 2;
            vector.y = object.position.y;
            vector.z = object.position.z * 2;

            object.lookAt(vector);

            targets.helix.push(object);
          }

      */

        //coliseum and theater

        const radius = (fullBlockWidth * Math.ceil(objects.length / rowAmount) ) / (2 * Math.PI);
        //console.log(radius, window.innerWidth);
        for (let i = 0, l = objects.length; i < l; i++) {

            const factor = (360 / (Math.ceil(l / rowAmount) * rowAmount)) * (Math.PI / 180);
            let theta = (i - (i % rowAmount)) * factor,
                y = (i % rowAmount - ( rowAmount / 2 )) * fullBlockHeight + fullBlockHeight / 2;


            const object = new THREE.Object3D();

            object.position.setFromCylindricalCoords(radius, theta, y);

            vector.x = object.position.x * 2;
            vector.y = object.position.y;
            vector.z = object.position.z * 2;

            object.lookAt(vector);

            targets.coliseum.push(object);
            /*
            const secondObject = new THREE.Object3D();

            secondObject.copy(object);
            secondObject.rotation._y = secondObject.rotation._y + Math.PI;

            targets.theater.push(secondObject);
            */
        }

        // grid
        /*
        for (let i = 0; i < objects.length; i++)
        {

          const object = new THREE.Object3D();

          object.position.x = ((i % 5) * 400) - 800;
          object.position.y = (- (Math.floor(i / 5) % 5) * 400) + 800;
          object.position.z = (Math.floor(i / 25)) * 1000 - 2000;

          // targets.grid.push( object );

        }
        */
        //

        renderer = new CSS3DRenderer();
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.domElement.className = 'stand-3d-wrap';


        if(standsWrap){
            standsWrap.remove();
        }

        sceneHolder.appendChild(renderer.domElement);

        controls = new OrbitControls(camera, renderer.domElement);


        controls.domElement.addEventListener('touchstart', () => {
            controls.enabled = false;
        });
        controls.domElement.addEventListener('touchmove', () => {
            controls.enabled = true;
        });
        controls.domElement.addEventListener('touchend', (e) => {

            if(controls.enabled === false){

                if(e.target.classList.contains('ee-stand')){

                    e.target.click();

                } else if(e.target.closest(".ee-stand") !== null){

                    e.target.closest(".ee-stand").click();

                }
            }

        });

        controls.minDistance = radius * 2;
        controls.maxDistance = radius * 2 + 1500;

        const averageDistance = (controls.minDistance + controls.maxDistance) / 2;
        //console.log(averageDistance);
        //controls.mouseButtons = {};
        controls.addEventListener('change', render);
        //document.addEventListener( 'wheel', onMouseWheel, false );
        /*
        const buttonTable = document.getElementById('table');
        buttonTable.addEventListener('click', function () {

            //controls.minDistance = 4600;
            //controls.maxDistance = 6000;
            controls.minPolarAngle = Math.PI * 0.5;
            controls.maxPolarAngle  = Math.PI * 0.7;

            transform(targets.table, 2000, 3600);

            //controls.minAzimuthAngle = -(Math.PI * 0.3);
            //controls.maxAzimuthAngle = Math.PI * 0.3;
        }, false);

        const buttonSphere = document.getElementById('sphere');

        buttonSphere.addEventListener('click', function () {

            //controls.minDistance = 500;
            //controls.maxDistance = 6000;
            controls.minPolarAngle = 0;
            controls.maxPolarAngle  = Math.PI * 2;

            transform(targets.sphere, 2000, 800);

            //controls.minAzimuthAngle = 0;
            //controls.maxAzimuthAngle = Math.PI * 2;

        }, false);
        */
        //const buttonColiseum = document.getElementById('coliseum');
        //buttonColiseum.addEventListener('click', function () {



        //}, false);

        controls.minPolarAngle = Math.PI * 0.5;
        controls.maxPolarAngle  = Math.PI * 0.5;
        //controls.minDistance = 5500;
        //controls.maxDistance = 8000;

        transform(targets.coliseum, 2000, averageDistance);
        /*
        const buttonTheater = document.getElementById('theater');
        buttonTheater.addEventListener('click', function () {

            controls.minPolarAngle = Math.PI * 0.5;
            controls.maxPolarAngle  = Math.PI * 0.5;
            //controls.minDistance = 5500;
            //controls.maxDistance = 8000;

            transform(targets.theater, 2000, 500);

        }, false);
        */

        // const buttonGrid = document.getElementById( 'grid' );
        // buttonGrid.addEventListener( 'click', function () {

        // 	transform( targets.grid, 2000 );

        // }, false );

        //buttonColiseum.dispatchEvent(new Event('click'));
        //transform(targets.coliseum, 2000);

        window.addEventListener('resize', onWindowResize, false);


    }
    /*
    function onMouseWheel( event ) {

        //event.preventDefault();

        //camera.position.y -= event.deltaY * 0.005;
        console.log(event);
        // prevent scrolling beyond a min/max value

        //camera.position.clampScalar( 0, 10 );

    }
    */
    function transform(targets, duration, cameraPositionX) {

        TWEEN.removeAll();

        for (let i = 0; i < objects.length; i++) {

            const object = objects[ i ];
            const target = targets[ i ];

            new TWEEN.Tween(object.position)
                .to({ x: target.position.x, y: target.position.y, z: target.position.z }, Math.random() * duration + duration)
                .easing(TWEEN.Easing.Exponential.InOut)
                .start();

            new TWEEN.Tween(object.scale)
                .to({ x: target.scale.x, y: target.scale.y, z: target.scale.z }, Math.random() * duration + duration)
                .easing(TWEEN.Easing.Exponential.InOut)
                .start();

            new TWEEN.Tween(object.rotation)
                .to({ x: target.rotation.x, y: target.rotation.y, z: target.rotation.z }, Math.random() * duration + duration)
                .easing(TWEEN.Easing.Exponential.InOut)
                .start();

        }

        new TWEEN.Tween(camera.position)
            .to({ x: 0, y: 0, z: cameraPositionX }, Math.random() * duration + duration * 2)
            .easing(TWEEN.Easing.Exponential.InOut)
            .onComplete( () => {
                const logo = document.querySelector('.expo-logo');

                if (logo && logo.classList.contains("hide")) {
                    logo.classList.remove("hide");

                }

            })
            .start();

        new TWEEN.Tween(this)
            .to({}, duration * 2)
            .onUpdate(render)
            .start();

    }

    function onWindowResize() {

        camera.aspect = window.innerWidth / window.innerHeight;
        camera.updateProjectionMatrix();

        renderer.setSize(window.innerWidth, window.innerHeight);

        render();

    }

    function animate() {

        requestAnimationFrame(animate);

        TWEEN.update();

        controls.update();

    }

    function render() {
        //console.log(camera.position);

        renderer.render(scene, camera);
    }
}

export default expoBuilder;



