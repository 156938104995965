import ShowroomDownloads from "./showroom-downloads";
import {
    useShowroomDownloadDelete,
    useShowroomDownloadUpdate
} from "./showroom-downloads-hook";
import ShowroomDownloadsAttach from "./showroom-downloads-attach";

export const hooks = {
    useShowroomDownloadDelete,
    useShowroomDownloadUpdate
}

export {
    ShowroomDownloadsAttach
}

export default ShowroomDownloads;