import AnalyticsTotalBlock from "./analytics-total-block";
import AnalyticsChartBlock from "./analytics-chart-block";
import AnalyticsVisitorsDetailedBlock from "./analytics-visitors-detailed-block";
import AnalyticsVisitorSingleBlock from "./analytics-visitor-single-block";
import AnalyticsVisitorSingleHeader from "./analytics-visitor-single-header";
import AnalyticsProductsDetailedBlock from "./analytics-products-detailed-block";
import AnalyticsProductSingleBlock from "./analytics-product-single-block";
import AnalyticsProductSingleHeader from "./analytics-product-single-header";

import "./analytics-blocks.scss";


const AnalyticsBlocks = {
    Total : AnalyticsTotalBlock,
    Chart : AnalyticsChartBlock,
    VisitorsDetailed : AnalyticsVisitorsDetailedBlock,
    VisitorSingle : AnalyticsVisitorSingleBlock,
    VisitorSingleHeader : AnalyticsVisitorSingleHeader,
    ProductsDetailed : AnalyticsProductsDetailedBlock,
    ProductSingle : AnalyticsProductSingleBlock,
    ProductSingleHeader : AnalyticsProductSingleHeader
}

export default AnalyticsBlocks;