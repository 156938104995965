import React from "react";

import { FavoriteRoutes } from "components/routes";
import { FavoriteHeader } from "components/favorite";
import { useVarParam } from "components/use-hooks";

import "./favorite-page.scss";


const ProductPage = () => {

    const varParam = useVarParam();

    varParam.set({
        appBlockClass: ["bg-primary-bg-color"],
        mainBlockClass: [""],
        headerOn: true
    });

    const spanList = {
        xs: 24, md: 24, lg: 24, xl: 21, xxl: 16, span: 24
    };

    return(
        <div className="favorite-page">
            <FavoriteHeader
                spanList={ spanList }
            />
            <FavoriteRoutes />
        </div>
    );

};

export default ProductPage;