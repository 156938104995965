import ProductBookCreateButton from "./product-book-create-button";
import ProductBookForm from "./product-book-form";
import ProductBookContent from "./product-book-content";


const ProductBook = {
    CreateButton: ProductBookCreateButton,
    Form : ProductBookForm,
    Content: ProductBookContent
}

export default ProductBook