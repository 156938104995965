import AuthorizationPage from "./authorization/authorization-page";
import HomePage from "./home/home-page";
import ProfilePage, { Profile } from "./profile";
import Showroom from "./showroom";
import Product from "./product";
import Search from "./search";
import Favorite from "./favorite";
import Analytics from "./analytics";



const Pages = {
    HomePage,
    ProfilePage,
    Profile,
    Showroom,
    Product,
    Search,
    Favorite,
    Analytics
};

export default Pages;

export {
    AuthorizationPage
}

