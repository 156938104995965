import React from "react";
import { useMutation } from "@apollo/client";
import { Button } from "antd";

import { COMMUNICATION_CREATE } from "graphql/mutation/communication-gql";

import {
    errorNotification,
    Loader,
    successNotification
} from "components/request-result";
import Icons from "components/icons";

import "./communication-create.scss";


const CommunicationCreate = ({ standId, setCommunication, conversationCount = 0 }) => {

    const availableConversations = 5 - conversationCount;

    const [ communicationCreate, { loading }] = useMutation( COMMUNICATION_CREATE, {

            update(cache, { data }) {

                const {
                    communicationCreate : {
                        label,
                        message,
                        communication
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message,
                });

                setCommunication(communication);
            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    return(
        <div className="communication-create-wrap">
            <p className="sub-text-bold in-package">
                { availableConversations } conversations <span className="sub-text">remain in your package</span>
            </p>
            <Button
                type="default"
                disabled={ loading }
                className="communication-create-button"
                onClick={ () => {
                    if( availableConversations ){
                        communicationCreate({
                            variables: { standId }
                        });
                    }
                }}
            >
                { loading ? <Loader spinClass="spin-bg" /> : <Icons.Communication />}

                 Start new conversation
            </Button>
            <p className="sub-text">The new window with video chat room will open</p>
        </div>
    );
}

export default CommunicationCreate;