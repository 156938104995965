import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { Product } from "components/product";
import { ProductWrap, WrapColumn } from "components/wraps";
import { FavoriteButton } from "components/favorite";
import Icons from "components/icons";


const closeAction = ({ location, history, standId }) => {

    if(location.state === undefined){
        return history.push(`/showroom-${standId}/product`);
    }

    return history.push(location.state.from);

}


const ProductViewSubPage = ({ product }) => {

    const history = useHistory();
    const location = useLocation();

    let prodType = product.type[0].toUpperCase() + product.type.slice(1);

    if(Product[prodType] === undefined){
        return null;
    }

    const ProductContent = Product[prodType].Content;

    return(
        <ProductWrap>
            <WrapColumn position={ "left" }>
                <button
                    onClick={
                        () => closeAction({
                            history,
                            location,
                            standId : product.stand.id
                        })
                    }
                    className="product-page-close"
                >
                    <Icons.Cross /> Close
                </button>
                <div className="to-favorite">
                    <FavoriteButton
                        buttonType={ "text" }
                        type={ "Product" }
                        favorite={ product.my_favorite }
                        id={ product.id }
                    />
                </div>
                <div className="product-media-wrap">
                    <Product.MediaType
                        product={ product }
                        control={ false }
                    />
                </div>
            </WrapColumn>
            <WrapColumn position={ "right" }>
                <div  className="product-content-wrap">
                    <ProductContent
                        product={ product }
                        control={ location.state && location.state.control ? location.state.control : false }
                        closeAction={
                            () => closeAction({
                                history,
                                location,
                                standId : product.stand.id
                            })
                        }
                    />
                </div>
            </WrapColumn>
        </ProductWrap>
    );
}

export default ProductViewSubPage;