import React from "react";
import { Link, NavLink } from "react-router-dom";

import { useVarParam } from "components/use-hooks";
import Icons from "components/icons";


const ShowroomControlDesignMenu = ({ standId }) => {
    return(
        <ul className="menu">
            { useVarParam().get("productsOn") &&
                <li>
                    <NavLink to="/showroom-configurator/product">
                        <Icons.Product/>
                        <span>Product page</span>
                    </NavLink>
                </li>
            }
            <li>
                <NavLink to="/showroom-configurator/company">
                    <Icons.Company />
                    <span>Company page</span>
                </NavLink>
            </li>
            { useVarParam().get("communicationOn") &&
                <li>
                    <NavLink to="/showroom-configurator/communication">
                        <Icons.Communication/>
                        <span>Communication page</span>
                    </NavLink>
                </li>
            }
            { useVarParam().get("downloadOn") &&
                <li>
                    <NavLink to={"/showroom-configurator/downloads"}>
                        <Icons.Download/>
                        <span>Downloads page</span>
                    </NavLink>
                </li>
            }
            <li className="btn-wrap">
                <Link to={`/showroom-${standId}/`} className="ant-btn ant-btn-default" target="_blank">
                    <Icons.Eye />
                    <span>Showroom Preview</span>
                </Link>
            </li>
        </ul>
    )
};

export default ShowroomControlDesignMenu;