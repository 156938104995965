import React from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Button } from "antd";

import { STAND_CREATE } from "graphql/mutation/stand-gql";

import { errorNotification } from "components/request-result";


const ShowroomSetupButton = ({ user }) => {

    const history = useHistory();

    const [standCreateRequest, { loading }] = useMutation(STAND_CREATE,
        {
            update() {
                history.push("/showroom-configurator/profile");
            }
        }
    );

    if(user.role !== "exhibitor"){
        return null;
    }

    return (
        <div className="showroom-setup-wrap">
            <Button
                type="default"
                loading={ loading }
                onClick={
                    () => {
                        if(user.stand_permission !== null){
                            history.push("/showroom-configurator/profile");
                        } else {
                            standCreateRequest().catch((error) => {
                                errorNotification(error);
                            });
                        }
                    }
                }
                className="showroom-setup-button"
            >
                setup showroom
            </Button>
        </div>
    );
};

export default ShowroomSetupButton;