import React, { useState } from "react";
import { Button, Modal } from "antd";

import TeamInviteForm from "../team-invite-form";
import Icons from "components/icons";

import "./team-invite-modal.scss";


const TeamInviteModal = ({ standId }) => {

    const [ toggleModal, setToggleModal ] = useState(false);

    return(
        <>
            <Button
                type="primary"
                className="team-invite-button"
                onClick={ () => setToggleModal(true) }
            >
                <Icons.Plus /> Invite team member
            </Button>
            <Modal
                className={ "team-invite-modal" }
                visible={ toggleModal }
                centered
                destroyOnClose
                width={ "auto" }
                closeIcon={ <Icons.Cross /> }
                footer={ null }
                onCancel={() => {
                    setToggleModal(false);
                }}
            >
                <h4>Invite team members</h4>
                <p className="sub-text">Send invitation links to team members</p>
                <TeamInviteForm
                    standId={ standId }
                />
            </Modal>
        </>
    );
};


export default TeamInviteModal;