import React from "react";
import { Upload } from "antd";

import { errorNotification } from "components/request-result";


const UploadFile = ({
        variables = {},
        uploadMutation = () => {},
        loading,
        extraClass = "",
        accept = 'image/*',
        children
    }) => {


    const uploadProps = {
        className: extraClass,
        disabled: loading,
        accept: accept,
        showUploadList: false,
        "beforeUpload": (file) => {

            let uploadVar;

            if(variables.input !== undefined){
                uploadVar = { ...variables };
                uploadVar.input.file = file;
            } else {
                uploadVar = { ...variables, file }
            }

            uploadMutation({
                variables: {
                    ...uploadVar
                }
            }).catch((error) => {
                errorNotification(error);
            });

            return false;
        },

    };

    return(
        <Upload {...uploadProps}>
            { children }
        </Upload>
    );
};


export default UploadFile;