import React from "react";
import { useMutation } from "@apollo/client";
import { Button, Form, Input } from "antd";

import { CHANGE_PASSWORD } from "graphql/mutation/user-gql";

import { errorNotification, successNotification } from "components/request-result";


const formSubmit = ({ values, changePasswordRequest }) => {

    changePasswordRequest({
        variables : {
            input: {
                password: values.old_password,
                new_password: values.new_password,
                new_password_confirmation: values.new_password
            }
        }
    }).catch((error) => {
        errorNotification(error);
    });

};

const ChangePasswordForm = () => {

    const [ form ] = Form.useForm();

    const [changePasswordRequest, { loading }] = useMutation(CHANGE_PASSWORD,
        {
            update(cache, { data : { userChangePassword } }) {

                const { label, message } = userChangePassword;

                successNotification({
                    title: label,
                    description: message
                });
            }
        }
    );

    const formLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    };

    return (
        <Form
            name="change-password"
            form={form}
            {...formLayout}
            onFinish ={(values) => {
                formSubmit({ values, changePasswordRequest });
            }}
        >
            <div className="field-group">
                <Form.Item
                    label={ <span>Old password</span>}
                    name="old_password"
                    rules={ [
                        { required: true, message: 'Please input old password' }
                    ]}
                >
                    <Input.Password type="password" name="old-password" />
                </Form.Item>
                <Form.Item
                    label={ <span>new password</span>}
                    name="new_password"
                    rules={ [
                        { min: 8, message: 'Password must be at least 8 characters' },
                        { required: true, message: 'Please input new password' }
                    ]}
                >
                    <Input.Password type="password" name="new-password" />
                </Form.Item>
            </div>
            <div className="actions">
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={ loading }
                >
                    Save
                </Button>
            </div>
        </Form>
    );
};

export default ChangePasswordForm;