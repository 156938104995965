import React from "react";
import { useHistory } from "react-router-dom";

import { Loader } from "components/request-result";
import Icons from "components/icons";


const ProductBookCreateButton = ({ className, loading, createProduct }) => {

    const history = useHistory();

    return(
        <>
            <button
                className={`${ className } book`}
                onClick={ () => createProduct(
                    {
                        variables: {
                            type : "book",
                            is_searchable: true,
                            is_top : false
                        },
                        onUpdate: ({ product }) => {
                            history.push(`/product/${product.id}/edit`);
                        },
                    }
                )}
            >
                { loading  ?
                    <Loader  /> : <Icons.CreateProduct type="book" />
                }
                Add new book
            </button>
        </>
    );

};

export default ProductBookCreateButton;