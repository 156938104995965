import React from 'react';
import Icon from '@ant-design/icons';


const WebSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M24.3,8.5C15.9,8.5,9,14.9,8.1,23c0,0,0,0,0,0.1l0,0C8,23.6,8,24.2,8,24.9c0,0.6,0,1.2,0.1,1.7l0,0c0,0,0,0,0,0
	c0.8,7.5,6.7,13.5,14.2,14.4c0,0,0,0,0,0l0,0c0.7,0.1,1.3,0.1,2,0.1c9,0,16.3-7.3,16.3-16.3S33.3,8.5,24.3,8.5z M25.6,39.1
	c-0.1,0-0.3,0-0.4,0V34c1.9,0,3.5-0.2,4.9-0.5C28.5,37.4,26.2,38.8,25.6,39.1z M18.2,33.2c1.5,0.4,3.1,0.7,5,0.8v5.1
	c-0.1,0-0.2,0-0.3,0C22.2,38.8,19.7,37.3,18.2,33.2z M23.1,10.6C23.1,10.6,23.2,10.6,23.1,10.6l0,5.1c-1.6,0.1-3,0.3-4.3,0.6
	C20.3,12.9,22.3,11.2,23.1,10.6z M29.4,15.9c-1.3-0.2-2.7-0.3-4.3-0.3v-5c0,0,0.1,0,0.1,0C26,11.1,27.9,12.8,29.4,15.9z M33.4,24.2
	c-0.1-2-0.4-3.8-0.8-5.4c4.3,1.6,5.7,4.2,6,4.7c0,0.2,0,0.5,0,0.7H33.4z M31.4,24.2h-6.2v-6.7c2,0,3.7,0.2,5.2,0.5
	C30.9,19.8,31.3,21.9,31.4,24.2z M23.2,17.6v6.6H17c0.1-2.2,0.4-4.1,0.9-5.7C19.4,18.1,21.2,17.8,23.2,17.6z M15,24.2h-5
	c0-0.2,0-0.4,0-0.6c0.6-0.8,2.4-2.8,5.6-4.3C15.3,20.9,15.1,22.5,15,24.2z M15,26.2c0,1.4,0.1,2.7,0.3,3.8c-2.7-1.3-4.3-2.9-5.1-3.8
	H15z M17,26.2h6.2V32c-2.2-0.1-4-0.5-5.6-1.1C17.2,29.6,17,28,17,26.2z M25.2,32v-5.8h6.3c0,2-0.3,3.7-0.6,5.1
	C29.2,31.8,27.4,32,25.2,32z M33.4,26.2h5.1c-0.4,0.7-1.8,3-5.5,4.4C33.2,29.4,33.4,27.9,33.4,26.2z M37.5,19.3
	c-1.3-1-3.1-2.1-5.6-2.8c-0.9-2.3-2-4-3-5.2C32.7,12.6,35.9,15.5,37.5,19.3z M19.5,11.4c-1.1,1.3-2.2,3.2-3.1,5.6
	c-2.4,0.9-4.3,2-5.6,3.1C12.2,16,15.5,12.8,19.5,11.4z M10.7,29.5c1.2,1,2.9,2.1,5.1,3c0.7,2.4,1.7,4.2,2.7,5.5
	C14.9,36.4,12.1,33.3,10.7,29.5z M29.7,38.1c1-1.2,2-2.9,2.7-5.1c2.3-0.7,3.9-1.7,5.1-2.7C36.1,33.8,33.3,36.7,29.7,38.1z"/>
    </svg>
);

const WebIcon = props => {
    return <Icon component={ WebSvg } />;

};


export default WebIcon;