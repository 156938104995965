import React from 'react';
import Icon from '@ant-design/icons';


const NotificationSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M39.1,32.4c-0.7-0.5-2-1.6-2.7-3c-0.5-1.2-0.6-1.7-0.7-4c0-0.8-0.1-1.8-0.2-3.1c-0.4-5.9-1.3-8.1-4.2-10.8
	c-1.4-1.2-3-1.8-4.4-2.1c-0.1-1.3-1.2-2.3-2.5-2.3c-1.3,0-2.4,1-2.5,2.3c-1.4,0.3-3.1,0.9-4.5,2.2c-2.9,2.6-4.2,4.8-4.2,10.8
	c0,3.8,0,5.1-0.9,6.6c-0.8,1.3-1.6,2-2.5,3L9.7,32c-0.7,0.7-1.4,1.9-1.2,3.2c0.2,1.2,1.3,1.8,2.2,1.9c0,0,0,0,0,0h0
	c0.1,0,0.2,0,0.3,0h7.5c0.7,2.7,3.1,4.6,5.9,4.6s5.3-1.9,5.9-4.6h7.2c1.1,0,2.3-0.6,2.6-1.9C40.5,33.6,39.5,32.8,39.1,32.4z
	 M11.1,33.4l0.1-0.1c1-1,1.9-1.8,2.8-3.4c1.2-2,1.2-3.9,1.2-7.6c0-5.9,1.3-7.3,3.5-9.4c1.3-1.2,3.1-1.6,4.3-1.8
	c0.5-0.1,0.9-0.5,0.9-1V9.6c0-0.3,0.2-0.5,0.5-0.5s0.5,0.2,0.5,0.5v0.6c0,0.5,0.4,0.9,0.9,1c1.2,0.2,2.9,0.6,4.1,1.8
	c2.3,2.1,3.1,3.7,3.5,9.4c0.1,1.3,0.2,2.3,0.2,3.1c0.1,2.4,0.2,3.2,0.9,4.7c0.8,1.8,2.5,3.1,3.2,3.8c0.5,0.4,0.5,0.6,0.5,0.9
	c0,0.1-0.2,0.2-0.4,0.2h-27c-0.2,0-0.4-0.1-0.4-0.2C10.4,34.4,10.7,33.8,11.1,33.4z M24.5,39.7c-1.7,0-3.2-1.1-3.8-2.7h7.7
	C27.8,38.6,26.3,39.7,24.5,39.7z"/>
    </svg>
);

const NotificationIcon = props => {
    return <Icon component={ NotificationSvg } />;

};


export default NotificationIcon;