import useVarParam from "./use-var-param";
import { metaFields, packageRules } from "utils";


const useCheckSet = (showroom) => {

    const varParam = useVarParam();

    const meta = metaFields.normalize(showroom.meta_fields);

    let rules = false;

    if(showroom.package && showroom.package.package_rules){
        rules = packageRules.normalize( showroom.package.package_rules );
    }

    return {
        showroom,
        meta : {
            fields : meta,
            getValue : metaFields.getValue
        },
        rules : {
            fields : rules,
            getField : packageRules.getField
        },
        showroomCounts : varParam.get("standCounts"),
        pages: {
            product : varParam.get("productsOn"),
            communication : varParam.get("communicationOn"),
            downloads : varParam.get("downloadOn")
        }
    };
}

export default useCheckSet;