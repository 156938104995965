import React from "react";
import { Link, useLocation } from "react-router-dom";

import Icons from "components/icons";
import { FavoriteButton } from "components/favorite";
import { link } from "utils";


const ProductTopSlide = ({ product, control }) => {

    const location = useLocation();

    let url = `/product/${product.id}${ product.title ? "-" + link.toSlug(product.title) : ""}`;

    if(product.status === "draft"){
        url += "/edit";
    }

    return(
        <div className="product-card" key={ product.id }>
            <div className="product-cover">
                { product.cover ?
                    <img src={ product.cover } alt={ product.title } /> :
                    <Icons.NoImage />
                }
            </div>
            <div className="product-description">
                <h5>{ product.title }</h5>
            </div>
            <div className="product-actions-wrap">
                <div className="product-actions">
                    <Link
                        to={{
                            pathname: url,
                            state: {
                                from : location.pathname,
                                control
                            }
                        }}
                        className="link-button second"
                    >View more</Link>
                    <div className="to-favorite">
                        <FavoriteButton
                            favorite={ product.my_favorite }
                            type={ "Product" }
                            id={ product.id }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProductTopSlide;