import { useEffect } from "react";

import { cssVar, hex, metaFields, getHolderElement } from "utils";
import { useExhibitionEvent, useVarParam } from "components/use-hooks";


const useAppSetup = () => {

    const ee = useExhibitionEvent();

    const { normalize, getBoolValue, getValue } = metaFields,
        meta = normalize(ee.meta_fields);

    const varParam = useVarParam();

    useEffect( () => {

        cssVar.setSelector("html").set({
            "card-glow-color": hex.toRgb(getValue(meta, "coliseum_glow")),
            "primary-color": hex.toRgb(getValue(meta, "primary_color")),
            "primary-color-hex": getValue(meta, "primary_color"),
            "antd-wave-shadow-color": getValue(meta, "primary_color")
        });

    },[ee, meta, getValue]);

    varParam.set( {
        productsOn: getBoolValue(meta, "product_active"),
        headerClass: getValue(meta, "ee_main_bg_type") === "image" ? ["bg-primary-image"] : ["bg-primary"],
        searchOn: ee.status === "active"
    })

    let node = null;

    if(getValue(meta, "ee_main_bg_type") === "image"){

        node = getHolderElement(".app").element;

        if(node && getValue(meta, "ee_main_bg")){

            if(varParam.get("appBlockClass").indexOf("bg-primary-image") !== -1){
                node.style.setProperty(`background-image`, `url(${getValue(meta, "ee_main_bg")})`);
            } else {
                node.removeAttribute('style');
            }
        }

        node = getHolderElement(".main-menu").element;

        if(node && getValue(meta, "ee_main_header_bg")){
            if(varParam.get("headerClass").indexOf("bg-primary-image") !== -1) {
                node.style.setProperty(`background-image`, `url(${getValue(meta, "ee_main_header_bg")})`);
            } else {
                node.removeAttribute('style');
            }
        }

    }

}

export default useAppSetup;