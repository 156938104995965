import React from "react";

import Icons from "components/icons";
import { metaFields } from "utils";


const ShowroomCompanyAddress = ( { meta } ) => {

    const { getValues } = metaFields;

    const address = getValues(meta, ["company_address", "company_city", "company_country", "company_postcode"], false);

    const {
        company_address,
        company_city,
        company_country,
        company_postcode
    } = meta;

    return(
        <>
        { Object.keys(address).length > 0 &&
            <div className="address-block">
                <Icons.Location/>
                { company_address &&
                    <p>{ company_address.meta_value }</p>
                }
                { company_city &&
                    <p>{ company_city.meta_value }</p>
                }
                { company_country &&
                    <p>{ company_country.meta_value }</p>
                }
                { company_postcode &&
                    <p>{ company_postcode.meta_value }</p>
                }
                { Object.keys(address).length === 4 &&
                    <p>
                        <a href={ `https://www.google.com/maps/place/${ Object.values(address).join("+") }` }
                           rel="noreferrer"
                           target="_blank"
                        >see on map</a>
                    </p>
                }
            </div>
        }
        </>
    );
};

export default ShowroomCompanyAddress;