import { gql } from '@apollo/client';


export const GET_EE_BY_URL = gql`
    query EE_By_Url($uri : String!){
        eeLocateByUrl(uri: $uri){
            id
            title
            uri
            start_at
            end_at
            archive_at
            status
            meta_fields{
                id
                meta_key
                meta_value
                meta_type
            }
            packages{
                id
                title
                price
                ee_id
                pdf
                package_rules{
                    id
                    title
                    package_id
                    rule_key
                    order
                    min_value
                    max_value
                    type
                    active
                }
                created_at
                updated_at
            }
            created_at
            updated_at
        }
    }
`;