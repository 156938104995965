import React from "react";
import { useQuery } from "@apollo/client";

import { SEARCH_PRODUCTS } from "graphql/query/search-gql";

import { Loader } from "components/request-result";
import { SearchResults } from "components/search";



const SearchProductsSubPage = ({ match, ee }) => {

    const { loading, data } = useQuery( SEARCH_PRODUCTS, {
        variables: {
            eeId: ee.id,
            text: match.params.searchQuery
        },
        fetchPolicy: "network-only"
    });

    const {
        eeSearchProducts: {
            paginatorInfo,
            data : products,
        } = {}
    } = data || {};

    return(
        <div className="search-result-wrap">
            { loading &&
                <Loader />
            }
            { data && !loading &&
            <>
                <h1>We found <b>{ paginatorInfo.total }</b> products for <b>“{ match.params.searchQuery }”</b></h1>
                <SearchResults
                    products={ products }
                    paginatorInfo={ paginatorInfo }
                />
            </>
            }
        </div>
    );
};

export default SearchProductsSubPage;
