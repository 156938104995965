import React from "react";

import { AvatarBlock } from "components/avatar";
import { metaFields } from "utils";
import Icons from "components/icons";


const prepareUserStand = (user, meta, getValue) => {

    let userStand;

    if(getValue(meta, "company_member_flag") === 1){
        userStand = {
            id: undefined,
            title :  getValue(meta, "company_name"),
            logo :  getValue(meta, "company_logo")
        };
    }

    if(user?.stand && user.stand.length > 0){
        userStand = {
            id: user.stand[0].id,
            title :  user.stand[0].title,
            logo :  user.stand[0].logo
        };
    }

    return userStand;
}

const UserAvatar = ({
        user,
        size = 122,
        companyView = 'full',
        children,
        h
    }) => {

    const { normalize, getValue } = metaFields;
    const meta = normalize(user?.meta_fields);

    const userStand = prepareUserStand(user, meta, getValue);

    return(
        <AvatarBlock
            shape="circle"
            size={ size }
            image={ user.avatar }
            badge={ !user.avatar ? <div className="badge-user"><Icons.Avatar type={ 'simple' } /></div> : null }
        >
            { companyView === 'full' ?
                <>
                    <h3>{ user.name } { user.surname }</h3>
                    <p className="sub-text-bold">{ getValue(meta, "profile_function") }</p>
                    { userStand ?
                        <AvatarBlock
                            shape="square"
                            size={ size / 2 }
                            image={ userStand.logo }
                        >
                            <h4>{ userStand.title }</h4>
                            <p className="sub-text">{ user.role }</p>
                        </AvatarBlock> :
                        <p className="user-role sub-text">
                            { user.role }
                        </p>
                    }
                </> :
                <>
                    { h === 3 ?
                        <h3>{ user.name } { user.surname }</h3> :
                        <h5>{ user.name } { user.surname }</h5>
                    }
                    <p className="sub-text">
                        { getValue(meta, "profile_function") }
                        { userStand?.title ? ` at ${ userStand.title }` : '' }
                    </p>
                    <p>{ getValue(meta, "profile_email") }</p>
                </>
            }
            { children }
        </AvatarBlock>
    );
}

export default UserAvatar;