import { gql } from '@apollo/client';


export const USER_LOGIN = gql`
  mutation UserLogin($data: UserLoginInput!) {
      userLogin(input: $data){
        access_token
      }
    }
`;

export const USER_REGISTRATION = gql`
  mutation UserRegistration($input: UserRegisterInput) {
      userRegister(input: $input){
        label
        message
      }
    }
`;

export const USER_LOGOUT = gql`
    mutation UserLogout{
        userLogout {
            status
            message
        }
    }
`;

export const CHANGE_PASSWORD = gql`
    mutation ChangePassword($input: UserCurrentChangePassword!) {
        userChangePassword(input: $input){
            label
            message  
        }
    }`;

export const FORGOT_PASSWORD = gql`
    mutation UserForgotPassword($input: UserForgotPasswordInput!) {
        userForgotPassword(input: $input){
            label
            message  
        }
    }`;

export const CHANGE_FORGOT_PASSWORD = gql`
    mutation ChangeForgotPassword(
        $password: String!
        $token: String!
    ){
        userUpdateForgotPassword(
            password: $password
            token: $token
        ){
            label
            message
        }
    }`;

export const ACTIVATE_ACCOUNT = gql`
    mutation UserActivate( $token: String ){
        userActivate( token: $token ){
            label
            message
        }
    }`;

export const USER_UPLOAD_AVATAR = gql`
    mutation UserUploadAvatar(
        $file: Upload!
    ){
        userUploadAvatar(
            file: $file
        ){
            label
            message 
            avatar
        }
    }`;

export const USER_UPLOAD_META = gql`
    mutation UserMetaUpload(
        $meta_key: String!
        $file: Upload!
    ){
        userMetaUpload(
            meta_key: $meta_key
            file: $file
        ){
            label
            message 
            user{
                id
                avatar
                meta_fields{
                    id
                    user_id
                    meta_key
                    meta_type
                    meta_value
                }
            }
        }
    }`;

export const UPDATE_PROFILE = gql`
    mutation UpdateProfile( 
        $userInput : UserUpdateInput!
        $metaInput : [UserMetaInput!]
    ){
        userUpdate( 
            input: $userInput
        ){
            label
            message
        }
        userMetaSet( 
            input: $metaInput
        ){
            label
            message
            user{
                id
                name
                surname
                email
                email_original
                locale
                status
                role
                avatar
                meta_fields{
                    id
                    user_id
                    meta_key
                    meta_type
                    meta_value
                }
            }
        }
    }`;

export const UPDATE_USER = gql`
    mutation UpdateAccount( 
        $input : UserUpdateInput!
    ){
        userUpdate( 
            input: $input
        ){
            label
            message
            user{
                id
                name
                surname
                email
                email_original
                locale
                status
                role
                avatar
            }
        }
    }`;
