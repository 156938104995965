import React from "react";
import { Link, useLocation } from "react-router-dom";

import { AvatarImage } from "components/avatar";

import "./product-author-content.scss";


const ProductAuthorContent = ({ author, control, action }) => {

    let location = useLocation();

    return(
        <div className="product-author-content">
            <div className="image-wrap">
                <AvatarImage
                    size={ 220 }
                    photoUrl={ author.cover }
                />
            </div>
            <div className="description-wrap">
                <h3 className="title">{ author.title }</h3>
                <div className="text">{ author.description }</div>
                { control &&
                    <div className="action">
                        <Link
                            className="link-button"
                            to={`${location.pathname}${location.hash}-edit`}
                        >
                            Edit Author
                        </Link>
                    </div>
                }
            </div>
        </div>
    )
};

export default ProductAuthorContent;