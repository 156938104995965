import React from "react";
import { Switch, Route } from 'react-router-dom';

import { userForms } from "../user";


const AuthorizationRoutes = () => {
    return(
        <Switch>
            <Route path="/forgot-password/:forgotToken" component={ userForms.ChangeForgotPasswordForm } exact />
            <Route path="/forgot-password/" component={ userForms.ForgotPasswordForm } exact />
            <Route path="/registration/" component={ userForms.RegistrationForm } exact />
            <Route path="/confirm-account/" component={ userForms.ActivationAccountForm } />
            <Route component={ userForms.SignInForm } />
        </Switch>
    );
};

export default AuthorizationRoutes;