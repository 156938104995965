import CardProductFlip from "./card-product-flip";
import CardProductVertical from "./card-product-vertical";

import "./card-product.scss";

const CardProduct = {
    Flip : CardProductFlip,
    Vertical: CardProductVertical
}

export default CardProduct;