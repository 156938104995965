import React from "react";
import { Form, Select } from "antd";


const { Option } = Select;

const TaxonomyFormItem = ({
        title,
        titlePrefix = "",
        selectPrefix = "",
        taxonomyId,
        initialTerms,
        name = `taxonomy_${taxonomyId}-terms`,
        terms = [],
        min = 0,
        max,
        minTextPrefix = "You can't choose more then",
        maxTextPrefix = "Please choose at least"
    }) => {

    return(
        <Form.Item
            label={ <span>{ titlePrefix } { title }</span> }
            name={ name }
            initialValue={ initialTerms }
            rules={ [
                {
                    max: max, type: "array",
                    message: `${maxTextPrefix} ${max} ${title}`
                },
                {
                    min: min, type: "array",
                    message: `${minTextPrefix} ${min} ${title}`
                }
            ]}
        >
            <Select
                mode="multiple"
                placeholder={`${selectPrefix} ${title}`}
                optionFilterProp={ "label" }
            >
                { terms.map(
                    (term) => {
                        return (
                            <Option
                                key={ term.id }
                                value={ term.id }
                                label={ term.title }
                            >
                                {term.title}
                            </Option>
                        )
                    })
                }
            </Select>
        </Form.Item>
    )
};

export default TaxonomyFormItem;