import React from "react";
import { Form, Button } from "antd";

import { ProductFileWrap } from "../file-wrap";
import { UploadFile } from "components/service";
import { Loader } from "components/request-result";
import Icons from "components/icons";


const ProductFile = ({
          value,
          accept,
          uploadMutation,
          variables,
          loading,
          buttonText,
          deleteMutation,
          deleteLoading
    }) => {

    if(!value){
        return(
            <Form.Item
                name={`${variables.input.meta_key}-file-unset`}
                initialValue={ value }
            >
                <UploadFile
                    variables={ variables }
                    uploadMutation={ uploadMutation }
                    loading={ loading }
                    accept={ accept }
                    extraClass="product-attachment-upload-wrap"
                >
                    <button
                        type="button"
                        className="product-attachment-upload"
                    >
                        { loading ? <Loader /> : <Icons.File /> }{ buttonText }
                    </button>
                </UploadFile>
            </Form.Item>
        );
    }

    return(
        <div className="product-from-file-container">
            <ProductFileWrap
                filepath={ value }
            />
            <Button
                type="default"
                disabled={ deleteLoading }
                className="delete-file-button"
                onClick={ () => deleteMutation() }
            >
                { deleteLoading ? <Loader /> : <Icons.Trash /> }
            </Button>
        </div>
    );

};

export default ProductFile;