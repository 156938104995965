import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Popover } from "antd";

import { AvatarImage } from "components/avatar";
import Icons from "components/icons";


const MenuPopover = ({ title, image, imageShape , children }) => {

    const location = useLocation();
    const [popoverVisible, setPopoverVisible] = useState(false);

    useEffect(() => {
        setPopoverVisible(false);
    }, [location]);


    return (
        <Popover
            content={ children }
            overlayClassName={"menu-popover"}
            title={
                <>
                    <h6>{ title }</h6>
                    <button className="menu-popover-close" onClick={ () => setPopoverVisible(false)}>
                        <Icons.Cross />
                    </button>
                </>
            }
            trigger={ ["click"] }
            placement="bottomRight"
            visible={ popoverVisible }
            onVisibleChange={ () => setPopoverVisible(!popoverVisible) }
        >
            <button className="menu-popover-button">
                <AvatarImage
                    photoUrl={ image }
                    shape={ imageShape }
                    size={ 40 }
                />
            </button>
        </Popover>
    );
};

export default MenuPopover;