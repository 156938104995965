import React from "react";

import ProductMediaVideoForm from "./product-media-video-form";
import { metaFields, youtubeUrl } from "utils";
import Icons from "components/icons";

import "./product-media-video.scss";


const ProductMediaVideo = ({ product, control, form }) => {

    const { normalize, getValue } = metaFields;
    const meta = normalize(product.meta_fields);

    return(
        <>
            { control ?
                <ProductMediaVideoForm
                    form={ form }
                    meta={ meta }
                    product={ product }
                /> :
                <div className="product-media-video">
                    { getValue(meta, "product_video_link") ?
                        <iframe
                            src={ `${youtubeUrl.embed(meta.product_video_link.meta_value)}` }
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />:
                        <Icons.NoVideo />
                    }
                </div>
            }
        </>
    )
};

export default ProductMediaVideo;