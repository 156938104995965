import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Form } from "antd";

import { Product } from "components/product";
import { ProductWrap, WrapColumn } from "components/wraps";
import Icons from "components/icons";


const closeAction = ({ location, history }) => {

    if(location.state === undefined){
       return history.push(`/showroom-configurator/product`);
    }

    return history.push(location.state.from);

}

const ProductEditSubPage = ({ product }) => {

    const history = useHistory();
    const location = useLocation();

    const [ form ] = Form.useForm();

    return(
        <ProductWrap className="product-edit">
            <WrapColumn position={ "left" }>
                <button
                    onClick={ () =>
                        closeAction({
                            history,
                            location,
                            standId : product.stand.id
                        })
                    }
                    className="product-page-close"
                >
                    <Icons.Cross /> Close
                </button>
                <div className="product-media-wrap">
                    <Product.MediaType
                        form={ form }
                        product={ product }
                        control={ true }
                    />
                </div>
            </WrapColumn>
            <WrapColumn position={ "right" }>
                <div  className="product-content-wrap">
                    <Product.EditForm
                        product={ product }
                        control={ true }
                        form={ form }
                    />
                </div>
            </WrapColumn>
        </ProductWrap>
    );
}

export default ProductEditSubPage;