import React, { useState } from "react";
import { Button, Modal } from "antd";

import UserProfileContent from "../user-profile-content";
import Icons from "components/icons";

import "./user-info-modal.scss";


const UserProfileModal = ({
        userId,
        buttonText = "See user profile",
        buttonType = "ghost",
        buttonAction = () => {}
    }) => {

    const [visibleModal, toggleModal] = useState(false);

    return(
        <>
            <Button
                type={ buttonType }
                className="user-profile-modal-button"
                onClick={ () => {
                    toggleModal(true);
                    buttonAction();
                }}
            >
                { buttonText }
            </Button>
            <Modal
                className="user-profile-modal"
                visible={ visibleModal }
                closeIcon={ <Icons.Cross /> }
                width={ 800 }
                destroyOnClose={ true }
                centered
                footer={ null }
                onCancel={() => toggleModal(false)}
            >
                <UserProfileContent
                    userId={ userId }
                />
            </Modal>
        </>
    )
};

export default UserProfileModal;