import React from "react";
import { Switch, Route, Redirect } from 'react-router-dom';

import Pages from "../pages";
import { useVarParam } from "components/use-hooks";


const ShowroomControlRoutes = ({ parent = "showroom-configurator", stand, me }) => {

    const { Showroom : { SubPage } } = Pages;

    const params = {
        stand,
        spanList: {
            xs: 24, md: 24, lg: 24, xl: 22, xxl: 19, span: 24
        },
        control: me.stand_permission && me.stand_permission.permission !== "member",
        configurator: true
    };

    const productsOn = useVarParam().get("productsOn");

    return(
        <Switch>
            { productsOn &&
                <Route path={`/${ parent }/product`} exact>
                    <SubPage.Product {...params} />
                </Route>
            }
            <Route path={`/${ parent }/company`} exact>
                <SubPage.Company {...params} />
            </Route>
            { useVarParam().get("communicationOn") &&
                <Route path={`/${parent}/communication`} exact>
                    <SubPage.Communication {...params} control={true}/>
                </Route>
            }
            { useVarParam().get("downloadOn") &&
                <Route path={`/${parent}/downloads`} exact>
                    <SubPage.Downloads {...params} />
                </Route>
            }
            <Route path={`/${ parent }/profile`} exact>
                <SubPage.Profile {...params} />
            </Route>
            <Route path={`/${ parent }/team`} exact>
                <SubPage.Team {...params} />
            </Route>
            <Route path={`/${ parent }/packages`} exact>
                <SubPage.Package {...params} />
            </Route>
            <Route path={`/${ parent }/analytics/`} >
                <SubPage.Analytics {...params} />
            </Route>
            <Route>
                <Redirect to={`/${ parent }/${ productsOn ? 'product' : 'company' }`} />
            </Route>
        </Switch>
    );
};

export default ShowroomControlRoutes;