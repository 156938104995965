import React, {useState} from "react";


const MoreBut = ({ setShowMore }) => {
    return (
        <span>
            <>...</>
            <button type="button"
                    className="show-more"
                    onClick={() => setShowMore(true)}
            >
                more
            </button>
        </span>
    )
};

const MoreText = ({ text, length }) => {

    const [ showMore, setShowMore ] = useState(false);

    let readyText,
        showMoreBut = false;

    if(text === null){
        return null;
    }

    if(((length && text.length > length) || text.match(/\n/)) && !showMore) {

        if(text.match(/\n/) && text.match(/\n/).index < length){
            readyText = text.slice(0, text.indexOf('\n'));
        } else {
            readyText = text.slice(0, text.slice(0, length).lastIndexOf(' '));
        }

        showMoreBut = true;
    } else {
        readyText = text;
    }

    return (
        <p>
            { readyText  }
            { showMoreBut &&
                <MoreBut setShowMore={setShowMore} />
            }
        </p>
    );
};

export default MoreText;