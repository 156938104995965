import React from "react";

import { CardUser } from "components/card";


const UserGrid = ({ users }) => {

    return(
        <div className="grid-two-col">
            { users &&  users.map(
                (user) => {
                    return(
                        <CardUser.Horizontal
                            key={ user.id }
                            user={ user }
                        />
                    )
                })
            }
        </div>
    )

};

export default UserGrid;