import React from "react";

import { FavoriteMenuBar } from "components/menus";
import { ContainerWrap } from "components/wraps";

import "./favorite-header.scss";


const FavoriteHeader = ({ spanList }) => {

    return(
        <ContainerWrap
            spanList={ spanList }
            className="favorite-header-wrap"
        >

            <h2>My favorite list</h2>
            <FavoriteMenuBar />
        </ContainerWrap>
    );
};

export default FavoriteHeader;