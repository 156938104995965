import React from 'react';
import Icon from '@ant-design/icons';


const FileSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M35.4,18.8l-8.2-8.1c-0.2-0.2-0.6-0.4-0.9-0.4H15.1c-0.7,0-1.3,0.6-1.3,1.3v26.2c0,0.7,0.6,1.3,1.3,1.3h19.4
	c0.7,0,1.3-0.6,1.3-1.3V19.7C35.8,19.4,35.7,19,35.4,18.8z M32.8,20.7h-7c-0.2,0-0.4-0.2-0.4-0.4v-7c0-0.4,0.4-0.5,0.7-0.3l7,7
	C33.3,20.3,33.1,20.7,32.8,20.7z"/>
    </svg>
);

const FileOutlineSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M35.9,18.8c0-0.3-0.1-0.5-0.3-0.7l-9.8-9.5c0,0,0,0,0,0c0,0,0,0,0,0c-0.2-0.2-0.4-0.3-0.7-0.3H12.1c-0.6,0-1,0.4-1,1v30.1
	c0,0.6,0.4,1,1,1h22.8c0.6,0,1-0.4,1-1L35.9,18.8C35.9,18.8,35.9,18.8,35.9,18.8z M26,11.6l6.5,6.3l-6.5,0L26,11.6z M33.9,38.3H13.1
	V10.2H24l0,8.6c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3l8.9,0V38.3z"/>
    </svg>
);

const FileIcon = props => {

    if(props.type === "outline"){
        return <Icon component={ FileOutlineSvg } className={ props.className }/>;
    }

    return <Icon component={ FileSvg } className={ props.className } />;

};


export default FileIcon;