import React from "react";
import { useQuery } from "@apollo/client";

import { GET_ME } from "graphql/query/user-gql";

import { userForms } from "components/user";


const ProfileSettingsSubPage = () => {

    const { data } = useQuery( GET_ME, { fetchPolicy: "cache-only" } );
    const { ChangePasswordForm, AccountForm } = userForms;

    return (
        <>
            <h2>Account settings</h2>
            { data !== undefined &&
                <>
                    <div className="form-wrap">
                        <h4>Account</h4>
                        <AccountForm user={ data.me } />
                    </div>
                    <div className="form-wrap">
                        <h4>Password</h4>
                        <ChangePasswordForm />
                    </div>
                </>
            }
        </>
    );
};


export default ProfileSettingsSubPage;