import React from "react";

import ShowroomControlDesignMenu from "./showroom-control-design-menu";
import ShowroomControlManageMenu from "./showroom-control-manage-menu";

import "./showroom-control-menu.scss";


const ShowroomControlMenu = ({ standId }) => {
    return(
        <nav className="showroom-control-menu">
            <div className="menu-block">
                <p className="menu-label">Design showroom</p>
                <ShowroomControlDesignMenu standId={standId} />
            </div>
            <div className="menu-block">
                <p className="menu-label">Manage showroom</p>
                <ShowroomControlManageMenu />
            </div>
        </nav>
    );
};

export default ShowroomControlMenu;