import React from "react";
import { useMutation } from "@apollo/client";
import { Form, Input } from "antd";

import { USER_UPLOAD_META } from "graphql/mutation/user-gql";

import { AvatarUpload } from "components/avatar";
import { successNotification } from "components/request-result";
import { metaFields } from "utils";


const ProfilePersonalInfo = ({ form, formLayout, user }) => {

    const { normalize, getValue } = metaFields;
    const meta = normalize(user.meta_fields);

    const [metaUpload, { loading }] = useMutation(USER_UPLOAD_META,
        {
            update(cache, { data }) {

                const {
                    userMetaUpload : {
                        label,
                        message,
                        user: {
                            avatar
                        }
                    }
                } = data;

                form.setFieldsValue({ "avatar-image-unset" : avatar });

                successNotification({
                    title: label,
                    description: message
                });
            }
        });

    return (
        <Form
            name="personal-info"
            form={form}
            {...formLayout}
            scrollToFirstError
        >
            <Form.Item
                label={ <span>Photo</span>}
                name="avatar-image-unset"
                initialValue={ user.avatar }
                //rules={[{ required: true, message: 'Please upload your Photo' }]}
            >
                <AvatarUpload
                    uploadMutation={ metaUpload }
                    variables={ { meta_key : "avatar" } }
                    image={ user.avatar }
                    loading={ loading }
                />
            </Form.Item>
            <div className="field-group">
                <Form.Item
                    label={ <span>Name</span>}
                    name="name-field"
                    initialValue={ user.name }
                    rules={ [
                        { required: true, message: 'Please input your name' }
                    ]}
                >
                    <Input placeholder="Max" />
                </Form.Item>
                <Form.Item
                    label={ <span>Surname</span>}
                    name="surname-field"
                    initialValue={ user.surname }
                    rules={ [
                        { required: true, message: 'Please input your surname' }
                    ]}
                >
                    <Input placeholder="Mustermann" />
                </Form.Item>
            </div>
            <div className="field-group">
                <Form.Item
                    label={ <span>Function</span>}
                    name="profile_function-string"
                    initialValue={ getValue(meta, "profile_function") }
                    rules={ [
                        { required: true, message: 'Please input your function' }
                    ]}
                >
                    <Input placeholder="Manager" />
                </Form.Item>
                <Form.Item
                    label={ <span>Mobile number</span>}
                    name="profile_mobile_phone-string"
                    initialValue={ getValue( meta, "profile_mobile_phone" ) }
                >
                    <Input placeholder="+0 000 000 00 00" />
                </Form.Item>
            </div>
            <div className="field-group">
                <Form.Item
                    label={ <span>Phone</span>}
                    name="profile_phone-string"
                    initialValue={ getValue( meta, "profile_phone") }
                >
                    <Input placeholder="+0 000 000 00 00" />
                </Form.Item>
                <Form.Item
                    label={ <span>Contact E-Mail</span>}
                    name="profile_email-string"
                    initialValue={ getValue( meta,  "profile_email" ) }
                    rules={[
                        {
                            required: true,
                            message: 'Please input your E-Mail'
                        },
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail',
                        }
                    ]}
                >
                    <Input placeholder="max_mustermann@mail.com" />
                </Form.Item>
            </div>
            <Form.Item
                hidden
                name="profile_filed_flag-string"
                initialValue={ 1 }
            >
                <Input />
            </Form.Item>
        </Form>
    );
};

export default ProfilePersonalInfo;