import React from "react";
import { Form, Select } from "antd";

import { useTaxonomy } from "components/use-hooks";
import { TaxonomyFormItem } from "components/service";



const CPExhibitionInfo = ({ form, formLayout, stand }) => {

    const taxonomies = useTaxonomy("showroom_taxonomies");

    return (
        <Form
            name="exhibition-info"
            form ={ form }
            {...formLayout}
        >
            { taxonomies && taxonomies.map(
                ({ id : taxonomyId, title, taxonomy_terms }) => {

                    let initialTerms = stand.terms.filter(({ taxonomy_id }) => taxonomy_id === taxonomyId)
                                                  .map(({ id }) => id);

                    return (
                        <TaxonomyFormItem
                            initialTerms={ initialTerms }
                            terms={ taxonomy_terms }
                            min={ 1 }
                            max={ 5 }
                            key={ taxonomyId }
                            titlePrefix={ "We propose such" }
                            selectPrefix={ "Enter" }
                            taxonomyId={ taxonomyId }
                            title={ title }
                        />
                    );

                })
            }
            <Form.Item
                label={ <span>Search keywords</span>}
                name="keywords-field"
                initialValue={ stand.keywords ? stand.keywords.split(",") : []}

                rules={ [
                    {
                        max: 10, type: "array",
                        message: "You can't input more then 10 keywords"
                    },
                    {
                        min: 0, type: "array",
                        message: 'Please input keywords'
                    }
                ]}
            >
                <Select
                    mode="tags"
                    tokenSeparators={[',']}
                    getPopupContainer={ () => null }
                    placeholder="Enter search keywords"
                />
            </Form.Item>
        </Form>
    );
};

export default CPExhibitionInfo;