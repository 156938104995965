import React from 'react';
import Icon from '@ant-design/icons';


const PrivacySvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M25.3,42.2c-0.2,0-0.4-0.1-0.5-0.2c-0.1,0-6.1-3.8-10.1-6.8c-4.5-3.2-4.1-10.1-4.1-10.4l0-10.6c0-0.4,0.2-0.7,0.6-0.9
		l13.7-6.7c0.3-0.1,0.6-0.1,0.9,0l13.7,6.7c0.3,0.2,0.6,0.5,0.6,0.9V25c0,0.6,0.3,7.2-4.1,10.3c-4,2.9-9.1,6.2-10.1,6.8
		C25.6,42.2,25.5,42.2,25.3,42.2z M12.5,15v10c0,0.1-0.3,6.1,3.2,8.7c3.3,2.4,7.9,5.4,9.5,6.4c1.7-1.1,6-3.9,9.4-6.4
		c3.5-2.6,3.2-8.6,3.2-8.6c0,0,0,0,0-0.1V15L25.3,8.8L12.5,15z"/>
        <path d="M24.4,28.7c-0.2,0-0.5-0.1-0.7-0.3l-4.6-4.2c-0.4-0.4-0.4-1-0.1-1.4c0.4-0.4,1-0.4,1.4-0.1l3.9,3.5l6.7-6.5
		c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4l-7.4,7.1C24.9,28.6,24.6,28.7,24.4,28.7z"/>
    </svg>
);

const PrivacyIcon = props => {
    return <Icon component={ PrivacySvg } />;

};


export default PrivacyIcon;