import React from 'react';
import Icon from '@ant-design/icons';


const FacebookSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M30.8,11.5v5.6h-2.8c-0.5,0-0.8,0.3-1.1,0.7c-0.2,0.4-0.3,0.9-0.3,1.4v3.5h4.2v5.7h-4.2v11.2h-5.7V28.4h-4.2v-5.7h4.2v-5.6
	c0-1.6,0.6-2.9,1.6-4c1.1-1.1,2.4-1.6,4-1.6H30.8z"/>
    </svg>
);

const LinkedinSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M11.7,17.6h5.7v18.4h-5.7V17.6z M14.5,8.4c1.8,0,3.3,1.5,3.3,3.3S16.3,15,14.5,15c-1.8,0-3.3-1.5-3.3-3.3
		S12.7,8.4,14.5,8.4"/>
        <path d="M21,17.6h5.5v2.5h0.1c0.8-1.4,2.6-3,5.4-3c5.8,0,6.9,3.8,6.9,8.8v10.1h-5.7V27c0-2.1,0-4.9-3-4.9c-3,0-3.4,2.3-3.4,4.7v9.1
		H21V17.6z"/>
    </svg>
);

const InstagramSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M30.4,41.1H17.8c-5.5,0-10-4.5-10-10V18.5c0-5.5,4.5-10,10-10h12.6c5.5,0,10,4.5,10,10v12.6
		C40.4,36.6,35.9,41.1,30.4,41.1z M17.8,11c-4.2,0-7.5,3.4-7.5,7.5v12.6c0,4.2,3.4,7.5,7.5,7.5h12.6c4.2,0,7.5-3.4,7.5-7.5V18.5
		c0-4.2-3.4-7.5-7.5-7.5H17.8z"/>
        <path d="M24.1,33.1c-4.5,0-8.2-3.7-8.2-8.2c0-4.5,3.7-8.2,8.2-8.2c4.5,0,8.2,3.7,8.2,8.2
		C32.4,29.4,28.7,33.1,24.1,33.1z M24.1,19.1c-3.2,0-5.7,2.6-5.7,5.7c0,3.2,2.6,5.7,5.7,5.7c3.2,0,5.7-2.6,5.7-5.7
		C29.9,21.6,27.3,19.1,24.1,19.1z"/>
        <path d="M35.4,15.7c0,1.2-1,2.1-2.1,2.1c-1.2,0-2.1-1-2.1-2.1c0-1.2,1-2.1,2.1-2.1C34.4,13.5,35.4,14.5,35.4,15.7z"
        />
    </svg>
);

const YoutubeSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M40.2,16.9c0.5,2.4,0.7,5.1,0.7,8.2c0,3.1-0.2,5.8-0.7,8.3c-0.5,2.4-1.4,3.9-2.6,4.4c-0.6,0.2-2.2,0.4-4.8,0.5
	c-2.5,0.1-5.4,0.2-8.6,0.2c-3.2,0-6-0.1-8.6-0.2c-2.5-0.1-4.1-0.3-4.8-0.5c-1.2-0.5-2.1-2-2.6-4.4c-0.5-2.4-0.7-5.2-0.7-8.3
	c0-3.1,0.2-5.8,0.7-8.2c0.5-2.4,1.4-3.9,2.6-4.4c0.6-0.2,2.2-0.3,4.8-0.4c2.5-0.1,5.4-0.2,8.6-0.2c3.2,0,6,0.1,8.6,0.2
	c2.5,0.2,4.1,0.3,4.8,0.5C38.8,13.1,39.7,14.5,40.2,16.9z M30.9,25.2l-10-7.5v15L30.9,25.2z"/>
    </svg>
);


const SocialIcon = props => {

    let svg;

    switch(props.type){
        case "facebook": svg = FacebookSvg; break;
        case "linkedin": svg = LinkedinSvg; break;
        case "instagram": svg = InstagramSvg; break;
        case "youtube": svg = YoutubeSvg; break;
        default: return null;
    }


    return <Icon component={ svg } />;

};


export default SocialIcon;