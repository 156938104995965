import React from "react";



const ProductMediaSlide = ({ image, children, deleteSlide}) => {

    return (
        <div className="media-slide">
            { deleteSlide }
            { children ?
                children :
                <img src={ image } alt="" />
            }
        </div>
    );
};

export default ProductMediaSlide;