import React, { useState } from "react";
import { Modal, Input } from "antd";

import {
    useShowroomDownloadDelete,
    useShowroomDownloadUpdate
} from "../showroom-downloads-hook";

import Icons from "components/icons";


const { TextArea } = Input;

const ShowroomDownloadsEditModal = ({ attachment, hideModal, modalVisibility = false }) => {

    const [ description, setDescription ] = useState(attachment.description);

    const { updateMutation, loading : updateLoading } = useShowroomDownloadUpdate( {
        callBack : hideModal
    });

    const { deleteMutation, loading : deleteLoading } = useShowroomDownloadDelete({
        standId: attachment.stand.id,
        attachmentId : attachment.id,
        notify: attachment.status !== "draft",
        callBack : hideModal
    });

    return(
        <Modal
            className="standard-request-modal stand-download-edit-modal"
            visible={ modalVisibility }
            centered
            maskClosable={ attachment.status === "active" }
            closeIcon={ <Icons.Cross /> }
            onOk={ () => {
                updateMutation( {
                    variables: {
                        attachmentId: attachment.id,
                        description
                    }
                });

            }}
            onCancel={ () => {
                if(attachment.status === "draft"){
                    deleteMutation({
                        variables: { attachmentId: attachment.id }
                    })
                } else {
                    hideModal();
                }
            }}
            okButtonProps={{
                loading: updateLoading
            }}
            cancelButtonProps={{
                type: "default",
                loading: deleteLoading

            }}
            okText={ attachment.status === "draft" ? "Add file" : "Save" }
            cancelText="Cancel"
        >
            <h2>
                { attachment.status === "draft" ? "Add file" : "Edit file description" }
            </h2>
            <div className="attachment-file">
                <div className="icon-wrap">
                    <Icons.Document type={ attachment.extension } />
                </div>
                <div className="file-wrap">
                    <p className={ `name ${ attachment.status }` }>
                        <a href={ attachment.path } target="_blank" rel="noreferrer">
                            { attachment.original_name }
                            { attachment.status === "draft" ?  <span> (DRAFT)</span> : "" }
                        </a>
                    </p>
                    <p className="size sub-text">{ attachment.size }</p>
                </div>
            </div>
            <div className="attachment-description">
                <label>Description</label>
                <TextArea
                    rows={ 8 }
                    maxLength={ 190 }
                    showCount={
                        { formatter: ({ count, maxLength }) => maxLength - count }
                    }
                    placeholder="Add description"
                    defaultValue={ description }
                    onChange={ (event) => {
                        setDescription(event.target.value);
                    }}
                />
            </div>
        </Modal>
    );
};

export default ShowroomDownloadsEditModal;