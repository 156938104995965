import React, { useState } from "react";
import { Popover } from "antd";

import TeamDeleteUser from "../team-delete-user";
import Icons from "components/icons";

import "./team-menu.scss";



const TeamMenu = ( { standId, userId, me } ) => {

    const [popoverVisible, setPopoverVisible] = useState(false);

    return(
        <div className="team-menu popover-wrap">
            <Popover
                content={
                    <nav className={ `popover-menu` }>
                        <nav className={ `popover-menu` }>
                            <ul>
                                <li>
                                    <TeamDeleteUser
                                        standId={ standId }
                                        userId={ userId }
                                        itsMe={ true }
                                        button={{
                                            type : "text",
                                            text : "Leave the team"
                                        }}
                                        action={
                                            (cache) => {

                                                cache.modify({
                                                    id: cache.identify(me),
                                                    fields: {
                                                        stand() {
                                                            return [];
                                                        },
                                                        stand_permission(){
                                                            return null;
                                                        }
                                                    },
                                                });
                                            }
                                        }
                                    />
                                </li>
                            </ul>
                        </nav>
                    </nav>
                }
                overlayClassName={"menu-popover"}
                title={ null }
                trigger={ ["click"] }
                placement="bottomRight"
                visible={ popoverVisible }
                onVisibleChange={ () => setPopoverVisible(!popoverVisible) }
            >
                <button className="popover-button">
                    <Icons.ThreeDot />
                </button>
            </Popover>
        </div>
    );
};

export default TeamMenu;