import React from "react";
import { useRouteMatch } from "react-router-dom";

import { AnalyticsBlocks } from "components/analytics";


const AnalyticsVisitorsPage = ({ standId }) => {

    const pathBase = "/showroom-configurator/analytics/";

    let match = useRouteMatch(`${pathBase}visitors/:pageNum`)

    return(
        <div className="analytics-visitors">
            <AnalyticsBlocks.VisitorsDetailed
                standId={ standId }
                currentPage={ match ? parseInt(match.params.pageNum) : 1  }
                pathBase={ pathBase }
            />
        </div>
    );
}


export default AnalyticsVisitorsPage;