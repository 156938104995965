import React from "react";

import { Product } from "components/product/index";
import Icons from "components/icons";
import { Loader } from "components/request-result";


const ProductMediaSliderUpload = ({ variables, buttonText = "Add image"  }) => {

    const UploadButton = ({ loading }) => {
        return(
            <button
                className="image-control-button empty"
            >
                { loading ? <Loader /> :
                    <p className="empty-text">
                        <Icons.Plus />
                        { buttonText }
                    </p>
                }
            </button>
        );
    }

    return(
        <Product.MediaUpload
            variables={ variables }
        >
            <UploadButton />
        </Product.MediaUpload>
    );
}

export default ProductMediaSliderUpload;