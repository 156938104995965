import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { VIEWS_LOG } from "graphql/query/analytics-gql";

import AnalyticsTable from "../analytics-table";
import AnalyticsExportButton from "../analytics-export";
import { Product } from "components/product";

import Icons from "components/icons";


const prepareTableData = (data, pathBase) => {

    return data.map( (row) => {

        return {
            key: row.product.id,
            user : {
                value : <Product.Avatar  product={ row.product } withLink/>,
                title : <><Icons.Product /> Product</>
            },
            views : {
                value : row.views,
                title : <><Icons.Eye type="product" className="product-views" /> Views</>
            },
            favorite : {
                value : row.favorite_product > 0 ? row.favorite_product : "–",
                title : <><Icons.Favorite type="product"  className="product-favorites" /> Favorites</>
            },
            pdf : {
                value : row.pdf_views ? row.pdf_views : "–",
                title : <><Icons.File type="outline" className="document-views" /> Document views</>
            },
            video : {
                value : row.video_views ? row.video_views : "–",
                title : <><Icons.Video type="circle"  className="video-views" /> Video views</>
            },
            more: {
                value : <Link to={ `${ pathBase }product/${ row.product.id }/` } className="analytics-more"><Icons.Arrow type="circle"  /></Link>,
                title : ""
            }
        };
    });

}

const AnalyticsProductsDetailedBlock = ({ standId, currentPage = 1, pathBase}) => {

    const history = useHistory();

    const { data : { logViews } = {} , loading } = useQuery( VIEWS_LOG, {
        variables: {
            standId,
            page: currentPage,
            input: {
                model: 'product'
            },
            sortByOrder: {
                sortBy: 'VIEWS',
                sortOrder: 'DESC'
            }
        },
        fetchPolicy: "cache-and-network"
    });

    const tableData = logViews ? prepareTableData( logViews.data, pathBase ) : [];
    const { paginatorInfo } = logViews ?? {};

    return(
        <div className="analytics-block">

            <h2>Detailed products analytics</h2>
            { tableData &&
            <>
                <div className="analytics-export-wrap">
                    <AnalyticsExportButton model={ "StandProducts" }/>
                </div>
                <AnalyticsTable
                    tableData={ tableData }
                    loading={ loading }
                    emptyText={ "Nobody has viewed your products yet." }
                    pagination={{
                        pageSize: paginatorInfo?.perPage,
                        total: paginatorInfo?.total,
                        hideOnSinglePage: paginatorInfo?.total / paginatorInfo?.perPage > 1,
                        current: currentPage,
                        onChange: ( page ) => {
                            let path = page === 1 ? `${ pathBase }products/` : `${ pathBase }products/${ page }`

                            history.push( path );
                        }
                    }}
                />
            </>
            }
        </div>
    );
};

export default AnalyticsProductsDetailedBlock;