import ShowroomProductSubPage from "./showroom-product-sub-page";
import ShowroomCompanySubPage from "./showroom-company-sub-page";
import ShowroomProfileSubPage from "./showroom-profile-sub-page";
import ShowroomCommunicationSubPage from "./showroom-communication-sub-page";
import ShowroomTeamSubPage from "./showroom-team-sub-page";
import ShowroomPackageSubPage from "./showroom-package-sub-page";
import ShowroomAnalyticsSubPage from "./showroom-analytics-sub-page";
import ShowroomDownloadsSubPage from "./showroom-downloads-sub-page";


const ShowroomSubPage = {
    Company : ShowroomCompanySubPage,
    Product : ShowroomProductSubPage,
    Profile : ShowroomProfileSubPage,
    Communication : ShowroomCommunicationSubPage,
    Team : ShowroomTeamSubPage,
    Package : ShowroomPackageSubPage,
    Analytics : ShowroomAnalyticsSubPage,
    Downloads : ShowroomDownloadsSubPage
};

export default ShowroomSubPage;
