import ProductServiceCreateButton from "./product-service-create-button";
import ProductServiceForm from "./product-service-form";
import ProductServiceContent from "./product-service-content";


const ProductService = {
    CreateButton: ProductServiceCreateButton,
    Form : ProductServiceForm,
    Content: ProductServiceContent
}

export default ProductService