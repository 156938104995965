import ProductMediaCover from "./product-media-cover";
import ProductMediaUpload from "./product-media-upload";
import ProductMediaVideo from "./product-media-video";
import ProductMediaType from "./product-media-type";
import ProductMediaSlider from "./product-media-slider";


const ProductMedia = {
    MediaType: ProductMediaType,
    MediaSlider : ProductMediaSlider,
    MediaUpload : ProductMediaUpload,
    MediaVideo: ProductMediaVideo,
    MediaCover : ProductMediaCover,
}

export default ProductMedia;