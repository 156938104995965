import React from "react";
import { useQuery } from "@apollo/client";
import { Prompt } from "react-router-dom";

import { GET_ME } from "graphql/query/user-gql";

import { userForms } from "components/user";


const ProfileUserSubPage = ({ profileComplete }) => {

    const { ProfileForm } = userForms;
    const { data } = useQuery( GET_ME, { fetchPolicy: "cache-only" } );

    //console.log("profileComplete", profileComplete);

    return (
        <>
            <h2>PERSONAL PROFILE</h2>
            { data !== undefined &&
                <>
                    <Prompt
                        message={
                            (location, action) =>
                                (action !== "POP") ? "Profile is not completed" : null
                        }
                        when={ !profileComplete } />
                    <div className="user-role">You are <b>expo { data.me.role }</b></div>
                    <ProfileForm user={ data.me } />
                </>
            }
        </>
    );
};


export default ProfileUserSubPage;