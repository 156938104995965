import React from "react";
import { useMutation } from "@apollo/client";
import { Button } from "antd";

import { PRODUCT_META_IMAGE_DELETE } from "graphql/mutation/product-gql";

import Icons from "components/icons";
import {errorNotification, Loader, successNotification} from "components/request-result";


const ProductMediaSlideDelete = ({ imageKey, productImages, goPrevious }) => {

    const [slideDeleteMutation, { loading }] = useMutation(PRODUCT_META_IMAGE_DELETE,
        {
            update(cache, { data }) {

                const {
                    productMetaDelete : {
                        label,
                        message,
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });

                goPrevious();
            }
        });

    const { product_id } = productImages[0];

    return(
        <Button
            ghost
            disabled={ loading }
            className="delete-slide"
            onClick={ () => {

                const newImageList = productImages.filter( (item) => item.meta_key !== imageKey );
                let metaInput = [];

                if( newImageList.length ){
                    for(let i = 0; i < newImageList.length; i++){

                        const { id } = newImageList[i];

                        metaInput.push({
                            id,
                            meta_key : `product_image_${i+1}`,
                            meta_type: "image"
                        });

                    }
                }

                slideDeleteMutation({
                    variables: {
                        productId : product_id,
                        meta_key : imageKey,
                        metaInput
                    }
                }).catch((error) => {
                    errorNotification(error);
                });
            }}
        >
            { loading ?
                <Loader /> : <Icons.Trash />
            }
            Delete
        </Button>
    );
};

export default ProductMediaSlideDelete;