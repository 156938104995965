import { gql } from '@apollo/client';


export const GET_STAND = gql`
    query GetStand($id : ID!){
        stand(id : $id){
            id
            ee_id
            title
            description
            logo
            my_favorite
            meta_fields{
                id
                stand_id
                meta_key
                meta_type
                meta_value
                meta_group
                stand{
                    id
                }
            }
            package{
                id
                title
                price
                ee_id
                pdf
                package_rules{
                    id
                    title
                    package_id
                    rule_key
                    order
                    min_value
                    max_value
                    type
                    active
                }
                created_at
                updated_at
            }
            terms{
                id
                taxonomy_id
                title
                sort_number
            }
            exhibitors{
                id
                name
                surname
                avatar
                role
                email_original
                my_favorite
                stand{
                    id
                    title
                    logo
                }
                meta_fields{
                    id
                    meta_key
                    meta_type
                    meta_value
                    meta_group
                }
                stand_permission{
                    id
                    stand_id
                    user_id
                    visible
                    permission
                    created_at
                    updated_at
                }
            }
            status
            created_at
            updated_at
        }
    }
`;

export const EXPO_HALL_STANDS = gql`
    query ExpoHallStands(
        $eeId : ID!
        $termId : ID
    ){
        expoHall(
            ee_id : $eeId
            term_id: $termId
        ){
            id
            ee_id
            title
            logo
            my_favorite
            status
            order_number
            created_at
            updated_at
        }
    }
`;

export const GET_STAND_ATTACHMENTS = gql`
    query GetStandAttachments(
        $standId : ID!
        $status : [AttachedStatusEnum]
        $orderBy : [OrderByClause!]
        $page : Int
    ){
        attachmentsStand(
            stand_id : $standId
            status: $status
            orderBy : $orderBy
            first : 100
            page : $page
        ){
            paginatorInfo{
                count
                total
                hasMorePages
            }
            data{
                id
                server_name
                status
                path
                extension
                size
                original_name
                description
                order
                stand{
                    id
                }
                created_at
                updated_at
            }
        }
    }
`;

export const GET_STAND_COUNTS = gql`
    query StandAmounts(
        $standId : ID!
    ){
        standAmounts(stand_id: $standId){
            product_amount
            product_amount_author
            stand_document_amount
            stand_keyword_amount
            video_chat_room_amount
            team_members_amount
        }
    }
`;