import React from "react";
import { Link } from "react-router-dom";

import { AvatarBlock } from "components/avatar";
import { CardPopoverMenu } from "components/card";
import { FavoriteButton } from "components/favorite";

import "./card-company-horizontal.scss";


const CardCompanyHorizontal = ({ stand }) => {

    return(
        <div className="card card-horizontal card-company-horizontal">
            <AvatarBlock
                shape="square"
                size={ 120 }
                image={ stand.logo }
            >
                <h5>{ stand.title }</h5>
                <p className="sub-text-bold">{ stand.description }</p>
            </AvatarBlock>
            <div className="actions-wrap">
                <CardPopoverMenu>
                    <ul>
                        <li>
                            <Link to={ `/showroom-${ stand.id }/`}>Go to showroom</Link>
                        </li>
                    </ul>
                </CardPopoverMenu>
                <FavoriteButton
                    favorite={ stand.my_favorite }
                    type={ "Stand" }
                    id={ stand.id }
                />
            </div>
        </div>
    );
};

export default CardCompanyHorizontal;