import React from "react";

import { ShowroomHeader, ShowroomProducts } from "components/showroom";
import { ShowroomMenuBar } from "components/menus";
import { Product } from "components/product";
import { ContainerWrap } from "components/wraps";
import { useExhibitionEvent } from "components/use-hooks";
import { metaFields } from "utils";


const ShowroomProductSubPage = ({ stand, control, spanList, configurator = false }) => {

    const ee = useExhibitionEvent();

    const { normalize, getValue } = metaFields;
    const meta = normalize(stand.meta_fields);

    const eeMeta = normalize( ee.meta_fields );

    return(
        <div className="showroom-product-wrap">
            { control &&
                <div className="badge-page-label">You are design product page</div>
            }
            <ContainerWrap
                spanList={ spanList }
                className="showroom-header-wrap"
            >
                <ShowroomHeader
                    stand={{
                        id : stand.id,
                        logo: stand.logo,
                        companyName: stand.title,
                        description: stand.description,
                        my_favorite: stand.my_favorite,
                        meta,
                    }}
                    control={ control }
                    imageKey={ "products_image_1" }
                >
                    { control ?
                        <Product.CreateBlock
                            standId={ stand.id }
                            createTypes = {
                                getValue(eeMeta, "product_types_platinum", ["product"])
                            }
                        /> :
                        <>
                            { !configurator &&
                                <ShowroomMenuBar standId={stand.id} />
                            }
                        </>

                    }
                </ShowroomHeader>
            </ContainerWrap>
            <ShowroomProducts
                spanList={ spanList }
                control={ control }
                stand={ stand }
                extraTypes={ getValue(eeMeta, "product_extra_blocks_platinum", []) }
            />
        </div>
    );
};

export default ShowroomProductSubPage;