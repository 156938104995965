import React from "react";

import { MenuPopover } from "components/service";
import { AvatarBlock } from "components/avatar";
import { ShowroomPopoverMenu } from "components/menus";
import ShowroomChecklist, { ShowroomChecklistDrawer } from "../showroom-checklist";

import "./showroom-menu-popover.scss";


const ShowroomMenuPopover = ({ user, children }) => {

    const {
        stand_permission,
        stand
    } = user;

    const [ showroom ] = stand;

    if(!user.stand_permission || !stand_permission || (stand_permission && showroom.status === "draft")){
        return (React.cloneElement( children, { user }));
    }

    if(!stand_permission){
        return null
    }

    return (
        <div className="showroom-menu-popover-wrap">
            <MenuPopover
                title="Company showroom"
                image={ showroom.logo }
                imageShape={ "square" }
            >
                <AvatarBlock
                    image={ showroom.logo }
                    size={ 60 }
                    shape={ "square" }
                >
                    <h5>{ showroom.title }</h5>
                    { showroom.status === "online" ?
                        <p className="sub-text-bold-success">online</p> :
                        <p className="sub-text-bold">offline</p>
                    }
                </AvatarBlock>
                <ShowroomChecklist
                    drawerButtonVisible={ true }
                    showroom={ showroom }
                />
                <ShowroomPopoverMenu
                    standId={  showroom.id }
                    navClass={ "showroom-popover-menu" }
                />
            </MenuPopover>
            <ShowroomChecklistDrawer
                showroom={ showroom }
            />
        </div>
    );
};

export default ShowroomMenuPopover;