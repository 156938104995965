import React from "react";
import { Button } from "antd";

import AvatarImage from "../avatar-image";
import { UploadFile } from "components/service";

import "./avatar-upload.scss";


const AvatarUpload = ({
        variables = {},
        uploadMutation = () => {},
        loading,
        image,
        shape,
        extraClass = "",
        size = 140,
        uploadButtonText = "Upload Photo",
        badge
    }) => {


    return(
        <UploadFile
            variables={ variables }
            uploadMutation={ uploadMutation }
            loading={ loading }
            extraClass={ `photo-upload ${ extraClass }` }
        >
            <AvatarImage
                size={ size }
                photoUrl={ image }
                shape={ shape }
                badge={
                    badge ? badge : <div className="badge-plus">+</div>
                }
                loading={ loading }
            />
            { !badge &&
                <Button type="default" disabled={loading}>{uploadButtonText}</Button>
            }
        </UploadFile>
    );
};

export default AvatarUpload;