import React from "react";
import { Link } from "react-router-dom";
import { Collapse } from 'antd';

import Icons from "components/icons";
import { useCheckSet } from "components/use-hooks";

const { Panel } = Collapse;


const ProductPageStep = ({ closeDrawer, showroom, ...props}) => {

    const checkSet = useCheckSet(showroom);
    const checkResult = productStepCheck({ ...checkSet });

    if(!checkSet.pages.product){
        return null;
    }

    return(
        <Panel
            {...props}
            header={
                <>
                    <p className={`sub-text${ checkResult.blockFinish ? "-success" : "" }`}>
                        { checkResult.blockFinish ? "Done" : `${ checkResult.stepsLeft } steps left`}
                    </p>
                    <h5>Product page</h5>
                </>
            }
            key="product-page"
        >
            <div className={ `step ${ checkResult.pageBanner ? "done" : "" }` }>
                <div className="success-badge">
                    <Icons.Check />
                </div>
                <div className="description">
                    <h6>Add page banner</h6>
                    <p className="sub-text">
                        This is the first image people see on the Page. Choose the one shows your best products.
                    </p>
                    <Link
                        onClick={ closeDrawer }
                        className="link-button second"
                        to="/showroom-configurator/product"
                    >
                        Add photo
                    </Link>
                </div>
            </div>
            { checkResult.products !== "no package" &&
                <div className={`step ${checkResult.products ? "done" : ""}`}>
                    <div className="success-badge">
                        <Icons.Check/>
                    </div>
                    <div className="description">
                        <h6>Add products ({checkResult.productsLeft})</h6>
                        <p className="sub-text">
                            Well-presented products will tell the best about your company.
                        </p>
                        <Link
                            onClick={closeDrawer}
                            className="link-button second"
                            to="/showroom-configurator/product"
                        >
                            Add products
                        </Link>
                    </div>
                </div>
            }
        </Panel>
    );
};

export const productStepCheck = ({ meta, rules, showroomCounts, pages } = {}) => {

    let checkResult = {
        stepAmount: 2,
        stepFinish: 0,
        stepsLeft: 0,
        blockFinish: false,
        pageBanner : false,
        products : false,
        productsLeft: 0
    };

    if(!pages.product){
        return false;
    }

    const { fields } = rules;

    if(rules.fields && showroomCounts) {
        if (showroomCounts.product_amount === fields?.product_amount?.max_value) {
            checkResult.stepFinish++;
            checkResult.products = true;
        }

        checkResult.productsLeft = fields?.product_amount?.max_value - showroomCounts.product_amount;
    } else {
        checkResult.stepAmount--;
        checkResult.products = "no package";
    }

    if(meta && meta.getValue( meta.fields, "products_image_1" )){
        checkResult.stepFinish++;
        checkResult.pageBanner = true;
    }

    checkResult.blockFinish = checkResult.stepAmount === checkResult.stepFinish;
    checkResult.stepsLeft = checkResult.stepAmount - checkResult.stepFinish;

    return checkResult;
}

export default ProductPageStep;