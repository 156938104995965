import ExhibitionIcon from "./exhibition-icon";
import FavoriteIcon from "./favorite-icon";
import NotificationIcon from "./notification-icon";
import CrossIcon from "./cross-icon";
import NoImageIcon from "./no-image-icon";
import ProductIcon from "./product-icon";
import EditIcon from "./edit-icon";
import CompanyIcon from "./company-icon";
import CommunicationIcon from "./communication-icon";
import TeamIcon from "./team-icon";
import PackageIcon from "./package-icon";
import AnalyticsIcon from "./analytics-icon";
import EyeIcon from "./eye-icon";
import SidebarIcon from "./sidebar-icon";
import PlusIcon from "./plus-icon";
import WebIcon from "./web-icon";
import PhoneIcon from "./phone-icon";
import EmailIcon from "./email-icon";
import LocationIcon from "./location-icon";
import SocialIcon from "./social-icon";
import NoVideoIcon from "./no-video-icon";
import CreateProductIcon from "./create-product-icon";
import ArrowIcon from "./arrow-icon";
import AvatarIcon from "./avatar-icon";
import TrashIcon from "./trash-icon";
import PlayerIcon from "./player-icon";
import FileIcon from "./file-icon";
import CheckIcon from "./check-icon";
import SearchIcon from "./search-icon";
import ThreeDotIcon from "./three_dot-icon";
import ExportIcon from "./export-icon";
import FlipIcon from "./flip-icon";
import VideoIcon from "./video-icon";
import DownloadIcon from "./download-icon";
import DocumentIcon from "./document-icon";
import PrivacyIcon from "./privacy-icon";
import SettingsIcon from "./settings-icon";
import LogoutIcon from "./logout-icon";


const Icons = {
    Exhibition : ExhibitionIcon,
    Favorite : FavoriteIcon,
    Notification : NotificationIcon,
    Cross : CrossIcon,
    NoImage : NoImageIcon,
    Product : ProductIcon,
    Edit : EditIcon,
    Company : CompanyIcon,
    Communication : CommunicationIcon,
    Team : TeamIcon,
    Package : PackageIcon,
    Analytics : AnalyticsIcon,
    Eye : EyeIcon,
    Sidebar : SidebarIcon,
    Plus : PlusIcon,
    Web : WebIcon,
    Phone : PhoneIcon,
    Email : EmailIcon,
    Location : LocationIcon,
    Social : SocialIcon,
    NoVideo : NoVideoIcon,
    CreateProduct : CreateProductIcon,
    Arrow : ArrowIcon,
    Avatar : AvatarIcon,
    Trash : TrashIcon,
    Player : PlayerIcon,
    File : FileIcon,
    Check : CheckIcon,
    Search : SearchIcon,
    ThreeDot : ThreeDotIcon,
    Export: ExportIcon,
    Flip : FlipIcon,
    Video : VideoIcon,
    Download : DownloadIcon,
    Document : DocumentIcon,
    Privacy : PrivacyIcon,
    Settings : SettingsIcon,
    Logout : LogoutIcon
};

export default Icons;