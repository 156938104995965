import React from "react";
import { useMutation } from "@apollo/client";

import { VIEW_LOG_EXPORT_XLS } from "graphql/mutation/analytics-gql";

import Icons from "components/icons";



const AnalyticsExportButton = ({ model }) => {

    const [ exportXLS, { loading } ] = useMutation( VIEW_LOG_EXPORT_XLS,
        {
            variables: {
                model
            },
            update(cache, { data }) {

                const { file } = data.logExport;

                const link = document.createElement('a');

                link.href = file;

                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);

            }
        });

    return(
        <button
            className={"export-button analytics"}
            onClick={ () => {
                if (!loading){
                    exportXLS();
                }
            }}
        >
            <Icons.Export type={ loading ? "loading" : null } /> Export
        </button>
    )
}

export default AnalyticsExportButton;