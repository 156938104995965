import React from "react";

import PackageRequest from "../package-request";
import Icons from "components/icons";

import "./package-item.scss";


const PackageItem = ({
        id,
        standId,
        price,
        title,
        package_rules : rules,
        pdf,
        possiblePackage = true,
        currentPackage = false,
        extraClass = "",
    }) => {


    if( !possiblePackage ){
        extraClass = "off";
    }

    if( currentPackage ){
        extraClass = "current";
    }

    return(
        <div className={ `package-item ${ extraClass }` }>
            <div className="package-header">
                <h3>{ title }</h3>
                { currentPackage &&
                    <div className="sub-text-bold">Your package</div>
                }
                { possiblePackage &&
                    <div className="price">€ <span>{ price }</span></div>
                }
            </div>
            { rules &&
                <ul className="package-rules">
                    { rules.map(
                        ({ title, active, id }) => {
                            return(
                                <li key={ id } className={ `${ active ? "" : "forbidden"}` }>
                                    { active ? <Icons.Check /> : <Icons.Cross /> }
                                    { title }
                                </li>
                            );
                        }
                    )}
                </ul>
            }
            <div className="package-footer">
                { possiblePackage &&
                    <div className="action">
                        <PackageRequest
                            packageId={ id }
                            standId={ standId }
                        />
                    </div>
                }
                { pdf &&
                    <div className="details">
                        <a href={ pdf } target="_blank" rel="noreferrer">
                            <b>More details</b>
                        </a>
                    </div>
                }
            </div>
        </div>
    )
};

export default PackageItem;