import React from "react";
import { useLocation, useRouteMatch } from "react-router-dom";

import { Product } from "components/product";
import { hash } from "utils";


const ProductAuthorModalList = (props) => {

    const { Author } = Product;

    const match = useRouteMatch("/showroom-:standId/product");
    const location = useLocation();

    const hashParams = hash.parse(location.hash);

    return(
        <>
            { hashParams.author &&
            <Author.Modal
                defaultState={ hashParams.author.value === props.author.id }
                backLink={ `/showroom-${match.params.standId}/product` }
            >
                { hashParams.author.action === "edit" ?
                    <Author.Form {...props }/>:
                    <Author.Content{...props }/>
                }
            </Author.Modal>
            }
        </>
    );
};

export default ProductAuthorModalList;
