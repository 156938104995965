import React, { useState } from "react";

import SearchForm from "./search-form";
import SearchTaxonomies from "./search-taxonomies";

import "./search.scss";


const Search = () => {

    const [ searchType, setSearchType ] = useState("companies");

    return(
        <div className="search">
            <SearchForm
                searchType={ searchType }
                setSearchType={ setSearchType }
            />
            <SearchTaxonomies
                type={ searchType }
            />
        </div>
    );
};

export default Search;