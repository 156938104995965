import getHolderElement from "./get-holder-element";


const setCssVar = (cssVars, value) => {
    if(typeof cssVars === "object"){
        for (const [key, value] of Object.entries(cssVars)) {
            setCssVar(key, value);
        }
    } else {

        if(cssVar.selector){
            cssVar.selector.style.setProperty(`--${cssVars}`, value);
        }

    }
};

const removeCssVar = (cssVars) => {
    cssVar.selector.style.removeProperty(`--${cssVars}`);
};

const resetAllCssVar = () => {
    cssVar.selector.removeAttribute('style');
};

const getCssVar = (cssVars, selector) => {

    const { element } = getHolderElement(selector);

    return element !== null ?
        window.getComputedStyle(element).getPropertyValue(`--${cssVars}`): null;

};

const setDomSelector = (selector) => {
    const { element } = getHolderElement(selector);

    cssVar.selector = element;

    return cssVar;
};

const cssVar = {
    selector : document.documentElement,
    setSelector: setDomSelector,
    set : setCssVar,
    get : getCssVar,
    remove : removeCssVar,
    removeAll : resetAllCssVar
};

export default cssVar;