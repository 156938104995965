import React from "react";

import ProductAuthorSlide from "./product-author-slide";
import ProductAuthorModalList from "../product-author-modal-list";
import { SwiperService } from "components/service";

import "./product-author-slider.scss";


const ProductAuthorSlider = ({ control, products }) => {

    const authors = products.data.filter( item => item.type === "author" );

    if(!authors.length){
        return null;
    }

    const  { Slider, Slide } = SwiperService;


    return(
        <div className="product-author-slider-wrap">
            <h2>Top Authors</h2>
            <Slider
                className="product-author-slider"
                settings={{
                    spaceBetween: 0,
                    loop: authors.length >= 8,
                    centerInsufficientSlides: authors.length < 8,
                    centeredSlides: authors.length >= 8
                }}
            >
            {
                authors.map( item => {
                    return (
                        <Slide key={ item.id }>
                            <ProductAuthorSlide
                                author={ item }
                                control={ control }
                                key={ item.id }
                            />
                        </Slide>
                    )
                })
            }
            </Slider>
            {
                authors.map( item => {
                    return (
                        <ProductAuthorModalList
                            author={ item }
                            control={ control }
                            key={ item.id }
                        />
                    )
                })
            }
        </div>
    );

};

export default ProductAuthorSlider;