import React from "react";
import { useQuery } from "@apollo/client";
import { Switch, Route } from 'react-router-dom';

import { GET_PLATFORM_PARAMS } from "graphql/query/local-store-gql";

import Pages from "../pages";


const ProfileRoutes = ({ parent = "profile" }) => {

    const { Profile } = Pages;
    const { data : { platformParams : pParams } } = useQuery( GET_PLATFORM_PARAMS );

    return(
        <Switch>
            <Route path={`/${ parent }/team`} exact>
                <Profile.TeamSubPage />
            </Route>
            <Route path={`/${ parent }/settings`} exact>
                <Profile.SettingsSubPage />
            </Route>
            <Route path={`/${parent}/`}>
                <Profile.UserSubPage
                    profileComplete={ pParams.profileComplete  }
                />
            </Route>
        </Switch>
    );
};

export default ProfileRoutes;