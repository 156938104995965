import React from 'react';
import { Col, Row } from "antd";

import { useColumnsChildren } from "components/use-hooks";


const ProductWrap = ({children, className = null}) => {

    const { leftColumn, rightColumn } = useColumnsChildren(children);

    const spanList = {
        left: { xs: 12, sm: 14, lg: 16, xl: 16, xxl: 16, span: 16 },
        right: { xs: 12, sm: 10, lg: 8, xl: 8, xxl: 8, span: 8 }
    };

    return (
        <Row className={className} type="flex" gutter={ 0 } justify="center">
            <Col { ...spanList.left }>
                { leftColumn }
            </Col>
            <Col { ...spanList.right }>
                { rightColumn }
            </Col>
        </Row>
    );
};

export default ProductWrap;