import React, { useState } from "react";
import { useMutation } from "@apollo/client";

import {
    FAVORITE_PRODUCT_ADD_DELETE,
    FAVORITE_STAND_ADD_DELETE,
    FAVORITE_USER_ADD_DELETE
} from "graphql/mutation/favorite-gql";

import favoriteButtonType from "./types";
import { successNotification } from "components/request-result";

import "./favorite-button.scss";


const favoriteMutation = {
    Product : FAVORITE_PRODUCT_ADD_DELETE,
    Stand : FAVORITE_STAND_ADD_DELETE,
    User : FAVORITE_USER_ADD_DELETE
};


const FavoriteButton = ({ type, id, favorite, buttonType = "icon" }) => {

    const [ favoriteState, setFavoriteState ] = useState(favorite);

    const [favoriteAddOrDelete, { loading }] = useMutation( favoriteMutation[type] );

    const FavButton = favoriteButtonType[buttonType];

    return(
        <FavButton
            loading={ loading }
            favorite={ favoriteState }
            action={
                () => {
                    favoriteAddOrDelete({
                        variables: {
                            model_id: id,
                            model: type
                        },
                        onQueryUpdated(){
                            return false;
                        },
                        update(cache, { data }){

                            const { favoriteAddOrDelete: {
                                label,
                                message,
                                model
                            } } = data;

                            cache.modify({
                                id: cache.identify(model),
                                fields: {
                                    my_favorite(favoriteState) {

                                        return favoriteState;
                                    }
                                },
                            });

                            setFavoriteState(model.my_favorite);

                            successNotification({
                                title: label,
                                description: message
                            });
                        },

                    })
                }
            }
        />
    );
};

export default FavoriteButton;