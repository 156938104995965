import React from "react";

import "./analytics-summary-list.scss";


const AnalyticsSummaryList = ({ className = "", children }) => {


    return(
        <div className={`analytics-summary-list ${ className }`}>
            { children.map(
                ( item, index ) => {

                    if(!item){
                        return null;
                    }

                    return (
                        <div key={ index }>
                            { item }
                        </div>
                    );
                })
            }
        </div>
    );
};

export default AnalyticsSummaryList;