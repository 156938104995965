import { gql } from '@apollo/client';

export const SEARCH_PRODUCTS = gql`
    query SearchProducts(
        $eeId : ID!
        $text : String
        $page : Int
    ){
        eeSearchProducts(
            ee_id : $eeId
            text : $text
            page : $page
            first: 500
            orderBy: {
                column: ID
                order: DESC
            }
        ){
            paginatorInfo{
                total
                currentPage
                hasMorePages
                perPage
                count
            }
            data{
                id
                cover
                title
                description
                my_favorite
                stand{
                    id
                    logo
                    title
                }
            }
        }
    }
`;

export const SEARCH_STANDS = gql`
    query SearchStands(
        $eeId : ID!
        $text : String
        $page : Int
    ){
        eeSearchStands(
            ee_id : $eeId
            text : $text
            page : $page
            first: 500
            orderBy: {
                column: ID
                order: DESC
            }
        ){
            paginatorInfo{
                total
                currentPage
                hasMorePages
                perPage
                count
            }
            data{
                id
                logo
                title
                description
                my_favorite
                products(
                    limit : 3
                    where:{
                        column: STATUS
                        operator: EQ
                        value: "active"
                        AND: {
                            column: IS_SEARCHABLE
                            operator: EQ
                            value: true
                        }
                    }
                ){
                    id
                    cover
                    title
                }
            }
        }
    }
`;