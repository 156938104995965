import React from "react";

import { UserGrid } from "components/user";


const CommunicationUsersOffline = ({ users, usersOnline }) => {

    const filteredUsers = users.filter(({ id, stand_permission }) => {

        return stand_permission.visible && !usersOnline.find( user => user.id === id );
    });

    return (
        <div className="communication-users offline">
            <h2>Other company members</h2>
            { filteredUsers?.length ?
                <UserGrid
                    users={filteredUsers}
                /> :
                <div className="no-users">
                    This showroom has no users
                </div>
            }
        </div>
    );
}

export default CommunicationUsersOffline;