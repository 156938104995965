import React from 'react';
import Icon from '@ant-design/icons';


const NoVideoSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M36.1,40.4H12c-2.4,0-4.4-2-4.4-4.4V11.9c0-2.4,2-4.4,4.4-4.4h24.1c2.4,0,4.4,2,4.4,4.4V36C40.5,38.4,38.5,40.4,36.1,40.4z
		     M12,8.5c-1.9,0-3.4,1.5-3.4,3.4V36c0,1.9,1.5,3.4,3.4,3.4h24.1c1.9,0,3.4-1.5,3.4-3.4V11.9c0-1.9-1.5-3.4-3.4-3.4H12z"/>
        <path d="M22.1,24.1v-2.4c0-0.4,0.4-0.7,0.8-0.5l2.1,1.2l2.1,1.2c0.4,0.2,0.4,0.7,0,0.9L25,25.8L22.8,27c-0.4,0.2-0.8-0.1-0.8-0.5
	        V24.1z"
        />
        <path d="M24.1,31.8c-4.4,0-8-3.6-8-8s3.6-8,8-8s8,3.6,8,8S28.5,31.8,24.1,31.8z M24.1,16.9c-3.8,0-7,3.1-7,7c0,3.8,3.1,7,7,7
	        s7-3.1,7-7C31.1,20,27.9,16.9,24.1,16.9z"
        />
    </svg>
);

const NoVideoIcon = props => {
    return <Icon component={ NoVideoSvg } />;

};


export default NoVideoIcon;