import CardCompanyFlip from "./card-company-flip";
import CardCompanyHorizontal from "./card-company-horizontal";

import "./card-company.scss";

const CardCompany = {
    Flip : CardCompanyFlip,
    Horizontal : CardCompanyHorizontal
}

export default CardCompany;