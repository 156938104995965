import React from "react";
import { useQuery } from "@apollo/client";

import { GET_STAND } from "graphql/query/stand-gql";

import { ShowroomRoutes } from "components/routes";
import { Suspense } from "components/request-result";
import { useVarParam } from "components/use-hooks";

import "./showroom-page.scss";


const ShowroomPage = ({ match }) => {

    const varParam = useVarParam();

    const { standId } = match.params;

    const { loading, error, data } = useQuery( GET_STAND, {
        variables: {
            id: standId
        }
    });


    varParam.set({
        appBlockClass: ["bg-base"],
        mainBlockClass: [""],
        headerOn: true
    });

    return(
        <div className="showroom-page">
            <Suspense state={ {loading, error, data} }>
                <ShowroomRoutes />
            </Suspense>
        </div>
    );

};

export default ShowroomPage;