import React from "react";
import { useQuery } from "@apollo/client";

import { IS_LOGGED_IN } from "graphql/query/local-store-gql";

import { AuthorizationPage } from 'components/pages';
import { Loader } from "components/request-result";
import { useExhibitionEvent } from "components/use-hooks";


const Authentication = ({ children }) => {

    const { data } = useQuery(IS_LOGGED_IN);
    const { eeLocateByUrl ,  loading } = useExhibitionEvent("cache-and-network", true);

    if(loading){
        return(
            <div className="auth-loader">
                <Loader type="block"/>
            </div>
        );
    }

    return (
        <>
            { data !== undefined && data.isLoggedIn ? children : <AuthorizationPage ee={ eeLocateByUrl } /> }
        </>
    );
};


export default Authentication;
