import React from 'react';
import Icon from '@ant-design/icons';


const AvatarSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <circle cx="24.1" cy="21.1" r="5.3"/>
        <path d="M24.1,8c-9.4,0-17,7.6-17,17s7.6,17,17,17s17-7.6,17-17S33.5,8,24.1,8z M33.7,37.8v-3.3c0-3.6-3-6.6-6.6-6.6h-6
		c-3.6,0-6.6,3-6.6,6.6v3.3c-3.9-2.9-6.4-7.6-6.4-12.8c0-8.8,7.2-16,16-16s16,7.2,16,16C40.1,30.2,37.6,34.8,33.7,37.8z"/>
    </svg>
);

const AvatarTwoSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M25.9,22.6c3.4-0.8,5.9-3.8,5.9-7.4c0-4.2-3.4-7.6-7.5-7.6s-7.5,3.4-7.5,7.6c0,3.6,2.5,6.6,5.9,7.4C15,23.5,9.1,30.4,9,38.8
	c0,0.6,0.4,1,1,1c0,0,0,0,0,0c0.5,0,1-0.4,1-1c0.1-7.9,6-14.3,13.3-14.3s13.2,6.4,13.3,14.3c0,0.5,0.5,1,1,1c0,0,0,0,0,0
	c0.6,0,1-0.5,1-1C39.5,30.4,33.5,23.5,25.9,22.6z M18.7,15.2c0-3.1,2.5-5.6,5.5-5.6c3.1,0,5.5,2.5,5.5,5.6s-2.5,5.6-5.5,5.6
	C21.2,20.8,18.7,18.3,18.7,15.2z"/>
    </svg>
);

const AvatarIcon = props => {

    if(props.type === 'simple'){
        return <Icon component={ AvatarTwoSvg } />;
    }

    return <Icon component={ AvatarSvg } />;

};


export default AvatarIcon;