import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { Button } from "antd";

import { ContainerWrap } from "components/wraps";
import { convertTaxonomyType } from "components/service";
import { link } from "utils";

import "./search-terms.scss";


const SearchTerms = ({ terms, type, action }) => {

    const [ showMore, setShowMore ] = useState(terms.length <= 10);
    let termsList = showMore ? terms : terms.slice(0, 10);

    const taxonomyType = convertTaxonomyType(type);

    return(
        <ContainerWrap className="search-terms-wrap">
            <div className="search-terms">
                { termsList.map(
                    (term) => {

                        let url = `/search/${ taxonomyType }/term/${ term.id }-${link.toSlug(term.title)}`;

                        return (
                            <NavLink
                                className="term-link"
                                key={ term.id }
                                to={ url }
                                onClick={ () => action(false) }
                            >
                                <b>{ term.title }</b>
                                ({ term.count_obj === null ? "0" : term.count_obj })
                            </NavLink>
                        )
                    }
                )}
                { !showMore &&
                    <Button
                        className="more-button"
                        onClick={ () => setShowMore(true)}
                    >
                        Show more
                    </Button>
                }
            </div>
        </ContainerWrap>
    );
};

export default SearchTerms;