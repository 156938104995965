import React from "react";


const ProfileTeamSubPage = () => {
    return (
        <>
            <h2>My Team</h2>
            <h3 className="color-error">-- PAGE IN PROGRESS --</h3>
        </>
    );
};


export default ProfileTeamSubPage;