import React from "react";
import { NavLink } from "react-router-dom";

import "./profile-menu.scss";


const ProfileMenu = () => {

    return (
        <ul className="profile-menu">
            <li>
                <NavLink exact to="/profile/">Personal Profile</NavLink>
            </li>
            <li>
                <NavLink exact to="/profile/settings">Account Settings</NavLink>
            </li>
        </ul>
    );
};

export default ProfileMenu;