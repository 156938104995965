import React from "react";

import ShowroomDownloadsDeleteButton from "./showroom-downloads-delete-button";
import ShowroomDownloadsEdit from "./showroom-downloads-edit";
import Icons from "components/icons";


const ShowroomDownloadsRow = ({ attachment, control }) => {

    return(
        <div className="attachment-row">
            <div className="attachment-file">
                <div className="icon-wrap">
                    <Icons.Document type={ attachment.extension } />
                </div>
                <div className="file-wrap">
                    <p className={ `name ${attachment.status}` }>
                        <a href={ attachment.path } target="_blank" rel="noreferrer">
                            { attachment.original_name }
                            { attachment.status === "draft" ?  <span> (DRAFT)</span> : "" }
                        </a>
                    </p>
                    <p className="size sub-text">{ attachment.size }</p>
                </div>
            </div>
            <div className="attachment-description">
                { attachment.description }
            </div>
            <div className="attachment-action">
                { control ?
                    <>
                        <ShowroomDownloadsEdit
                            attachment={ attachment }
                        />
                        <ShowroomDownloadsDeleteButton
                            standId={ attachment.stand.id }
                            attachmentId={ attachment.id }
                        />
                    </> :
                    <a href={ attachment.path } className={"link-button second"} target="_blank" rel="noreferrer">
                        <Icons.Download/> DOWNLOAD
                    </a>
                }
            </div>
        </div>
    );
};

export default ShowroomDownloadsRow;