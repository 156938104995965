import React from "react";

import ShowroomHeaderImage from "./showroom-header-image";
import ShowroomHeaderUpload from "./showroom-header-upload";
import { AvatarBlock } from "components/avatar";
import { FavoriteButton } from "components/favorite";
import { metaFields } from "utils";

import "./showroom-header.scss";


const ShowroomHeader = ({
        stand,
        control = false,
        imageKey,
        children,
    }) => {

    const { getValue } = metaFields;

    return(

        <div className="showroom-header">
            <ShowroomHeaderImage
                image={ getValue(stand.meta, imageKey) }
                control={ control }
            >
            { control &&
                <ShowroomHeaderUpload
                    meta={ stand.meta }
                    standId={ stand.id }
                    imageKey={ imageKey }
                />
            }
            </ShowroomHeaderImage>
            <div className="showroom-header-logo">
                <AvatarBlock
                    shape="square"
                    size={ 170 }
                    image={ stand.logo }
                >
                    <h3>{ stand.companyName }</h3>
                    <p className="sub-text-bold">{ stand.description }</p>
                </AvatarBlock>
                { !control &&
                    <div className="to-favorite">
                        <FavoriteButton
                            buttonType={ "text" }
                            type={ "Stand" }
                            favorite={ stand.my_favorite }
                            id={ stand.id }
                        />
                    </div>
                }
            </div>
            { children }
        </div>
    );

};

export default ShowroomHeader;