import { gql } from '@apollo/client';


export const STAND_CREATE = gql`
    mutation StandCreate{
        standCreate{
            label
            message
            stand{
                id
                exhibitors{
                    id
                    stand_permission{
                        id
                        stand_id
                        user_id
                        visible
                        permission
                        created_at
                        updated_at
                    }
                    stand{
                        id
                        ee_id
                        title
                        description
                        showcase
                        keywords
                        logo
                        preview_banner
                        order_number
                        my_favorite
                        meta_fields{
                            id
                            stand_id
                            meta_key
                            meta_type
                            meta_value
                            meta_group
                        }
                        terms{
                            id
                            taxonomy_id
                            title
                            sort_number
                        }
                        status
                        is_moderated
                        created_at
                        updated_at
                    }
                }
            }
        }
    }
`;

export const STAND_PROFILE_UPDATE = gql`
    mutation StandProfileUpdate(
        $standId : ID!
        $metaInput : [StandMetaInput]
        $standInput : StandCreateOrUpdateInput!
        $termIds : [ID!]
    ){
        standUpdate(
            input: $standInput
        ){
            label
            message
        }
        termAddStand(
            stand_id: $standId
            term_id: $termIds
        ){
            label
            message
        }
        standMetaSet(
            stand_id: $standId
            input: $metaInput
        ){
            label
            message
            stand{
                id
                exhibitors{
                    id
                    stand_permission{
                        id
                        stand_id
                        user_id
                        visible
                        permission
                        created_at
                        updated_at
                    }
                    stand{
                        id
                        ee_id
                        title
                        description
                        showcase
                        keywords
                        logo
                        preview_banner
                        order_number
                        my_favorite
                        meta_fields{
                            id
                            stand_id
                            meta_key
                            meta_type
                            meta_value
                            meta_group
                        }
                        terms{
                            id
                            taxonomy_id
                            title
                            sort_number
                        }
                        status
                        is_moderated
                        created_at
                        updated_at
                    }
                }
            }
        }
    }
`;

export const STAND_META_SET = gql`
    mutation StandMetaSet(
        $standId : ID!
        $metaInput : [StandMetaInput]
    ){
        standMetaSet(
            stand_id: $standId
            input: $metaInput
        ){
            label
            message
            stand{
                id
                exhibitors{
                    id
                    stand_permission{
                        id
                        stand_id
                        user_id
                        visible
                        permission
                        created_at
                        updated_at
                    }
                    stand{
                        id
                        ee_id
                        title
                        description
                        showcase
                        keywords
                        logo
                        preview_banner
                        order_number
                        my_favorite
                        meta_fields{
                            id
                            stand_id
                            meta_key
                            meta_type
                            meta_value
                            meta_group
                        }
                        status
                        is_moderated
                        created_at
                        updated_at
                    }
                }
            }
        }
    }
`;

export const STAND_UPLOAD_FILE = gql`
    mutation StandUploadFile(
        $input : StandUploadFileInput!
    ){
        standMetaUpload(
            input : $input
        ){
            label
            message
            stand{
                id
                logo
                exhibitors{
                    id
                    stand_permission{
                        id
                        stand_id
                        user_id
                        visible
                        permission
                        created_at
                        updated_at
                    }
                    stand{
                        id
                        ee_id
                        title
                        description
                        showcase
                        keywords
                        logo
                        preview_banner
                        order_number
                        my_favorite
                        meta_fields{
                            id
                            stand_id
                            meta_key
                            meta_type
                            meta_value
                            meta_group
                        }
                        status
                        is_moderated
                        created_at
                        updated_at
                    }
                }
            }
        }
    }`;


export const STAND_UPDATE_EXHIBITOR = gql`
    mutation StandUpdateExhibitor(
        $input : StandAddExhibitorInput!
    ){
        standAddOrUpdateExhibitor(
            input : $input
        ){
            label
            message
            stand{
                id
                logo
                exhibitors{
                    id
                    stand_permission{
                        id
                        stand_id
                        user_id
                        visible
                        permission
                        created_at
                        updated_at
                    }
                    stand{
                        id
                        ee_id
                        title
                        description
                        showcase
                        keywords
                        logo
                        preview_banner
                        order_number
                        my_favorite
                        meta_fields{
                            id
                            stand_id
                            meta_key
                            meta_type
                            meta_value
                            meta_group
                        }
                        status
                        is_moderated
                        created_at
                        updated_at
                    }
                }
            }
        }
    }`;


export const STAND_DELETE_EXHIBITOR = gql`
    mutation StandDeleteExhibitor(
        $standId : ID!
        $userId  : ID!
    ){
        standDeleteExhibitor(
            stand_id : $standId
            user_id  : $userId
        ){
            label
            message
            stand{
                id
                ee_id
                title
                description
                showcase
                keywords
                logo
                preview_banner
                order_number
                my_favorite
                meta_fields{
                    id
                    stand_id
                    meta_key
                    meta_type
                    meta_value
                    meta_group
                }
                status
                is_moderated
                created_at
                updated_at
                exhibitors{
                    id
                    stand_permission{
                        id
                        stand_id
                        user_id
                        visible
                        permission
                        created_at
                        updated_at
                    }
                }
            }
        }
    }`;



export const STAND_UPLOAD_ATTACHMENT = gql`
    mutation AttachmentUpload(
        $standId :  ID!
        $file : Upload!
    ){
        attachedUpload(
            stand_id : $standId
            file : $file
    ){
            label
            message
            attachment{
                id
                server_name
                status
                path
                extension
                size
                original_name
                description
                order
                stand{
                    id
                }
                created_at
                updated_at
            }
        }
    }`;

export const STAND_UPDATE_ATTACHMENT = gql`
    mutation AttachmentUpdate(
        $attachmentId :  ID!
        $description : String
    ){
        attachedUpdate(
            attachment_id : $attachmentId
            description : $description
        ){
            label
            message
            attachment{
                id
                server_name
                status
                path
                extension
                size
                original_name
                description
                order
                stand{
                    id
                }
                created_at
                updated_at
            }
        }
    }`;


export const STAND_DELETE_ATTACHMENT = gql`
    mutation AttachmentUpdate(
        $attachmentId :  ID!
    ){
        attachedDelete(
            attachment_id : $attachmentId
        ){
            label
            message
        }
    }`;
