import React from "react";
import { useRouteMatch, useParams } from "react-router-dom";

import { AnalyticsBlocks } from "components/analytics";


const AnalyticsVisitorsSubPage = ({ standId }) => {

    const pathBase = "/showroom-configurator/analytics/";

    let { userId } = useParams();

    let match = useRouteMatch(`${ pathBase }visitor/${ userId }/:pageNum`);

    return(
        <div className="analytics-visitor">
            <AnalyticsBlocks.VisitorSingleHeader
                userId={ userId }
                standId={ standId }
            />
            <AnalyticsBlocks.VisitorSingle
                standId={ standId }
                userId={ parseInt(userId) }
                currentPage={ match ? parseInt(match.params.pageNum) : 1  }
                pathBase={ pathBase }
            />
        </div>
    );
}


export default AnalyticsVisitorsSubPage;