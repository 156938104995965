import FavoriteProductsSubPage from "./favorite-products-sub-page";
import FavoriteCompaniesSubPage from "./favorite-companies-sub-page";
import FavoriteUsersSubPage from "./favorite-users-sub-page";


const FavoriteSubPage = {
    Products : FavoriteProductsSubPage,
    Companies : FavoriteCompaniesSubPage,
    Users : FavoriteUsersSubPage
};

export default FavoriteSubPage;
