import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Col, Row } from "antd";
import moment from "moment";

import { GET_AUTH_PARAMS } from "graphql/query/local-store-gql";

import authorizationPageSetup from "./authorization-page-setup";
import { AuthorizationRoutes } from "components/routes";
import {cssVar, hex, metaFields} from "utils";

import "./authorization-page.scss";


const AuthorizationPage = ({ ee }) => {

    const { data : { authParams } } = useQuery(GET_AUTH_PARAMS);

    const { getValue, getBoolValue, normalize } = metaFields,
        meta = normalize(ee.meta_fields);

    useEffect( () => {
        cssVar.set({
            "primary-color": hex.toRgb(getValue(meta, "primary_color")),
            "primary-color-hex": getValue(meta, "primary_color"),
            "antd-wave-shadow-color": getValue(meta, "primary_color"),
        });

        if (getBoolValue(meta, "ee_info_block")){
            cssVar.setSelector(".expo-info").set({
                "primary-color-hex": getValue(meta, "info_block_bg_color"),
                "base-color": hex.lightOrDark(getValue(meta, "info_block_bg_color")) === "light" ? "black" : "white"
            });
        }
    }, [meta, getValue, getBoolValue]);


    const legalFiles = {
        terms : getValue(meta, "ee_terms_of_service", false),
        privacy : getValue(meta, "ee_privacy_policy", false),
        imprint : getValue(meta, "ee_imprint", false),
        protection : getValue(meta, "ee_data_protection", false)
    };

    return(
        <main className={ `authorization-page ${ getBoolValue(meta, "ee_info_block") ? "" : "without-info" }`}>
            { authorizationPageSetup({ ee }) &&
                <Row type="flex" justify="center" gutter={0}>
                    <Col flex="auto">
                        <div className="expo-image">
                            <img src={ getValue(meta, "ee_login_bg") } alt={ ee.title } />
                        </div>
                        { getBoolValue(meta, "ee_info_block") &&
                            <div className="expo-info">
                                { getValue(meta, "ee_logo") &&
                                    <div className="logo">
                                        <img src={ getValue(meta, "ee_logo") } alt={ ee.title }/>
                                    </div>
                                }
                                <div className="date">
                                    <p>THE EXHIBITION DATES ARE</p>
                                    <p>{ moment( ee.start_at ).format('D. MMMM') } - { moment( ee.end_at ).format('D. MMMM') }</p>
                                </div>
                            </div>
                        }
                    </Col>
                    <Col className={ `forms-col  ${ getBoolValue(meta, "ee_login_form_shadow") ? "" : "without-shadow" }` } span={7}>
                        <AuthorizationRoutes />
                        <div className="legacy-links-wrap">
                            <ul>
                                { authParams.legacyBlock &&
                                    <>
                                        { legalFiles.terms &&
                                            <li>
                                                <a href={ legalFiles.terms } target="_blank" rel="noreferrer">Terms of
                                                    Service</a>
                                            </li>
                                        }
                                        { legalFiles.privacy &&
                                            <li>
                                                <a href={ legalFiles.privacy } target="_blank" rel="noreferrer">Privacy
                                                    Policy</a>
                                            </li>
                                        }
                                    </>
                                }
                                { legalFiles.imprint &&
                                    <li>
                                        <a href={ legalFiles.imprint } target="_blank" rel="noreferrer">Imprint</a>
                                    </li>
                                    }
                                    { legalFiles.protection &&
                                    <li>
                                        <a href={ legalFiles.protection } target="_blank" rel="noreferrer">Data Protection</a>
                                    </li>
                                }
                            </ul>
                        </div>
                    </Col>
                </Row>
            }
        </main>
    );
};


export default AuthorizationPage;