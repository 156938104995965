import React from "react";
import { Button } from "antd";

import Icons from "components/icons";


const FavoriteButtonText = ({ loading, favorite, action }) => {

    let iconType = "";

    if(loading){
        iconType = "loading";
    } else{
        iconType = favorite ? "filled" : "";
    }

    return(
        <Button
            type={ "default" }
            className={ `favorite-button text ${ favorite ? "my-favorite" : "" }` }
            disabled={ loading }
            onClick={ action }
        >
            <Icons.Favorite type={ iconType } /> Add to favorite
        </Button>
    )
}


export default FavoriteButtonText;