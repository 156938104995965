import React, { useState } from "react";
import { Form, Input, Radio } from "antd";
import { useMutation } from "@apollo/client";

import { USER_UPLOAD_META } from "graphql/mutation/user-gql";

import { AvatarUpload } from "components/avatar";
import { successNotification } from "components/request-result";
import { metaFields } from "utils";


const ProfileCompanyInfo = ({ form, formLayout, user }) => {

    const { normalize, getValue } = metaFields,
          meta = normalize(user.meta_fields);

    const [metaUpload, { loading }] = useMutation(USER_UPLOAD_META,
        {
            update(cache, { data }) {

                const {
                    userMetaUpload : {
                        label,
                        message,
                        user
                    }
                } = data;

                const meta = normalize(user.meta_fields);

                form.setFieldsValue({ "company_logo-image-unset" : getValue(meta, "company_logo") });

                successNotification({
                    title: label,
                    description: message
                });
            }
        });

    const [ companyFlag, setCompanyFlag ] = useState(
                parseInt(getValue(meta, "company_member_flag", 1))
          );

    return (
        <Form
            name="company-info"
            form={ form }
            { ...formLayout }
        >
            <Form.Item
                name="company_member_flag-string"
                initialValue={ companyFlag }
                rules={ [
                    { required: true, message: "Choose option" }
                ]}
            >
                <Radio.Group
                    onChange={ ({ target }) => setCompanyFlag(target.value) }
                >
                    <Radio value={ 1 }>I’m a company member</Radio>
                    <Radio value={ 0 }>I’m a private person</Radio>
                </Radio.Group>
            </Form.Item>
            { companyFlag === 1 &&
                <>
                    <Form.Item
                        label={ <span>Logo</span>}
                        name="company_logo-image-unset"
                        initialValue={ getValue(meta, "company_logo") }
                        rules={ [
                            { required: true, message: 'Please upload company logo' }
                        ]}
                    >
                        <AvatarUpload
                            shape={"square"}
                            uploadMutation={ metaUpload }
                            variables={ { meta_key : "company_logo" } }
                            image={ getValue(meta, "company_logo") }
                            loading={ loading }
                            extraClass="contain"
                            uploadButtonText="Upload Logo"
                        />
                    </Form.Item>
                    <div className="field-group">
                        <Form.Item
                            label={ <span>Company name</span>}
                            name="company_name-string"
                            initialValue={ getValue(meta, "company_name") }
                            rules={ [
                                { required: true, message: 'Please input Company name' }
                            ]}
                        >
                            <Input placeholder="Company name" />
                        </Form.Item>
                        <Form.Item
                            label={ <span>Company website</span>}
                            name="company_site_url-string"
                            initialValue={ getValue(meta, "company_site_url") }
                            /*
                            rules={ [
                                { pattern: link.regExp, message: 'Type URL in right format (https://company-name.com)' }
                            ]}
                             */
                        >
                            <Input placeholder="https://company-name.com" />
                        </Form.Item>
                    </div>
                    <div className="field-group">
                        <Form.Item
                            label={ <span>Address</span>}
                            name="company_address-string"
                            initialValue={ getValue(meta, "company_address") }
                        >
                            <Input placeholder="Messe-Allee 1, 00000 Kundorf" />
                        </Form.Item>
                        <Form.Item
                            label={ <span>City</span>}
                            name="company_city-string"
                            initialValue={ getValue(meta, "company_city") }
                        >
                            <Input placeholder="Your company city" />
                        </Form.Item>


                    </div>
                    <div className="field-group">
                        <Form.Item
                            label={ <span>Country</span>}
                            name="company_country-string"
                            initialValue={ getValue(meta, "company_country") }
                        >
                            <Input placeholder="Germany" />
                        </Form.Item>
                        <Form.Item
                            label={ <span>Postcode</span>}
                            name="company_postcode-string"
                            initialValue={ getValue( meta, "company_postcode" ) }
                        >
                            <Input placeholder="Your company postcode" />
                        </Form.Item>
                    </div>
                </>
            }
        </Form>
    );
};

export default ProfileCompanyInfo;