import React from "react";

import { ProfileRoutes } from "components/routes";
import { ProfileMenu } from "components/menus";
import { ContainerWrap, ProfileWrap, WrapColumn } from "components/wraps";
import { useVarParam } from "components/use-hooks";

import "./profile-page.scss";


const ProfilePage = () => {

    const varParam = useVarParam();

    varParam.set({
        appBlockClass: ["bg-base"],
        mainBlockClass: [""]
    });

    return(
        <ContainerWrap className="profile-page">
            <ProfileWrap>
                <WrapColumn position="left">
                    <ProfileMenu />
                </WrapColumn>
                <WrapColumn position="right">
                    <ProfileRoutes />
                </WrapColumn>
            </ProfileWrap>
        </ContainerWrap>
    );

};

export default ProfilePage;