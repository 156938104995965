import React from "react";
import { Button } from "antd";

import ShowroomDownloadsEditModal from "./showroom-download-edit-modal";
import Icons from "components/icons";
import { useVarParam } from "components/use-hooks";


const ShowroomDownloadsEdit = ({
        attachment
    }) => {

    const varParam = useVarParam();

    return(
        <>
            <Button
                onClick={
                    () => varParam.set({ downloadModalId: attachment.id })
                }
            >
                <Icons.Edit/>
            </Button>
            <ShowroomDownloadsEditModal
                attachment={ attachment }
                modalVisibility={ attachment.id === varParam.get("downloadModalId") }
                hideModal = { () => varParam.set({ downloadModalId: false }) }
            />
        </>
    );
};

export default ShowroomDownloadsEdit;