import { gql } from '@apollo/client';


export const GET_TAXONOMIES = gql`
    query GetTaxonomies ( $ids : Mixed ){
        taxonomies( 
            where: {
                column: ID
                operator: IN
                value: $ids
            }
            orderBy: {
                column: ID
                order: ASC
            }
        ){
            id
            title
            type
            original_id
            ee_id
            count_term
            count_obj
            taxonomy_terms{
                id
                title
                sort_number
                count_obj
            }
            created_at
            updated_at
        }
    }
`;


