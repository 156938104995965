import React, { useRef } from "react";
import SwiperCore, { Navigation, Pagination, Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

import Icons from "components/icons";

import 'swiper/modules/pagination/pagination.scss';
import 'swiper/modules/lazy/lazy.scss';
import 'swiper/swiper.scss';


SwiperCore.use( [Navigation, Pagination, Keyboard ]);

const SwiperSlider = ({ settings, className, children }) => {

    const navigationPrevRef = useRef(null);
    const navigationNextRef = useRef(null);


    return(
        <Swiper
            className={ className }
            spaceBetween={ 20 }
            slidesPerView={ "auto" }
            slideToClickedSlide={ true }
            navigation={{
                disabledClass: "slick-disabled",
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
            }}
            onBeforeInit={ (Swiper) => {

                const navigation = Swiper.params.navigation

                navigation.prevEl = navigationPrevRef.current;
                navigation.nextEl = navigationNextRef.current;
            }}
            { ...settings }
        >
            { children }
            <button
                className="slick-arrow slick-prev"
                ref={ navigationPrevRef }
            >
                <Icons.Arrow />
            </button>
            <button
                className="slick-arrow slick-next"
                ref={navigationNextRef}
            >
                <Icons.Arrow />
            </button>
        </Swiper>
    );
}

const SwiperService = {
    Slider : SwiperSlider,
    Slide : SwiperSlide
}

export default SwiperService;