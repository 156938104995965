import React from "react";
import { useMutation } from "@apollo/client";
import { Button, Form, Input } from "antd";

import { PRODUCT_META_UPLOAD, PRODUCT_UPDATE } from "graphql/mutation/product-gql";

import { Product } from "components/product";
import { AvatarUpload } from "components/avatar";
import { successNotification } from "components/request-result";
import Icons from "components/icons";
import { metaFields } from "utils";

import "./product-author-form.scss";


const formSubmit = ({ values, productUpdate, author }) => {

    let { fieldValues } = metaFields.parseForm(values);

    //console.log(fieldValues);

    productUpdate({
        variables: {
            productId : parseInt(author.id),
            productInput: {
                id: parseInt(author.id),
                stand_id: parseInt(author.stand.id),
                title: fieldValues.title,
                description: fieldValues.description,
                status: fieldValues.status
            },
            metaInput: [],
            termIds: []
        }
    })
};


const ProductAuthorForm = ({ author, control, action }) => {

    const [ form ] = Form.useForm();

    const [ productUploadCover, { loading } ] = useMutation( PRODUCT_META_UPLOAD,
        {
            update(cache, { data }) {

                const { label, message, product } = data.productMetaUpload;

                form.setFieldsValue({ "cover-unset" : product.cover });

                successNotification({
                    title: label,
                    description: message
                });

            }
        });

    const [ productUpdate, { loading: updateLoading } ] = useMutation( PRODUCT_UPDATE,
        {
            update(cache, { data }) {

                const { label, message } = data.productCreateOrUpdate;

                successNotification({
                    title: label,
                    description: message
                });

                action.close()
            }
        });

    const { TextArea } = Input;

    const formLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    };

    return(
        <>
            <h2>Edit Author ({ author.status })</h2>
            <Form
                className="product-author-form"
                onFinish ={(values) => {
                    formSubmit({ values, productUpdate, author });
                }}
                form={ form }
                {...formLayout}
            >
                <div className="image-wrap">
                    <Form.Item
                        name="cover-unset"
                        initialValue={ author.cover }
                        rules={ [
                            { required: true, message: 'Please upload Author’s photo' }
                        ]}
                    >
                        <AvatarUpload
                            uploadMutation={ productUploadCover }
                            image={ author.cover }
                            size={ 220 }
                            loading={ loading }
                            badge={ <Icons.Avatar /> }
                            variables={{
                                input: {
                                    meta_key : "cover",
                                    product_id : author.id
                                }
                            }}
                        />
                    </Form.Item>
                    <div className="upload-info">
                        <p>
                            { author.cover ?
                                <b>Change image</b>:
                                <b>Add image</b>
                            }

                        </p>
                        <p>.jpg or .png files up to 10Mb</p>
                    </div>
                </div>
                <div className="description-wrap">
                    <Form.Item
                        label={ <span>Author’s name</span>}
                        name="title-field"
                        initialValue={ author.title }
                        rules={ [
                            { required: true, message: 'Please input Author’s name' }
                        ]}
                    >
                        <Input placeholder="Lewis Carroll" />
                    </Form.Item>
                    <Form.Item
                        label={ <span>Description</span>}
                        shouldUpdate
                        name="description-field"
                        initialValue={ author.description }
                    >
                        <TextArea
                            rows={ 9 }
                            maxLength={ 1200 }
                            showCount={
                                { formatter: ({ count, maxLength }) => maxLength - count}
                            }
                            placeholder="Add some words about author"
                        />
                    </Form.Item>
                    <Form.Item
                        hidden
                        name="status-field"
                        initialValue={ author.status === "draft" ?
                            "active" : author.status
                        }
                    >
                        <Input />
                    </Form.Item>
                    { control &&
                        <div className="action">
                            <Button
                                loading={ updateLoading }
                                type="primary"
                                htmlType="submit"
                            >
                                { author.status === "draft" ? "Publish Author" : "Save" }
                            </Button>
                            <Product.DeleteButton
                                product={ author }
                                modalText={ "Are you sure you want to delete this Author?" }
                                buttonText={ author.status === "draft" ? "Delete draft" : "Delete Author" }
                                action={ action }
                            />
                        </div>
                    }
                </div>
            </Form>
        </>
    );
};

export default ProductAuthorForm;