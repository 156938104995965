const parseFormMeta = (values) => {

    let metaValues = [],
        fieldValues = {},
        terms = [];

    for (let [key, meta_value] of Object.entries(values)) {

        let [meta_key, meta_type, action, meta_group] = key.split('-');

        if(action !== "unset" && [ "field", "terms" ].indexOf(meta_type) === -1) {

            metaValues.push({
                meta_key,
                meta_type,
                meta_value,
                meta_group
            });

            if(meta_type === undefined){
                delete metaValues[ metaValues.length - 1 ].meta_type;
            }

            if(meta_group === undefined){
                delete metaValues[ metaValues.length - 1 ].meta_group;
            }
        }

        if(meta_type === "field"){
            fieldValues[meta_key] = meta_value;
        }

        if(meta_type === "terms"){

            terms = [ ...terms, ...meta_value];
        }
    }

    return { metaValues, fieldValues, terms } ;

};

const parseFormArray = (values) => {

    let fields = {},
        fieldValues = [];

    for (let [key, value] of Object.entries(values)) {

        let [fieldName, type, id] = key.split('-');

        if(type !== "unset" && id){

            if(!fields[id]){
                fields[id] = {}
            }

            fields[id][fieldName] = value;

        }

    }

    for (let [, value] of Object.entries(fields)) {
        fieldValues.push(value);
    }

    return fieldValues;
}

const metaFilterEmpty = (newMeta, oldMeta = false) => {

    return newMeta.filter( (metaItem) => {

        let search = false;

        if(oldMeta){
            search = !!oldMeta.find( (item) => item.meta_key === metaItem.meta_key);
        }

        if(metaItem.meta_value !== "" || search) {
            return metaItem;
        }

        return false;
    });

};

const metaToObj = (meta_fields = {}) => {

    let meta = {};

    if(meta_fields) {
        for (let [, field] of Object.entries(meta_fields)) {
            meta[field.meta_key] = field;
        }
    }

    metaFields.meta = meta;

    return meta;
};

const getMetaGroup = ( meta = {}, groupName, empty = true) => {

    let newMeta = Object.values(meta).filter( ( item ) => item.meta_group === groupName );

    if(!empty){
        return metaFilterEmpty( newMeta );
    }

    return newMeta;
};

const getMetaFieldValues = ( meta = {}, fields = [], empty = true) => {

    let metaValues = { };

    fields.map( (item) => {

        if(getMetaFieldValue(meta , item) !== "" || empty){
            metaValues[item] = getMetaFieldValue(meta , item);
        }

        return item;
    });


    return metaValues;

};

const getMetaFieldBoolValue = ( meta, fieldName, defaultVal = false ) => {

    if(meta[fieldName] !== undefined){
        if(meta[fieldName].meta_value === "true"){
            return true;
        }

        if(meta[fieldName].meta_value === "false"){
            return false;
        }

        return !!parseInt(meta[fieldName].meta_value);
    }

    return defaultVal;
};


const getMetaFieldValue = ( meta, fieldName, defaultVal = "" ) => {
    return meta[fieldName] !== undefined ? meta[fieldName].meta_value : defaultVal;
};


const getMetaField = ( meta, fieldName, valueName = "meta_value", defaultVal = "" ) => {
    return meta[fieldName] !== undefined ? meta[fieldName][valueName] : defaultVal;
};


const metaFields = {
    parseForm : parseFormMeta,
    parseFormArray : parseFormArray,
    normalize : metaToObj,
    getValue : getMetaFieldValue,
    getValues : getMetaFieldValues,
    getBoolValue : getMetaFieldBoolValue,
    getField: getMetaField,
    getGroup : getMetaGroup,
    filterEmpty : metaFilterEmpty,
};

export default metaFields;