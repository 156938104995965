import React from "react";

import { ImageLoader } from "components/service";
import Icons from "components/icons";

import "./showroom-header-image.scss";


const ShowroomHeaderImage = ({ image, children }) => {


    return(
        <div className={ `showroom-header-image`}>
            { image ?
                <ImageLoader
                    wrapClass={ "image-wrap" }
                    altText={ "page_headers" }
                    image={ image }
                /> :
                <div className="no-image-wrap">
                    <Icons.NoImage/>
                </div>
            }
            { children }
        </div>
    );
};

export default ShowroomHeaderImage;