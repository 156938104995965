import React from "react";
import { useQuery } from "@apollo/client";

import { SUMMARY_STATISTICS } from "graphql/query/analytics-gql";

import AnalyticsSummaryList from "../analytics-summary-list";
import { Loader } from "components/request-result";
import Icons from "components/icons";
import { useVarParam } from "components/use-hooks";


const AnalyticsTotalBlock = ({ standId }) => {

    const { data : { logSummaryStatistics } = { }, loading } = useQuery( SUMMARY_STATISTICS, {
        variables: { standId },
        fetchPolicy: "cache-and-network"
    });

    const productsOn = useVarParam().get("productsOn");

    const {
        showroom_views,
        showroom_favorites,
        products_views,
        products_favorites
    } = logSummaryStatistics ?? {};

    return(
        <div className="analytics-block analytics-block-total">
            <h2>Views and Favorites (in total)</h2>
            { !loading && logSummaryStatistics &&
                <AnalyticsSummaryList>
                    <>
                        <p className="label">
                            <Icons.Eye className="showroom-views" />
                            Showroom views
                        </p>
                        <p className="count">{ showroom_views }</p>
                    </>
                    <>
                        <p className="label">
                            <Icons.Favorite className="showroom-favorites" />
                            Showroom favorites
                        </p>
                        <p className="count">{ showroom_favorites }</p>
                    </>
                    { productsOn &&
                        <>
                            <p className="label">
                                <Icons.Eye className="product-views" type={ "product" } />
                                Products views
                            </p>
                            <p className="count">{products_views}</p>
                        </>
                    }
                    { productsOn &&
                        <>
                            <p className="label">
                                <Icons.Favorite type={ "product" } className="product-favorites" />
                                Products favorites
                            </p>
                            <p className="count">{products_favorites}</p>
                        </>
                    }
                </AnalyticsSummaryList>
            }
            { loading && <Loader /> }
        </div>
    )
};

export default AnalyticsTotalBlock;
