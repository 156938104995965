import React from "react";
import { useQuery } from "@apollo/client";
import { Drawer } from "antd";

import { GET_STAND_COUNTS } from "graphql/query/stand-gql";

import ShowroomChecklist from "../showroom-checklist";
import ShowroomChecklistSteps from "../showroom-checklist-steps";
import Icons from "components/icons";
import { useVarParam } from "components/use-hooks";


const ShowroomChecklistDrawer = ({ showroom }) => {

    const { loading, data } = useQuery( GET_STAND_COUNTS, {
        variables: {
            standId: showroom.id
        }
    });

    const varParam = useVarParam();

    if( !loading && data ){
        varParam.set({
            standCounts : { ...data.standAmounts }
        })
    }

    return(
        <Drawer
            closeIcon={ <Icons.Cross /> }
            className={ "showroom-checklist-drawer" }
            placement="right"
            onClose={ () => varParam.set({ "showroomChecklistDrawerOn" : false }) }
            visible={ varParam.get("showroomChecklistDrawerOn") }
        >
            <div className="wrap">
                <h2>Set up the showroom</h2>
                <p className="status">
                    Your showroom is
                    { showroom.status === "online" ?
                        <span className="sub-text-bold-success"> online</span> :
                        <span className="sub-text-bold"> offline</span>
                    }
                </p>
                <ShowroomChecklist showroom={ showroom } />
            </div>
            <ShowroomChecklistSteps showroom={ showroom } />
        </Drawer>
    );
}

export default ShowroomChecklistDrawer;