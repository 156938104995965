import React from "react";
import { Collapse } from 'antd';

import CheckStep from "./steps";
import Icons from "components/icons";
import { useVarParam } from "components/use-hooks";


const ShowroomChecklistSteps = ({ showroom }) => {

    const varParam = useVarParam();

    const { Package, Product, Communication, Company, Downloads } = CheckStep;

    const settings = {
        closeDrawer : () => varParam.set({ "showroomChecklistDrawerOn" : false }),
        showroom
    }

    return(
        <div className="showroom-checklist-steps-wrap">
            <Collapse
                defaultActiveKey={['product-page']}
                accordion
                ghost
                expandIcon={ () => <Icons.Arrow /> }
                expandIconPosition="right"
            >
                <Package.Page { ...settings } />
                <Product.Page { ...settings } />
                <Company.Page { ...settings } />
                <Communication.Page { ...settings } />
                <Downloads.Page  { ...settings } />
            </Collapse>
        </div>
    );
}

export default ShowroomChecklistSteps;