import React from "react";
import { useLocation } from "react-router-dom";

import { CardProduct } from "components/card";
import { link } from "utils";


const FavoriteProductGrid = ({ products }) => {

    const location = useLocation();

    return(
        <div className="grid-five-col">
            { products &&  products.map(
                (product) => {

                    let url = `/product/${product.id}${ product.title ? "-" + link.toSlug(product.title) : ""}`;

                    return(
                        <CardProduct.Vertical
                            key={ product.id }
                            product={ product }
                            uriParams={{
                                pathname: url,
                                state: {
                                    from : location.pathname,
                                    control : false
                                }
                            }}
                        />
                    )
                })
            }
        </div>
    );
};

export default FavoriteProductGrid;