import ProductPageStep, { productStepCheck } from "./product-page-step";
import CompanyPageStep, { companyStepCheck } from "./company-page-step";
import CommunicationPageStep, { communicationStepCheck } from "./communication-page-step";
import DownloadPageStep, { downloadStepCheck } from "./download-page-step";
import PackagePageStep, { packageStepCheck } from "./package-page-step";


const CheckStep = {
    Package : {
        Page : PackagePageStep,
        check: packageStepCheck
    },
    Product : {
        Page : ProductPageStep,
        check: productStepCheck
    },
    Company : {
        Page : CompanyPageStep,
        check: companyStepCheck
    },
    Communication : {
        Page: CommunicationPageStep,
        check: communicationStepCheck
    },
    Downloads : {
        Page: DownloadPageStep,
        check: downloadStepCheck
    },
}

export default CheckStep;