import React from "react";
import { Result, Button } from "antd";

import './error-result.css';


const ErrorResult = (props) => {

    const { error, action, goToText} = props;

    const ErrorMessage = error.graphQLErrors.map(({ message: label, extensions : {reason, action} }) => {
        return (
            <div
                className="error-result"
                key={action}
            >
                <h3>{label}</h3>
                <p>{reason}</p>
            </div>
        );
    });

    return(
        <Result
            status="warning"
            title={ ErrorMessage }
            extra={
                <Button type="primary" onClick={action}>
                    { goToText }
                </Button>
            }
        />
    );
};

export default ErrorResult;