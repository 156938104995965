import React from "react";
import { Switch, Route, Redirect } from 'react-router-dom';

import Pages from "../pages";
import { useVarParam } from "components/use-hooks";


const AnalyticsRoutes = ({ parent = "showroom-configurator/analytics", standId }) => {

    const { Analytics } = Pages;
    const { SubPage } = Analytics;

    const productsOn = useVarParam().get("productsOn");

    return(
        <Switch>
            <Route path={`/${ parent }/`} exact>
                <Analytics.General standId={ standId } />
            </Route>
            <Route path={`/${ parent }/visitors/`}>
                <Analytics.Visitors standId={ standId } />
            </Route>
            <Route path={`/${ parent }/visitor/:userId/`}>
                <SubPage.Visitor standId={ standId } />
            </Route>
            { productsOn &&
                <Route path={`/${parent}/products`}>
                    <Analytics.Products standId={ standId } />
                </Route>
            }
            { productsOn &&
                <Route path={`/${ parent }/product/:productId/`}>
                    <SubPage.Product standId={ standId } />
                </Route>
            }
            <Route>
                <Redirect to={`/${parent}/`}/>
            </Route>
        </Switch>
    );
};

export default AnalyticsRoutes;