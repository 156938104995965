import React from "react";
import { useLocation } from "react-router-dom";

import { CardProduct } from "components/card";
import { link } from "utils";

import "./product-grid.scss";


const ProductGrid = ({ control, standProducts }) => {

    const products = standProducts.data.filter( item => item.is_searchable );

    const location = useLocation();

    return (
        <div className="product-grid grid-five-col">
        { products.map(
            (product) => {

                let url = `/product/${product.id}${ product.title ? "-" + link.toSlug(product.title) : ""}`;

                if(product.status === "draft"){
                    url += "/edit";
                }

                return(
                    <CardProduct.Vertical
                        key={ product.id }
                        product={ product }
                        cardClass={ "hover" }
                        imgWithLink={ true }
                        uriParams={{
                            pathname: url,
                            state: {
                                from : location.pathname,
                                control
                            }
                        }}
                    />
                )
            })
        }
        </div>
    );
};

export default ProductGrid;