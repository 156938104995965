import React from "react";

import ProductMediaSliderUpload from "./product-media-slider-upload";
import Icons from "components/icons";


const ImageControlButton = ({ image, slideNum, sliderRef, current }) => {

    return(
        <button
            className={ `image-control-button ${ image ? "" : "empty"} ${current ? "active" : ""}` }
            onClick={ () => {
                sliderRef.current.goTo(slideNum);
            }}
        >
            { image ?
                <img src={ image } alt="" />:
                <p className="empty-text">
                    <Icons.Plus />
                    Add image
                </p>
            }
        </button>
    )
}


const ProductMediaSliderControl = ({ product, productImages, sliderRef, slideIndex }) => {

    return (
        <>
            { product.cover &&
                <div className="product-media-slider-control">
                    <div className="cover-control">
                        <p className="label">Cover image</p>
                        <ImageControlButton
                            sliderRef={sliderRef}
                            image={product.cover}
                            slideNum={0}
                            current={slideIndex === 0}
                        />
                    </div>
                    <div className="additional-control">
                        <p className="label">Additional slides</p>
                        <ul className="additional-control-list">
                            { productImages.map(
                                (item, index) => {
                                    return (
                                        <li key={item.id}>
                                            <ImageControlButton
                                                sliderRef={sliderRef}
                                                image={item.meta_value}
                                                slideNum={index + 1}
                                                current={slideIndex === index + 1}
                                            />
                                        </li>
                                    );
                                })
                            }
                            { productImages.length < 4 &&
                                <li>
                                    <ProductMediaSliderUpload
                                        variables={{
                                            input: {
                                                product_id: product.id,
                                                meta_group: "product_`photo",
                                                meta_type: "image",
                                                meta_key: `product_image_${productImages.length + 1}`
                                            }
                                        }}
                                    />
                                </li>
                            }
                        </ul>
                    </div>
                    <div className={"possibility-text"}>
                        {4 - productImages.length === 0 ?
                            <>You can't add more picture for this product</> :
                            <>You can add <b>{4 - productImages.length} more pictures</b> for this product</>
                        }
                    </div>
                </div>
            }
        </>
    );
};

export default ProductMediaSliderControl;
