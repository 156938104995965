import React from "react";
import { Switch, Route, Redirect } from 'react-router-dom';

import Pages from "../pages";
import { useVarParam } from "components/use-hooks";


const FavoriteRoutes = ({ parent = "favorite", spanList }) => {

    const { Favorite : { SubPage } } = Pages;

    const productsOn = useVarParam().get("productsOn");

    return(
        <Switch>
            { productsOn &&
                <Route path={`/${ parent }/products`} exact>
                    <SubPage.Products spanList={ spanList } />
                </Route>
            }
            <Route path={`/${ parent }/companies`} exact>
                <SubPage.Companies />
            </Route>
            <Route path={`/${ parent }/users`} exact>
                <SubPage.Users />
            </Route>
            <Route>
                <Redirect to={`/${ parent }/${ productsOn ? 'products' : 'companies' }`} />
            </Route>
        </Switch>
    );
};

export default FavoriteRoutes;