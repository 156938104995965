import React from "react";
import { Line } from '@ant-design/charts';


const AnalyticsChart = ({ points, color }) => {

    let config = {
        data: points,
        xField: 'date',
        yField: 'value',
        color: color,
        point: {},
        cursor: 'pointer',
    };

    return (
        <Line {...config}  className={"chart"} />
    );
};

export default AnalyticsChart;