import Router from "./router";

import MainRoutes from "./main-routes";
import AuthorizationRoutes from "./authorization-routes";
import ProfileRoutes from "./profile-routes";
import ShowroomControlRoutes from "./showroom-control-routes";
import ShowroomRoutes from "./showroom-routes";
import ProductRoutes from "./product-routes";
import SearchRoutes from "./search-routes";
import FavoriteRoutes from "./favorite-routes";
import AnalyticsRoutes from "./analytics-routes";

import RouteRedirector from "./route-redirector";

export default Router;

export {
    MainRoutes,
    ProfileRoutes,
    ShowroomControlRoutes,
    ShowroomRoutes,
    ProductRoutes,
    SearchRoutes,
    FavoriteRoutes,
    AuthorizationRoutes,
    RouteRedirector,
    AnalyticsRoutes
}

