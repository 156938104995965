import React from "react";
import { Link, useLocation } from "react-router-dom";

import { AvatarBlock } from "components/avatar";
import Icons from "components/icons";
import { link } from "utils";


const ProductAvatar = (
    {
        product,
        size = 84,
        control = false,
        withLink = false,
        h,
        children
    }) => {

    const location = useLocation();

    const Avatar = () => {
        return(
            <AvatarBlock
                shape="square"
                size={ size }
                image={ product.cover }
                className="product-avatar"
                badge={ !product.cover ? <div className="badge-user"><Icons.NoImage /></div> : null }
            >
                { h === 3 ?
                    <h3>{ product.title }</h3> :
                    <h5>{ product.title }</h5>
                }
                { children }
            </AvatarBlock>
        )
    }

    if(withLink){
        let url = `/product/${product.id}${ product.title ? "-" + link.toSlug(product.title) : ""}`;

        return(
            <Link to={{
                pathname: url,
                state: {
                    from : location.pathname,
                    control : control
                }
            }}>
                <Avatar />
            </Link>
        );
    }

    return <Avatar />;

}

export default ProductAvatar;