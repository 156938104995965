import React, { useState, useCallback } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { Modal } from "antd";

import { GET_ME } from "graphql/query/user-gql";
import { COMMUNICATION_CLOSE } from "graphql/mutation/communication-gql";

import { JitsiClient } from "components/service";
import Icons from "components/icons";
import { metaFields } from "utils";

import "./communication-modal.scss";

import { errorNotification, successNotification } from "../../request-result";


const CommunicationModal = ({ communication, setCommunication }) => {

    const [ visibleModal, toggleModal ] = useState(!!communication.id );

    const [ communicationClose ] = useMutation( COMMUNICATION_CLOSE, {

            update(cache, { data }) {

                const {
                    communicationClose : {
                        label,
                        message,
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message,
                });
            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    const closeCallback = useCallback(() => {
        communicationClose({
            variables: {
                id: communication.id
            }
        });
        toggleModal(false);
        setCommunication({})
    }, [communication.id, communicationClose, setCommunication]);

    const { data : { me } } = useQuery( GET_ME, { fetchPolicy: "cache-only" } );

    const { normalize } = metaFields;
    const meta = normalize(me.meta_fields);

    return (
        <Modal
            className="communication-modal"
            visible={ visibleModal }
            closeIcon={ <Icons.Cross /> }
            width={ 1024 }
            destroyOnClose={ true }
            centered
            footer={ null }
            onCancel={() => toggleModal(false)}
        >
            <JitsiClient
                clientData={{
                    email : meta?.profile_email?.meta_value,
                    roomName : `video-call-${ communication.token_jitsi }`,
                    username : `${ me.name } ${ me.surname }`

                }}
                communication={ communication }
                closeAction={ closeCallback }
            />
        </Modal>
    );
}

export default CommunicationModal;