import { useMutation } from "@apollo/client";

import { STAND_DELETE_ATTACHMENT } from "graphql/mutation/stand-gql";
import { GET_STAND_ATTACHMENTS } from "graphql/query/stand-gql";

import { successNotification } from "components/request-result";


const useShowroomDownloadDelete = ({
        standId,
        attachmentId,
        callBack = () => {},
        notify = true
    }) => {

    const [ deleteMutation, { loading, client } ] = useMutation( STAND_DELETE_ATTACHMENT,
        {
            update(cache, { data }) {

                const { label, message } = data.attachedDelete;

                const variables = {
                    standId,
                    status : [ "active", "draft" ],
                    orderBy : [{
                        column: "created_at",
                        order: "DESC"
                    }]
                }

                const { attachmentsStand } = client.readQuery({
                    query: GET_STAND_ATTACHMENTS,
                    variables
                });

                client.writeQuery({
                    query: GET_STAND_ATTACHMENTS,
                    variables,
                    data: {
                        attachmentsStand: {
                            ...attachmentsStand,
                            data : [
                                ...attachmentsStand.data.filter( ( item ) => item.id !== attachmentId )
                            ]
                        }
                    },
                });

                callBack();
                if(notify){
                    successNotification({
                        title: label,
                        description: message
                    });
                }
            },
            refetchQueries: ["StandAmounts"],
        });

    return{
        deleteMutation,
        loading
    }
}

export default useShowroomDownloadDelete;