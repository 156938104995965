import React from 'react';
import Icon from '@ant-design/icons';


const NoImageSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M36.4,7.5H12.3c-2.4,0-4.4,2-4.4,4.4V36c0,1.3,0.6,2.5,1.5,3.3c0,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.1,0.3,0.1
		    c0,0,0.1,0,0.1,0c0.7,0.4,1.4,0.6,2.3,0.6h24.1c2.4,0,4.4-2,4.4-4.4V11.9C40.8,9.5,38.8,7.5,36.4,7.5z M12.3,39.4
		    c-0.6,0-1.1-0.2-1.5-0.4l7.1-10.1l3.6,4.3c0.1,0.1,0.3,0.2,0.4,0.2c0.2,0,0.3-0.1,0.4-0.2l6.7-10.9l9.2,16.7
		    c-0.5,0.3-1.1,0.5-1.7,0.5H12.3z M39.8,36c0,0.9-0.3,1.6-0.9,2.2L29.3,21c-0.1-0.2-0.2-0.3-0.4-0.3c-0.1,0-0.3,0.1-0.4,0.2
		    l-6.7,11.1l-3.6-4.3c-0.1-0.1-0.3-0.2-0.4-0.2c-0.2,0-0.3,0.1-0.4,0.2L9.9,38.4c-0.6-0.6-1-1.5-1-2.4V11.9c0-1.9,1.5-3.4,3.4-3.4
		    h24.1c1.9,0,3.4,1.5,3.4,3.4V36z"
        />
        <path d="M20.4,15.2c-1.7,0-3.2,1.4-3.2,3.2s1.4,3.2,3.2,3.2s3.2-1.4,3.2-3.2S22.2,15.2,20.4,15.2z M20.4,20.6c-1.2,0-2.2-1-2.2-2.2
		    c0-1.2,1-2.2,2.2-2.2c1.2,0,2.2,1,2.2,2.2C22.6,19.6,21.6,20.6,20.4,20.6z"
        />
    </svg>
);

const NoImageIcon = props => {
    return <Icon component={ NoImageSvg } />;

};


export default NoImageIcon;