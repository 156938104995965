import React from "react";
import { useMutation } from "@apollo/client";

import { PRODUCT_CREATE } from "graphql/mutation/product-gql";
import { GET_PRODUCTS_BY_STAND } from "graphql/query/product-gql";

import { Product } from "components/product";

import "./product-create-button.scss";


const productCreateAction = ({
        productCreate,
        client,
        standId,
        onUpdate = () => {},
        variables
    }) => {

    productCreate({
        variables: {
            input: {
                stand_id : standId,
                status : "draft",
                ...variables
            }
        },
        update(cache, { data}) {

            const {
                productCreateOrUpdate : {
                    product
                }
            } = data;

            const { standProducts } = client.readQuery({
                query: GET_PRODUCTS_BY_STAND,
                variables: {
                    id: standId
                }
            });

            client.writeQuery({
                query: GET_PRODUCTS_BY_STAND,
                variables: {
                    id: standId
                },
                data: {
                    standProducts: {
                        data : [
                            product,
                            ...standProducts.data
                        ],
                        __typename: standProducts.__typename
                    }
                },
            });

            setTimeout(
                onUpdate({ product }), 250
            );

        },
        refetchQueries: ["StandAmounts"]
    })


};

const ProductCreateButton = ({ type, standId }) => {

    const [productCreate, { loading, client }] = useMutation( PRODUCT_CREATE );

    let prodType = type[0].toUpperCase() + type.slice(1);

    if(Product[prodType] === undefined){
        return null;
    }

    const CreateButton = Product[prodType].CreateButton;

    return(
        <CreateButton
            className="product-create-button"
            loading={ loading }
            createProduct={ ({ onUpdate, variables }) => {
                productCreateAction({
                    client,
                    productCreate,
                    standId,
                    onUpdate,
                    variables
                });
            }}
        />
    );
};

export default ProductCreateButton;