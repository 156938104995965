import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import { metaFields } from "utils";

import "./exhibition-empty.scss";


const ExhibitionEmpty = ({ ee }) => {

    const { normalize, getValue } = metaFields,
        meta = normalize(ee.meta_fields);

    return(
        <div className="expo-empty">
            <div>
                <div className="expo-logo">
                    <img src={ getValue(meta, "ee_alt_logo", getValue(meta, "ee_logo")) } alt={ ee.title } />
                </div>
                <h1>
                    { ee.title } <br />
                    Will start working <br />
                    on { moment( ee.start_at ).format('MMMM D') }
                </h1>
                <p>
                    You`ll be able to explore all the exhibition stands <br />
                    and communicate with them from then. <br />
                    For now you can update all the info in your <br />
                    <Link to="/profile/">profile</Link>
                </p>
            </div>
        </div>
    );
};


export default ExhibitionEmpty;