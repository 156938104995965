import React from "react";

import ShowroomControlStatusSwitcher from "../showroom-control-status-switcher";
import { ShowroomControlMenu } from "components/menus";
import { AvatarBlock } from "components/avatar";
import { useVarParam } from "components/use-hooks";
import Icons from "components/icons";

import "./showroom-control-sidebar.scss";


const ShowroomControlSidebar = ({ stand }) => {

    const varParam = useVarParam();
    let { sidebarOpen, mainBlockClass }= varParam.get();

    varParam.set({
        mainBlockClass: [
            ...mainBlockClass,
            sidebarOpen ? "open" : ""
        ]
    });

    const disabled = stand.status === "draft" ? "disabled" : "";

    //console.log('SIDEBAR', stand);

    return(
        <div
            className={`showroom-control-sidebar ${disabled}`}
        >
            <button
                className="expand-button"
                onClick={ () => {
                    varParam.set({ sidebarOpen: !sidebarOpen });
                }}
            >
                <Icons.Sidebar />
            </button>
            <div className="content">
                <div className="company-logo">
                    <AvatarBlock
                        image={ stand.logo }
                        size={ 40 }
                        shape="square"
                    >
                        <h5>
                            { stand.title !== null ? stand.title  : "Company name"}
                        </h5>
                    </AvatarBlock>
                </div>
                <ShowroomControlMenu
                    standId={ stand.id }
                />
                <ShowroomControlStatusSwitcher
                    status={ stand.status }
                    standId={ stand.id }
                />
            </div>
        </div>
    );
};

export default ShowroomControlSidebar;
