import ProductEditSubPage from "./product-edit-sub-page";
import ProductViewSubPage from "./product-view-sub-page";


const ProductSubPage = {
    Edit : ProductEditSubPage,
    View : ProductViewSubPage,
};

export default ProductSubPage;
