import SignInForm from "./sign-in-form";
import RegistrationForm from "./registration-form";
import ForgotPasswordForm from "./forgot-password-form";
import ChangeForgotPasswordForm from "./change-forgot-password-form";
import ActivationAccountForm from "./activation-account-form";
import ChangePasswordForm from "./change-password-form";
import AccountForm from "./account-form";
import ProfileForm from "./profile-form";


const forms = {
    SignInForm,
    RegistrationForm,
    ForgotPasswordForm,
    ChangeForgotPasswordForm,
    ActivationAccountForm,
    ProfileForm,
    ChangePasswordForm,
    AccountForm
};

export default forms;