import React from "react";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Button } from "antd";

import { LOG_VISITORS } from "graphql/query/analytics-gql";

import AnalyticsSummaryList from "../analytics-summary-list";
import { UserAvatar } from "components/user";
import Icons from "components/icons";


const AnalyticsVisitorSingleHeader = ({ standId, userId }) => {

    const { data : { logVisitors } = {} , loading } = useQuery( LOG_VISITORS, {
        variables: {
            standId,
            userId
        },
        fetchPolicy: "cache-and-network"
    });

    const history = useHistory();

    if(loading){
        return null;
    }

    const [ totalData ] = logVisitors.data;

    return(
        <div className="analytics-block analytics-header">
            <div className="back-button-wrap">
                <Button type="default" onClick={ () => history.goBack() }>
                    <Icons.Arrow />
                    GO BACK
                </Button>
            </div>
            <div className="total-data">
                <UserAvatar
                    user={ totalData.user }
                    size={ 140 }
                    companyView="short"
                    h={ 3 }
                >
                    <AnalyticsSummaryList className="compact">
                        <>
                            <p className="label">
                                Showroom views
                            </p>
                            <p className="count">{ totalData.view_stands }</p>
                        </>
                        <>
                            <p className="label">
                                Showroom favorite
                            </p>
                            <p className="check-mark">{ totalData.favorite_stands ? <Icons.Check className="check-icon" /> : "–" }</p>
                        </>

                        <>
                            <p className="label">
                                Products views
                            </p>
                            <p className="count">{ totalData.view_products }</p>
                        </>

                        <>
                            <p className="label">
                                Products favorites
                            </p>
                            <p className="count">{ totalData.favorite_products }</p>
                        </>

                    </AnalyticsSummaryList>
                </UserAvatar>
            </div>
        </div>
    );
}

export default AnalyticsVisitorSingleHeader;