import React from 'react';
import Icon from '@ant-design/icons';


const LogoutSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M27.2,32.7c0.2,0.2,0.5,0.3,0.7,0.3c0.2,0,0.5-0.1,0.7-0.3l7.4-7.1c0.2-0.2,0.4-0.5,0.4-0.8s-0.2-0.6-0.4-0.8L28.6,17
		c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l5.6,5.4H19.1c-0.6,0-1,0.4-1,1s0.4,1,1,1h13.7l-5.6,5.4C26.8,31.7,26.8,32.3,27.2,32.7
		z"/>
        <path d="M37.3,18.1c0.6,0,1-0.4,1-1v-3.5c0-2.4-2.2-4.4-4.9-4.4H16.2c-2.7,0-4.9,2-4.9,4.4v22.5c0,2.4,2.2,4.4,4.9,4.4h17.2
		c2.7,0,4.9-2,4.9-4.4v-3.6c0-0.6-0.4-1-1-1s-1,0.4-1,1v3.6c0,1.3-1.3,2.4-2.9,2.4H16.2c-1.6,0-2.9-1.1-2.9-2.4V13.6
		c0-1.3,1.3-2.4,2.9-2.4h17.2c1.6,0,2.9,1.1,2.9,2.4v3.5C36.3,17.6,36.7,18.1,37.3,18.1z"/>
    </svg>
);

const LogoutIcon = props => {
    return <Icon component={ LogoutSvg } />;

};


export default LogoutIcon;