import React from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { GET_ME } from "graphql/query/user-gql";

import { ShowroomControlRoutes } from "components/routes";
import { ShowroomControlSidebar } from "components/showroom";
import { useVarParam } from "components/use-hooks";
import { ErrorResultText } from "components/request-result";


const ShowroomControlPage = () => {

    const varParam = useVarParam();
    const history = useHistory();

    const { loading, data: { me } = {} } = useQuery( GET_ME );

    varParam.set({
        appBlockClass: ["bg-base"],
        mainBlockClass: ["sidebar"],
        headerOn: true
    });

    if(loading){
        return null;
    }

    const [ stand ] = me.stand;

    return(
        <div className="showroom-control-page">
            { !loading && !stand &&
                <ErrorResultText
                    title={"Permissions error"}
                    description={"Sorry, you do not have permission to access this section."}
                    goToText={"Back to home page"}
                    action={() => {
                        history.replace("/");
                    }}
                />
            }
            { !loading && stand &&
                <>
                    <ShowroomControlSidebar
                        stand={ stand }
                    />
                    <ShowroomControlRoutes
                        stand={ stand }
                        me={ me }
                    />
                </>
            }
        </div>
    );
}

export default ShowroomControlPage;