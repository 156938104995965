
const convertTaxonomyType = (type) => {

    let newType;

    switch (type){
        case "companies":
            newType = "stand";
        break;
        case "products":
            newType = "product";
        break;
        case "stand":
            newType = "companies";
        break;
        case "product":
            newType = "products";
        break;

        default: return undefined;

    }

    return newType;
}

export default convertTaxonomyType;