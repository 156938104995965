import AnalyticsGeneralPage from "./analytics-general-page";
import AnalyticsProductsPage from "./analytics-products-page";
import AnalyticsVisitorsPage from "./analytics-visitors-page";
import AnalyticsSubPage from "./sub-page";


const Analytics = {
    General : AnalyticsGeneralPage,
    Products : AnalyticsProductsPage,
    Visitors : AnalyticsVisitorsPage,
    SubPage : AnalyticsSubPage
};

export default Analytics;