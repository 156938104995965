import React from "react";
import { Button } from "antd";

import { CheckStep } from "./showroom-checklist-steps";
import { useVarParam, useCheckSet } from "components/use-hooks";

import "./showroom-checklist.scss";


const checkResult = (checkSet) => {

    const steps = {
        total: 0,
        done: 0
    }

    Object.entries(CheckStep).map(([, item ]) => {

        if(item.check){
            const result =  item.check({ ...checkSet });

            if(result) {
                steps.total = steps.total + result.stepAmount;
                steps.done = steps.done + result.stepFinish;
            }
        }

        return null;
    });

    return steps;
}

const ShowroomChecklist = ({ showroom, drawerButtonVisible = false }) => {

    const varParam = useVarParam();
    const checkSet = useCheckSet(showroom);

    let { total, done } = checkResult(checkSet);

    //console.log(steps);

    return (
        <div className="showroom-checklist">
            <p className="check-results sub-text">
                { total === done ? "All" : `${ done } of ${ total }`  } steps completed
            </p>
            <ul className="check-step-results">
                {[...Array(total)].map(
                    ( x, i) => {
                        return <li key={ i } className={ done-- > 0 ? "done" : "" } />;
                    })
                }
            </ul>
            { drawerButtonVisible &&
                <p className="button-wrap">
                    <Button
                        type="link"
                        onClick={ () => varParam.set({ "showroomChecklistDrawerOn" : true }) }
                    >
                        View what is left
                    </Button>
                </p>
            }
        </div>
    );
}

export default ShowroomChecklist;