import { gql } from '@apollo/client';


export const PRODUCT_CREATE = gql`
    mutation ProductCreate( $input: ProductCreateOrUpdateInput! ){
        productCreateOrUpdate( input: $input){
            label
            message
            product{
                id
                cover
                title
                description
                type
                is_searchable
                is_top
                status
                my_favorite
                stand{
                    id
                    logo
                    title
                }
                updated_at
                created_at
            }
        }
    }
`;

export const PRODUCT_UPDATE = gql`
    mutation ProductUpdate(
        $productId: ID!
        $productInput: ProductCreateOrUpdateInput!
        $metaInput: [ProductMetaInput]
        $termIds : [ID!]
    ){
        productCreateOrUpdate( input: $productInput){
            label
            message
        }
        termAddProduct(
            product_id: $productId
            term_id: $termIds
        ){
            label
            message
        }
        productMetaSet(
            product_id: $productId
            input: $metaInput
        ){
            product{
                id
                cover
                title
                description
                type
                is_searchable
                is_top
                status
                my_favorite
                meta_fields{
                    id
                    product_id
                    meta_key
                    meta_type
                    meta_value
                    meta_group
                }
                terms{
                    id
                    taxonomy_id
                    title
                    sort_number
                }
                stand{
                    id
                    logo
                    title
                }
                updated_at
                created_at
            }
        }
    }
`;

export const PRODUCT_META_UPLOAD = gql`
    mutation ProductUploadFile(
        $input : ProductUploadFileInput!
    ){
        productMetaUpload(
            input : $input
        ){
            label
            message
            product{
                id
                cover
                title
                description
                type
                is_searchable
                is_top
                status
                stand_id
                my_favorite
                meta_fields{
                    id
                    product_id
                    meta_key
                    meta_type
                    meta_value
                    meta_group
                }
                stand{
                    id
                    logo
                    title
                }
                terms{
                    id
                    taxonomy_id
                    title
                    sort_number
                }
                updated_at
                created_at
            }
        }
    }`;

export const PRODUCT_META_DELETE = gql`
    mutation ProductMetaDelete(
        $productId: ID!
        $meta_key: String
    ){
        productMetaDelete(
            product_id: $productId
            meta_key: $meta_key
        ){
            label
            message
            product{
                id
                cover
                title
                description
                type
                is_searchable
                is_top
                status
                stand_id
                my_favorite
                meta_fields{
                    id
                    product_id
                    meta_key
                    meta_type
                    meta_value
                    meta_group
                }
                stand{
                    id
                    logo
                    title
                }
                terms{
                    id
                    taxonomy_id
                    title
                    sort_number
                }
                updated_at
                created_at
            }
        }
    }
`;

export const PRODUCT_META_IMAGE_DELETE = gql`
    mutation ProductMetaDelete(
        $productId: ID!
        $meta_key: String
        $metaInput: [ProductMetaInput]
    ){
        productMetaDelete(
            product_id: $productId
            meta_key: $meta_key
        ){
            label
            message
        }
        productMetaSet(
            product_id: $productId
            input: $metaInput
        ){
            product{
                id
                cover
                title
                description
                type
                is_searchable
                is_top
                status
                my_favorite
                meta_fields{
                    id
                    product_id
                    meta_key
                    meta_type
                    meta_value
                    meta_group
                }
                stand{
                    id
                    logo
                    title
                }
                terms{
                    id
                    taxonomy_id
                    title
                    sort_number
                }
                updated_at
                created_at
            }
        }
    }
`;


export const PRODUCT_DELETE = gql`
    mutation ProductDelete(
        $productId: ID!
    ){
        productDelete( id: $productId){
            label
            message
        }
    }
`;