import {gql} from "@apollo/client";


export const IS_LOGGED_IN = gql`
  query IsUserLoggedIn {
    isLoggedIn @client
  }
`;

export const GET_AUTH_PARAMS = gql`
  query GetAuthParams {
    authParams @client
  }
`;

export const GET_PLATFORM_PARAMS = gql`
  query GetPlatformParams {
    platformParams @client
  }
`;

export const clientSchemaExtensions = gql`

    directive @client on FIELD

    extend type Query {
        isLoggedIn: Boolean
        authParams: Mixed
        platformParams: Mixed
    }
`;
