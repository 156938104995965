import React from 'react';
import Icon from '@ant-design/icons';

import { Loader } from "components/request-result";


const ExportSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M41.2,18.1l-9.9-7.7c-0.3-0.2-0.7-0.3-1.1-0.1c-0.3,0.2-0.6,0.5-0.6,0.9v3.7c-1.3,0.3-3.7,0.9-6.3,2.2
		c-4.9,2.5-7.8,6.3-8.4,11.1c-0.1,0.5,0.2,1,0.7,1.1c0.1,0,0.2,0,0.3,0c0.4,0,0.7-0.2,0.9-0.6C17,28.4,19.6,23,29.7,23v3.7
		c0,0.4,0.2,0.7,0.6,0.9c0.3,0.2,0.8,0.1,1.1-0.1l9.9-7.7c0.2-0.2,0.4-0.5,0.4-0.8S41.5,18.3,41.2,18.1z M31.7,24.6V22
		c0-0.5-0.4-1-1-1c-0.3,0-0.6,0-0.9,0c-5.5,0-9.1,1.5-11.4,3.1c3.7-6.1,12.2-7.3,12.3-7.4c0.5-0.1,0.9-0.5,0.9-1v-2.4l7.3,5.7
		L31.7,24.6z"/>
        <path d="M37.6,28.4c-0.6,0-1,0.4-1,1v7.4H11.1V11.4h11.3c0.6,0,1-0.4,1-1s-0.4-1-1-1H10.1c-0.6,0-1,0.4-1,1v27.5c0,0.6,0.4,1,1,1
		h27.5c0.6,0,1-0.4,1-1v-8.4C38.6,28.9,38.1,28.4,37.6,28.4z"/>
    </svg>
);

const ExportIcon = props => {

    if(props.type === "loading"){
        return <Loader />;
    }

    return <Icon component={ ExportSvg } />;

};


export default ExportIcon;