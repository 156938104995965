import React from "react";
import { useHistory } from "react-router-dom";

import { Loader } from "components/request-result";
import Icons from "components/icons";


const ProductAuthorCreateButton = ({ className, loading, createProduct }) => {

    const history = useHistory();

    return(
        <>
            <button
                className={`${ className } author`}
                onClick={ () => createProduct(
                    {
                        variables: {
                            type : "author",
                            is_searchable: false,
                            is_top : false
                        },
                        onUpdate: ({ product }) => {
                            history.push(`/showroom-configurator/product#author-${product.id}-edit`);
                        },
                    }
                )}
            >
                { loading  ?
                    <Loader  /> : <Icons.CreateProduct type="author" />
                }
                Add new author
            </button>
        </>
    );

};

export default ProductAuthorCreateButton;