import React from "react";
import {useMutation} from "@apollo/client";

import { PRODUCT_META_UPLOAD } from "graphql/mutation/product-gql";

import { UploadFile } from "components/service";
import { successNotification } from "components/request-result";


const ProductMediaUpload = ({ variables, children  }) => {

    const [uploadMutation, { loading }] = useMutation(PRODUCT_META_UPLOAD,
        {
            update(cache, { data }) {

                const {
                    productMetaUpload : {
                        label,
                        message,
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });
            }
        });

    return(
        <UploadFile
            variables={ variables }
            uploadMutation={ uploadMutation }
            loading={ loading }
        >
            { React.cloneElement( children, { loading }) }
        </UploadFile>
    );
}

export default ProductMediaUpload;