import React from "react";

import CommunicationJoinButton from "../communication-join-button";
import { CardUser } from "components/card";


const CommunicationUsersOnline = ( { conversations, setCommunication, myId } ) => {

    return(
        <div className="communication-users online">
            <h2>Company members online</h2>
            { conversations?.length ?
                <div className="conversation-block-wrap grid-two-col">
                    { conversations.map(
                        ( { exhibitor, accepted_user_id, ...conversation } ) => {

                            return(
                                <div className="conversation-block" key={  conversation.id }>
                                    <CardUser.Horizontal
                                        user={ exhibitor }
                                    />
                                    { conversation.status === "waiting" || accepted_user_id === myId ?
                                        <>
                                            { exhibitor.id !== myId ?
                                                <CommunicationJoinButton
                                                    conversationId={ conversation.id }
                                                    setCommunication={ setCommunication }
                                                /> :
                                                <div className="sub-text-bold busy-text">
                                                    You can't call to yourself
                                                </div>
                                            }
                                        </>:
                                        <div className="sub-text-bold busy-text">
                                            Is talking to other guest at the moment
                                        </div>
                                    }
                                </div>
                            );
                        })
                    }
                </div> :
                <div className="no-users">
                    This showroom has no users online
                </div>
            }
        </div>
    );
}

export default CommunicationUsersOnline;