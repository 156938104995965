import React, {useRef, useState} from "react";

import Icons from "components/icons";


const ProductAudioFileWrap = ({ filepath }) => {

    const audioRef = useRef(null);
    const filename = filepath.replace(/^.*[\\/]/, '');

    const [ played, setPlayed ] = useState( false );

    return(
        <div className={`product-audio-file-wrap`}>
            <button type="button" onClick={
                () => {
                    let audio = audioRef.current;

                    if(!audio.paused){
                        audio.pause();
                        setPlayed(false);
                    } else {
                        audio.play();
                        setPlayed(true);
                    }


                }
            }>
                <Icons.Player
                    type={ (played) ? "pause" : "play" }
                />{ filename }
            </button>
            <audio src={`${filepath}`} ref={audioRef} />
        </div>
    );
}

export default ProductAudioFileWrap;