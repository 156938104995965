import { gql } from '@apollo/client';


export const GET_TERM_STANDS = gql`
    query GetTermStands(
        $id : ID!
        $page : Int
    ){
        term(
            id : $id
        ){
            id
            title
            taxonomy_id
            sort_number
            count_obj
            created_at
            updated_at
            stands(
                where:{
                    column: STATUS
                    operator: EQ
                    value: "online"
                    AND: {
                        column: IS_MODERATED
                        operator: EQ
                        value: true
                    }
                }
                orderBy: {
                    column: ID
                    order: DESC
                }
                first: 500
                page : $page
            ){
                paginatorInfo{
                    total
                    currentPage
                    hasMorePages
                    perPage
                    count
                }
                data{
                    id
                    logo
                    title
                    description
                    my_favorite
                    products(
                        limit : 3
                        where:{
                            column: STATUS
                            operator: EQ
                            value: "active"
                            AND: {
                                column: IS_SEARCHABLE
                                operator: EQ
                                value: true
                            }
                        }
                    ){
                        id
                        cover
                        title
                        status
                    }
                }
            }
        }
    }
`;

export const GET_TERM_PRODUCTS = gql`
    query GetTermProducts(
        $id : ID!
        $page : Int
    ){
        term(
            id : $id
        ){
            id
            title
            taxonomy_id
            sort_number
            count_obj
            created_at
            updated_at
            products(
                where:{
                    column: STATUS
                    operator: EQ
                    value: "active"
                    AND: {
                        column: IS_SEARCHABLE
                        operator: EQ
                        value: true
                    }
                }
                orderBy: {
                    column: ID
                    order: DESC
                }
                first: 500
                page : $page
            ){
                paginatorInfo{
                    total
                    currentPage
                    hasMorePages
                    perPage
                    count
                }
                data{
                    id
                    cover
                    title
                    description
                    my_favorite
                    stand{
                        id
                        logo
                        title
                    }
                }
            }
        }
    }
`;