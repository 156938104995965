import React from "react";
import { useQuery } from "@apollo/client";

import { FAVORITE_PRODUCTS } from "graphql/query/favorite-gql";

import { FavoriteProductGrid, FavoriteExportButton } from "components/favorite";
import { ContainerWrap } from "components/wraps";
import { Loader } from "components/request-result";


const FavoriteProductsSubPage = ({ spanList }) => {

    const { loading, data } = useQuery( FAVORITE_PRODUCTS, {
        fetchPolicy: "cache-and-network"
    });

    const {
        favoriteProducts = {}
    } = data ?? {};

    return (
        <ContainerWrap
            spanList={ spanList }
            className={ "favorites-wrap" }
        >
            { data && favoriteProducts?.paginatorInfo?.total !== 0 ?
                <div className="favorite-export-wrap">
                    <FavoriteExportButton model="Product" />
                </div> :
                <div className="no-favorites">
                    <h3>You do not have any favorite products</h3>
                </div>
            }
            { loading && !data && <Loader /> }
            { data &&
                <FavoriteProductGrid
                    products={ favoriteProducts.data }
                />
            }
        </ContainerWrap>
    );
};

export default FavoriteProductsSubPage;