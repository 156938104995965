import React from "react";
import { useQuery } from "@apollo/client";

import { GET_TERM_STANDS } from "graphql/query/term-gql";

import { SearchResults } from "components/search";
import { Loader } from "components/request-result";


const SearchTermCompaniesSubPage = ({ match }) => {

    const { loading, data } = useQuery( GET_TERM_STANDS, {
        variables: {
            id: match.params.termId
        },
        fetchPolicy: "network-only"
    });

    const {
        term: {
            title,
            stands,
            //...term
        } = {}
    } = data || {};

    return(
        <div className="search-result-wrap">
            { loading &&
                <Loader />
            }
            { data && !loading &&
                <>
                    <h1>We found <b>{ stands.paginatorInfo.total }</b> companies for <b>“{ title }”</b></h1>
                    <SearchResults
                        stands={ stands.data }
                        paginatorInfo={ stands.paginatorInfo }
                    />
                </>
            }
        </div>
    );
};

export default SearchTermCompaniesSubPage;
