import React from "react";
import { Prompt } from "react-router-dom";

import { forms } from "components/showroom";
import { ContainerWrap } from "components/wraps";


const ShowroomProfileSubPage = ({ stand }) => {

    const { CompanyProfileForm } = forms;

    return(
        <ContainerWrap className="showroom-config-profile">
        { stand !== undefined &&
            <>
                <h2>Company profile</h2>
                <CompanyProfileForm stand={ stand } />
                <Prompt
                    message={
                        (location, action) => {
                            ///(action !== "POP") ?  : null

                            return !location.pathname.startsWith("/showroom")
                                ? true
                                : "Please setup your showroom"
                        }
                    }
                    when={ stand.status === "draft"  } />
                <div className="badge-page-label">
                    { stand.status === "draft" ?
                        "Add information about your company  and start to design the showroom" :
                        "You are editing company profile"
                    }
                </div>
            </>
        }
        </ContainerWrap>
    );
};

export default ShowroomProfileSubPage;