
const parseHash = (hash, valueSeparator="-", paramsSeparator="/") =>{

    let hashes = hash.slice(hash.indexOf('#') + 1).split(paramsSeparator);

    let parsed = {}

     hashes.reduce((params, hash) => {
         let [key, val, action] = hash.split(valueSeparator);

         if(key !== ""){
            parsed[key] = {
                value: val,
                action: action
            };
         }

         return parsed;

    }, {})

    return parsed;

};

const hash = {
    parse: parseHash,
};

export default hash;