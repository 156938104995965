import React, { useState } from "react";
import { Button, Modal } from "antd";

import SearchTerms from "../search-terms";
import Icons from "components/icons";


const SearchTaxonomy = ({ taxonomy }) => {

    const [ showModal, setShowModal ] = useState(false);

    const { taxonomy_terms } = taxonomy;

    return(
        <div className="search-taxonomy">
            <Button
                className={ showModal ? "active" : ""}
                onClick={ () => setShowModal(!showModal) }
            >
                { taxonomy.title }
                <Icons.Arrow type="filled"/>
            </Button>
            <Modal
                visible={ showModal }
                footer={ null }
                onCancel={ () => setShowModal(false) }
                wrapClassName={ "taxonomy-terms-modal" }
            >
                <SearchTerms
                    terms={ taxonomy_terms }
                    type={ taxonomy.type }
                    action={ setShowModal }
                />
            </Modal>
        </div>
    );

};

export default SearchTaxonomy;