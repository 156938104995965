import ProductAuthorSlider from "./product-author-slider";
import ProductAuthorPreview from "./product-author-preview";
import ProductAuthorModal from "./product-author-modal";
import ProductAuthorContent from "./product-author-content";
import ProductAuthorForm from "./product-author-form";
import ProductAuthorCreateButton from "./product-author-create-button";

const ProductAuthor = {
    Slider : ProductAuthorSlider,
    Modal : ProductAuthorModal,
    Preview : ProductAuthorPreview,
    Content : ProductAuthorContent,
    Form : ProductAuthorForm,
    CreateButton: ProductAuthorCreateButton
}

export default ProductAuthor;