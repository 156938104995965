import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Switch, Select } from "antd";

import { STAND_UPDATE_EXHIBITOR } from "graphql/mutation/stand-gql";

import { TeamDeleteUser } from "components/team";
import { successNotification } from "components/request-result";


const { Option } = Select;


const canEditPermission = ({ me, user, itsMe }) => {

    const canEdit = {
        visible : false,
        permission: false,
        delete: false
    };

    switch(me.permission){
        case "owner":
            canEdit.visible = true;

            if(!itsMe){
                canEdit.permission = true;
                canEdit.delete = true;
            }
        break;

        case "editor":
            if(user.permission !== "owner"){
                canEdit.visible = true;

                if(!itsMe){
                    canEdit.permission = true;
                }
            }
        break;

        default:
            if(itsMe){
                canEdit.visible = true;
            }
    }

    return canEdit;
}

const TeamListPermission = ({
        userId,
        standPermission : {
            id,
            stand_id,
            permission,
            visible
        },
        myPermission,
        itsMe = false
    }) => {

    const [ checkSwitch, setCheckSwitch ] = useState( visible );

    const [standUpdateExhibitor, { loading }] = useMutation( STAND_UPDATE_EXHIBITOR,
        {
            update(cache, { data }) {

                const {
                    standAddOrUpdateExhibitor : {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message,
                });

            }
        }
    );

    const canEdit = canEditPermission({
        me : { ...myPermission},
        user : {
            permission,
            visible
        },
        itsMe
    });


    return(
        <div className="permission-control">
            <div className={`switch-group field-group ${ !checkSwitch ? "off" : "" }`}>
                { canEdit.visible &&
                    <Switch
                        checked={ checkSwitch }
                        defaultChecked={ checkSwitch }
                        loading={ loading }
                        onChange={ (checked) => {

                            standUpdateExhibitor({
                                variables: {
                                    input : {
                                        id,
                                        stand_id,
                                        visible: checked
                                    }
                                }
                            })

                            setCheckSwitch( checked )
                        }}
                    />
                }
                <div className="switch-text">
                    { checkSwitch ? "Visible" : "Hidden" } on <br />
                    communication page
                </div>
            </div>
            <div className="permission-group field-group">
                <Select
                    defaultValue={ permission }
                    loading={ loading }
                    disabled={ !canEdit.permission }
                    onChange={ (value) => {
                        standUpdateExhibitor({
                            variables: {
                                input : {
                                    id,
                                    stand_id,
                                    permission: value
                                }
                            }
                        })
                    }}
                >
                    <Option value="member">Member</Option>
                    <Option value="editor">Editor</Option>
                    { (myPermission.permission === "owner" || !canEdit.permission) &&
                        <Option value="owner">Admin</Option>
                    }
                </Select>
                { canEdit.delete &&
                    <TeamDeleteUser
                        userId={ userId }
                        standId={ stand_id }
                        itsMe={ itsMe }
                    />
                }
            </div>
        </div>
    )
};

export default TeamListPermission;