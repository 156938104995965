import React from "react";
import { useQuery } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { Button } from "antd";

import { LOG_STAND_PRODUCT } from "graphql/query/analytics-gql";

import AnalyticsSummaryList from "../analytics-summary-list";
import { Product } from "components/product";
import Icons from "components/icons";


const AnalyticsProductSingleHeader = ({ standId, productId }) => {

    const { data : { logStandProduct } = {} , loading } = useQuery( LOG_STAND_PRODUCT, {
        variables: {
            standId,
            productId
        },
        fetchPolicy: "cache-and-network"
    });

    const history = useHistory();

    if(loading){
        return null;
    }


    return(
        <div className="analytics-block analytics-header">
            <div className="back-button-wrap">
                <Button type="default" onClick={ () => history.goBack() }>
                    <Icons.Arrow />
                    GO BACK
                </Button>
            </div>
            <div className="total-data">
                <Product.Avatar
                    product={ logStandProduct.product }
                    size={ 142 }
                    h={ 3 }
                >
                    <AnalyticsSummaryList className="compact">
                        <>
                            <p className="label">
                                Views
                            </p>
                            <p className="count">{ logStandProduct.product_views }</p>
                        </>
                        <>
                            <p className="label">
                                Favorites
                            </p>
                            <p className="count">{ logStandProduct.product_favorites }</p>
                        </>
                        <>
                            <p className="label">
                                Document views
                            </p>
                            <p className="count">{ logStandProduct.attachment_views }</p>
                        </>

                        <>
                            <p className="label">
                                Video views
                            </p>
                            <p className="count">{ logStandProduct.video_views }</p>
                        </>

                    </AnalyticsSummaryList>
                </Product.Avatar>
            </div>
        </div>
    );
}

export default AnalyticsProductSingleHeader;