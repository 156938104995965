import React from "react";

import SearchTaxonomy from "./search-taxonomy";
import { Loader } from "components/request-result";
import { useTaxonomy } from "components/use-hooks";

import "./search-taxonomies.scss";



const toTaxonomyType = (type) => {

    switch (type){
        case "companies": return "stand";
        case "products": return "product";
        default: return "stand";
    }

}

const SearchTaxonomies = ({ type }) => {

    const taxonomies = useTaxonomy( ["showroom_taxonomies", "product_taxonomies"]);

    const taxonomyType = toTaxonomyType(type);

    return(
        <div className="search-taxonomy-wrap">
            { !taxonomies &&
                <Loader/>
            }
            { taxonomies !== undefined &&
                <>
                { taxonomies.map((taxonomy) => {
                        if (
                            taxonomy.type !== taxonomyType &&
                            taxonomy.taxonomy_terms.length > 0
                        ){
                            return null;
                        }

                        return (
                            <SearchTaxonomy
                                key={taxonomy.id}
                                taxonomy={taxonomy}
                            />
                        )
                    })
                }
                </>
            }
        </div>
    );

}

export default SearchTaxonomies;