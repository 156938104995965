import React from "react";
import { Form, Input } from "antd";

import { metaFields, link } from "utils";


const CPSocialLinks = ({ form, formLayout, stand }) => {

    const { normalize, getValue } = metaFields;
    const meta = normalize(stand.meta_fields);

    return (
        <Form
            name="social-links"
            form={form}
            {...formLayout}
        >
            <div className="field-group">
                <Form.Item
                    label={ <span>Facebook</span>}
                    name="link_facebook-link--social_links"
                    initialValue={ getValue(meta, "link_facebook") }
                    rules={ [
                        {
                            pattern: link.regExpPattern("facebook.com"),
                            message: "Please insert valid https://facebook.com link"
                        },
                    ]}

                >
                    <Input placeholder="Link to your company Facebook page" />
                </Form.Item>
                <Form.Item
                    label={ <span>Instagram</span>}
                    name="link_instagram-link--social_links"
                    initialValue={ getValue(meta, "link_instagram") }
                    rules={ [
                        {
                            pattern: link.regExpPattern("instagram.com"),
                            message: "Please insert valid https://instagram.com link"
                        },
                    ]}
                >
                    <Input placeholder="Link to your company Instagram page" />
                </Form.Item>
            </div>
            <div className="field-group">
                <Form.Item
                    label={ <span>Linkedin</span>}
                    name="link_linkedin-link--social_links"
                    initialValue={ getValue(meta, "link_linkedin") }
                    rules={[
                        {
                            pattern: link.regExpPattern("linkedin.com"),
                            message: "Please insert valid https://linkedin.com link"
                        },
                    ]}
                >
                    <Input placeholder="Link to your company Linkedin page" />
                </Form.Item>
                <Form.Item
                    label={ <span>Youtube</span>}
                    name="link_youtube-link--social_links"
                    initialValue={ getValue( meta, "link_youtube" ) }
                    rules={[
                        {
                            pattern: link.regExpPattern("youtube.com|youtu.be"),
                            message: "Please insert valid https://youtube.com link"
                        },
                    ]}
                >
                    <Input placeholder="Link to your company Youtube page" />
                </Form.Item>
            </div>
        </Form>
    );
};

export default CPSocialLinks;