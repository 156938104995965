import React from "react";
import { useMutation } from "@apollo/client";

import { PRODUCT_META_UPLOAD, PRODUCT_META_DELETE } from "graphql/mutation/product-gql";

import { productUploadTypes } from "./type";
import { successNotification } from "components/request-result";

import "./product-file-or-upload.scss"


const ProductFileOrUpload = (props) => {

    const [metaUpload, { loading }] = useMutation(PRODUCT_META_UPLOAD,
        {
            update(cache, { data }) {

                const {
                    productMetaUpload : {
                        label,
                        message,
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });
            }
        });

    const [metaDelete, { loading : deleteLoading }] = useMutation(PRODUCT_META_DELETE,
        {
            variables: {
                meta_key: props.variables.input.meta_key,
                productId: parseInt(props.variables.input.product_id)
            },
            update(cache, { data }) {

                const {
                    productMetaDelete : {
                        label,
                        message,
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message
                });
            }
        });

    const ProductFileOrUpload = productUploadTypes[props.type];

    return(
        <ProductFileOrUpload
            uploadMutation={ metaUpload }
            loading={ loading }
            deleteMutation={ metaDelete }
            deleteLoading={ deleteLoading }
            { ...props }
        />
    );

};

export default ProductFileOrUpload;