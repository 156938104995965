import React from "react";
import {Link} from "react-router-dom";
import { useQuery } from "@apollo/client";

import { GET_USER } from "graphql/query/user-gql";

import { AvatarBlock, AvatarImage } from "components/avatar";
import UserContacts from "../user-contacts";
import { ShowroomCompanyAddress } from "components/showroom";
import { Loader } from "components/request-result";
import Icons from "components/icons";
import { metaFields} from "utils";

import "./user-profile-content.scss";


const companyInfo = (user) => {

    const { normalize, getValue } = metaFields;

    if(user?.stand.length){
        const [ stand ] = user.stand;
        const standMeta = normalize(stand?.meta_fields);

        return {
            id : stand.id,
            companyName : stand.title,
            logo : stand.logo,
            meta : standMeta,
            siteLink : getValue(standMeta, "company_site_url" )
        }
    } else {
        const metaUser = normalize(user?.meta_fields)

        if(parseInt(getValue(metaUser, "company_member_flag", 0))){

            return {
                id: undefined,
                companyName : getValue(metaUser, "company_name" ),
                logo : getValue(metaUser, "company_logo" ),
                meta : metaUser,
                siteLink : getValue(metaUser, "company_site_url" )
            }
        }
    }

    return undefined;

};

const UserProfileContent = ({ userId }) => {

    const { data : { user } = {} , loading } = useQuery( GET_USER, {
        variables: {
            id: userId
        }
    } );

    const { normalize, getValue } = metaFields;
    const metaUser = normalize(user?.meta_fields);

    const company = companyInfo(user);

    return(
        <div className="user-profile-content">
            { loading ?
                <Loader /> :
                <>
                    <div className="image-wrap">
                        <AvatarImage
                            size={ 200 }
                            photoUrl={ user.avatar }
                        />
                    </div>
                    <div className="description-wrap">
                        <h3 className="title">
                            { user.name } { user.surname }
                        </h3>
                        { getValue(metaUser, "profile_function") &&
                            <p className="user-function sub-text-bold">
                                { getValue(metaUser, "profile_function") }
                            </p>
                        }
                        <p className="user-role sub-text">
                            { user.role }
                        </p>
                        <div className="contacts block">
                            <UserContacts metaUser={ metaUser } />
                        </div>
                        { company &&
                        <div className="company-info block">
                            <h5 className="sub-text">Company information</h5>
                            <AvatarBlock
                                size={ 60 }
                                shape={ "square" }
                                image={ company.logo  }
                            >
                                <p className="company-name">{ company.companyName }</p>
                                { company.id &&
                                    <Link to={`/showroom-${ company.id }/product`}>View Showroom</Link>
                                }
                            </AvatarBlock>
                            <ShowroomCompanyAddress meta={ company.meta } />
                            <p className="site-link">
                                <Icons.Web/>
                                <a href={`//${ company.siteLink }`} target="_blank" rel="noreferrer">
                                    { `${ company.siteLink }`}
                                </a>
                            </p>
                        </div>
                        }
                        { getValue(metaUser, "looking_for") &&
                            <div className="interest block">
                                <h5 className="sub-text">I’m looking for</h5>
                                { getValue(metaUser, "looking_for") }
                            </div>
                        }
                    </div>
                </>
            }
        </div>
    );
};

export default UserProfileContent;