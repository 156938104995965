import { gql } from '@apollo/client';


export const GET_PRODUCTS_BY_STAND = gql`
    query GetProductsByStand(
        $id : ID!
        $type : String
        $status : ProductStatusEnum
        $page : Int
    ){
        standProducts(
            stand_id : $id
            status: $status
            type: $type
            page: $page
            first: 100
            orderBy:{
                column: ID
                order: DESC
            }
        ){
            data{
                id
                cover
                title
                description
                type
                is_searchable
                is_top
                status
                my_favorite
                stand{
                    id
                    logo
                    title
                }
                updated_at
                created_at
            }
        }
    }
`;

export const GET_PRODUCT = gql`
    query GetProduct(
        $id : ID!
    ){
        product(
            id : $id
        ){
            id
            cover
            title
            description
            type
            is_searchable
            is_top
            status
            stand_id
            my_favorite
            meta_fields{
                id
                product_id
                meta_key
                meta_type
                meta_value
                meta_group
            }
            terms{
                id
                taxonomy_id
                title
                sort_number
            }
            stand{
                id
                logo
                title
            }
            updated_at
            created_at
        }
    }
`;