import React from "react";
import {Button, Form, Input, Switch} from "antd";

import { Product } from "components/product";
import { TaxonomyFormItem } from "components/service";
import ProductFileOrUpload from "components/product/product-file-or-upload";
import { metaFields } from "utils";

import "./product-book-form.scss";



const ProductBookForm = ({
         form,
         formSubmit,
         formClass,
         formLayout,
         updateLoading,
         product,
         control,
         onDeleteAction,
         meta,
         taxonomies
    }) => {

    const { getValue, getField } = metaFields;

    const { TextArea } = Input;


    return(
        <Form
            className={ `product-book-form ${formClass}` }
            name={ "product" }
            form={ form }
            {...formLayout}
        >
            <h2>Edit book ({ product.status })</h2>
            <Form.Item
                name="is_top-field"
                initialValue={ product.is_top }
                valuePropName="checked"
            >
                <Switch data-text={"This is a top product"}/>
            </Form.Item>
            <Form.Item
                label={ <span>Title</span>}
                name="title-field"
                initialValue={ product.title }
                rules={ [
                    { required: true, message: 'Please input Book title' }
                ]}
            >
                <Input placeholder="Alice's Adventures in Wonderland" />
            </Form.Item>
            <Form.Item
                label={ <span>Subtitle</span> }
                name="book_subtitle-string"
                initialValue={ getValue(meta, "book_subtitle") }
            >
                <Input placeholder="One of the English language's most popular books" />
            </Form.Item>
            <Form.Item
                label={ <span>Author</span> }
                name="book_author-string"
                initialValue={ getValue(meta, "book_author") }
            >
                <Input placeholder="Lewis Carroll" />
            </Form.Item>
            <div className="field-group">
                <Form.Item
                    label={ <span>Publisher</span> }
                    name="book_publisher-string"
                    initialValue={ getValue(meta, "book_publisher") }
                >
                    <Input placeholder="Book publisher name" />
                </Form.Item>
                <Form.Item
                    label={ <span>ISBN NR.</span> }
                    name="book_isbn-string"
                    initialValue={ getValue( meta, "book_isbn" ) }
                >
                    <Input placeholder="000-0-00-000000-0" />
                </Form.Item>
            </div>
            <Form.Item
                label={ <span>Description</span>}
                shouldUpdate
                name="description-field"
                initialValue={ product.description }
            >
                <TextArea
                    rows={ 8 }
                    maxLength={ 1200 }
                    showCount={
                        { formatter: ({ count, maxLength }) => maxLength - count }
                    }
                    placeholder="Add book description"
                />
            </Form.Item>
            <Form.Item
                label={ <span>Link to product</span>}
                name="product_link-string"
                initialValue={ getValue(meta, "product_link") }
            >
                <Input placeholder="https://buyproduct.com/" />
            </Form.Item>
            <ProductFileOrUpload
                value={ getValue(meta, "product_audio_1") }
                type={ "audio" }
                meta_id={ getField(meta, "product_audio_1", "id") }
                variables={{
                    input : {
                        product_id: product.id,
                        meta_type: "audio",
                        meta_key: "product_audio_1",
                        meta_group : "attachments"
                    }
                }}
            />
            <ProductFileOrUpload
                value={ getValue(meta, "product_file_1") }
                type={ "file" }
                buttonText={ "Add PDF file" }
                accept={ "application/pdf" }
                meta_id={ getField(meta, "product_file_1", "id") }
                variables={{
                    input : {
                        product_id: product.id,
                        meta_type: "file",
                        meta_key: "product_file_1",
                        meta_group : "attachments"
                    }
                }}
            />
            { taxonomies && taxonomies.map(
                ({ id : taxonomyId, title, taxonomy_terms }) => {

                    let initialTerms = product.terms.filter(({ taxonomy_id }) => taxonomy_id === taxonomyId)
                                                    .map(({ id }) => id);

                    return (
                        <TaxonomyFormItem
                            initialTerms={ initialTerms }
                            terms={ taxonomy_terms }
                            min={ 1 }
                            max={ 5 }
                            key={ taxonomyId }
                            selectPrefix={ "Add" }
                            taxonomyId={ taxonomyId }
                            title={ title }
                        />
                    );
                })
            }
            <Form.Item
                hidden
                name="status-field"
                initialValue={ product.status === "draft" ?
                    "active" : product.status
                }
            >
                <Input />
            </Form.Item>
            { control &&
                <div className="action float-block">
                    <Button
                        loading={ updateLoading }
                        disabled={ updateLoading }
                        type="primary"
                        onClick={ formSubmit }
                    >
                        { product.status === "draft" ? "Publish" : "Save" }
                    </Button>
                    <Product.DeleteButton
                        product={ product }
                        modalText={ "Are you sure you want to delete this Book?" }
                        buttonText={ product.status === "draft" ? "Delete draft" : "Delete Book" }
                        action={ onDeleteAction }
                    />
                </div>
            }
        </Form>
    );
};

export default ProductBookForm;