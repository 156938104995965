import React from 'react';
import Icon from '@ant-design/icons';


const CommunicationSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M31.4,8.9H17c-5.4,0-9.8,4.4-9.8,9.8V25c0,5.4,4.4,9.8,9.8,9.8h7.5l8.6,6.9c0.2,0.1,0.4,0.2,0.6,0.2c0.1,0,0.3,0,0.4-0.1
		c0.3-0.2,0.6-0.5,0.6-0.9l0.4-6.9c3.7-1.5,6.1-5,6.1-9v-6.4C41.2,13.2,36.8,8.9,31.4,8.9z M39.2,25c0,3.3-2.2,6.3-5.4,7.3
		c-0.4,0.1-0.7,0.5-0.7,0.9l-0.3,5.6L25.4,33c-0.2-0.1-0.4-0.2-0.6-0.2H17c-4.3,0-7.8-3.5-7.8-7.8v-6.4c0-4.3,3.5-7.8,7.8-7.8h14.4
		c4.3,0,7.8,3.5,7.8,7.8V25z"/>
        <path d="M18.9,19.7c-0.9,0-1.6,0.7-1.6,1.6S18,23,18.9,23c0.9,0,1.6-0.7,1.6-1.6S19.8,19.7,18.9,19.7z"/>
        <path d="M24.6,19.7c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6S25.5,19.7,24.6,19.7z"/>
        <path d="M30.2,19.7c-0.9,0-1.6,0.7-1.6,1.6s0.7,1.6,1.6,1.6c0.9,0,1.6-0.7,1.6-1.6S31.1,19.7,30.2,19.7z"/>
    </svg>
);

const CommunicationIcon = props => {
    return <Icon component={ CommunicationSvg } />;

};


export default CommunicationIcon;