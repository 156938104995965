import React from "react";
import { NavLink } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { GET_ME } from "graphql/query/user-gql";

import { UserMenuPopover } from "components/user";
import { ShowroomMenuPopover, ShowroomSetupButton } from "components/showroom";
import Search from "components/search";
import Icons from "components/icons";

import "./main-menu-bar.scss";


const MainMenuBar = ({ pParams }) => {

    const { data } = useQuery( GET_ME, { fetchPolicy: "cache-only" } );

    if(data === undefined){
        return null;
    }

    return (
        <header
            className={`main-menu fixed ${ pParams.headerClass.join(" ") }`}
        >
        { pParams.profileComplete &&
            <>
                <div className="left-side">
                    <NavLink
                        to="/"
                        className="menu-button home"
                        exact
                    >
                        <Icons.Exhibition />
                    </NavLink>
                    { pParams.searchOn &&
                        <Search />
                    }
                </div>
                <div className="right-side">
                    <NavLink
                        to="/favorite/"
                        className="menu-button favorites"

                    >
                        <Icons.Favorite type="list" />
                    </NavLink>
                    <NavLink
                        to="#notifi"
                        className="menu-button notifications"
                        exact
                        isActive={ () => false }
                    >
                        <Icons.Notification />
                    </NavLink>
                    <ShowroomMenuPopover user={ data.me } >
                        <ShowroomSetupButton />
                    </ShowroomMenuPopover>
                    <UserMenuPopover user={ data.me } />
                </div>
            </>
        }
        </header>
    );
};


export default MainMenuBar;