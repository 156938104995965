import React from 'react';
import Icon from '@ant-design/icons';


const TeamSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M33.9,24.5c2.8-0.8,4.9-3.5,4.9-6.6c0-3.8-3.1-6.9-6.8-6.9s-6.8,3.1-6.8,6.9c0,3.1,2.1,5.7,4.9,6.6c-2.2,0.4-4.3,1.5-6,3.2
	c-1.7-1.6-3.7-2.7-5.9-3.2c2.8-0.8,4.9-3.5,4.9-6.6c0-3.8-3.1-6.9-6.8-6.9s-6.8,3.1-6.8,6.9c0,3.1,2.1,5.7,4.9,6.6
	C8.4,25.6,3.8,31.6,3.8,38.9c0,0.6,0.4,1,1,1c0,0,0,0,0,0c0.5,0,1-0.4,1-1c0.1-6.9,4.7-12.6,10.4-12.6S26.5,32,26.6,38.9
	c0,0.5,0.5,1,1,1c0,0,0,0,0,0c0.6,0,1-0.5,1-1c0-3.7-1.3-7.1-3.2-9.7c1.9-1.8,4.2-2.9,6.7-2.9c5.7,0,10.3,5.4,10.4,12.1
	c0,0.5,0.5,1,1,1c0,0,0,0,0,0c0.6,0,1-0.5,1-1C44.3,31.4,39.8,25.6,33.9,24.5z M11.3,17.9c0-2.7,2.2-4.9,4.8-4.9
	c2.7,0,4.8,2.2,4.8,4.9s-2.2,4.9-4.8,4.9C13.5,22.8,11.3,20.6,11.3,17.9z M27.2,17.9c0-2.7,2.2-4.9,4.8-4.9s4.8,2.2,4.8,4.9
	s-2.2,4.9-4.8,4.9S27.2,20.6,27.2,17.9z"/>
    </svg>
);

const TeamIcon = props => {
    return <Icon component={ TeamSvg } />;

};


export default TeamIcon;