import React from "react";
import { useMutation } from "@apollo/client";

import { FAVORITE_EXPORT_XLS } from "graphql/mutation/favorite-gql";

import Icons from "components/icons";

import "./favorite-export-button.scss";


const FavoriteExportButton = ({ model }) => {

    const [ exportXLS, { loading } ] = useMutation( FAVORITE_EXPORT_XLS,
        {
            update(cache, { data }) {

                const { file } = data.favoriteExportFile;

                const link = document.createElement('a');

                link.href = file;

                document.body.appendChild(link);

                link.click();

                document.body.removeChild(link);

            }
        });

    return(
        <button
            className={"export-button favorite"}
            onClick={ () => {
                if (!loading){
                    exportXLS({
                        variables: { model }
                    });
                }
            }}
        >
            <Icons.Export type={ loading ? "loading" : null } /> Export List
        </button>
    )
}

export default FavoriteExportButton;