import React from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Form, Input, Button } from "antd";

import { CHANGE_FORGOT_PASSWORD } from "graphql/mutation/user-gql";

import { errorNotification, successNotification } from "components/request-result";

import "./change-forgot-password-form.scss";


const submitForm = ({ values, changeForgotRequest, forgotToken }) => {

    changeForgotRequest({
        variables : {
            password: values.password,
            token: forgotToken
        }
    }).catch((error) => {
        errorNotification(error);
    });

};

const ChangeForgotPasswordForm = () => {

    const { forgotToken } = useParams(),
          history = useHistory(),
          [ form ] = Form.useForm();

    const [changeForgotRequest, { loading : sendFormLoading}] = useMutation( CHANGE_FORGOT_PASSWORD,
        {
            update(cache, { data }) {

                const {
                    userUpdateForgotPassword : {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message,
                    duration: 8
                });

                history.push("/");
            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    const formItemLayout ={
        labelCol: { span: 24 },
        wrapperCol: { span: 24 },
    };


    return(
        <div className="form-holder">
            <h1>Change password</h1>
            <Form
                className="change-forgot-password-form"
                form={ form }
                onFinish={
                    (values) => {

                        submitForm({
                            values,
                            changeForgotRequest,
                            forgotToken
                        })
                    }
                }
            >
                <Form.Item
                    label={ <span className="site-label-text-color">New password</span>}
                    {...formItemLayout}
                    name="password"
                    rules={[
                        { min: 8, message: 'Password must be at least 8 characters' },
                        { required: true, message: 'Please input your Password' }
                    ]}
                >
                    <Input.Password name="password" />
                </Form.Item>
                <Form.Item>
                    <div className="form-actions">
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={sendFormLoading}
                        >
                            Confirm
                        </Button>
                    </div>
                </Form.Item>
            </Form>
            <div className="sign-link-wrap">
                <span>Remember your password?</span>
                <Link to="/">Sign In</Link>
            </div>
        </div>
    );
};

export default ChangeForgotPasswordForm;