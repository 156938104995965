import React from "react";
import { useQuery } from "@apollo/client";
import moment from "moment";

import { SUMMARY_STATISTICS_RANGE } from "graphql/query/analytics-gql";

import AnalyticsChart from "../analytics-chart";
import { Loader } from "components/request-result";


const prepareGraphArr = (statistics) => {

    const arr = {
        views : [],
        favorites : [],
    };

    for(let i = 0; i < statistics.length; i++){

        let statistic = statistics[i];

        arr.views.push({
            date: moment( statistic.date ).format('D MMM').toUpperCase(),
            value: statistic.views
        })

        arr.favorites.push({
            date: moment( statistic.date ).format('D MMM').toUpperCase(),
            value: statistic.favorites
        })

    }

    return arr;
}

const AnalyticsChartBlock = ({
        type,
        standId,
        colors,
        graphs = ['views', 'favorites']
    }) => {

    const { data : { logSummaryStatisticsRange } = { }, loading } = useQuery( SUMMARY_STATISTICS_RANGE, {
        variables: {
            standId,
            model: type,
            date:{
                from: moment().subtract(6, 'days').format("YYYY-MM-DD"),
                to: moment().format("YYYY-MM-DD"),
            }
        },
        fetchPolicy: "cache-and-network"
    });

    const graphData = !loading ? prepareGraphArr(logSummaryStatisticsRange) : {}

    return(
        <div className="analytics-block analytics-block-chart">
            { graphs.map(
                (graphName) => {
                    if(graphData[graphName]){
                        return(
                            <div className="chart-block" key={ graphName }>
                                <h2>{ type === 'product' ? "Products" : 'Showroom' } { graphName } for week</h2>
                                <AnalyticsChart
                                    points={ graphData[graphName] }
                                    color={ colors[graphName] }
                                />
                            </div>
                        )
                    }

                    return null;
                })
            }
            { loading && <Loader /> }
        </div>
    );
}

export default AnalyticsChartBlock;