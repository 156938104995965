import React from "react";

import { CardCompany } from "components/card";


const FavoriteStandGrid = ({ stands }) => {

    return(
        <div className="grid-two-col">
            { stands &&  stands.map(
                (stand) => {
                    return(
                        <CardCompany.Horizontal
                            key={ stand.id }
                            stand={ stand }
                            uriParams={{
                                pathname: `/showroom-${stand.id}/`,
                            }}
                        />
                    )
                })
            }
        </div>
    );
}

export default FavoriteStandGrid;