import React from "react";
import { Link } from "react-router-dom";

import { useVarParam } from "components/use-hooks";
import Icons from "components/icons";


const ShowroomPopoverMenu = ({ navClass="", standId }) => {

    return(
        <nav className={ `popover-menu ${navClass}` }>
            <ul>
                { useVarParam().get("productsOn") &&
                    <li>
                        <Link to={"/showroom-configurator/product"} className="with-icon">
                            <Icons.Product/>
                            <span>Design product page</span>
                        </Link>
                    </li>
                }
                <li>
                    <Link to={"/showroom-configurator/company"} className="with-icon">
                        <Icons.Company/>
                        <span>Design company page</span>
                    </Link>
                </li>
                { useVarParam().get("communicationOn") &&
                    <li>
                        <Link to={"/showroom-configurator/communication"} className="with-icon">
                            <Icons.Communication/>
                            <span>Design communication page</span>
                        </Link>
                    </li>
                }
                { useVarParam().get("downloadOn") &&
                    <li>
                        <Link to={"/showroom-configurator/downloads"} className="with-icon">
                            <Icons.Download/>
                            <span>Design downloads page</span>
                        </Link>
                    </li>
                }
                <li>
                    <Link to={"/showroom-configurator/profile"} className="with-icon">
                        <Icons.Edit/>
                        <span>Edit company profile</span>
                    </Link>
                </li>
                <li>
                    <Link to={"/showroom-configurator/team"} className="with-icon">
                        <Icons.Team/>
                        <span>Manage team</span>
                    </Link>
                </li>
                <li>
                    <Link to={"/showroom-configurator/analytics"} className="with-icon">
                        <Icons.Analytics/>
                        <span>Showroom analytics</span>
                    </Link>
                </li>
                <li>
                    <Link to={`/showroom-${standId}/`} className="with-icon but">
                        <Icons.Eye/>
                        <span>Showroom preview</span>
                    </Link>
                </li>
            </ul>
        </nav>
    );
};

export default ShowroomPopoverMenu;