import React from "react";

import { SearchRoutes } from "components/routes";
import { useVarParam, useExhibitionEvent } from "components/use-hooks";
import { metaFields } from "utils";

import "./search-page.scss";


const SearchPage = () => {

    const eeLocateByUrl = useExhibitionEvent();

    const { normalize, getValue } = metaFields,
        meta = normalize(eeLocateByUrl.meta_fields);

    const varParam = useVarParam();

    varParam.set({
        appBlockClass: getValue(meta, "ee_main_bg_type") === "image" ? ["bg-primary-image"] : ["bg-primary-gradient"],
        mainBlockClass: [""],
        headerOn: true
    });

    return (
        <div className="search-page">
            <SearchRoutes ee={ eeLocateByUrl } />
        </div>
    );

}

export default SearchPage;