import React from "react";
import { useQuery } from "@apollo/client";

import { FAVORITE_STANDS } from "graphql/query/favorite-gql";

import { FavoriteExportButton, FavoriteStandGrid } from "components/favorite";
import { ContainerWrap } from "components/wraps";
import { Loader } from "components/request-result";


const FavoriteCompaniesSubPage = ({ spanList }) => {

    const { loading, data } = useQuery( FAVORITE_STANDS, {
        fetchPolicy: "cache-and-network"
    });

    const {
        favoriteStands = {}
    } = data ?? {};

    return (
        <ContainerWrap
            spanList={ spanList }
            className={ "favorites-wrap" }
        >
            { data && favoriteStands?.paginatorInfo?.total !== 0 ?
                <div className="favorite-export-wrap">
                    <FavoriteExportButton model="Stand" />
                </div>:
                <div className="no-favorites">
                    <h3>You do not have any favorite companies</h3>
                </div>

            }
            { loading && !data && <Loader /> }
            { data &&
                <FavoriteStandGrid
                    stands={ favoriteStands.data }
                />
            }
        </ContainerWrap>
    );
}

export default FavoriteCompaniesSubPage;
