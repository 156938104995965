import React from 'react';
import Icon from '@ant-design/icons';


const EditSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M39.6,15.1l-6-6c-0.4-0.4-1-0.4-1.4,0L12.5,28.7c-0.1,0.1-0.2,0.3-0.3,0.5l-1.7,7.6c-0.1,0.3,0,0.7,0.3,0.9
            c0.2,0.2,0.4,0.3,0.7,0.3c0.1,0,0.1,0,0.2,0l7.7-1.6c0.2,0,0.4-0.1,0.5-0.3l19.6-19.6c0.2-0.2,0.3-0.4,0.3-0.7S39.8,15.3,39.6,15.1z
             M19.9,33.4l-4.6-4.6l14.2-14.2l4.6,4.6L19.9,33.4z M14,30.4l4.2,4.2l-5.4,1.1L14,30.4z M35.5,17.8l-4.6-4.6l2-2l4.6,4.6L35.5,17.8z
        "/>
    </svg>
);

const EditIcon = props => {
    return <Icon component={ EditSvg } />;

};


export default EditIcon;