import React from "react";

import {
    ExhibitionMap,
    ExhibitionEmpty,
    ExhibitionStands
} from "components/exhibition";
import {
    useExhibitionEvent,
    useVarParam
} from "components/use-hooks";
import { metaFields } from "utils";


const HomePage = () => {

    const varParam = useVarParam(),
          { mapOn } = varParam.get();

    const ee = useExhibitionEvent();

    const { normalize, getValue } = metaFields,
        meta = normalize(ee.meta_fields);

    varParam.set({
        appBlockClass: getValue(meta, "ee_main_bg_type") === "image" ? ["bg-primary-image"] : ["bg-primary-gradient"],
        mainBlockClass: [""]
    });

    return(
        <>
            <div className="expo-hall">
                { ee.status === "active" ?
                    <ExhibitionStands
                        eeId={ ee.id }
                        eeTitle={ ee.title  }
                        eeMeta={  meta  }
                    /> :
                    <ExhibitionEmpty ee={ ee } />
                }
            </div>
            { mapOn &&
                <ExhibitionMap />
            }
        </>

    );
};

export default HomePage;