import React from "react";
import { useQuery } from "@apollo/client";

import { GET_PRODUCTS_BY_STAND } from "graphql/query/product-gql";

import { ShowroomExtraContent } from "components/showroom";
import { Product } from "components/product";
import { Suspense } from "components/request-result";
import { ContainerWrap } from "components/wraps";


const ShowroomProducts = ({ spanList, control, stand, extraTypes = [] }) => {


    const { loading, error, data } = useQuery( GET_PRODUCTS_BY_STAND, {
        variables: {
            id: stand.id,
            status : control ? undefined : "active"
        }
    });

    return(
        <>
            <Suspense state={ {loading, error, data} }>
                <ShowroomExtraContent
                    types={ extraTypes }
                    control={ control }
                    key="extra-content"
                    stand={ stand }
                />
            </Suspense>
            <ContainerWrap
                key="showroom-content-wrap"
                className="showroom-content-wrap"
                spanList={ spanList }
            >
                <Suspense state={ {loading, error, data} }>
                    <Product.Grid
                        control={ control }
                    />
                </Suspense>
            </ContainerWrap>
        </>
    );
};

export default ShowroomProducts;