import { useQuery } from "@apollo/client";

import { GET_TAXONOMIES } from "graphql/query/taxonomy-gql";

import { useExhibitionEvent } from "components/use-hooks";
import { metaFields } from "utils";


const useTaxonomy = (fromEEMeta = false, taxonomyIds) => {

    const eeLocateByUrl = useExhibitionEvent();

    const { normalize, getField } = metaFields;
    const eeMeta = normalize( eeLocateByUrl.meta_fields );

    let searchIds = [];

    if(fromEEMeta){

        if(Array.isArray(fromEEMeta)){

            for(let i = 0; i < fromEEMeta.length; i++){
                searchIds = [...searchIds, ...getField( eeMeta, fromEEMeta[i] ) ]
            }

        } else {
            searchIds = getField( eeMeta, fromEEMeta );
        }

    } else {
        searchIds = taxonomyIds;
    }

    const { data : { taxonomies } = {} } = useQuery( GET_TAXONOMIES, {
        variables: {
            ids : searchIds
        }
    });

    return taxonomies;
}

export default useTaxonomy;