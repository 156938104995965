import React from "react";
import { Link } from "react-router-dom";
import { Collapse } from 'antd';

import Icons from "components/icons";
import { useCheckSet } from "components/use-hooks";

const { Panel } = Collapse;


const CommunicationPageStep = ({ closeDrawer, showroom, ...props}) => {

    const checkSet = useCheckSet(showroom);
    const checkResult = communicationStepCheck({ ...checkSet });

    if(!checkSet.pages.communication){
        return null;
    }

    return(
        <Panel
            {...props}
            header={
                <>
                    <p className={`sub-text${ checkResult.blockFinish ? "-success" : "" }`}>
                        { checkResult.blockFinish ? "Done" : `${ checkResult.stepsLeft } steps left`}
                    </p>
                    <h5>Communication page</h5>
                </>
            }
            key="communication-page"
        >
            <div className={ `step ${ checkResult.pageBanner ? "done" : "" }` }>
                <div className="success-badge">
                    <Icons.Check />
                </div>
                <div className="description">
                    <h6>Add page banner</h6>
                    <p className="sub-text">
                        This is the first image people see on the Page.
                    </p>
                    <Link
                        onClick={ closeDrawer }
                        className="link-button second"
                        to="/showroom-configurator/communication"
                    >
                        Add photo
                    </Link>
                </div>
            </div>

        </Panel>
    );
};

export const communicationStepCheck = ({ meta, pages } = {}) => {

    let checkResult = {
        stepAmount: 1,
        stepFinish: 0,
        stepsLeft: 0,
        blockFinish: false,
        pageBanner : false,
    };

    if(!meta || !pages.communication){
        return false;
    }

    if(meta.getValue( meta.fields, "communication_image_1" )){
        checkResult.stepFinish++;
        checkResult.pageBanner = true;
    }

    checkResult.blockFinish = checkResult.stepAmount === checkResult.stepFinish;
    checkResult.stepsLeft = checkResult.stepAmount - checkResult.stepFinish;

    return checkResult;
};

export default CommunicationPageStep;