import React from "react";

import { ImageLoader } from "components/service";
import { metaFields, youtubeUrl } from "utils";


const ShowroomCompanyContent = ({ meta }) => {

    const { getValue } = metaFields;

    return (
        <div>
            { getValue( meta,"about_description") !== "" &&
                <h1>{ getValue( meta,"about_title") }</h1>
            }
            { getValue( meta,"page_media_type_1") === "image" &&
                <ImageLoader
                    wrapClass={ "media-wrap" }
                    image={ getValue(meta, "page_image_1") }
                />
            }
            { getValue( meta,"page_media_type_1") === "video" &&
                <div className="media-wrap">
                    { getValue( meta,"page_video_link_1") !== "" &&
                        <iframe
                            src={ `${youtubeUrl.embed(meta.page_video_link_1.meta_value)}` }
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    }
                </div>
            }
            { getValue( meta,"about_description") !== "" &&
                <div className="description">{ getValue( meta,"about_description") }</div>
            }
        </div>
    );

};

export default ShowroomCompanyContent;