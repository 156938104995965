import React from "react";

import { Product } from "components/product";


const ProductAuthorSlide = (props) => {

    const { Author } = Product;


    return (
        <div className="slide">
            <Author.Preview author={ props.author } />
        </div>
    )
}

export default ProductAuthorSlide;