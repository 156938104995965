import React from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";

import { PRODUCT_UPDATE } from "graphql/mutation/product-gql";

import { Product } from "components/product";
import { useTaxonomy } from "components/use-hooks";
import { errorNotification, successNotification } from "components/request-result";
import { metaFields } from "utils";

import "./product-edit-form.scss";


const formSubmit = ({ form, productUpdate, product }) => {

    form.validateFields().then( values => {

        let { fieldValues, metaValues, terms } = metaFields.parseForm(values);

        metaValues = metaFields.filterEmpty(metaValues, product.meta_fields);

        productUpdate({
            variables: {
                productId : parseInt(product.id),
                productInput: {
                    id: parseInt(product.id),
                    is_top: fieldValues.is_top,
                    stand_id: parseInt(product.stand.id),
                    title: fieldValues.title,
                    description: fieldValues.description,
                    status: fieldValues.status
                },
                metaInput: [
                    ...metaValues
                ],
                termIds: [ ...terms ]
            }
        }).catch( error => {
            errorNotification(error);
        });

    }).catch( errorInfo => {
        console.log(errorInfo);
    });

};


const ProductEditForm = ({ product, control, form }) => {

    const { normalize } = metaFields;
    const meta = normalize(product.meta_fields);

    const history = useHistory();

    const taxonomies = useTaxonomy("product_taxonomies");

    const [ productUpdate, { loading: updateLoading } ] = useMutation( PRODUCT_UPDATE,
        {
            update(cache, { data }) {

                const { label, message } = data.productCreateOrUpdate;

                successNotification({
                    title: label,
                    description: message
                });

                history.push("/showroom-configurator/product")
            }
        });

    let prodType = product.type[0].toUpperCase() + product.type.slice(1);

    if(Product[prodType] === undefined){
        return null;
    }

    const ProductForm = Product[prodType].Form;

    return(
        <ProductForm
            form={ form }
            formClass={ "product-edit-form" }
            formLayout={{
                labelCol: { span: 24 },
                wrapperCol: { span: 24 }
            }}
            formSubmit={
                ( ) =>
                    formSubmit(
                        {
                            form,
                            productUpdate,
                            product,
                        }
                    )
            }
            control={ control }
            updateLoading={ updateLoading }
            meta={ meta }
            taxonomies={ taxonomies }
            product={ product }
            onDeleteAction={{
                close: () => history.replace("/showroom-configurator/product")
            }}
        />
    );
};

export default ProductEditForm;