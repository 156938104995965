import React from "react";
import { NavLink } from "react-router-dom";

import { useVarParam } from "components/use-hooks";
import Icons from "components/icons";


const AnalyticsMenuBar = () => {

    return(
        <nav className="analytics-menu-bar menu-bar">
            <ul>
                <li>
                    <NavLink to={`/showroom-configurator/analytics/`} exact>
                        <Icons.Analytics />
                        <span>General</span>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`/showroom-configurator/analytics/visitors/`}>
                        <Icons.Team />
                        <span>Visitors</span>
                    </NavLink>
                </li>
                { useVarParam().get("productsOn") &&
                    <li>
                        <NavLink to={`/showroom-configurator/analytics/products/`}>
                            <Icons.Product />
                            <span>Products</span>
                        </NavLink>
                    </li>
                }
            </ul>
        </nav>
    );
};

export default AnalyticsMenuBar;