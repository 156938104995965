import React from "react";

import { ShowroomHeader } from "components/showroom";
import { ShowroomMenuBar } from "components/menus";
import CommunicationBlocks from "components/communication";
import { ContainerWrap } from "components/wraps";
import { metaFields } from "utils";


const ShowroomCommunicationSubPage = ({ stand, control, spanList }) => {

    const { normalize } = metaFields;
    const meta = normalize(stand.meta_fields);

    return(
        <div className="showroom-communication-page">
            { control &&
                <div className="badge-page-label">You are design communication page</div>
            }
            <ContainerWrap
                spanList={ spanList }
                className="showroom-header-wrap"
            >
                <ShowroomHeader
                    stand={{
                        id : stand.id,
                        logo: stand.logo,
                        companyName: stand.title,
                        description: stand.description,
                        my_favorite: stand.my_favorite,
                        meta
                    }}
                    control={ control }
                    imageKey={ "communication_image_1" }
                >
                    { !control &&
                        <ShowroomMenuBar standId={stand.id} />
                    }
                </ShowroomHeader>
            </ContainerWrap>
            <ContainerWrap
                className="showroom-content-wrap"
                spanList={ spanList }
            >
                <CommunicationBlocks
                    control={ control }
                    stand={ stand }
                />

            </ContainerWrap>
        </div>
    );
};

export default ShowroomCommunicationSubPage;