import React from 'react';
import Icon from '@ant-design/icons';


const PhoneSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M31.7,39.5c-0.6,0-1.2-0.1-1.9-0.2c-2.6-0.7-5-1.8-7.2-3.2c-2-1.3-3.9-2.8-5.6-4.5c-1.6-1.5-3-3.2-4.2-5.1
                c-1.3-2-2.4-4.1-3.2-6.4c-1-3-0.2-6.1,2-8.3l1.6-1.6c0.8-0.8,2.2-0.8,3,0c0,0,0,0,0.1,0.1l5,5c0.8,0.8,0.8,2.1,0.1,2.9
                c0,0,0,0.1-0.1,0.1l-2.9,2.9c-0.5,0.5-0.6,1.3-0.1,1.8c1,1.4,2.2,2.8,3.4,4c1.4,1.4,2.9,2.7,4.5,3.8c0.6,0.4,1.3,0.3,1.8-0.2
                l2.8-2.9c0.8-0.8,2.2-0.8,3,0l5,5c0.8,0.8,0.8,2.2,0,3c0,0,0,0,0,0l-1.3,1.3C36,38.6,33.9,39.5,31.7,39.5z M14.9,11.5
                C14.8,11.5,14.8,11.5,14.9,11.5l-1.7,1.6c-1.7,1.6-2.3,4-1.5,6.3c0.7,2.1,1.7,4.1,2.9,5.9c1.1,1.7,2.5,3.3,3.9,4.8
                c1.6,1.6,3.4,3,5.3,4.2c2,1.3,4.3,2.3,6.6,3c2,0.5,4.3-0.1,5.8-1.7l1.4-1.3c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2l-5-5
                c-0.1-0.1-0.1-0.1-0.2,0L29.4,32c-1.2,1.2-3,1.3-4.4,0.4c-1.7-1.2-3.3-2.5-4.8-4c-1.3-1.3-2.5-2.7-3.6-4.2c-1-1.4-0.9-3.2,0.4-4.4
                l2.9-2.9c0,0,0,0,0.1-0.1c0.1-0.1,0.1-0.1,0-0.2L14.9,11.5C15,11.6,15,11.6,14.9,11.5C14.9,11.5,14.9,11.5,14.9,11.5z M38.2,35
                L38.2,35L38.2,35z"
        />
    </svg>
);

const MobilePhoneSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M33.3,41.8h-18c-1.9,0-3.5-1.6-3.5-3.5V9.9c0-1.9,1.6-3.5,3.5-3.5h18c1.9,0,3.5,1.6,3.5,3.5v28.5
		    C36.8,40.3,35.2,41.8,33.3,41.8z M15.3,8.4c-0.8,0-1.5,0.7-1.5,1.5v28.5c0,0.8,0.7,1.5,1.5,1.5h18c0.8,0,1.5-0.7,1.5-1.5V9.9
		    c0-0.8-0.7-1.5-1.5-1.5H15.3z"
        />
        <path d="M26.7,37.8h-4.8c-0.6,0-1-0.4-1-1s0.4-1,1-1h4.8c0.6,0,1,0.4,1,1S27.3,37.8,26.7,37.8z"/>
        <path d="M28.8,33.6h-9c-1.9,0-3.5-1.6-3.5-3.5v-16c0-1.9,1.6-3.5,3.5-3.5h9c1.9,0,3.5,1.6,3.5,3.5v16
		    C32.3,32.1,30.7,33.6,28.8,33.6z M19.8,12.7c-0.8,0-1.5,0.7-1.5,1.5v16c0,0.8,0.7,1.5,1.5,1.5h9c0.8,0,1.5-0.7,1.5-1.5v-16
		    c0-0.8-0.7-1.5-1.5-1.5H19.8z"
        />
    </svg>
);

const PhoneIcon = props => {

    if(props.type === "mobile"){
        return <Icon component={ MobilePhoneSvg } />
    }

    return <Icon component={ PhoneSvg } />;

};


export default PhoneIcon;