import React from "react";

import { useVarParam } from "components/use-hooks";

import "./exhibition-map.scss";
import Icons from "../../icons";


const ExhibitionMap = () => {

    const varParam = useVarParam();

    let { mapOpen, appBlockClass }= varParam.get();

    varParam.set({
        appBlockClass: [
            ...appBlockClass,
            "expo-map",
            mapOpen ? "open" : ""
        ]
    });

    return (
        <div className="exhibition-map">
            <button
                className="expand-button"
                onClick={ () => {
                    varParam.set({
                        mapOpen: !mapOpen
                    });
                }}
            >
                <Icons.Sidebar />
            </button>
            <div className="content">
                <h2>Exhibition map</h2>
            </div>
        </div>
    );
};

export default ExhibitionMap;