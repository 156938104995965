import React from "react";
import { Button } from "antd";

import { useShowroomDownloadDelete } from "../showroom-downloads-hook";

import { errorNotification, Loader } from "components/request-result";
import Icons from "components/icons";


const ShowroomDownloadsDeleteButton = ( { standId, attachmentId } ) => {

    const { loading, deleteMutation } = useShowroomDownloadDelete({
        standId,
        attachmentId,
        notify : true
    });

    return(
        <Button
            disabled={ loading }
            onClick={ () => {
                deleteMutation({
                    variables: {
                        attachmentId
                    }
                }).catch((error) => {
                    errorNotification(error);
                });
            } }
        >
            { loading ? <Loader /> : <Icons.Trash/> }
        </Button>
    );
}

export default ShowroomDownloadsDeleteButton;