import { gql } from '@apollo/client';


export const PACKAGE_REQUEST_CREATE = gql`
    mutation PackageRequestCreate( 
        $standId: ID!
        $packageId: ID!
    ){
        packageRequestCreate( 
            stand_id: $standId
            package_id : $packageId
        ){
            label
            message
        }
    }
`;