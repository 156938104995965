import React from "react";

import Icons from "components/icons";
import { metaFields } from "utils";


const UserContacts = ({ metaUser }) => {

    const { getValues } = metaFields;

    const userData = getValues(metaUser, ["profile_function", "profile_mobile_phone", "profile_phone", "profile_email"], false);

    return(
        <>
            { Object.keys(userData).length > 0 &&
                <ul>
                    { userData.profile_mobile_phone &&
                        <li>
                            <Icons.Phone type="mobile" />
                            <a href={`tel:${ userData.profile_mobile_phone }`}>
                                { userData.profile_mobile_phone }
                            </a>
                        </li>
                    }
                    { userData.profile_phone &&
                        <li>
                            <Icons.Phone/>
                            <a href={`tel:${ userData.profile_phone }`}>
                                { userData.profile_phone }
                            </a>
                        </li>
                    }
                    { userData.profile_email &&
                        <li>
                            <Icons.Email/>
                            <a href={`mailto:${ userData.profile_email }`}>
                                { userData.profile_email }
                            </a>
                        </li>
                    }
                </ul>
            }
        </>
    );
};

export default UserContacts;