import React from "react";
import { Form, Input } from "antd";

import { metaFields } from "utils";


const CPContactInfo = ({ form, formLayout, stand }) => {

    const { normalize, getValue } = metaFields;
    const meta = normalize(stand.meta_fields);

    return (
        <Form
            name="company-contact-info"
            form={form}
            {...formLayout}
        >
            <div className="field-group">
                <Form.Item
                    label={ <span>Address</span>}
                    name="company_address-string--contacts"
                    initialValue={ getValue(meta, "company_address") }
                    rules={ [
                        { required: true, message: 'Field required' }
                    ]}
                >
                    <Input placeholder="Your company address" />
                </Form.Item>
                <Form.Item
                    label={ <span>City</span>}
                    name="company_city-string--contacts"
                    initialValue={ getValue(meta, "company_city") }
                    rules={ [
                        { required: true, message: 'Field required' }
                    ]}
                >
                    <Input placeholder="Your company city" />
                </Form.Item>
            </div>
            <div className="field-group">
                <Form.Item
                    label={ <span>Country</span>}
                    name="company_country-string--contacts"
                    initialValue={ getValue(meta, "company_country") }
                    rules={ [
                        { required: true, message: 'Field required' }
                    ]}
                >
                    <Input placeholder="Your company country" />
                </Form.Item>
                <Form.Item
                    label={ <span>Postcode</span>}
                    name="company_postcode-string--contacts"
                    initialValue={ getValue( meta, "company_postcode" ) }
                >
                    <Input placeholder="Your company postcode" />
                </Form.Item>
            </div>
            <div className="field-group">
                <Form.Item
                    label={ <span>Phone</span>}
                    name="company_phone-string--contacts"
                    initialValue={ getValue( meta, "company_phone") }
                >
                    <Input placeholder="Field required" />
                </Form.Item>
                <Form.Item
                    label={ <span>Fax</span>}
                    name="company_fax-string--contacts"
                    initialValue={ getValue( meta,  "company_fax" ) }
                >
                    <Input placeholder="Your company fax number" />
                </Form.Item>
            </div>
            <div className="field-group">
                <Form.Item
                    label={ <span>Website</span>}
                    name="company_site_url-string--contacts"
                    /*
                    rules={ [
                        { pattern: link.regExp, message: 'Type URL in right format (https://company-site.com)' }
                    ]}
                     */
                    initialValue={ getValue( meta, "company_site_url") }
                >
                    <Input placeholder="https://company-site.com" />
                </Form.Item>
                <Form.Item
                    label={ <span>E-mail</span>}
                    name="company_email-string--contacts"
                    initialValue={ getValue( meta,  "company_email" ) }
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail',
                        }
                    ]}
                >
                    <Input placeholder="Your company general email" />
                </Form.Item>
            </div>
        </Form>
    );
};

export default CPContactInfo;