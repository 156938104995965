import React from "react";
import { useMutation } from "@apollo/client";
import { Button } from "antd";

import { STAND_META_SET, STAND_UPLOAD_FILE } from "graphql/mutation/stand-gql";

import { UploadFile } from "components/service";
import Icons from "components/icons";
import {errorNotification, successNotification} from "components/request-result";


const DeleteImageButton = ({ standId }) => {

    const [ updateAboutCompany, { loading }] = useMutation( STAND_META_SET,
        {
            update() {

                successNotification({
                    title: "Image removed",
                    description: ""
                });

            },
            onError(error){
                errorNotification(error);
            }
        }
    );


    return (
        <Button
            ghost
            disabled={ loading }
            loading={ loading }
            onClick={ () => {
                updateAboutCompany({
                    variables: {
                        standId : standId,
                        metaInput: [
                            { meta_key : "page_image_1", meta_value : "" },
                            { meta_key : "page_media_type_1", meta_value : "" }
                        ]
                    }
                });
            }}
        >
            Delete
        </Button>
    );

};

const AboutCompanyImageForm = ({image, standId, form}) => {

    const [ standUploadFile, { loading : fileLoading } ] = useMutation( STAND_UPLOAD_FILE,
        {
            update(cache, { data }) {

                const { label, message } = data.standMetaUpload;

                successNotification({
                    title: label,
                    description: message
                });

            }
        });

    if(image !== ""){
        return(
            <div className="media-placeholder image">
                <img src={ image } alt="about company"  />
                <DeleteImageButton standId={standId} />
            </div>
        )
    }

    return(
        <UploadFile
            extraClass={ 'upload-page-image' }
            loading={ fileLoading }
            uploadMutation={ standUploadFile }
            variables={{
                input : {
                    stand_id: standId,
                    meta_key: "page_image_1",
                    meta_group: "about_company"
                }
            }}
        >
            <div className="media-placeholder image">
                <span>
                    <Icons.NoImage />
                    <p className="label">Add image</p>
                </span>
            </div>
        </UploadFile>
    );

};

export default AboutCompanyImageForm;