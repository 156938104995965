import React from "react";
import { Button, Form, Input, Switch } from "antd";

import { Product } from "components/product";
import ProductFileOrUpload from "components/product/product-file-or-upload";
import { TaxonomyFormItem } from "components/service";
import { metaFields } from "utils";

import "./product-service-form.scss";



const ProductServiceForm = ({
        form,
        formSubmit,
        formClass,
        formLayout,
        updateLoading,
        product,
        control,
        onDeleteAction,
        meta,
        taxonomies
    }) => {

    const { getValue, getField } = metaFields;

    const { TextArea } = Input;

    return(
        <Form
            className={ `product-service-form ${formClass}`}
            form={ form }
            {...formLayout}
        >
            <h2>Edit product/service ({ product.status })</h2>
            <Form.Item
                name="is_top-field"
                initialValue={ product.is_top }
                valuePropName="checked"
            >
                <Switch data-text={"This is a top product"}/>
            </Form.Item>
            <Form.Item
                label={ <span>Title</span>}
                name="title-field"
                initialValue={ product.title }
                rules={ [
                    { required: true, message: 'Please input title' }
                ]}
            >
                <Input placeholder="Alice's Adventures in Wonderland" />
            </Form.Item>
            <Form.Item
                label={ <span>Description</span>}
                shouldUpdate
                name="description-field"
                initialValue={ product.description }
            >
                <TextArea
                    rows={ 8 }
                    maxLength={ 1200 }
                    showCount={
                        { formatter: ({ count, maxLength }) => maxLength - count }
                    }
                    placeholder="Add description"
                />
            </Form.Item>
            <Form.Item
                label={ <span>Link to product</span>}
                name="product_link-string"
                initialValue={ getValue(meta, "product_link") }
            >
                <Input placeholder="https://buyproduct.com/" />
            </Form.Item>
            <ProductFileOrUpload
                value={ getValue(meta, "product_file_1") }
                meta_id={ getField(meta, "product_file_1", "id") }
                type={ "file" }
                buttonText={ "Add PDF file" }
                accept={ "application/pdf" }
                variables={{
                    input : {
                        product_id: product.id,
                        meta_type: "file",
                        meta_key: "product_file_1",
                        meta_group : "attachments"
                    }
                }}
            />
            { taxonomies && taxonomies.map(
                ({ id : taxonomyId, title, taxonomy_terms }) => {

                    let initialTerms = product.terms.filter(({ taxonomy_id }) => taxonomy_id === taxonomyId)
                                                    .map(({ id }) => id);

                    return (
                        <TaxonomyFormItem
                            initialTerms={ initialTerms }
                            terms={ taxonomy_terms }
                            min={ 1 }
                            max={ 5 }
                            selectPrefix={ "Add" }
                            taxonomyId={ taxonomyId }
                            key={ taxonomyId }
                            title={ title }
                        />
                    );

                })
            }
            <Form.Item
                hidden
                name="status-field"
                initialValue={ product.status === "draft" ?
                    "active" : product.status
                }
            >
                <Input />
            </Form.Item>
            { control &&
                <div className="action float-block">
                    <Button
                        loading={ updateLoading }
                        disabled={ updateLoading }
                        type="primary"
                        onClick={ formSubmit }
                    >
                        { product.status === "draft" ? "Publish" : "Save" }
                    </Button>
                    <Product.DeleteButton
                        product={ product }
                        buttonText={ product.status === "draft" ? "Delete draft" : "Delete" }
                        action={ onDeleteAction }
                    />
                </div>
            }
        </Form>
    );
};

export default ProductServiceForm;