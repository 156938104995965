import React from "react";

import Icons from "components/icons";
import { metaFields, link } from "utils";


const ShowroomCompanyContacts = ( { meta } ) => {

    const { getGroup, getValues } = metaFields;

    const socialLinks = getGroup(meta, "social_links", false);
    const contacts = getValues(meta, ["company_phone", "company_site_url", "company_email"]);

    const {
        company_phone,
        company_site_url,
        company_email
    } = meta;

    let siteLink;

    if(company_site_url !== undefined) {
        siteLink = link.toUrl(company_site_url.meta_value);

        if (!siteLink) {
            siteLink = {
                href: "//" + company_site_url.meta_value,
                host: company_site_url.meta_value
            }
        }
    }

    return(
        <>
            { Object.keys(contacts).length  > 0 &&
                <ul className="contacts-block">
                { company_phone &&
                    <li>
                        <Icons.Phone/>
                       <a href={`tel:${ company_phone.meta_value }`}>{ company_phone.meta_value }</a>
                    </li>
                }
                { siteLink &&
                    <li>
                        <Icons.Web/>
                        <a href={`${ siteLink.href }`} target="_blank" rel="noreferrer">
                            { `${ siteLink.host}`}
                        </a>
                    </li>
                }
                { company_email &&
                    <li>
                        <Icons.Email/>
                        <a href={`mailto:${ company_email.meta_value }`}>{ company_email.meta_value }</a>
                    </li>
                }
                </ul>
            }
            { socialLinks.length > 0 &&
                <ul className="social-links-block">
                    { socialLinks.map( (item) => {
                        const key = item.meta_key.replace("link_", "");

                        return (
                            <li key={item.id}>
                                <a href={ item.meta_value } rel="noreferrer" target="_blank">
                                    <Icons.Social type={ key } />
                                </a>
                            </li>
                        )
                      })
                    }
                </ul>
            }
        </>
    );
};

export default ShowroomCompanyContacts;