import React from "react";
import { Button, Form } from "antd";

import { UploadFile } from "components/service";
import { ProductAudioFileWrap } from "../file-wrap";
import { Loader } from "components/request-result";
import Icons from "components/icons";


const ProductFileAudio = ({
        value,
        accept = "audio/mp3, audio/wav",
        uploadMutation,
        variables,
        loading,
        buttonText = "Add audio file",
        deleteMutation,
        deleteLoading
    }) => {


    if(!value){
        return(
            <Form.Item
                name={`${variables.input.meta_key}-audio-unset`}
                initialValue={ value }
            >
                <UploadFile
                    variables={ variables }
                    uploadMutation={ uploadMutation }
                    loading={ loading }
                    accept={ accept }
                    extraClass="product-attachment-upload-wrap"
                >
                    <button
                        type="button"
                        className="product-attachment-upload"
                    >
                        { loading ?
                            <Loader /> :
                            <Icons.Player type={ "sound" } /> }{ buttonText }
                    </button>
                </UploadFile>
            </Form.Item>
        );
    }

    return(
        <div className="product-from-file-container">
            <ProductAudioFileWrap
                filepath={ value }
            />
            <Button
                type="default"
                disabled={ deleteLoading }
                className="delete-file-button"
                onClick={ () => deleteMutation() }
            >
                { deleteLoading ? <Loader /> : <Icons.Trash /> }
            </Button>
        </div>
    );

};

export default ProductFileAudio;