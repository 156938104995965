import React, { useEffect } from "react";

const domain = 'video-stream-server.vep.mp-dev.eu';

const options = {
    roomName: 'newRoom',
    width: 1024,
    height: 750,
    userInfo: {
        email: 'user@mail.com',
        displayName: 'user'
    },
    interfaceConfigOverwrite: {
        VERTICAL_FILMSTRIP: false,
        HIDE_INVITE_MORE_HEADER: true,
        SHOW_JITSI_WATERMARK:false, //! not working
        SHOW_WATERMARK_FOR_GUESTS:false, //! not working
        TOOLBAR_BUTTONS: [
            'microphone',
            'camera',
            'desktop',
            'fullscreen',
            'fodeviceselection',
            'profile',
            'chat',
            'videoquality',
            'filmstrip',
            'shortcuts',
            'mute-everyone'
        ],
    }
};

const loadJitsiScript = () =>
    new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = "https://meet.jit.si/external_api.js";
        script.id = "jitsiApi";
        script.async = true;
        script.onload = resolve;

        document.head.append(script);

    });

const JitsiClient = ( {
        //communication,
        clientData,
        closeAction = () => {}
    }) => {

    options.roomName = clientData.roomName;
    options.userInfo.email = clientData.email;
    options.userInfo.displayName = clientData.username;

    useEffect(() => {

        let jitsi = {};

        loadJitsiScript().then(() => {

            options.parentNode = document.querySelector('#jitsiWrap');

            jitsi = new window.JitsiMeetExternalAPI(domain, options);

        }).catch(err => {
            console.error('Jitsi Meet API library not loaded.', err)
        });

        return () => {
            jitsi?.dispose?.();
            document.head.removeChild(document.querySelector('#jitsiApi'));

            closeAction();
        }
    }, [closeAction]);

    return <div id="jitsiWrap" />;
}

export default JitsiClient;