import React from "react";
import { Form, Input } from "antd";

import { TaxonomyFormItem } from "components/service";
import { useTaxonomy } from "components/use-hooks";
import { metaFields } from "utils";


const { TextArea } = Input;


const ProfileExhibitionInfo = ({ form, formLayout, user }) => {

    const taxonomies = useTaxonomy("user_interest_taxonomies");

    const { normalize, getValue } = metaFields;
    const meta = normalize(user.meta_fields);

    return (
        <Form
            name="exhibition-info"
            form={ form }
            {...formLayout}
        >
            { taxonomies && taxonomies.map(
                ({ id : taxonomyId, title, taxonomy_terms }) => {

                    return (
                        <TaxonomyFormItem
                            initialTerms={ getValue(meta, `user_interest_${ taxonomyId }`, []) }
                            terms={ taxonomy_terms }
                            name={ `user_interest_${ taxonomyId }-string--interest_taxonomy` }
                            min={ 0 }
                            max={ 7 }
                            key={ taxonomyId }
                            titlePrefix={ "I’M INTERESTED IN SUCH" }
                            selectPrefix={ "Add" }
                            taxonomyId={ taxonomyId }
                            title={ title }
                        />
                    );

                })
            }
            <Form.Item
                label={ <span>I’m looking for</span>}
                name="looking_for-string"
                initialValue={ getValue(meta, "looking_for") }
            >
                <TextArea
                    rows={6}
                    maxLength={ 180 }
                    showCount={
                        { formatter: ({ count, maxLength }) => maxLength - count}
                    }
                    placeholder={"You can write what products/services you are looking for"}
                />
            </Form.Item>
        </Form>
    );
};

export default ProfileExhibitionInfo;