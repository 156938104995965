import React from 'react';
import Icon from '@ant-design/icons';


const ExhibitionSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M15.7,7.3H7.6c-0.6,0-1,0.4-1,1v7.3c0,0.6,0.4,1,1,1h8.1c0.6,0,1-0.4,1-1V8.3C16.7,7.8,16.3,7.3,15.7,7.3z M14.7,14.6H8.6
		V9.3h6.1V14.6z"/>
        <path d="M28,7.3h-8.1c-0.6,0-1,0.4-1,1v7.3c0,0.6,0.4,1,1,1H28c0.6,0,1-0.4,1-1V8.3C29,7.8,28.6,7.3,28,7.3z M27,14.6h-6.1V9.3H27
		V14.6z"/>
        <path d="M40.6,7.3h-8.1c-0.6,0-1,0.4-1,1v7.3c0,0.6,0.4,1,1,1h8.1c0.6,0,1-0.4,1-1V8.3C41.6,7.8,41.2,7.3,40.6,7.3z M39.6,14.6
		h-6.1V9.3h6.1V14.6z"/>
        <path d="M15.7,19H7.6c-0.6,0-1,0.4-1,1v7.3c0,0.6,0.4,1,1,1h8.1c0.6,0,1-0.4,1-1V20C16.7,19.5,16.3,19,15.7,19z M14.7,26.3H8.6V21
		h6.1V26.3z"/>
        <path d="M28,19h-8.1c-0.6,0-1,0.4-1,1v7.3c0,0.6,0.4,1,1,1H28c0.6,0,1-0.4,1-1V20C29,19.5,28.6,19,28,19z M27,26.3h-6.1V21H27V26.3
		z"/>
        <path d="M40.6,19h-8.1c-0.6,0-1,0.4-1,1v7.3c0,0.6,0.4,1,1,1h8.1c0.6,0,1-0.4,1-1V20C41.6,19.5,41.2,19,40.6,19z M39.6,26.3h-6.1
		V21h6.1V26.3z"/>
        <path d="M15.7,30.8H7.6c-0.6,0-1,0.4-1,1v7.3c0,0.6,0.4,1,1,1h8.1c0.6,0,1-0.4,1-1v-7.3C16.7,31.3,16.3,30.8,15.7,30.8z M14.7,38.1
		H8.6v-5.3h6.1V38.1z"/>
        <path d="M28,30.8h-8.1c-0.6,0-1,0.4-1,1v7.3c0,0.6,0.4,1,1,1H28c0.6,0,1-0.4,1-1v-7.3C29,31.3,28.6,30.8,28,30.8z M27,38.1h-6.1
		v-5.3H27V38.1z"/>
        <path d="M40.6,30.8h-8.1c-0.6,0-1,0.4-1,1v7.3c0,0.6,0.4,1,1,1h8.1c0.6,0,1-0.4,1-1v-7.3C41.6,31.3,41.2,30.8,40.6,30.8z
		 M39.6,38.1h-6.1v-5.3h6.1V38.1z"/>
    </svg>
);

const ExhibitionIcon = props => {
    return <Icon component={ ExhibitionSvg } />;

};


export default ExhibitionIcon;