import React from 'react';
import Icon from '@ant-design/icons';


const FlipSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M36.6,8H16.3c-1.6,0-2.8,1.3-2.8,2.8v4.8h-0.2c-3.6,0-6.5,2.9-6.5,6.5s2.9,6.5,6.5,6.5h0.2v8.7c0,1.6,1.3,2.8,2.8,2.8h20.3
	c1.6,0,2.8-1.3,2.8-2.8V10.8C39.4,9.2,38.1,8,36.6,8z M13.2,26.7c-2.5,0-4.5-2-4.5-4.5s2-4.5,4.5-4.5h0.2v9H13.2z M37.4,37.4
	c0,0.5-0.4,0.8-0.8,0.8H16.3c-0.5,0-0.8-0.4-0.8-0.8v-8.7h10.8l-3.9,3.7c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.5,0.3,0.7,0.3
	c0.2,0,0.5-0.1,0.7-0.3l5.6-5.4c0.2-0.2,0.3-0.4,0.3-0.7s-0.1-0.5-0.3-0.7l-5.6-5.4c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4
	l3.8,3.7H15.4V10.8c0-0.5,0.4-0.8,0.8-0.8h20.3c0.5,0,0.8,0.4,0.8,0.8V37.4z"/>
    </svg>
);

const FlipIcon = props => {
    return <Icon component={ FlipSvg } />;

};


export default FlipIcon;