import { gql } from '@apollo/client';


export const GET_ME = gql`
    query GetMe {
        me{
            id
            name
            surname
            email
            email_original
            locale
            status
            role
            avatar
            meta_fields{
                id
                user_id
                meta_key
                meta_type
                meta_value
                meta_group
            }
            stand_permission{
                id
                stand_id
                user_id
                visible
                permission
                created_at
                updated_at
            }
            stand{
                id
                ee_id
                title
                description
                showcase
                keywords
                logo
                preview_banner
                order_number
                my_favorite
                exhibitors{
                    id
                    name
                    surname
                    avatar
                    role
                    email_original
                    meta_fields{
                        id
                        user_id
                        meta_key
                        meta_type
                        meta_value
                        meta_group
                    }
                    stand_permission{
                        id
                        stand_id
                        user_id
                        visible
                        permission
                        created_at
                        updated_at
                    }
                }
                terms{
                    id
                    taxonomy_id
                    title
                    sort_number
                }
                meta_fields{
                    id
                    stand_id
                    meta_key
                    meta_type
                    meta_value
                    meta_group
                }
                package{
                    id
                    title
                    price
                    ee_id
                    pdf
                    package_rules{
                        id
                        title
                        package_id
                        rule_key
                        order
                        min_value
                        max_value
                        type
                        active
                    }
                    created_at
                    updated_at
                }
                status
                is_moderated
                created_at
                updated_at
            }            
            updated_at
            created_at
        }
    }
`;

export const GET_USER = gql`
    query GetUser( $id : ID! ){
        user( id : $id ){
            id
            name
            surname
            email
            email_original
            locale
            status
            role
            avatar
            meta_fields{
                id
                user_id
                meta_key
                meta_type
                meta_value
            }
            stand_permission{
                id
                stand_id
                user_id
                visible
                permission
                created_at
                updated_at
            }
            stand{
                id
                ee_id
                title
                description
                showcase
                keywords
                logo
                my_favorite
                meta_fields{
                    id
                    stand_id
                    meta_key
                    meta_type
                    meta_value
                    meta_group
                }
                package{
                    id
                    title
                    price
                    ee_id
                    pdf
                    description
                    package_rules{
                        id
                        title
                        package_id
                        rule_key
                        order
                        min_value
                        max_value
                        type
                        active
                    }
                    created_at
                    updated_at
                }
                status
                is_moderated
                created_at
                updated_at
            }
            updated_at
            created_at
        }
    }
`;