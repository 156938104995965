import React from 'react';
import { useQuery } from "@apollo/client";

import { GET_ME } from "graphql/query/user-gql";
import { GET_PLATFORM_PARAMS } from "graphql/query/local-store-gql";

import { MainRoutes, RouteRedirector } from "../routes";
import { MainMenuBar } from "../menus";
import { Suspense } from "../request-result";
import { useAppSetup } from "components/use-hooks";

import './app.scss';


const App = () => {

    const { loading, error } = useQuery( GET_ME ),
          { data : { platformParams : pParams } } = useQuery( GET_PLATFORM_PARAMS );

    useAppSetup();

    return (
        <div className={ `app ${ pParams.appBlockClass.join(" ") }`}>
            <Suspense type="block" state={ { loading, error } }>
                <RouteRedirector rule="userProfile">
                    { pParams.headerOn &&
                        <MainMenuBar pParams={ pParams } />
                    }
                    <main className={ `main-block ${ pParams.mainBlockClass.join(" ")}` }>
                        { pParams.pageLabelText !== "" &&
                            <div className="badge-page-label">{ pParams.pageLabelText }</div>
                        }
                        <MainRoutes />
                    </main>
                </RouteRedirector>
            </Suspense>
        </div>
    );
};

export default App;