import React from "react";
import { Button, Form } from "antd";
import { useMutation } from "@apollo/client";

import { STAND_PROFILE_UPDATE } from "graphql/mutation/stand-gql";

import {
    CPGeneralInfo,
    CPContactInfo,
    CPSocialLinks,
    CPExhibitionInfo
} from "./form-part";
import { errorNotification, successNotification } from "components/request-result";
import { metaFields } from "utils";


const submitForms = ({ values, updateStandProfile, stand }) => {

    let { metaValues, fieldValues, terms } = metaFields.parseForm(values);

    metaValues = metaFields.filterEmpty(metaValues, stand.meta_fields);

    updateStandProfile({
        variables: {
            standId : parseInt(stand.id),
            standInput: {
                id: parseInt(stand.id),
                title: fieldValues.company_name,
                description: fieldValues.description,
                keywords: fieldValues.keywords.join(","),
                status: fieldValues.status
            },
            metaInput: [ ...metaValues ],
            termIds: [ ...terms ]
        }
    })

};

const CompanyProfileForm = (props) => {

    const [ updateStandProfile, { loading }] = useMutation( STAND_PROFILE_UPDATE,
        {
            update(cache, { data }) {

                const { label, message } = data.standUpdate;

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    const [ form ] = Form.useForm();

    const formParams = {
        form,
        formLayout : {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 }
        },
        ...props
    };

    return (
        <Form.Provider
            onFormFinish={(name, {values}) => {
                submitForms({
                    values,
                    updateStandProfile,
                    stand : props.stand
                });
            }}
        >
            <div className="form-wrap">
                <h4>General info</h4>
                <CPGeneralInfo { ...formParams } />
            </div>
            <div className="form-wrap">
                <h4>Contact info</h4>
                <CPContactInfo { ...formParams } />
            </div>
            <div className="form-wrap">
                <h4>Social links</h4>
                <CPSocialLinks { ...formParams } />
            </div>
            <div className="form-wrap">
                <h4>Exhibition info</h4>
                <CPExhibitionInfo { ...formParams }/>
            </div>
            <div className="actions">
                <Button
                    type="primary"
                    loading={ loading }
                    onClick={() => form.submit()}
                >
                    Save
                </Button>
            </div>
        </Form.Provider>
    );
};


export default CompanyProfileForm;