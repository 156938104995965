import React from "react";
import { Link, useLocation } from "react-router-dom";

import { AvatarBlock } from "components/avatar";
import { FavoriteButton } from "components/favorite";
import Icons from "components/icons";
import { link } from "utils";


const CardProductFlip = ({ product, flipped }) => {

    const location = useLocation();

    let url = `/product/${product.id}${ product.title ? "-" + link.toSlug(product.title) : ""}`;

    return(
        <div
            className={ `card card-product ${ flipped ? "flipped" : ""}` }
            key={ product.id }
        >
            <div className="card-inner-flip">
                <div className="card-front">
                    <div className="product-cover">
                        { product.cover ?
                            <img src={ product.cover } alt={ product.title } /> :
                            <Icons.NoImage />
                        }
                    </div>
                    <div className="card-text">
                        <h5>{ product.title }</h5>
                    </div>
                </div>
                <div className="card-back">
                    <div className="product-cover">
                        { product.cover ?
                            <img src={ product.cover } alt={ product.title } /> :
                            <Icons.NoImage />
                        }
                    </div>
                    <div className="card-text">
                        <h5>{ product.title }</h5>
                        <div className="product-description">
                            { product.description }
                        </div>
                    </div>
                    <div className="product-showroom">
                        <Link to={`/showroom-${product.stand.id}/`}>
                            <AvatarBlock
                                image={ product.stand.logo }
                                size={ 60 }
                                shape={ "square" }
                            >
                                <h5>{ product.stand.title }</h5>
                            </AvatarBlock>
                        </Link>
                    </div>
                    <div className="card-actions">
                        <Link
                            to={{
                                pathname: url,
                                state: {
                                    from : location.pathname,
                                    control : false
                                }
                            }}
                            className="link-button"
                        >
                            View product
                        </Link>
                        <div className="to-favorite">
                            <FavoriteButton
                                favorite={ product.my_favorite }
                                type={ "Product" }
                                id={ product.id }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardProductFlip;