import React, { useState } from "react";
import { useQuery } from "@apollo/client";

import { GET_ME } from "graphql/query/user-gql";
import { GET_COMMUNICATIONS } from "graphql/query/communication-gql";

import CommunicationCreate from "../communication-create";
import CommunicationModal from "../communication-modal";
import CommunicationConversations from "../communication-conversations";
import CommunicationUsersOffline from "../communication-users-offline";
import CommunicationUsersOnline from "../communication-users-online";
import { Loader } from "components/request-result";


const CommunicationBlocks = ({ control, stand }) => {

    const [ currentCommunication, setCurrentCommunication ] = useState({});

    const { data : { me } } = useQuery( GET_ME, { fetchPolicy: "cache-only" } );

    const { loading, data : { communications } = [] } = useQuery( GET_COMMUNICATIONS, {
        variables:{
            standId : stand.id
        },
        fetchPolicy: "cache-and-network",
        pollInterval : 10000
    });

    const onlineUsers = !communications ? [] : communications.map( ( { exhibitor } ) => exhibitor );
    //console.log(currentCommunication);
    return(
        <div className="communication-blocks">
            { communications && control &&
                <>
                    <CommunicationCreate
                        standId={ stand.id }
                        conversationCount={ onlineUsers.length }
                        setCommunication={ setCurrentCommunication }
                    />
                    <CommunicationConversations
                        conversations={ communications }
                    />
                </>
            }
            { communications && !control &&
                <>
                    <CommunicationUsersOnline
                        conversations={ communications }
                        setCommunication={ setCurrentCommunication }
                        myId = { me.id }
                    />
                    <CommunicationUsersOffline
                        users={ stand.exhibitors }
                        usersOnline={ onlineUsers }
                    />
                </>
            }
            { loading && <Loader /> }
            { !!currentCommunication.id && currentCommunication?.status !== "close" &&
                <CommunicationModal
                    communication={ currentCommunication }
                    setCommunication={ setCurrentCommunication }
                />
            }
        </div>
    )
};

export default CommunicationBlocks;