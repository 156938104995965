import ProfilePage from "./profile-page";
import ProfileUserSubPage from "./profile-user-sub-page";
import ProfileTeamSubPage from "./profile-team-sub-page";
import ProfileSettingsSubPage from "./profile-settings-sub-page";


export const Profile = {
    UserSubPage : ProfileUserSubPage,
    TeamSubPage : ProfileTeamSubPage,
    SettingsSubPage : ProfileSettingsSubPage
};

export default ProfilePage;