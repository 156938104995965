import React from "react";

import { ShowroomHeader, ShowroomAboutCompany } from "components/showroom";
import { ShowroomMenuBar } from "components/menus";
import { ContainerWrap } from "components/wraps";
import { metaFields } from "utils";


const ShowroomCompanySubPage = ({ stand, control, spanList, configurator }) => {

    const { normalize } = metaFields;
    const meta = normalize(stand.meta_fields);

    return(
        <div className="showroom-company-wrap">
            { control &&
                <div className="badge-page-label">You are design company page</div>
            }
            <ContainerWrap
                spanList={ spanList }
                className="showroom-header-wrap"
            >
                <ShowroomHeader
                    stand={{
                        id : stand.id,
                        logo: stand.logo,
                        companyName: stand.title,
                        description: stand.description,
                        my_favorite: stand.my_favorite,
                        meta
                    }}
                    control={ control }
                    imageKey={ "company_image_1" }
                >
                    { !configurator &&
                        <ShowroomMenuBar standId={stand.id} />
                    }
                </ShowroomHeader>
            </ContainerWrap>
            <ContainerWrap className="showroom-content-wrap">
                <ShowroomAboutCompany
                    stand={ stand }
                    control={ control }
                />
            </ContainerWrap>
        </div>
    );
};

export default ShowroomCompanySubPage;