const urlRegExp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/g;

const linkToUrl = (link = "") => {
    return link && link.match(urlRegExp) ? new URL(link) : undefined;
};

const regExpPattern = (link) => {
    //return new RegExp('^(https?://)?(www.)?'+ link + '/[a-zA-Z0-9(.?)?]', "i");
    return new RegExp('(?:(?:http|https)://)(^|^[^:]+://|[^.]+.)?(' + link + ')(?:(?:w)*!)?', "i");
};


const copyLink = (link) =>{

    const textField = document.createElement('textarea');

    if(link.indexOf(window.location.hostname)){
        textField.innerText =  window.location.protocol + '//' + window.location.hostname + link;
    } else {
        textField.innerText = link;
    }

    document.body.appendChild(textField);
    textField.select();

    document.execCommand('copy');

    textField.remove();

};

const slugText = (text) => {
    return text.toLowerCase()
               .replace(/ /g,'-')
               .replace(/[^\w-]+/g,'')
               .replace(/---/gi,'-')
               .replace(/--/gi,'-');
};

const link = {
    toUrl: linkToUrl,
    copy: copyLink,
    regExp: urlRegExp,
    regExpPattern,
    toSlug : slugText
};

export default link;