import React from "react"
import { useMutation } from "@apollo/client";
import { Button } from "antd";

import { STAND_UPLOAD_FILE } from "graphql/mutation/stand-gql";

import { UploadFile } from "components/service";
import { Loader, successNotification } from "components/request-result";
import Icons from "components/icons";
import { metaFields } from "utils";


const ShowroomHeaderUpload = ({
        standId,
        imageKey,
        meta
    }) => {

    const { getValue } = metaFields;

    const [ standUploadFile, { loading } ] = useMutation( STAND_UPLOAD_FILE,
        {
            update(cache, { data }) {

                const { label, message } = data.standMetaUpload;

                successNotification({
                    title: label,
                    description: message
                });

            }
        });

    return(
        <UploadFile
            extraClass="showroom-header-upload"
            loading={ loading }
            uploadMutation={ standUploadFile }
            variables={{
                input : {
                    stand_id: standId,
                    meta_key: imageKey,
                    meta_group: "page_headers"
                }
            }}
        >
            <Button type="default" disabled={ loading }>
                { loading ? <Loader /> :
                    <>
                        { getValue(meta, imageKey) !== "" ?
                            <Icons.Edit/> : <Icons.Plus />
                        }
                    </>
                }
                <span>{ getValue(meta, imageKey) !== "" ? "Change" : "Add"} page header</span>
            </Button>
        </UploadFile>
    );
};

export default ShowroomHeaderUpload;