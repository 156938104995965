import React from "react";
import { Table } from "antd";

import { Loader } from "components/request-result";

import "./analytics-table.scss";


const AnalyticsTable = ({ tableData, emptyText, className, pagination, loading }) => {

    let dataSource = [],
        columns = [];


    tableData.forEach( (row) =>  {

        let item = {};

        for (let [key, cell] of Object.entries(row)) {

            item[key] = cell.value ?? cell;

            if(typeof cell === 'object') {

                if( Object.keys(row).length - 1 !== columns.length ) {
                    columns.push({
                        title: <p>{ cell.title }</p>,
                        dataIndex: key,
                        key: key
                    });
                }
            }
        }
        dataSource.push(item);
    });


    return(
        <>
            { loading && !dataSource.length && <Loader /> }
            { !loading && !dataSource.length &&
                <div className="empty">{ emptyText }</div>
            }
            { dataSource.length > 0 &&
                <Table
                    loading={ loading }
                    dataSource={ dataSource }
                    columns={ columns }
                    className={ `analytics-table ${className ?? ""}` }
                    pagination={ pagination }
                />
            }
        </>
    )
};

export default AnalyticsTable;