import React from 'react';
import Icon from '@ant-design/icons';


const TrashSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M34.5,12h-5.2c-0.4-2.3-2.4-4-4.9-4s-4.4,1.7-4.9,4h-5.2c-0.9,0-1.6,0.7-1.6,1.6v3.2c0,0.9,0.7,1.6,1.6,1.6h0v19.4
		c0,1.1,0.9,2.1,2.1,2.1h15.9c1.1,0,2.1-0.9,2.1-2.1V18.3h0c0.9,0,1.6-0.7,1.6-1.6v-3.2C36,12.7,35.3,12,34.5,12z M24.4,10
		c1.3,0,2.4,0.9,2.8,2h-5.6C22,10.8,23.1,10,24.4,10z M14.8,14H34v2.3H14.8V14z M32.5,37.7C32.5,37.8,32.4,37.8,32.5,37.7l-16,0.1
		c0,0-0.1,0-0.1-0.1V18.3h16V37.7z"/>
        <path d="M20.3,34.9c0.6,0,1-0.4,1-1V23.1c0-0.6-0.4-1-1-1s-1,0.4-1,1v10.8C19.3,34.4,19.7,34.9,20.3,34.9z"/>
        <path d="M24.4,34.9c0.6,0,1-0.4,1-1V23.1c0-0.6-0.4-1-1-1s-1,0.4-1,1v10.8C23.4,34.4,23.9,34.9,24.4,34.9z"/>
        <path d="M28.6,34.9c0.6,0,1-0.4,1-1V23.1c0-0.6-0.4-1-1-1s-1,0.4-1,1v10.8C27.6,34.4,28,34.9,28.6,34.9z"/>
    </svg>
);

const TrashIcon = props => {
    return <Icon component={ TrashSvg } />;

};


export default TrashIcon;