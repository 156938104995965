import React from "react";
import { Button, Form } from "antd";
import { useMutation } from "@apollo/client";

import { UPDATE_PROFILE } from "graphql/mutation/user-gql";

import ProfilePersonalInfo from "./profile-personal-info-form";
import ProfileCompanyInfo from "./profile-company-info-form";
import ProfileExhibitionInfo from "./profile-exhibition-info-form";
import ProfileCompanyInheritShowroom from "./profile-company-inherit-showroom";
import { errorNotification, successNotification } from "components/request-result";
import { UserProfileModal } from "components/user";
import { metaFields } from "utils";

import "./profile-form.scss";



const submitForms = ({ values, updateProfile, user }) => {

    let { metaValues, fieldValues } = metaFields.parseForm(values);

    metaValues = metaFields.filterEmpty(metaValues, user.meta_fields);

    updateProfile({
        variables: {
            userInput: {
                name: fieldValues.name,
                surname: fieldValues.surname
            },
            metaInput: [ ...metaValues ]
        }
    })

};

const ProfileForm = (props) => {

    const [ updateProfile, { loading }] = useMutation( UPDATE_PROFILE,
        {
            update(cache, { data }) {

                const { label, message } = data.userMetaSet;

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    const [ form ] = Form.useForm();

    const formParams = {
        form,
        formLayout : {
            labelCol: { span: 24 },
            wrapperCol: { span: 24 }
        },
        ...props
    };

    const {
        id,
        stand
    } = props.user;

    return (
        <Form.Provider
            onFormFinish={(name, {values}) => {
                submitForms({
                    values,
                    updateProfile,
                    user : props.user
                });
            }}
        >
            <div className="form-wrap">
                <h4>Personal info</h4>
                <ProfilePersonalInfo {...formParams}/>
            </div>
            <div className="form-wrap">
                <h4>Company info</h4>
                { stand[0] !== undefined && stand[0].status !== "draft"?
                    <ProfileCompanyInheritShowroom
                        stand={ stand[0] }
                        { ...formParams }
                    />:
                    <ProfileCompanyInfo { ...formParams }/>
                }
            </div>
            <div className="form-wrap">
                <h4>Exhibition info</h4>
                <ProfileExhibitionInfo { ...formParams }/>
            </div>
            <div className="actions">
                <UserProfileModal
                    userId={ id }
                    buttonType="default"
                    buttonText="View business card"
                />
                <Button
                    type="primary"
                    loading={ loading }
                    onClick={() => {
                        form.submit();
                    }}
                >
                    Save
                </Button>
            </div>
        </Form.Provider>
    );
};


export default ProfileForm;