import React from 'react';
import Icon from '@ant-design/icons';


const ProductSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M8.3,34.3L23.6,42c0.3,0.1,0.6,0.1,0.9,0l15-7.7c0.3-0.2,0.5-0.5,0.5-0.9V17.1c0-0.4-0.2-0.7-0.5-0.9L24.2,7.5
	        c-0.2-0.1-0.3-0.1-0.5-0.1s-0.3,0-0.5,0.1L8.3,16.2c-0.3,0.2-0.5,0.5-0.5,0.9v16.3C7.8,33.7,8,34.1,8.3,34.3z M9.8,18.6l13,6.8v13.9
	        l-13-6.6V18.6z M24.8,39.6V25.3L38,18.6v14.2L24.8,39.6z M23.7,9.5L36.8,17l-13.1,6.7L11,17L23.7,9.5z"/>
    </svg>
);

const ProductIcon = props => {
    return <Icon component={ ProductSvg } />;

};


export default ProductIcon;