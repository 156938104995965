import React, { useRef, useState } from "react";
import { Carousel } from "antd";

import { Product } from "components/product";
import ProductMediaSlide from "./product-media-slide";
import ProductMediaSliderControl from "./product-media-slider-control";
import ProductMediaSlideDelete from "./product-media-slide-delete";
import Icons from "components/icons";
import { metaFields } from "utils";

import "./product-media-slider.scss";


const NextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <button
            className={className}
            style={{ ...style }}
            onClick={onClick}
        >
            <Icons.Arrow />
        </button>
    );
};

const PrevArrow = (props) => {
    const { className, style, onClick } = props;

    return (
        <button
            className={className}
            style={{ ...style }}
            onClick={onClick}
        >
            <Icons.Arrow />
        </button>
    );
};

const SliderDots = ({ images, slideIndex, sliderRef }) => {

    if( !images.length ){
        return null;
    }

    const Dot = ({ slideNum }) => {
        return(
            <li key={ slideNum } className={  slideIndex === slideNum ? "active" : "" }>
                <button
                    onClick={ () => {
                        sliderRef.current.goTo(slideNum);
                    }}
                >{ slideNum }</button>
            </li>
        );
    }

    return(
        <div className="product-slider-dots-wrap">
            <ul className="product-slider-dots">
                <Dot slideNum={ 0 } key={ 0 } />
                { images.map(
                    (item, index) => <Dot key={index + 1 } slideNum={ index + 1 } />)
                }
            </ul>
        </div>
    );
}


const sortImages = (productImages) => {

    productImages.sort(function (a, b) {
        if (a.meta_key > b.meta_key) {
            return 1;
        }
        if (a.meta_key < b.meta_key) {
            return -1;
        }

        return 0;
    });

    return productImages;
}


const ProductMediaSlider = ({ product, control }) => {

    const sliderRef = useRef(null);
    const [ slideIndex, setSlideIndex ] = useState(0);

    const { normalize, getGroup } = metaFields;

    const meta = normalize(product.meta_fields);
    const productImages = sortImages(getGroup(meta, "product_photo"));

    const settings = {
        dots: false,
        infinite: false,
        arrows: true,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        ref : sliderRef,
        beforeChange: (current, next) => setSlideIndex(next)
        //appendDots: (dots) => { console.log(dots);}
    };


    return(
        <div className="product-media-slider">
            <Carousel { ...settings }>
                <ProductMediaSlide >
                    <Product.MediaCover
                        control={ control }
                        product={ product }
                    />
                </ProductMediaSlide>
                { productImages.map( item => {
                    return(
                        <ProductMediaSlide
                            control={ control }
                            key={ item.id }
                            image={ item.meta_value }
                            deleteSlide={
                                control ?
                                <ProductMediaSlideDelete
                                    productImages={ productImages }
                                    imageKey={ item.meta_key }
                                    goPrevious={ () => {
                                        sliderRef.current.goTo(slideIndex - 1);
                                    }}
                                /> :
                                null
                            }
                        />
                    );
                })}
            </Carousel>
            { control ?
                <ProductMediaSliderControl
                    product={ product }
                    sliderRef={ sliderRef }
                    slideIndex={ slideIndex }
                    productImages={ productImages }
                /> :
                <SliderDots
                    sliderRef={ sliderRef }
                    images={ productImages }
                    slideIndex={ slideIndex }
                />
            }
        </div>
    );
};

export default ProductMediaSlider;