import React, { useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";

import { EXPO_HALL_STANDS } from "graphql/query/stand-gql";

import { Loader } from "components/request-result";
import {expoBuilder, metaFields} from "utils";

import "./exhibition-stands.scss";


const standMultiplier = (expoHall, multiplier = 0 ) => {

    let newHall = [];

    if(expoHall) {
        for (let i = 0; i < multiplier; i++) {
            newHall = [...newHall, ...expoHall];
        }
    }

    return newHall;
};

const ExhibitionStands = ({
        eeId,
        eeTitle = "",
        eeMeta,
    }) => {

    const { data, loading } = useQuery( EXPO_HALL_STANDS, {
        variables: { eeId },
        fetchPolicy: "cache-and-network"
    });

    const { getValue } = metaFields;
    const rowAmount = parseInt(getValue(eeMeta, "ee_coliseum_row_amount", 1));

    const { expoHall } = data ?? [];

    useEffect(() => {

        if(!loading){
            //setTimeout(() => {

                expoBuilder({ rowAmount });
            //}, 500)
        }//

    }, [ loading, rowAmount ]);

    const expoStands = useMemo(
            () => standMultiplier(expoHall, parseInt(getValue(eeMeta, "ee_stand_multiply", 1))),
        [expoHall, eeMeta, getValue])

    return(
        <div className="exhibition-stands">
            { loading ?
                <Loader /> :
                <>
                    <div className="expo-logo hide">
                        <img src={ getValue(eeMeta, "ee_alt_logo", getValue(eeMeta, "ee_logo")) } alt={ eeTitle } />
                    </div>
                    { expoStands?.map( ( stand, index ) => {
                            return(
                                <Link key={ `${stand.id}_${index}`} className="ee-stand" to={`/showroom-${stand.id}/`} >
                                    <img src={ stand.logo } className="logo" alt={ stand.title } />
                                </Link>
                            );
                        })
                    }
                </>

            }
        </div>

    )
}

export default ExhibitionStands;