import React from "react";
import { Form, Button, Input, Select, notification} from "antd";

import Icons from "components/icons";

import "./team-invite-form.scss";


const formSubmit = ({ values }) => {

    console.log('Received values of form:', values);
    notification['error']({
        message: "This feature is still being tested!",
        description: "",
        duration: 5,
        className: "error-notification",
        //icon: <Icons.ActionStatus type="success"/>,
        //closeIcon: <Icons.Close />,
    });
};

const { Option } = Select;

const TeamInviteForm = ( { standId } ) => {

    const [ form ] = Form.useForm();


    if(!form.getFieldValue("users")){
        form.setFieldsValue({ users: [ {user_email : "", permission: "member"} ] });
    }

    return(
        <Form
            form={ form }
            className="team-invite-form"
            autoComplete="off"
            onFinish={
                (values) => {
                    formSubmit({ values, standId });
                }
            }
        >
            <Form.List name="users">
                {(fields, { add, remove }) => (
                    <>
                        { fields.map(({ key, name, fieldKey, ...restField }) => (
                            <div key={ key } className="field-group">
                                <Form.Item
                                    { ...restField }
                                    name={[ name, 'user_email' ]}
                                    fieldKey={[ fieldKey, 'user_email' ]}
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'The input is not valid E-mail',
                                        },
                                        {
                                            required: true,
                                            message: 'Please input your Email'
                                        }
                                    ]}
                                >
                                    <Input placeholder="enter E-mail" />
                                </Form.Item>
                                <div className="field-sub-group">
                                    <Form.Item
                                        { ...restField }
                                        name={[ name, 'permission' ]}
                                        fieldKey={[ fieldKey, 'permission' ]}
                                    >
                                        <Select>
                                            <Option value="member">Member</Option>
                                            <Option value="editor">Editor</Option>
                                            <Option value="owner">Admin</Option>
                                        </Select>
                                    </Form.Item>
                                    { fields.length > 1 &&
                                        <button
                                            type="button"
                                            className="remove-button"
                                            onClick={ () => remove(name) }>
                                            <Icons.Cross />
                                        </button>
                                    }
                                </div>
                            </div>
                        )) }
                        <Form.Item>
                            <button
                                onClick={ () => add({user_email : "", permission: "member"}) }
                                type="button"
                                className="add-button"
                            >
                                <Icons.Plus /> Add new member
                            </button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
            <div>
                <Button
                    type="primary"
                    htmlType="submit"
                >
                    Invite
                </Button>
            </div>
        </Form>
    );
};

export default TeamInviteForm;
