import React, { useState } from "react";

import { Loader } from "components/request-result";


const ImageLoader = ({ image, wrapClass, altText, children }) => {

    const [ imageLoading, setImageLoading ] = useState(true);

    return(
        <>
        { image !== "" &&
            <div className={`${wrapClass} image-loader  ${imageLoading ? "loading" : ""}`}>
                <img
                    src={image}
                    alt={ altText }
                    onLoad={ () => setImageLoading(false) }
                />
                { imageLoading &&
                    <Loader spinClass="spin-bg" />
                }
                { children }
            </div>
        }
        </>
    );
};


export default ImageLoader;