import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { AvatarBlock } from "components/avatar";
import { SwiperService } from "components/service";
import { FavoriteButton } from "components/favorite";
import { useVarParam } from "components/use-hooks";
import Icons from "components/icons";
//import { link } from "utils";


const FlipButton = ({ cardFlip, setCardFlip }) => {
    return(
        <button
            type="button"
            className="flip-button"
            onClick={ () =>  setCardFlip(!cardFlip) }
        >
            <Icons.Flip />
            FLIP
        </button>
    )
};

const CardCompanyFlip = ({ stand, isActive = false, flipped = false, showBackside = false }) => {

    //const location = useLocation();
    const  { Slider, Slide } = SwiperService;
    const [ cardFlip, setCardFlip] = useState( flipped );

    useEffect( () => {
        if( !isActive ){
            setCardFlip(false);
        }
    }, [ isActive ]);

    return(
        <div
            className={ `card card-company ${  isActive ? "active" : "" } ${ cardFlip && isActive ? "flipped" : ""}` }
            key={ stand.id }
        >
            <div className="card-inner-flip">
                <div className="card-front">
                    { useVarParam().get("productsOn") &&
                        <FlipButton
                            cardFlip={cardFlip}
                            setCardFlip={setCardFlip}
                        />
                    }
                    <div className="company-logo-wrap">
                        <div className="company-logo">
                            <img src={ stand.logo } alt={ stand.title } />
                        </div>
                    </div>
                    <div className="card-text">
                        <h5>{ stand.title }</h5>
                        <div className="company-description">
                            { stand.description }
                        </div>
                    </div>
                    <div className="card-actions">
                        <Link
                            to={`/showroom-${ stand.id }/`}
                            className="link-button"
                        >
                            View Showroom
                        </Link>
                        <div className="to-favorite">
                            <FavoriteButton
                                favorite={ stand.my_favorite }
                                type={ "Stand" }
                                id={ stand.id }
                            />
                        </div>
                    </div>
                </div>
                { useVarParam().get("productsOn") && showBackside &&
                    <div className="card-back">
                        <FlipButton
                            cardFlip={cardFlip}
                            setCardFlip={setCardFlip}
                        />
                        <div className={`company-products ${ stand.products.length < 2 ? "hide-nav" : "" }`}>
                            { stand.products.length ?
                                <Slider
                                    settings={{
                                        spaceBetween: 0,
                                        loop: true,

                                        pagination: {
                                            el: '.swiper-pagination',
                                            clickable: true,
                                        }
                                    }}
                                >
                                    { stand.products.map(
                                        (product) => {

                                            return (
                                                <Slide key={ product.id }>
                                                    <div className="product-photo">
                                                        <img src={ product.cover } alt={ product.title }/>
                                                    </div>
                                                    <h5>{product.title}</h5>
                                                </Slide>
                                            );
                                        }
                                    )}
                                    <div className="swiper-pagination"/>
                                </Slider> : null
                            }
                        </div>
                        <div className="company-showroom">
                            <Link to={`/showroom-${ stand.id }/`}>
                                <AvatarBlock
                                    image={ stand.logo }
                                    size={60}
                                    shape={"square"}
                                >
                                    <h5>{ stand.title }</h5>
                                </AvatarBlock>
                            </Link>
                        </div>
                        <div className="card-actions">
                            <Link
                                to={`/showroom-${ stand.id }/`}
                                className="link-button"
                            >
                                View Showroom
                            </Link>
                            <div className="to-favorite">
                                <FavoriteButton
                                    favorite={ stand.my_favorite }
                                    type={ "Stand" }
                                    id={ stand.id }
                                />
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}

export default CardCompanyFlip;