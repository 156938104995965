import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Button, Form, Input, Radio } from "antd";

import { STAND_META_SET } from "graphql/mutation/stand-gql";

import AboutCompanyImageForm from "./about-company-image-form";
import {errorNotification, successNotification} from "components/request-result";
import Icons from "components/icons";
import { metaFields, youtubeUrl } from "utils";

import "./about-company-form.scss";



const formSubmit = ({ values, updateAboutCompany, standId }) => {

    let { metaValues } = metaFields.parseForm(values);

    updateAboutCompany({
        variables: {
            standId : standId,
            metaInput: [ ...metaValues ]
        }
    });
};

const AboutCompanyForm = ({ meta, standId }) => {

    const [ form ] = Form.useForm();
    const { TextArea } = Input;

    const [ updateAboutCompany, { loading }] = useMutation( STAND_META_SET,
        {
            update(cache, { data }) {

                const { label, message } = data.standMetaSet;

                successNotification({
                    title: label,
                    description: message
                });

            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    const { getValue } = metaFields;

    const formLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    };

    const [ mediaTypeFlag, setMediaTypeFlag ] = useState(
        getValue(meta, "page_media_type_1")
    );

    return (
        <Form
            name="about-company"
            form={form}
            {...formLayout}
            onFinish ={(values) => {
                formSubmit({ values, updateAboutCompany, standId });
            }}
        >

            <Form.Item
                label={ <span>Header</span>}
                className="company_title"
                name="about_title-string--about_company"
                initialValue={ getValue(meta, "about_title") }
            >
                <TextArea
                    rows={2}
                    maxLength={ 80 }
                    showCount={
                        { formatter: ({ count, maxLength }) => maxLength - count}
                    }
                    placeholder="Add header to the company page"
                />
            </Form.Item>
            <Form.Item
                name="page_media_type_1-string--about_company"
                initialValue={ mediaTypeFlag }
            >
                <Radio.Group
                    onChange={ ({ target }) => setMediaTypeFlag(target.value) }
                >
                    <Radio value={ "image" }>Image</Radio>
                    <Radio value={ "video" }>Video</Radio>
                </Radio.Group>
            </Form.Item>
            { mediaTypeFlag === "video" &&
                <>
                    <Form.Item
                        label={ <span>Youtube link</span>}
                        name="page_video_link_1-link--about_company"
                        initialValue={ getValue(meta, "page_video_link_1") }
                        rules={ [
                            () => ({
                                validator(_, value) {
                                    if (youtubeUrl.parse(value)) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(new Error('Please insert valid youtube link'));
                                },
                            })
                        ]}
                    >
                        <Input placeholder="Add youtube link" />
                    </Form.Item>
                    <div className="media-placeholder video">
                        { getValue(meta, "page_video_link_1") !== "" ?
                            <iframe
                                src={ `${youtubeUrl.embed(meta.page_video_link_1.meta_value)}` }
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                            />:
                            <Icons.NoVideo />
                        }
                    </div>
                </>
            }
            { mediaTypeFlag === "image" &&
                <Form.Item
                    name="page_image_1-image-unset-about_company"
                    initialValue={ getValue(meta, "page_image_1") }
                >
                    <AboutCompanyImageForm
                        image={  getValue(meta, "page_image_1") }
                        standId={ standId }
                    />
                </Form.Item>
            }
            <Form.Item
                label={ <span>Text about the company</span>}
                shouldUpdate
                name="about_description-string--about_company"
                initialValue={ getValue(meta, "about_description") }
            >
                <TextArea
                    rows={16}
                    maxLength={ 2000 }
                    showCount={
                        { formatter: ({ count, maxLength }) => maxLength - count}
                    }
                    placeholder="Add some text about your company"
                />
            </Form.Item>
            <Form.Item>
                <div className="form-actions">
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={ loading }
                    >
                        Save
                    </Button>
                </div>
            </Form.Item>
        </Form>
    );
};

export default AboutCompanyForm;