import React from "react";
import { NavLink } from "react-router-dom";

import { useVarParam } from "components/use-hooks";
import Icons from "components/icons";

import "./showroom-menu-bar.scss";


const ShowroomMenuBar = ({ standId }) => {

    return(
        <nav className="showroom-menu-bar menu-bar">
            <ul>
                { useVarParam().get("productsOn") &&
                    <li>
                        <NavLink to={`/showroom-${standId}/product`}>
                            <Icons.Product/>
                            <span>Product</span>
                        </NavLink>
                    </li>
                }
                <li>
                    <NavLink to={`/showroom-${standId}/company`}>
                        <Icons.Company />
                        <span>Company info</span>
                    </NavLink>
                </li>
                { useVarParam().get("communicationOn") &&
                    <li>
                        <NavLink to={`/showroom-${standId}/communication`}>
                            <Icons.Communication />
                            <span>Talk online</span>
                        </NavLink>
                    </li>
                }
                { useVarParam().get("downloadOn") &&
                    <li>
                        <NavLink to={`/showroom-${standId}/downloads`}>
                            <Icons.Download/>
                            <span>Downloads</span>
                        </NavLink>
                    </li>
                }
            </ul>
        </nav>
    );
};

export default ShowroomMenuBar;