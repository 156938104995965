import React from "react";
import { useQuery } from "@apollo/client";

import { SEARCH_STANDS } from "graphql/query/search-gql";

import { SearchResults } from "components/search";
import { Loader } from "components/request-result";
import {useExhibitionEvent} from "../../../use-hooks";


const SearchCompaniesSubPage = ({ match }) => {

    const ee = useExhibitionEvent();

    const { loading, data } = useQuery( SEARCH_STANDS, {
        variables: {
            eeId: ee.id,
            text: match.params.searchQuery
        },
        fetchPolicy: "network-only"
    });

    const {
        eeSearchStands: {
            paginatorInfo,
            data : stands,
        } = {}
    } = data || {};

    return(
        <div className="search-result-wrap">
            { loading &&
                <Loader />
            }
            { data && !loading &&
            <>
                <h1>We found <b>{ paginatorInfo.total }</b> companies for <b>“{ match.params.searchQuery }”</b></h1>
                <SearchResults
                    stands={ stands }
                    paginatorInfo={ paginatorInfo }
                />
            </>
            }
        </div>
    );
};

export default SearchCompaniesSubPage;
