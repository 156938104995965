import React from "react";

import { Product } from "components/product/index";
import Icons from "components/icons";
import { Loader } from "components/request-result";

import "./product-media-cover.scss";
import {Button} from "antd";


const Cover = ({
       loading,
       image,
       showControlButton,
       noImageText
    }) => {

    return(
        <>
            { showControlButton &&
                <Button
                    ghost
                    loading={ loading }
                >
                    Change cover
                </Button>
            }
            { image ?
                <img src={image} alt=""/> :
                <div className="no-image">
                    { loading ?
                        <Loader /> :
                        <>
                            <Icons.NoImage/>
                            <p className="label">{ noImageText }</p>
                        </>
                    }

                </div>
            }
        </>
    )
};

const ProductMediaCover = ({
       product,
       control,
    }) => {

    return(
        <div
            className={ `product-media-cover`}
        >
            { !control ?
                <Cover
                    image={ product.cover }
                    noImageText={ <>No cover image</> }
                /> :
                <Product.MediaUpload
                    variables={{
                        input: {
                            product_id: product.id,
                            meta_type: "image",
                            meta_key: `cover`
                        }
                    }}
                >
                    <Cover
                        image={ product.cover }
                        showControlButton={ product.cover }
                        noImageText={
                            <>
                                <b>Add cover</b> .jpg or .png files up to 10Mb
                            </>
                        }
                    />
                </Product.MediaUpload>
            }
        </div>
    );

};

export default ProductMediaCover;