import React from 'react';
import Icon from '@ant-design/icons';


const LocationSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M24.7,40.5c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.2-0.8-0.6-5.5-7.1c-3.2-4.4-5.1-8.9-5.5-13.4c0,0,0-0.1,0-0.1
	c0-3.3,1.2-6.1,3.4-8.3c2.2-2.2,5-3.3,8.3-3.4c3.3,0.1,6.1,1.2,8.3,3.4c2.2,2.2,3.3,5,3.4,8.3c0,0,0,0.1,0,0.1
	c-0.4,4.5-2.3,9-5.5,13.4c-4.8,6.5-5.3,6.9-5.5,7.1C25.1,40.5,24.9,40.5,24.7,40.5z M15.1,19.7c0.4,4.1,2.1,8.3,5.1,12.3
	c2.6,3.5,3.9,5.2,4.5,6c0.7-0.8,2-2.5,4.5-6c3-4.1,4.7-8.2,5.1-12.3c-0.1-2.8-1-5-2.8-6.8c-1.8-1.8-4.1-2.7-6.9-2.8
	c-2.8,0-5,1-6.8,2.8C16,14.7,15.1,16.9,15.1,19.7z M24.7,24.5c-1.4,0-2.5-0.5-3.4-1.4c-0.9-0.9-1.4-2.1-1.4-3.4
	c0-1.4,0.5-2.5,1.4-3.4c0.9-0.9,2.1-1.4,3.4-1.4c1.4,0,2.5,0.5,3.4,1.4c0.9,0.9,1.4,2.1,1.4,3.4c0,1.4-0.5,2.5-1.4,3.4
	C27.2,24.1,26.1,24.5,24.7,24.5z M24.7,17c-0.8,0-1.5,0.3-2,0.8c-0.5,0.5-0.8,1.2-0.8,2c0,0.8,0.3,1.5,0.8,2c0.5,0.5,1.2,0.8,2,0.8
	c0.8,0,1.5-0.3,2-0.8c0,0,0,0,0,0c0.5-0.5,0.8-1.2,0.8-2c0-0.8-0.3-1.5-0.8-2C26.2,17.2,25.5,17,24.7,17z"/>
    </svg>
);

const LocationIcon = props => {
    return <Icon component={ LocationSvg } />;

};


export default LocationIcon;