import React from "react";
import { Switch, Route } from 'react-router-dom';

import Pages from "components/pages";


const SearchRoutes = ({ parent = "search", ee }) => {

    const { Search : { SubPage } } = Pages;

    return(
        <Switch>
            <Route path={`/${ parent }/products/term/:termId-:termTitle`} exact>
                { ( { match }) =>
                    <SubPage.TermProducts
                        match={ match }
                    />
                }
            </Route>
            <Route path={`/${ parent }/products/:searchQuery`} exact>
                {({match}) =>
                    <SubPage.Products
                        match={ match }
                        ee={ ee }
                    />
                }
            </Route>
            <Route path={`/${ parent }/companies/term/:termId-:termTitle`} exact>
                {({match}) =>
                    <SubPage.TermCompanies
                        match={ match }
                    />
                }
            </Route>
            <Route path={`/${ parent }/companies/:searchQuery`} exact>
                {({match}) =>
                    <SubPage.Companies
                        match={ match }
                        ee={ ee }
                    />
                }
            </Route>
        </Switch>
    );
};

export default SearchRoutes;