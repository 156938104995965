import React from "react";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Button, Form, Input } from "antd";

import { FORGOT_PASSWORD } from "graphql/mutation/user-gql";
import { authParamsVar } from "graphql/cache";

import { errorNotification } from "components/request-result";
import { useExhibitionEvent } from "components/use-hooks";

import './forgot-password-form.scss';



const submitForm = ({ values, forgotRequest }) => {

    forgotRequest({
        variables : {
            input: {
                email: values.email,
                ee_id: authParamsVar().eeId
            }
        }
    }).catch((error) => {
        errorNotification(error);
    });

};

const NormalForgotPasswordForm = (props) => {

    const ee = useExhibitionEvent();

    const [ form ] = Form.useForm();

    const {
        loading,
        forgotRequest,
    } = props;

    const formItemLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    };

    return (
        <div className="form-holder">
            <h1>
                Forgot password <br />
                { ee.title }
            </h1>
            { authParamsVar().sendForgotPasswordEmail ?
                <div className="forgot-form-message">
                    <p>{ authParamsVar().sendForgotPasswordText }</p>
                    <p>
                        <Link to="/" className="route-link">To the sign in page</Link>
                    </p>
                </div>:
                <>
                    <Form
                        className="forgot-password-form"
                        form={ form }
                        onFinish={(values) => {
                                submitForm({  values, forgotRequest })
                            }
                        }
                    >
                        <Form.Item
                            label={ <span className="site-label-text-color">Email</span>}
                            name="email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your E-Mail'
                                },
                                {
                                    type: 'email',
                                    message: 'Please type correct E-Mail'
                                }
                            ]}
                            {...formItemLayout}
                        >
                            <Input name="email" />
                        </Form.Item>
                        <Form.Item>
                            <div className="form-actions">
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={ loading }
                                >
                                    Send Forgot Link
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                    <div className="sign-link-wrap">
                        <span>Already remembered?</span>
                        <Link to="/" className="company-color">Sign in</Link>
                    </div>
                </>
            }
        </div>
    );

};

const ForgotPasswordForm = () => {
    
    const [ forgotRequest, { loading }] = useMutation( FORGOT_PASSWORD,
        {
            update(cache, { data : { userForgotPassword } }) {

                authParamsVar({
                    ...authParamsVar(),
                    sendForgotPasswordEmail: true,
                    sendForgotPasswordText: userForgotPassword.message
                });
            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    return(
        <NormalForgotPasswordForm
            forgotRequest={forgotRequest}
            loading={ loading }
        />
    );
};


export default ForgotPasswordForm;