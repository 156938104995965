import React from "react";
import { Link, useLocation } from "react-router-dom";

import { AvatarImage } from "components/avatar";

import "./product-author-preview.scss";


const ProductAuthorPreview = ({ author }) => {

    let location = useLocation();

    return(

        <Link
            className="product-author-preview"
            to={`${location.pathname}#author-${author.id}${author.status === "draft" ? "-edit" : ""}`}
        >
            <AvatarImage
                size={ 178 }
                photoUrl={ author.cover }
            />
            <h4 className="title">
                { author.title }
                { author.status === "draft" && <p>({author.status})</p> }
            </h4>
        </Link>
    );
};

export default ProductAuthorPreview;