import SearchTermProductsSubPage from "./search-term-products-sub-page";
import SearchTermCompaniesSubPage from "./search-term-companies-sub-page";
import SearchCompaniesSubPage from "./search-companies-sub-page";
import SearchProductsSubPage from "./search-products-sub-page";


const SearchSubPage = {
    TermProducts : SearchTermProductsSubPage,
    TermCompanies : SearchTermCompaniesSubPage,
    Products : SearchProductsSubPage,
    Companies : SearchCompaniesSubPage
};

export default SearchSubPage;