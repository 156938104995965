import React from "react";
import { Link } from "react-router-dom";

import { ProductFileWrap } from "../../product-file-or-upload";
import { AvatarBlock } from "components/avatar";
import { MoreText } from "components/service";
import { link, metaFields } from "utils";


const ProductServiceContent = ({ product, control }) => {

    const { normalize, getField } = metaFields;
    const meta = normalize(product.meta_fields);

    let url = `/product/${ product.id }${ product.title ? "-" + link.toSlug(product.title) + "/edit" : ""}`;

    return(
        <div className="product-content">
            <h3>{ product.title }</h3>
            { product.description &&
                <div className="description text-block">
                    <MoreText
                        text={ product.description }
                        length={ 300 }
                    />
                </div>
            }
            { getField(meta, "product_file_1") &&
                <div className="text-block">
                    <ProductFileWrap
                        filepath={ getField(meta, "product_file_1") }
                    />
                </div>
            }
            { getField(meta, "product_link") &&
                <div className="buy-block">
                    <a
                        href={getField(meta, "product_link")}
                        target={"_blank"}
                        rel={"noreferrer"}
                        className="link-button"
                    >
                        Buy
                    </a>
                </div>
            }
            { control ?
                <div className="action float-block">
                    <Link className="link-button" to={ url }>
                        Edit
                    </Link>
                </div>:
                <div className="company float-block">
                    <div>
                        <h6 className="label">Company information</h6>
                        <AvatarBlock
                            size={ 60 }
                            shape={ "square" }
                            image={ product.stand.logo  }
                        >
                            <p className="company-name">{ product.stand.title }</p>
                            <Link to={`/showroom-${ product.stand.id }/product`}>View Showroom</Link>
                        </AvatarBlock>
                    </div>
                </div>
            }
        </div>
    );
};

export default ProductServiceContent;