import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Switch, Modal } from "antd";

import { STAND_PROFILE_UPDATE } from "graphql/mutation/stand-gql";

import Icons from "components/icons";

import "./showroom-control-status-switcher.scss";



const ShowroomControlStatusSwitcher = ({ status, standId }) => {

    const [ checkSwitch, setCheckSwitch ] = useState( status === "online" );
    const [ showModal, setShowModal ] = useState( false );

    const [standUpdateRequest, { loading }] = useMutation(STAND_PROFILE_UPDATE,
        {
            update() {
                setCheckSwitch(!checkSwitch);
                setShowModal(false)
            }
        }
    );

    return(
        <div className={`showroom-status-switcher ${status}`}>
            <div className="switcher-label">
                <span>Your showroom is</span>
            </div>
            <Switch
                checkedChildren="Online"
                unCheckedChildren="Offline"
                checked={ checkSwitch }
                defaultChecked={ checkSwitch }
                disabled={ status === "draft" }
                onClick={ () => setShowModal(true) }
            />
            <Modal
                className="showroom-status-switcher-modal"
                visible={ showModal }
                centered
                maskClosable={ false }
                closeIcon={ <Icons.Cross /> }
                onOk={ () => {
                    standUpdateRequest({
                        variables: {
                            standId,
                            metaInput : [],
                            standInput: {
                                id : standId,
                                status: status === "online" ? "offline" : "online"
                            },
                            termIds : []
                        }
                    })
                }}
                onCancel={ () => setShowModal(false)}
                okButtonProps={{
                    loading
                }}
                cancelButtonProps={{
                    type: "default"
                }}
                okText="Confirm"
                cancelText="Decline"
            >
                { checkSwitch ?
                    <>
                        <p>You are trying to bring your showroom offline, after which the showroom and its products will become unavailable to other users.</p>
                        <p><b>Do you confirm this?</b></p>
                    </>:
                    <>
                        <p>You are trying to bring your showroom online, after which the showroom and its products will become available to other users.</p>
                        <p><b>Do you confirm this?</b></p>
                    </>
                }
            </Modal>
        </div>
    );
};

export default ShowroomControlStatusSwitcher;