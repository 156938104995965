import React from "react";
import { Form, Input } from "antd";

import { AvatarBlock } from "components/avatar";
import { metaFields } from "utils";


const ProfileCompanyInheritShowroom = ({ formLayout, stand }) => {

    const { normalize, getValue } = metaFields,
        meta = normalize(stand.meta_fields);

    return (
        <Form
            name="company-inherit-info"
            { ...formLayout }
        >
            <Form.Item
                label={ <span>Logo</span>}
                initialValue={ getValue(meta, "company_logo") }
            >
                <AvatarBlock
                    shape="square"
                    size={ 140 }
                    image={ stand.logo }
                />
            </Form.Item>
            <div className="field-group">
                <Form.Item
                    label={ <span>Company name</span>}
                    name="company_name-string"
                    initialValue={ stand.title }
                >
                    <Input placeholder="Company name" disabled />
                </Form.Item>
                <Form.Item
                    label={ <span>Company website</span>}
                    name="company_site_url-string"
                    initialValue={ getValue(meta, "company_site_url") }
                >
                    <Input
                        placeholder="https://company-name.com"
                        disabled
                    />
                </Form.Item>
            </div>
            <div className="field-group">
                <Form.Item
                    label={ <span>Address</span>}
                    name="company_address-string"
                    initialValue={ getValue(meta, "company_address") }
                >
                    <Input placeholder="Messe-Allee 1, 00000 Kundorf" disabled />
                </Form.Item>
                <Form.Item
                    label={ <span>City</span>}
                    name="company_city-string"
                    initialValue={ getValue(meta, "company_city") }
                >
                    <Input placeholder="Your company city" disabled />
                </Form.Item>
            </div>
            <div className="field-group">
                <Form.Item
                    label={ <span>Country</span>}
                    name="company_country-string"
                    initialValue={ getValue(meta, "company_country") }
                >
                    <Input placeholder="Germany" disabled />
                </Form.Item>
                <Form.Item
                    label={ <span>Postcode</span>}
                    name="company_postcode-string"
                    initialValue={ getValue( meta, "company_postcode" ) }
                >
                    <Input placeholder="Your company postcode" disabled />
                </Form.Item>
            </div>

        </Form>
    );
};

export default ProfileCompanyInheritShowroom;