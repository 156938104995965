import React from "react";
import { useQuery } from "@apollo/client";

import { FAVORITE_USERS } from "graphql/query/favorite-gql";

import { UserGrid } from "components/user";
import { FavoriteExportButton } from "components/favorite";
import { ContainerWrap } from "components/wraps";
import { Loader } from "components/request-result";


const FavoriteUsersSubPage = ({ spanList }) => {

    const { loading, data } = useQuery( FAVORITE_USERS, {
        fetchPolicy: "cache-and-network"
    });

    const {
        favoriteUsers = {}
    } = data ?? {};

    return (
        <ContainerWrap
            spanList={ spanList }
            className={ "favorites-wrap" }
        >
            { data && favoriteUsers?.paginatorInfo?.total !== 0 ?
                <div className="favorite-export-wrap">
                    <FavoriteExportButton model="User" />
                </div> :
                <div className="no-favorites">
                    <h3>You do not have any favorite users</h3>
                </div>
            }
            { loading && !data && <Loader /> }
            { data &&
                <UserGrid
                    users={ favoriteUsers.data }
                />
            }
        </ContainerWrap>
    );

}

export default FavoriteUsersSubPage;