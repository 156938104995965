import React from 'react';
import Icon from '@ant-design/icons';


const PlusSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M36.6,22.9h-9.9v-9.9c0-0.8-0.7-1.5-1.5-1.5s-1.5,0.7-1.5,1.5v9.9h-10c-0.8,0-1.5,0.7-1.5,1.5s0.7,1.5,1.5,1.5h10v9.9
	        c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-9.9h9.9c0.8,0,1.5-0.7,1.5-1.5S37.4,22.9,36.6,22.9z"/>
    </svg>
);

const PlusIcon = props => {
    return <Icon component={ PlusSvg } />;

};


export default PlusIcon;