import React from 'react';
import Icon from '@ant-design/icons';


const CrossSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M27.2,24.4l9.1-9.1c0.6-0.6,0.6-1.5,0-2.1c-0.6-0.6-1.5-0.6-2.1,0l-9.1,9.1L16,13.2c-0.6-0.6-1.5-0.6-2.1,0
	            c-0.6,0.6-0.6,1.5,0,2.1l9.1,9.1l-9.1,9.1c-0.6,0.6-0.6,1.5,0,2.1c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4l9.1-9.1l9.1,9.1
	            c0.3,0.3,0.7,0.4,1.1,0.4s0.8-0.1,1.1-0.4c0.6-0.6,0.6-1.5,0-2.1L27.2,24.4z"
        />
    </svg>
);

const CrossIcon = props => {
    return <Icon component={ CrossSvg } />;

};


export default CrossIcon;