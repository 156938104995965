import { gql } from '@apollo/client';


export const VIEW_LOG_EXPORT_XLS = gql`
    mutation ViewLogExport(
        $model: modelLogExport
    ){
        logExport(
            model: $model
        ){
            label
            message
            file
        }
    }
`;