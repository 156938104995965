import React from 'react';
import Icon from '@ant-design/icons';
import {Loader} from "../request-result";


const FavoriteListSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M44.4,27.7c-0.1-0.4-0.4-0.6-0.8-0.7l-7.7-1.1l-3.3-6.7V8.7c0-0.6-0.4-1-1-1H9.4c-0.6,0-1,0.4-1,1v28.3c0,0.6,0.4,1,1,1
		h14.5l-0.7,3.9c-0.1,0.4,0.1,0.8,0.4,1c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.3,0,0.5-0.1l6.9-3.6l6.9,3.6c0.3,0.2,0.7,0.1,1.1-0.1
		c0.3-0.2,0.5-0.6,0.4-1l-1.3-7.7l5.6-5.4C44.5,28.5,44.6,28.1,44.4,27.7z M10.4,35.9V9.7h20.2v9.4l-3.4,6.8L19.6,27
		c-0.4,0.1-0.7,0.3-0.8,0.7c-0.1,0.4,0,0.8,0.3,1l5.6,5.4l-0.3,1.8H10.4z M36.8,33.1c-0.2,0.2-0.3,0.6-0.3,0.9l1.1,6.2l-5.6-2.9
		c-0.1-0.1-0.3-0.1-0.5-0.1s-0.3,0-0.5,0.1l-5.6,2.9l1.1-6.2c0.1-0.3-0.1-0.7-0.3-0.9l-4.5-4.4l6.2-0.9c0.3,0,0.6-0.3,0.8-0.5
		l2.8-5.6l2.8,5.6c0.1,0.3,0.4,0.5,0.8,0.5l6.2,0.9L36.8,33.1z"/>
        <path d="M15.2,16.6h10.7c0.6,0,1-0.4,1-1c0-0.6-0.4-1-1-1H15.2c-0.6,0-1,0.4-1,1C14.2,16.2,14.6,16.6,15.2,16.6z"/>
        <path d="M26.8,20.8c0-0.6-0.4-1-1-1H15.2c-0.6,0-1,0.4-1,1s0.4,1,1,1h10.7C26.4,21.8,26.8,21.4,26.8,20.8z"/>
    </svg>
);

const FavoriteFilledSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M40.4,20.2c-0.1-0.4-0.4-0.6-0.8-0.7l-9.8-1.4l-4.4-8.9c-0.2-0.3-0.5-0.6-0.9-0.6s-0.7,0.2-0.9,0.6l-4.4,8.9l-9.8,1.4
            c-0.4,0.1-0.7,0.3-0.8,0.7c-0.1,0.4,0,0.8,0.3,1l7.1,6.9l-1.7,9.8c-0.1,0.4,0.1,0.8,0.4,1c0.2,0.1,0.4,0.2,0.6,0.2
            c0.2,0,0.3,0,0.5-0.1l8.8-4.6l8.8,4.6c0.3,0.2,0.7,0.1,1.1-0.1c0.3-0.2,0.5-0.6,0.4-1L33,28.1l7.1-6.9C40.4,21,40.5,20.6,40.4,20.2z"
        />
    </svg>
);

const FavoriteSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M33.7,39.1c-0.2,0-0.3,0-0.5-0.1l-8.8-4.6L15.7,39c-0.3,0.2-0.7,0.1-1.1-0.1c-0.3-0.2-0.5-0.6-0.4-1l1.7-9.8l-7.1-6.9
            c-0.3-0.3-0.4-0.7-0.3-1c0.1-0.4,0.4-0.6,0.8-0.7l9.8-1.4l4.4-8.9c0.3-0.7,1.5-0.7,1.8,0l4.4,8.9l9.8,1.4c0.4,0.1,0.7,0.3,0.8,0.7
            c0.1,0.4,0,0.8-0.3,1L33,28.1l1.7,9.8c0.1,0.4-0.1,0.8-0.4,1C34.1,39,33.9,39.1,33.7,39.1z M11.7,21.2l6,5.9
            c0.2,0.2,0.3,0.6,0.3,0.9l-1.4,8.3l7.4-3.9c0.3-0.2,0.6-0.2,0.9,0l7.4,3.9L30.9,28c-0.1-0.3,0.1-0.7,0.3-0.9l6-5.9L28.9,20
            c-0.3,0-0.6-0.3-0.8-0.5l-3.7-7.5l-3.7,7.5c-0.1,0.3-0.4,0.5-0.8,0.5L11.7,21.2z"
        />
    </svg>
);

const FavoriteProductSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M39.5,15.2L24.2,7.5c-0.3-0.1-0.6-0.1-0.9,0L8.3,15.2c-0.3,0.2-0.5,0.5-0.5,0.9v16.3c0,0.4,0.2,0.7,0.5,0.9L23.6,42
		c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5-0.1l14.9-8.7c0.3-0.2,0.5-0.5,0.5-0.9V16.1C40,15.8,39.8,15.4,39.5,15.2z M38,31.9
		L24.1,40L9.8,31.9V16.7l13.9-7.2L38,16.7V31.9z"/>
        <path d="M23.2,15.6l-2.4,4.8l-5.3,0.8c-0.4,0.1-0.7,0.3-0.8,0.7c-0.1,0.4,0,0.8,0.3,1l3.8,3.7l-0.9,5.3c-0.1,0.4,0.1,0.8,0.4,1
		c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.3,0,0.5-0.1l4.7-2.5l4.7,2.5c0.3,0.2,0.7,0.1,1.1-0.1c0.3-0.2,0.5-0.6,0.4-1l-0.9-5.3l3.8-3.7
		c0.3-0.3,0.4-0.7,0.3-1c-0.1-0.4-0.4-0.6-0.8-0.7l-5.3-0.8L25,15.6c-0.2-0.3-0.5-0.6-0.9-0.6S23.3,15.2,23.2,15.6z M25.8,21.7
		c0.1,0.3,0.4,0.5,0.8,0.5l3.8,0.6l-2.8,2.7c-0.2,0.2-0.3,0.6-0.3,0.9l0.7,3.8l-3.4-1.8c-0.1-0.1-0.3-0.1-0.5-0.1s-0.3,0-0.5,0.1
		l-3.4,1.8l0.7-3.8c0.1-0.3-0.1-0.7-0.3-0.9l-2.8-2.7l3.8-0.6c0.3,0,0.6-0.3,0.8-0.5l1.7-3.5L25.8,21.7z"/>
    </svg>
);

const FavoriteIcon = props => {

    if(props.type === "list"){
        return <Icon component={ FavoriteListSvg } />;

    }

    if(props.type === "filled"){
        return <Icon component={ FavoriteFilledSvg } />;
    }

    if(props.type === "product"){
        return <Icon component={ FavoriteProductSvg } className={ props.className } />;
    }

    if(props.type === "loading"){
        return <Loader />;
    }

    return <Icon component={ FavoriteSvg } className={ props.className } />;

};


export default FavoriteIcon;