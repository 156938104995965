import React from "react";
import { useQuery } from "@apollo/client";

import { GET_TERM_PRODUCTS } from "graphql/query/term-gql";

import { Loader } from "components/request-result";
import { SearchResults } from "components/search";


const SearchTermProductsSubPage = ({ match }) => {

    const { loading, data } = useQuery( GET_TERM_PRODUCTS, {
        variables: {
            id: match.params.termId
        },
        fetchPolicy: "network-only"
    });

    const {
        term: {
            title,
            products,
            //...term
        } = {}
    } = data || {};

    return(
        <div className="search-result-wrap">
            { loading &&
                <Loader />
            }
            { data && !loading &&
            <>
                <h1>We found <b>{ products.paginatorInfo.total }</b> products for <b>“{ title }”</b></h1>
                <SearchResults
                    products={ products.data }
                    paginatorInfo={ products.paginatorInfo }
                />
            </>
            }
        </div>
    );
};

export default SearchTermProductsSubPage;
