import React from "react";

import { CardUser } from "components/card";


const CommunicationConversations = ( { conversations } ) => {
    //console.log(conversations)
    return (
        <div className="communication-conversations">
            <h2>Existed conversations</h2>

            { conversations && conversations.map(
                ( { exhibitor, customer, ...conversation }, index ) => {

                    return(
                        <div className="conversation-block-wrap" key={  conversation.id }>
                            <h4>Conversation { index + 1 }</h4>
                            <div className="conversation-block grid-two-col">
                                <CardUser.Horizontal
                                    key={ exhibitor.id }
                                    user={ exhibitor }
                                    badge={
                                        <div className="badge">Host</div>
                                    }
                                />
                                <CardUser.Horizontal
                                    key={ customer?.id ?? `empty_${conversation.id}` }
                                    badge={
                                        <div className="badge primary">Guest</div>
                                    }
                                    user={ customer }
                                    emptyText={ "Waiting for the guest…" }
                                />
                            </div>
                        </div>
                    );
                })
            }
            { (!conversations || !conversations.length)  &&
                <div className="no-users">
                    The showroom has no online conversations
                </div>
            }
        </div>
    );

}

export default CommunicationConversations;