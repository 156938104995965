import React from 'react';
import Icon from '@ant-design/icons';


const CompanySvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M41.8,22.7H25.8V8.9c0-0.6-0.4-1-1-1H7.7c-0.6,0-1,0.4-1,1v30.5c0,0.6,0.4,1,1,1h17.1h17.1c0.6,0,1-0.4,1-1V23.7
		C42.8,23.2,42.4,22.7,41.8,22.7z M8.7,9.9h15.1v13.8v14.6H8.7V9.9z M31.6,38.3v-5.3h3.6v5.3H31.6z M40.8,38.4h-3.7v-6.3
		c0-0.6-0.4-1-1-1h-5.6c-0.6,0-1,0.4-1,1v6.3h-3.8V24.7h15.1V38.4z"/>
        <path d="M20,15.4h-7.2c-0.6,0-1,0.4-1,1c0,0.6,0.4,1,1,1H20c0.6,0,1-0.4,1-1C21,15.8,20.6,15.4,20,15.4z"/>
        <path d="M20,20.2h-7.2c-0.6,0-1,0.4-1,1s0.4,1,1,1H20c0.6,0,1-0.4,1-1S20.6,20.2,20,20.2z"/>
        <path d="M20,25.5h-7.2c-0.6,0-1,0.4-1,1s0.4,1,1,1H20c0.6,0,1-0.4,1-1S20.6,25.5,20,25.5z"/>
        <path d="M20,31h-7.2c-0.6,0-1,0.4-1,1s0.4,1,1,1H20c0.6,0,1-0.4,1-1S20.6,31,20,31z"/>
    </svg>
);

const CompanyIcon = props => {
    return <Icon component={ CompanySvg } />;

};


export default CompanyIcon;