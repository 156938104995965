import React from "react";

import ProductTopSlide from "./product-top-slide";
import ProductTopChoiceColor from "./product-top-choice-color";
import { SwiperService } from "components/service";
import { metaFields } from "utils";

import "./product-top-slider.scss";


const ProductTopSlider = ({ control, products, stand }) => {

    const top = products.data.filter( item => item.is_top );

    if(!top.length){
        return null;
    }

    const  { Slider, Slide } = SwiperService;

    const { normalize, getValue } = metaFields;
    const meta = normalize(stand.meta_fields);


    return(
        <div
            className="product-top-slider-wrap"
            style={{
                backgroundColor : getValue(meta, "top_bg_color") }
            }
        >
            <h2>Top Products</h2>
            <Slider
                className="product-top-slider"
                settings={{
                    spaceBetween: 0,
                    loop: top.length >= 7,
                    centerInsufficientSlides: top.length < 7,
                    centeredSlides: top.length >= 7
                }}
            >
                {
                    top.map( product => {
                        return (
                            <Slide key={ product.id }>
                                <ProductTopSlide
                                    control={ control }
                                    product={ product }
                                    extraField={ "book_author" }
                                />
                            </Slide>
                        )
                    })
                }
            </Slider>
            { control &&
                <ProductTopChoiceColor
                    defaultColor={ getValue(meta, "top_bg_color") }
                    standId={ stand.id }
                />
            }
        </div>
    );
};

export default ProductTopSlider;