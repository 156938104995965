import React from "react";
import { useMutation } from "@apollo/client";
import { Button } from "antd";

import { COMMUNICATION_JOIN } from "graphql/mutation/communication-gql";

import {
    errorNotification,
    Loader,
    successNotification
} from "components/request-result";
import Icons from "components/icons";

import "./communication-join-button.scss"


const CommunicationJoinButton = ( { conversationId, setCommunication } ) => {

    const [ communicationJoin, { loading }] = useMutation( COMMUNICATION_JOIN, {

            update(cache, { data }) {

                const {
                    communicationJoin : {
                        label,
                        message,
                        communication
                    }
                } = data;

                setCommunication(communication);

                successNotification({
                    title: label,
                    description: message,
                });
            },
            onError(error){
                errorNotification(error);
            }
        }
    );

    return(
        <Button
            type="primary"
            disabled={ loading }
            className="communication-join-button"
            onClick={ () => {
                communicationJoin({
                    variables: {
                        id : conversationId
                    }
                });
            }}
        >
            { loading ? <Loader /> : <Icons.Communication />}

            Start video chat
        </Button>
    );
}

export default CommunicationJoinButton;