import ShowroomControlPage from "./showroom-control-page";
import ShowroomPage from "./showroom-page";
import ShowroomSubPage from "./sub-page";

import "./showroom-page.scss";

const Showroom = {
    Control : ShowroomControlPage,
    View : ShowroomPage,
    SubPage : ShowroomSubPage
};

export default Showroom;