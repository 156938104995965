import React from "react";
import { useQuery } from "@apollo/client";

import { GET_ME } from "graphql/query/user-gql";

import { TeamInviteModal, TeamList, TeamMenu } from "components/team";
import { ContainerWrap } from "components/wraps";


const ShowroomTeamSubPage = ({ stand }) => {

    const { data : { me } } = useQuery( GET_ME, { fetchPolicy: "cache-only" } );

    return (
        <ContainerWrap className="showroom-team-wrap">
        { stand &&
            <>
                <div className="team-header">
                    <h3>{ stand.title } team</h3>
                    { me?.stand_permission?.permission === "owner" ?
                        <TeamInviteModal standId={ stand.id } /> :
                        <TeamMenu
                            standId={ stand.id }
                            userId={ me.id  }
                            me={ me }
                        />
                    }
                </div>
                <TeamList
                    users={ stand.exhibitors }
                    title="Team members"
                    me={ me }
                />
                <div className="badge-page-label">
                    You are on your company team page
                </div>
            </>
        }
        </ContainerWrap>
    );
};


export default ShowroomTeamSubPage;