import React from "react";
//import { useQuery } from "@apollo/client";

//import { GET_ME } from "graphql/query/user-gql";

import TeamListItem from "./team-list-item";

import "./team-list.scss";


const TeamList = ({
        users,
        title = "",
        type="permission-control",
        me
    }) => {

    return(
        <div className="team-list-wrap">
            <p className="sub-text-bold">{ title }</p>
            <div className={ `team-list ${ type }` }>
                { users.map(
                    (user) => {
                        return(
                            <TeamListItem
                                key={ user.id }
                                user={ user }
                                me={ me }
                                type={ type }
                            />
                        )
                    }
                )}
            </div>
        </div>
    );
}

export default TeamList;