import React from "react";

import { MenuPopover } from "components/service";
import { AvatarBlock } from "components/avatar";
import { UserPopoverMenu } from "components/menus";


const UserMenuPopover = ({ user }) => {

    return (
        <div className="user-menu-popover-wrap">
            <MenuPopover
                title="Account"
                image={ user.avatar }
            >
                <AvatarBlock
                    image={ user.avatar }
                    size={ 60 }
                >
                    <h5>{ user.name }<br />{ user.surname }</h5>
                </AvatarBlock>
                <UserPopoverMenu />
            </MenuPopover>
        </div>
    );
};

export default UserMenuPopover;