import React from "react";

import { AnalyticsBlocks } from "components/analytics";
import { useVarParam } from "components/use-hooks";


const AnalyticsGeneralPage = ({ standId }) => {

    const productsOn = useVarParam().get("productsOn");

    return(
        <div className="analytics-general">
            <AnalyticsBlocks.Total
                standId={ standId }
            />
            <AnalyticsBlocks.Chart
                standId={ standId }
                type={ 'user' }
                colors={{
                    views: "#1E9FFF",
                    favorites: "#8E34D4"
                }}
            />
            { productsOn &&
                <AnalyticsBlocks.Chart
                    standId={ standId }
                    type={ 'product' }
                    colors={{
                        views: "#339A11",
                        favorites: "#FFA400"
                    }}
                />
            }
        </div>
    );
}


export default AnalyticsGeneralPage;