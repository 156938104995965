import { authParamsVar } from "graphql/cache";

import { changeLanguage } from "components/languages";
import { metaFields } from "utils";


const authorizationPageSetup = ({ee}) => {

    const {
        id,
        meta_fields
    } = ee;

    const { normalize } = metaFields,
        meta = normalize(meta_fields);

    let lang = meta["ee_default_language"] !== undefined ? meta["ee_default_language"] : "en";
    lang = localStorage.getItem("interface-language") === null ? lang : localStorage.getItem("interface-language");

    changeLanguage({
        value: lang,
        store: ["localstorage", "authParams"]
    });


    authParamsVar({...authParamsVar(),
        eeId: parseInt(id)
    });


    return ee !== undefined;

};

export default authorizationPageSetup;