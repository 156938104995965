import React from "react";
import { Button } from "antd";

import Icons from "components/icons";


const FavoriteButtonIcon = ({ loading, favorite, action }) => {

    let iconType = "";

    if(loading){
        iconType = "loading";
    } else{
        iconType = favorite ? "filled" : "";
    }

    return(
        <Button
            className={ `favorite-button icon ${ favorite ? "my-favorite" : "" }` }
            disabled={ loading }
            onClick={ action }
        >
            <Icons.Favorite type={ iconType } />
        </Button>
    )
}


export default FavoriteButtonIcon;