import React from "react";
import {Form, Input } from "antd";

import { Product } from "components/product/index";
import { youtubeUrl, metaFields } from "utils";


const ProductMediaVideoForm = ({ form, meta, product }) => {

    const { getValue } = metaFields;

    const formLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    };


    return (
        <div className="product-media-video-form-wrap">
            <Form
                form={ form }
                name={ "product" }
                className={ "product-media-video-form" }
                { ...formLayout }
            >
                <Form.Item
                    label={ <span>Youtube link</span>}
                    name="product_video_link-link"
                    initialValue={ getValue(meta, "product_video_link") }
                    rules={ [

                        () => ({
                            validator(_, value) {
                                if (youtubeUrl.parse(value)) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(new Error('Please input valid youtube link'));
                            },
                        })
                    ]}
                >
                    <Input placeholder="Add youtube link" />
                </Form.Item>
                <Form.Item
                    label={ <span>Cover image</span>}
                    name="cover-field-unset"
                    initialValue={ product.cover }
                >
                    <Product.MediaCover
                        control={ true }
                        product={ product }
                    />
                </Form.Item>
            </Form>
        </div>
    );
}

export default ProductMediaVideoForm;