import { gql } from '@apollo/client';


export const FAVORITE_EXPORT_XLS = gql`
    mutation FavoriteExportFile(
        $model: modelFavorite!
    ){
        favoriteExportFile(
            model: $model
        ){
            label
            message
            file
        }
    }
`;

export const FAVORITE_PRODUCT_ADD_DELETE = gql`
    mutation FavoriteAddOrDeleteProduct(
        $model_id: ID!
        $model: modelFavorite!
        $description: String
    ){
        favoriteAddOrDelete(
            model_id: $model_id
            model: $model
            description: $description
        ){
            label
            message
            model{
                ... on Product{
                    id
                    my_favorite
                }
            }
        }
    }
`;



export const FAVORITE_STAND_ADD_DELETE = gql`
    mutation FavoriteAddOrDeleteStand(
        $model_id: ID!
        $model: modelFavorite!
        $description: String
    ){
        favoriteAddOrDelete(
            model_id: $model_id
            model: $model
            description: $description
        ){
            label
            message
            model{
                ... on Stand{
                    id
                    my_favorite
                }
            }
        }
    }
`;

export const FAVORITE_USER_ADD_DELETE = gql`
    mutation FavoriteAddOrDeleteUser(
        $model_id: ID!
        $model: modelFavorite!
        $description: String
    ){
        favoriteAddOrDelete(
            model_id: $model_id
            model: $model
            description: $description
        ){
            label
            message
            model{
                ...on User{
                    id
                    my_favorite
                }
            }
        }
    }
`;
