import { gql } from '@apollo/client';


export const GET_COMMUNICATIONS = gql`
    query GetCommunications($standId : ID!){
        communications(
            stand_id : $standId
            status : [ waiting, busy ]
        ){
            id
            stand_id
            user_id
            accepted_user_id
            token_jitsi
            status
            exhibitor{
                id
                name
                surname
                avatar
                role
                my_favorite
                stand{
                    id
                    title
                    logo
                }
                meta_fields{
                    id
                    meta_key
                    meta_type
                    meta_value
                    meta_group
                }
                stand_permission{
                    id
                    stand_id
                    user_id
                    visible
                    permission
                    created_at
                    updated_at
                }
            }
            customer{
                id
                name
                surname
                avatar
                role
                my_favorite
                stand{
                    id
                    title
                    logo
                }
                meta_fields{
                    id
                    meta_key
                    meta_type
                    meta_value
                    meta_group
                }
                stand_permission{
                    id
                    stand_id
                    user_id
                    visible
                    permission
                    created_at
                    updated_at
                }
            }
            created_at
            updated_at
            status
            created_at
            updated_at
        }
    }
`;