import React from "react";
import { useMutation } from "@apollo/client";
import { Button, Form, Input } from "antd";

import { UPDATE_USER } from "graphql/mutation/user-gql";

import LanguageSelector from "components/languages";
import { errorNotification, successNotification } from "components/request-result";


const formSubmit = ({ values, updateUserRequest }) => {

    updateUserRequest({
        variables : {
            input: {
                email: values.email,
                locale: values.locale
            }
        }
    }).catch((error) => {
        errorNotification(error);
    });

};

const AccountForm = ({ user }) => {

    const [ form ] = Form.useForm();

    const [updateUserRequest, { loading }] = useMutation(UPDATE_USER,
        {
            update(cache, { data : { userUpdate } }) {

                const { label, message } = userUpdate;

                successNotification({
                    title: label,
                    description: message
                });
            }
        }
    );

    const formLayout = {
        labelCol: { span: 24 },
        wrapperCol: { span: 24 }
    };

    return (
        <Form
            name="user-account"
            form={form}
            {...formLayout}
            onFinish ={(values) => {
                formSubmit({ values, updateUserRequest });
            }}
        >
            <div className="field-group">
                <Form.Item
                    label={ <span>Login E-Mail</span>}
                    name="email"
                    initialValue={ user.email_original }
                    rules={[
                        {
                            type: 'email',
                            message: 'The input is not valid E-mail',
                        },
                        {
                            required: true,
                            message: 'Please input your Email'
                        }
                    ]}
                >
                    <Input name="login-email" />
                </Form.Item>
                <Form.Item
                    label={ <span>Language</span>}
                    name="locale"
                    initialValue={ user.locale }
                >
                    <LanguageSelector />
                </Form.Item>
            </div>
            <div className="actions">
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={ loading }
                >
                    Save
                </Button>
            </div>
        </Form>
    );
};

export default AccountForm;