import React from "react";
import { Form, Input } from "antd";
import { useMutation } from "@apollo/client";

import { STAND_UPLOAD_FILE } from "graphql/mutation/stand-gql";

import { AvatarUpload } from "components/avatar";
import { successNotification } from "components/request-result";


const CPGeneralInfo = ({ form, formLayout, stand }) => {

    const { TextArea } = Input;

    const [ standUploadLogo, { loading } ] = useMutation( STAND_UPLOAD_FILE,
        {
            update(cache, { data }) {

                const { label, message, stand } = data.standMetaUpload;

                form.setFieldsValue({ "logo-image-unset" : stand.logo });

                successNotification({
                    title: label,
                    description: message
                });

            }
        });

    return (
        <Form
            name="general-info"
            form={form}
            {...formLayout}
        >
            <Form.Item
                label={ <span>Company Logo</span>}
                name="logo-image-unset"
                initialValue={ stand.logo }
                rules={ [
                    { required: true, message: 'Please input company logo' }
                ]}
            >
                <AvatarUpload
                    uploadMutation={ standUploadLogo }
                    image={ stand.logo }
                    shape={"square"}
                    loading={ loading }
                    variables={{
                        input: {
                            meta_key : "logo",
                            stand_id : stand.id
                        }
                    }}
                    uploadButtonText="Upload logo"
                />
            </Form.Item>
            <Form.Item
                label={ <span>Company Name</span>}
                name="company_name-field"
                initialValue={ stand.title }
                rules={ [
                    { required: true, message: 'Please input your Company name' }
                ]}
            >
                <Input placeholder="Your company full name" />
            </Form.Item>
            <Form.Item
                label={ <span>Short company description</span>}
                shouldUpdate
                name="description-field"
                initialValue={ stand.description }
                rules={ [
                    { required: true, message: 'Please input description' }
                ]}
            >
                <TextArea
                    rows={3}
                    maxLength={ 200 }
                    showCount={
                        { formatter: ({ count, maxLength }) => maxLength - count}
                    }
                    placeholder="Add a few words about your company"
                />
            </Form.Item>
            <Form.Item
                hidden
                name="status-field"
                initialValue={ stand.status === "draft" ? "offline" : stand.status }
            >
                <Input />
            </Form.Item>
        </Form>
    );
};

export default CPGeneralInfo;