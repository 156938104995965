import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Modal } from "antd";

import { STAND_DELETE_EXHIBITOR } from "graphql/mutation/stand-gql";

import { successNotification } from "components/request-result";
import Icons from "components/icons";

import "./team-delete-user.scss";


const TeamDeleteUser = ({
        userId,
        standId,
        itsMe,
        action = () => {},
        button = {
            type : "cross",
            text :  <Icons.Cross />
        }
}) => {

    const [ showModal, setShowModal ] = useState( false );

    const [standDeleteExhibitor, { loading }] = useMutation( STAND_DELETE_EXHIBITOR,
        {
            update(cache, { data }) {

                const {
                    standDeleteExhibitor : {
                        label,
                        message
                    }
                } = data;

                successNotification({
                    title: label,
                    description: message,
                });

                action(cache);

                setShowModal(false);

            }
        }
    );

    return(
        <>
            <button
                type="button"
                className={ `team-remove-button ${ button.type }` }
                onClick={ () => setShowModal(true) }
            >
                { button.text }
            </button>
            <Modal
                className="team-remove-modal"
                visible={ showModal }
                centered
                maskClosable={ false }
                closeIcon={ <Icons.Cross /> }
                onOk={ () => {
                    standDeleteExhibitor({
                        variables: {
                            standId,
                            userId
                        }
                    })
                }}
                onCancel={ () => setShowModal(false) }
                okButtonProps={{ loading }}
                cancelButtonProps={{
                    type: "default"
                }}
                okText={ itsMe ? "Leave" : "Remove" }
                cancelText="Cancel"
            >
                { itsMe ?
                    <>
                        <p>Are you sure you want to leave the team?</p>
                    </>:
                    <>
                        <p>Are you sure you want to remove this team member?</p>
                    </>
                }
            </Modal>
        </>
    );
};

export default TeamDeleteUser;