import React from 'react';
import Icon from '@ant-design/icons';


const SidebarSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M38.5,15.9H19.7c-1.1,0-2-0.9-2-2s0.9-2,2-2h18.8c1.1,0,2,0.9,2,2S39.6,15.9,38.5,15.9z"/>
        <path d="M19.7,33.1h18.8c1.1,0,2,0.9,2,2s-0.9,2-2,2H19.7c-1.1,0-2-0.9-2-2S18.6,33.1,19.7,33.1z"/>
        <path d="M38.5,26.1H26.5c-1.1,0-2-0.9-2-2s0.9-2,2-2h12.1c1.1,0,2,0.9,2,2S39.6,26.1,38.5,26.1z"/>
        <path d="M16.4,24.2l-8.2-8.9c-0.7-0.8-0.7-2.1,0.1-2.8c0.8-0.7,2.1-0.7,2.8,0.1l9.4,10.3c0.7,0.8,0.7,1.9,0,2.7l-9.4,10.3
		c-0.4,0.4-0.9,0.6-1.5,0.6c-0.5,0-1-0.2-1.3-0.5c-0.8-0.7-0.9-2-0.1-2.8L16.4,24.2z"/>
    </svg>
);

const SidebarIcon = props => {
    return <Icon component={ SidebarSvg } />;

};


export default SidebarIcon;