import React from 'react';
import Icon from '@ant-design/icons';


const SettingsSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M39.4,21l-2.1-0.4c-0.3-0.8-0.6-1.7-1-2.4l1.2-1.8c0.4-0.4,0.6-1,0.6-1.6c0-0.6-0.2-1.2-0.7-1.7l-1.8-1.8
		c-0.9-0.9-2.4-0.9-3.3-0.1l-1.8,1.2c-0.8-0.4-1.6-0.8-2.5-1l-0.4-2.1c-0.1-1.3-1.1-2.3-2.3-2.3h-2.6c-1.3,0-2.3,1-2.3,2.3l-0.4,2.1
		c-0.8,0.3-1.7,0.6-2.5,1l-1.8-1.2c-0.9-0.8-2.4-0.8-3.3,0.1l-1.8,1.8c-0.9,0.9-0.9,2.3-0.1,3.3l1.2,1.8c-0.4,0.8-0.8,1.6-1,2.4
		L8.8,21c-1.3,0.1-2.3,1.1-2.3,2.4v2.6c0,1.3,1,2.3,2.3,2.4l2.1,0.4c0.3,0.8,0.6,1.7,1,2.4l-1.2,1.8c-0.8,0.9-0.8,2.4,0.1,3.3
		l1.8,1.8c0.9,0.9,2.4,0.9,3.3,0.1l1.8-1.2c0.8,0.4,1.6,0.8,2.4,1l0.4,2.1c0.1,1.3,1.1,2.3,2.3,2.3h2.6c1.3,0,2.3-1,2.3-2.3l0.4-2.1
		c0.8-0.3,1.7-0.6,2.4-1l1.8,1.2c0.9,0.8,2.4,0.8,3.3-0.1l1.8-1.8c0.4-0.4,0.7-1,0.7-1.7c0-0.6-0.2-1.2-0.6-1.6l-1.2-1.8
		c0.4-0.8,0.8-1.6,1-2.4l2.1-0.4c1.3-0.1,2.3-1.1,2.3-2.4v-2.6C41.6,22.1,40.6,21,39.4,21z M39.6,25.9c0,0.2-0.2,0.4-0.4,0.4
		c-0.1,0-0.1,0-0.2,0l-2.8,0.5c-0.4,0.1-0.7,0.4-0.8,0.7c-0.3,1.1-0.7,2.1-1.3,3.1c-0.2,0.3-0.2,0.8,0,1.1l1.6,2.3
		c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.2l-1.8,1.8c-0.2,0.2-0.3,0.2-0.5,0c0,0-0.1-0.1-0.1-0.1l-2.3-1.6
		c-0.3-0.2-0.7-0.2-1.1,0c-1,0.6-2,1-3.1,1.3c-0.4,0.1-0.7,0.4-0.7,0.8l-0.5,2.8c0,0.1,0,0.1,0,0.2c0,0.2-0.2,0.4-0.4,0.4h-2.6
		c-0.2,0-0.4-0.2-0.4-0.4c0-0.1,0-0.1,0-0.2L22,36.8c-0.1-0.4-0.4-0.7-0.7-0.8c-1.1-0.3-2.1-0.7-3.1-1.3c-0.2-0.1-0.3-0.1-0.5-0.1
		c-0.2,0-0.4,0.1-0.6,0.2l-2.3,1.6c0,0-0.1,0.1-0.1,0.1c-0.2,0.2-0.3,0.2-0.5,0l-1.8-1.8c-0.1-0.1-0.1-0.4,0-0.5
		c0,0,0.1-0.1,0.1-0.1l1.6-2.3c0.2-0.3,0.2-0.7,0-1.1c-0.6-1-1-2-1.3-3.1c-0.1-0.4-0.4-0.7-0.8-0.7l-2.8-0.5c-0.1,0-0.1,0-0.2,0
		c-0.2,0-0.4-0.2-0.4-0.4v-2.6c0-0.2,0.2-0.4,0.4-0.4c0.1,0,0.1,0,0.2,0l2.8-0.5c0.4-0.1,0.7-0.4,0.8-0.7c0.3-1.1,0.7-2.1,1.3-3.1
		c0.2-0.3,0.2-0.8,0-1.1l-1.6-2.3c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.4,0-0.5l1.8-1.8c0.2-0.2,0.3-0.2,0.5,0c0,0,0.1,0.1,0.1,0.1
		l2.3,1.6c0.3,0.2,0.7,0.2,1.1,0c1-0.6,2-1,3.1-1.3c0.4-0.1,0.7-0.4,0.7-0.8l0.5-2.8c0-0.1,0-0.1,0-0.2c0-0.2,0.2-0.4,0.4-0.4h2.6
		c0.2,0,0.4,0.2,0.4,0.4c0,0.1,0,0.1,0,0.2l0.5,2.8c0.1,0.4,0.4,0.7,0.7,0.8c1.1,0.3,2.1,0.7,3.1,1.3c0.3,0.2,0.8,0.2,1.1,0l2.3-1.6
		c0,0,0.1-0.1,0.1-0.1c0.2-0.2,0.3-0.2,0.5,0l1.8,1.8c0.1,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.2-0.1,0.2c0,0-0.1,0.1-0.1,0.1l-1.6,2.3
		c-0.2,0.3-0.2,0.7,0,1.1c0.6,1,1,2,1.3,3.1c0.1,0.4,0.4,0.7,0.8,0.7l2.8,0.5c0.1,0,0.1,0,0.2,0c0.2,0,0.4,0.2,0.4,0.4V25.9z"/>
        <path d="M24.1,17.2c-4.1,0-7.4,3.3-7.4,7.4S20,32,24.1,32s7.4-3.3,7.4-7.4S28.2,17.2,24.1,17.2z M24.1,30c-3,0-5.4-2.4-5.4-5.4
		c0-3,2.4-5.4,5.4-5.4c3,0,5.4,2.4,5.4,5.4C29.5,27.6,27.1,30,24.1,30z"/>
    </svg>
);

const SettingsIcon = props => {
    return <Icon component={ SettingsSvg } />;

};


export default SettingsIcon;