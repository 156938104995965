import React, { useState } from  "react";

import ProductMediaTypeForm from "./product-media-type-form";
import { Product } from "components/product";
import { metaFields } from "utils";

import "./product-media-type.scss";


const ProductMediaType = ({ product, form, control = false }) => {

    const { normalize, getValue } = metaFields;
    const meta = normalize(product.meta_fields);

    const [ mediaType, setMediaType ] = useState(getValue(meta, "product_media_type", "image"));

    return(
        <>
            { control &&
                <ProductMediaTypeForm
                    form={ form }
                    mediaType={ mediaType }
                    setMediaType={ setMediaType }
                />
            }
            { mediaType === "image" &&
                <Product.MediaSlider
                    product={ product }
                    control={ control }
                />
            }
            { mediaType === "video" &&
                <Product.MediaVideo
                    form={ form }
                    product={ product }
                    control={ control }
                />
            }
        </>
    );
};

export default ProductMediaType;