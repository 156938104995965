import React from "react";

import { AvatarBlock } from "components/avatar";
import { TeamListPermission } from "./side-block";
import { metaFields } from "utils";


const TeamListItem = ({ user, me, type }) => {

    const {
        id,
        email_original,
        meta_fields,
        stand_permission
    } = user;

    const { normalize, getValue } = metaFields;
    const meta = normalize(meta_fields);

    return(
        <div className="team-list-item">
            <div className="left-side">
                <AvatarBlock
                    shape="round"
                    size={ 52 }
                    image={ user.avatar }
                >
                    <p><b>{ user.name } { user.surname } { user.id === me.id ? "(you)" : "" }</b></p>
                    <p className="sub-text">{ getValue(meta, "profile_function") }</p>
                    <p>
                        <a href={ `mailto:${ email_original }` } target="_blank" rel="noreferrer">
                            { email_original }
                        </a>
                    </p>
                </AvatarBlock>
            </div>
            <div className="right-side">
                { type === "permission-control" &&
                    <TeamListPermission
                        userId={ id }
                        standPermission={ stand_permission }
                        itsMe={ user.id === me.id }
                        myPermission={ me.stand_permission }
                    />
                }
            </div>
        </div>
    )

}

export default TeamListItem;