import React from 'react';
import Icon from '@ant-design/icons';


const BookSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M32.7,36.9H14.2c-0.3,0-0.5-0.2-0.5-0.5S14,36,14.2,36h18.4c0.3,0,0.5,0.2,0.5,0.5S32.9,36.9,32.7,36.9z"/>
        <path d="M36.1,10.6h-3V9.1C33.1,8.5,32.6,8,32,8h-4.4c0,0,0,0-0.1,0h-6.1v11l-3-1.6l-3,1.6V8h-1c-1.9,0-3.4,1.5-3.4,3.4v2.7
		c0,0.1,0,0.3,0,0.4v21.8c0,2.1,1.7,3.9,3.9,3.9h21.1c0.6,0,1.1-0.5,1.1-1.1V11.8C37.2,11.1,36.7,10.6,36.1,10.6z M36.3,39
		c0,0.1-0.1,0.2-0.2,0.2H15v0c-1.6,0-3-1.3-3-3c0-1.6,1.3-2.9,2.8-2.9h0.6c0,0,0,0,0,0H32c0.6,0,1.1-0.5,1.1-1.1V11.6h3
		c0.1,0,0.2,0.1,0.2,0.2V39z"/>
    </svg>
);

const AuthorSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <circle cx="24.1" cy="21.1" r="5.3"/>
        <path d="M24.1,8c-9.4,0-17,7.6-17,17s7.6,17,17,17s17-7.6,17-17S33.5,8,24.1,8z M33.7,37.8v-3.3c0-3.6-3-6.6-6.6-6.6h-6
		c-3.6,0-6.6,3-6.6,6.6v3.3c-3.9-2.9-6.4-7.6-6.4-12.8c0-8.8,7.2-16,16-16s16,7.2,16,16C40.1,30.2,37.6,34.8,33.7,37.8z"/>
    </svg>
);

const ServiceSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M14.8,37.6c-0.1,0.1-0.2,0.2-0.4,0.2c-0.2,0-0.3,0-0.4-0.1l-3.4-3l-4.6,6.6c0.7,0.5,1.5,0.8,2.4,0.8h14.4
		c0.8,0,1.5-0.2,2.1-0.6l-5.7-11.5L14.8,37.6z"/>
        <path d="M40.1,7.8H26c-2.3,0-4.2,1.9-4.2,4.2v7.8H8.5c-2.2,0-4,1.8-4,4v14.4c0,0.9,0.3,1.8,0.9,2.4l4.9-6.9
		c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.3,0,0.4,0.1l3.4,2.9l4.6-7.7c0.1-0.2,0.3-0.3,0.4-0.2c0.2,0,0.3,0.1,0.4,0.3l6,12.1
		c0.7-0.7,1.1-1.7,1.1-2.7v-8h13.2c2.3,0,4.2-1.9,4.2-4.2V12C44.2,9.7,42.4,7.8,40.1,7.8z M12.5,29.4c-1.3,0-2.4-1.1-2.4-2.4
		s1.1-2.4,2.4-2.4c1.3,0,2.4,1.1,2.4,2.4S13.8,29.4,12.5,29.4z M43.2,26c0,1.7-1.4,3.2-3.2,3.2H26.8v-5.4c0-2.2-1.8-4-4-4h0V12
		c0-1.7,1.4-3.2,3.2-3.2h14c1.7,0,3.2,1.4,3.2,3.2V26z"/>
        <path d="M33.6,17.8L32.2,17c-0.2-0.1-0.5,0-0.5,0.3v1.6v1.6c0,0.3,0.3,0.4,0.5,0.3l1.4-0.8l1.4-0.8c0.2-0.1,0.2-0.5,0-0.6
		L33.6,17.8z"/>
        <path d="M33.1,13.3c-3,0-5.4,2.4-5.4,5.4c0,3,2.4,5.4,5.4,5.4s5.4-2.4,5.4-5.4C38.5,15.8,36,13.3,33.1,13.3z M33.1,23.2
		c-2.4,0-4.4-2-4.4-4.4c0-2.4,2-4.4,4.4-4.4c2.4,0,4.4,2,4.4,4.4C37.5,21.2,35.5,23.2,33.1,23.2z"/>
        <path d="M12.5,25.6c-0.8,0-1.4,0.6-1.4,1.4s0.6,1.4,1.4,1.4c0.8,0,1.4-0.6,1.4-1.4S13.3,25.6,12.5,25.6z"/>
    </svg>
);


const CreateProductIcon = props => {

    let svg;

    switch(props.type){
        case "book": svg = BookSvg; break;
        case "author": svg = AuthorSvg; break;
        case "service": svg = ServiceSvg; break;
        default: return null;
    }


    return <Icon component={ svg } />;

};


export default CreateProductIcon;