import React from "react";
import { Switch, Route } from 'react-router-dom';

import Pages from "../pages";


const MainRoutes = () => {


    return(
        <Switch>
            <Route path="/" component={ Pages.HomePage } exact/>
            <Route path="/profile/" component={ Pages.ProfilePage } />
            <Route path="/showroom-configurator/" component={ Pages.Showroom.Control } />
            <Route path="/showroom-:standId/"  component={ Pages.Showroom.View } />
            <Route path="/product/:productName" component={ Pages.Product.Page } />
            <Route path="/favorite/" component={ Pages.Favorite.Page } />
            <Route path="/search/" component={ Pages.Search.Page } />
            <Route><>PAGE DOESN'T EXIST</></Route>
        </Switch>
    );
};

export default MainRoutes;