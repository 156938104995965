import React from 'react';
import Icon from '@ant-design/icons';


const EyeSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M41.7,23.6c-6.1-6.3-12.3-9.2-18.6-8.8c-9.3,0.7-15.1,8.6-15.4,8.9c-0.3,0.4-0.2,0.9,0.1,1.2c5.2,5.9,10.8,8.9,16.7,8.9
		c0.1,0,0.2,0,0.4,0c9.8-0.2,16.7-8.6,16.9-9C42,24.5,42,24,41.7,23.6z M9.8,24.3c1.1-1.3,3.8-4.1,7.7-5.9c-1.3,1.6-2.1,3.6-2.1,5.9
		c0,2.4,0.9,4.5,2.4,6.2C15,29.1,12.4,27.1,9.8,24.3z M24.7,31.5c-4,0-7.3-3.3-7.3-7.3c0-4,3.3-7.3,7.3-7.3c4,0,7.3,3.3,7.3,7.3
		C32.1,28.2,28.8,31.5,24.7,31.5z M32,30c1.3-1.6,2.1-3.6,2.1-5.8c0-2-0.6-3.8-1.7-5.3c2.4,1.3,4.8,3.1,7.2,5.4
		C38.4,25.6,35.7,28.2,32,30z"/>
        <path d="M28.2,23.1c0,0.7-0.6,1.3-1.3,1.3s-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3c0.4,0,0.7,0.1,0.9,0.4c-0.7-1-1.8-1.7-3.1-1.7
		c-2.1,0-3.7,1.7-3.7,3.7c0,2.1,1.7,3.7,3.7,3.7s3.7-1.7,3.7-3.7c0-0.5-0.1-1-0.3-1.4C28.2,22.9,28.2,23,28.2,23.1z"/>
    </svg>
);

const EyeProductSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M13.3,24.4c-0.3,0.4-0.2,0.9,0.1,1.2c3.4,3.9,7.1,5.8,10.9,5.8c0.1,0,0.2,0,0.2,0c6.4-0.1,10.9-5.6,11.1-5.8
		c0.3-0.4,0.3-1-0.1-1.3c-4-4.1-8-6-12.2-5.7C17.2,19,13.4,24.2,13.3,24.4z M24.4,29.2c-2.4,0-4.3-1.9-4.3-4.3s1.9-4.3,4.3-4.3
		c2.4,0,4.3,1.9,4.3,4.3S26.8,29.2,24.4,29.2z M18.8,22.1c-0.4,0.8-0.7,1.8-0.7,2.8c0,1,0.3,2,0.7,2.9c-1.2-0.7-2.3-1.7-3.4-2.9
		C16,24.3,17.2,23.1,18.8,22.1z M30.1,27.7c0.4-0.8,0.6-1.8,0.6-2.7c0-0.8-0.2-1.6-0.5-2.4c1,0.7,2.1,1.5,3.1,2.5
		C32.8,25.7,31.6,26.7,30.1,27.7z"/>
        <path d="M26.7,24.2c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9c0.2,0,0.4,0.1,0.6,0.2
		c-0.4-0.6-1.2-1.1-2-1.1c-1.3,0-2.4,1.1-2.4,2.4s1.1,2.4,2.4,2.4c1.3,0,2.4-1.1,2.4-2.4c0-0.3-0.1-0.6-0.2-0.9
		C26.7,24.1,26.7,24.1,26.7,24.2z"/>
        <path d="M39.5,15.2L24.2,7.5c-0.3-0.1-0.6-0.1-0.9,0L8.3,15.2c-0.3,0.2-0.5,0.5-0.5,0.9v16.3c0,0.4,0.2,0.7,0.5,0.9L23.6,42
		c0.2,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.5-0.1l14.9-8.7c0.3-0.2,0.5-0.5,0.5-0.9V16.1C40,15.8,39.8,15.4,39.5,15.2z M38,31.9
		L24.1,40L9.8,31.9V16.7l13.9-7.2L38,16.7V31.9z"/>
    </svg>
);

const EyeIcon = props => {

    if( props.type === "product" ){
        return <Icon component={ EyeProductSvg } className={ props.className } />;
    }

    return <Icon component={ EyeSvg } className={ props.className } />;

};


export default EyeIcon;