import React, { useState } from "react";
import { Popover } from "antd";

import Icons from "components/icons";


const CardPopoverMenu = ( { children } ) => {

    const [popoverVisible, setPopoverVisible] = useState(false);

    return(
        <div className="popover-wrap">
            <Popover
                content={
                    <nav className={ `popover-menu` }>
                        {
                            React.cloneElement( children, {
                                action : () => setPopoverVisible(false)
                            })
                        }
                    </nav>
                }
                overlayClassName={"menu-popover"}
                title={ null }
                trigger={ ["click"] }
                placement="bottomRight"
                visible={ popoverVisible }
                onVisibleChange={ () => setPopoverVisible(!popoverVisible) }
            >
                <button className="popover-button">
                    <Icons.ThreeDot />
                </button>
            </Popover>
        </div>
    )

};

export default CardPopoverMenu;