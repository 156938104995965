import ProductCreateBlock from "./product-create-block";
import ProductDelete from "./product-delete-button";
import ProductGrid from "./product-grid";
import ProductEditForm from "./product-edit-form";
import ProductTopSlider from "./product-top-slider";
import ProductAvatar from "./product-avatar";
import ProductMedia from "./product-media";

import ProductAuthor from "./product-author";
import ProductBook from "./product-book";
import ProductService from "./product-service";

import "./product.scss";


export const Product = {
    Avatar : ProductAvatar,
    CreateBlock : ProductCreateBlock,
    EditForm : ProductEditForm,
    DeleteButton: ProductDelete,
    Grid : ProductGrid,
    TopSlider: ProductTopSlider,
    ...ProductMedia,

    Author: { ...ProductAuthor },
    Book: { ...ProductBook },
    Product: { ...ProductService },
};

