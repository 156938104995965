import React from "react";
import { Link } from "react-router-dom";

import { CardPopoverMenu } from "components/card";
import { UserProfileModal, UserAvatar } from "components/user";
import { FavoriteButton } from "components/favorite";
import Icons from "components/icons";
import { metaFields } from "utils";

import "./card-user-horizontal.scss";


const Menu = ({ action = () => {}, userId, meta, standId, getValue }) => {

    return(
        <ul>
            <li>
                <UserProfileModal
                    userId={ userId }
                    buttonAction={ action }
                />
            </li>
            { getValue(meta, "profile_email") &&
                <li>
                    <a href={ `mailto:${getValue(meta, "profile_email")}` }>
                        Send email
                    </a>
                </li>
            }
            { standId &&
                <li>
                    <Link to={`/showroom-${ standId }/`}>Go to showroom</Link>
                </li>
            }
        </ul>
    )
}

const CardUserHorizontal = ({ user, badge, emptyText }) => {

    const { normalize, getValue } = metaFields;
    const meta = normalize(user?.meta_fields);

    return(
        <div className="card card-horizontal card-user-horizontal">
            { badge }
            { !user ?
                <div className="empty sub-text-bold">
                    <Icons.Avatar />
                    { emptyText }
                </div>
                 :
                <>
                    <UserAvatar
                        user={ user }
                    />
                    <div className="actions-wrap">
                        <CardPopoverMenu>
                            <Menu
                                userId={ user.id }
                                standId={ user.stand[0] &&  user.stand[0].id ?  user.stand[0].id :  undefined }
                                meta={ meta }
                                getValue={ getValue }
                            />
                        </CardPopoverMenu>
                        <FavoriteButton
                            favorite={ user.my_favorite }
                            type={ "User" }
                            id={ user.id }
                        />
                    </div>
                </>
            }
        </div>
    );
};

export default CardUserHorizontal;