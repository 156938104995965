import React from 'react';
import Icon from '@ant-design/icons';


const EmailSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M40.5,12.8c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0c0-0.1-0.1-0.1-0.2-0.1
	c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0H8.8c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0
	c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2
	c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1v23.7c0,0.6,0.4,1,1,1h30.7c0.6,0,1-0.4,1-1V12.9C40.5,12.9,40.5,12.8,40.5,12.8z M36.8,13.9
	L24.2,24.4L11.6,13.9H36.8z M9.8,35.6V15.1l13.7,11.4c0.2,0.2,0.4,0.2,0.6,0.2s0.5-0.1,0.6-0.2l13.7-11.4v20.6H9.8z"/>
    </svg>
);

const EmailIcon = props => {
    return <Icon component={ EmailSvg } />;

};


export default EmailIcon;