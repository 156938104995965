import React, { useState } from "react";
import { Modal } from "antd";

import { useHistory } from "react-router-dom";
import Icons from "components/icons";

import "./product-author-modal.scss";


const ProductAuthorModal = ({ defaultState = false, backLink, children }) => {

    const [showModal, setShowModal] = useState(defaultState);
    const history = useHistory();

    return (
        <Modal
            className={ "product-author-modal" }
            visible={ showModal }
            centered
            width={ "auto" }
            closeIcon={
                <><Icons.Cross /> Close</>
            }
            footer={ null }
            afterClose={ () => {
                history.push(backLink);
            }}
            onOk={() => setShowModal(false)}
            onCancel={() => {
                setShowModal(false)
            }}
        >
        {
            React.cloneElement( children, {
                action : {
                    close : () => setShowModal(false),
                }
            })
        }
        </Modal>
    );

};

export default ProductAuthorModal;