import React from "react";

import { Product } from "components/product";


const ShowroomExtraContent = ({ types = [], control, standProducts, stand  }) => {

    const {
        Author,
        TopSlider
    } = Product;

    return(
        <>
            { types.indexOf("top") !== -1 &&
                <TopSlider
                    control={ control }
                    products={ standProducts }
                    stand={ stand }
                />
            }
            { types.indexOf("author") !== -1 &&
                <Author.Slider
                    control={ control }
                    products={ standProducts }
                />
            }
        </>
    );

};

export default ShowroomExtraContent;