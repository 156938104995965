import React from 'react';
import Icon from '@ant-design/icons';


const DownloadSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M23.2,31.2c0.2,0.3,0.5,0.4,0.8,0.4s0.6-0.2,0.8-0.4l8.2-8.6c0.4-0.4,0.4-1,0-1.4c-0.4-0.4-1-0.4-1.4,0L25,28.1V11.7
		c0-0.6-0.4-1-1-1s-1,0.4-1,1v16.4l-6.6-6.9c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4L23.2,31.2z"/>
        <path d="M39.5,26.4c-0.6,0-1,0.4-1,1v8.3h-28v-8.3c0-0.6-0.4-1-1-1s-1,0.4-1,1v9.3c0,0.6,0.4,1,1,1h30c0.6,0,1-0.4,1-1v-9.3
		C40.5,26.9,40.1,26.4,39.5,26.4z"/>
    </svg>
);

const DownloadIcon = props => {
    return <Icon component={ DownloadSvg } />;

};


export default DownloadIcon;