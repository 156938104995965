import React from 'react';
import Icon from '@ant-design/icons';


const AnalyticsSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <path d="M37.8,9c-0.4-0.4-1-0.4-1.4,0l-8.9,9.3l-7.3-2.6c-0.3-0.1-0.6-0.1-0.9,0.1l-9,6.4c-0.5,0.3-0.6,0.9-0.2,1.4
		c0.2,0.3,0.5,0.4,0.8,0.4c0.2,0,0.4-0.1,0.6-0.2l8.6-6.1l7.4,2.7c0.4,0.1,0.8,0,1.1-0.2l9.3-9.7C38.2,10,38.2,9.4,37.8,9z"/>
        <path d="M11.1,27.3c-0.6,0-1,0.4-1,1V38c0,0.6,0.4,1,1,1s1-0.4,1-1v-9.8C12.1,27.7,11.6,27.3,11.1,27.3z"/>
        <path d="M19.7,23c-0.6,0-1,0.4-1,1v14c0,0.6,0.4,1,1,1s1-0.4,1-1V24C20.7,23.5,20.3,23,19.7,23z"/>
        <path d="M28.1,24.2c-0.6,0-1,0.4-1,1V38c0,0.6,0.4,1,1,1s1-0.4,1-1V25.2C29.1,24.6,28.6,24.2,28.1,24.2z"/>
        <path d="M36.5,17.8c-0.6,0-1,0.4-1,1V38c0,0.6,0.4,1,1,1s1-0.4,1-1V18.8C37.5,18.3,37,17.8,36.5,17.8z"/>
    </svg>
);

const AnalyticsIcon = props => {
    return <Icon component={ AnalyticsSvg } />;

};


export default AnalyticsIcon;