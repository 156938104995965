import React from "react";
import { Switch, Route } from 'react-router-dom';

import Pages from "../pages";


const ProductRoutes = ({ parent = "product", product }) => {

    const { Product : { SubPage } } = Pages;

    return(
        <Switch>
            <Route path={`/${ parent }/:productName`} exact>
                <SubPage.View product={product} />
            </Route>
            <Route path={`/${ parent }/:productName/edit`} exact>
                <SubPage.Edit product={product}/>
            </Route>
        </Switch>
    );
};

export default ProductRoutes;