import React from "react";

import { ContainerWrap } from "components/wraps";
import { AnalyticsMenuBar } from "components/menus";
import { AnalyticsRoutes } from "components/routes";


const ShowroomAnalyticsSubPage = ({ stand }) => {

    return (
        <div className="showroom-analytics-wrap">
            <div className="badge-page-label">
                You are looking at the  showroom analytics
            </div>
            <ContainerWrap className="subpage-wrap">
                <AnalyticsMenuBar/>
                <AnalyticsRoutes standId={ stand.id } />
            </ContainerWrap>
        </div>
    );

};


export default ShowroomAnalyticsSubPage;