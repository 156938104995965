import React from 'react';
import Icon from '@ant-design/icons';


const ThreeDotSvg = () => (
    <svg fill="currentColor"  width="1em" height="1em" viewBox="0 0 48 48" strokeWidth="1" >
        <circle cx="9.7" cy="24.1" r="3.8"/>
        <circle cx="24.3" cy="24.1" r="3.8"/>
        <circle cx="39" cy="24.1" r="3.8"/>
    </svg>
);

const ThreeDotIcon = props => {
    return <Icon component={ ThreeDotSvg } />;

};


export default ThreeDotIcon;